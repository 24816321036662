import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../common/axiosRateLimit'
import qs from "qs";

const sendStats = createAsyncThunk('sendStats',
    async (arg) => {
        const response = await axios.post(
            "/marketing-api/v1/stats/track.json",
            qs.stringify(arg.params)
        )

        return response.data
    })


const uiStatsSlice = createSlice({
    name: 'stats',
    initialState: {},
    reducers: {
        platformChange(state, action) {
            const {platform} = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    tag: "ui.change.platform",
                    platform
                }
            }))
        },
        categoryChange(state, action) {
            const {category} = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    tag: "ui.change.category",
                    category
                }
            }))
        },
        editAdvertiserAlert(state, action) {
            const options = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    'tag': "ui.edit.alert",
                    'type': 'advertiser',
                    'advertiser': options.advertiser,
                    'link': options.link,
                    'country': options.countries,
                    'types': options.types,
                    'pubTo': options.publishTo,
                    'period': options.emailPeriod
                }
            }))
        },
        createAdvertiserAlert(state, action) {
            const options = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    'tag': "ui.create.alert",
                    'type': 'advertiser',
                    'advertiser': options.advertiser,
                    'link': options.link,
                    'country': options.countries,
                    'types': options.types,
                    'pubTo': options.publishTo,
                    'period': options.emailPeriod
                }
            }))
        },
        createAdvertiserBookmark(state, action) {
            const options = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    'tag': "ui.create.bookmark",
                    'type': 'advertiser',
                    'history_token': options.link,
                    'value': options.advertiser
                }
            }))
        },
        editKeywordAlert(state, action) {
            const options = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    'tag': "ui.edit.alert",
                    'type': 'keyword',
                    'keyword': options.keyword,
                    'link': options.link,
                    'country': options.countries,
                    'types': options.types,
                    'pubTo': options.publishTo,
                    'period': options.emailPeriod
                }
            }))
        },
        createKeywordAlert(state, action) {
            const options = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    'tag': "ui.create.alert",
                    'type': 'keyword',
                    'keyword': options.keyword,
                    'link': options.link,
                    'country': options.countries,
                    'types': options.types,
                    'pubTo': options.publishTo,
                    'period': options.emailPeriod
                }
            }))
        },
        createKeywordBookmark(state, action) {
            const options = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    'tag': "ui.create.bookmark",
                    'type': 'keyword',
                    'history_token': options.link,
                    'value': options.keyword
                }
            }))
        },
        generateProfileReport(state, action) {
            const options = action.payload
            action.asyncDispatch(sendStats({
                params: {
                    'tag': "ui.create.report",
                    'type': 'profile',
                    'link': options.link,
                    'platformId': options.platform,
                    'countryId': options.country,
                    'range': options.dateRange
                }
            }))
        }
    }
})


export const {
    platformChange, categoryChange,
    editAdvertiserAlert, createAdvertiserAlert, createAdvertiserBookmark,
    editKeywordAlert, createKeywordAlert, createKeywordBookmark,
    generateProfileReport
} = uiStatsSlice.actions

export default uiStatsSlice.reducer