import axios from '../../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import qs from "qs";
import {networkIsAllByNetwork} from "../../../../misc/allByNetwork";
import {resetTopAdsDestinationUrls} from "../../../top-ads/destinationUrlSlice";
import {resetTopAdsPublishers} from "../../../top-ads/topPublishersSlice";
import {resetTopAdsChannels} from "../../../top-ads/topChannelsSlice";


const fetchPublisherUIOwnCreativesTopAds = createAsyncThunk('fetchPublisherUIOwnCreativesTopAds',
    async (arg) => {

        let ownNetwork = arg.params.network
        if (ownNetwork === 'allByNetwork') {
            ownNetwork = 'all'
        }

        let response = await axios.post("/data-api/ads",
            qs.stringify({
                publisher: arg.params.ownProperties,
                network: ownNetwork,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: arg.params.metrics,
                sortBy: 'sumAdSpend',
                sortOrder: 'desc',
                noAdInfo: 'true',
                rows: 2500
            })
        )

        if ('rows' in response.data) {
            let ownSet = new Set(response.data.rows.map((item) => item.adHash))

            return {
                status: 'ok',
                adHashes: Array.from(ownSet)
            }
        }
        else {
            return {
                status: 'error'
            }
        }
    })


const fetchPublisherUIAdTrends = createAsyncThunk('fetchPublisherUIAdTrends',
    async (arg) => {
        if (!arg.ads.length) {
            return
        }

        const isAllByNetwork = networkIsAllByNetwork(arg.query.network)

        const entities = (ad) => {
            const result = {
                adHash: ad.adHash
            }
            if (isAllByNetwork) {
                result.network = ad.network
            }
            return result
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.ads.map(entities))
        }

        params.metrics += ',globalSeenDates'

        delete params.pageNumber
        delete params.pageSize
        delete params.adSize
        delete params.sortBy
        delete params.sortOrder
        delete params.adType
        delete params.q
        delete params.queryField
        delete params.minFirstSeen

        if (isAllByNetwork) {
            delete params.network
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params
        })
        return response.data
    })

const fetchPublisherUiTopAds = createAsyncThunk('fetchPublisherUiTopAds',
    async (arg) => {
        const params = {
            publisher: arg.query.publisherSet,
            pageNumber: arg.query.pageNumber,
            pageSize: arg.query.pageSize,
            country: arg.query.country,
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            platform: arg.query.platform,
            metrics: arg.query.metrics,
            sortBy: arg.query.sortBy,
            sortOrder: arg.query.sortOrder,
            network: arg.query.network,
            adType: arg.query.adType,
            adSize: arg.query.adSize
        }

        if (arg.query.q.length) {
            params.q = arg.query.q
            params.queryField = arg.query.queryField
        }

        if (arg.query.advertisers) {
            params.advertiser = arg.query.advertisers
        }

        if (arg.query.minFirstSeen) {
            params.minFirstSeen = arg.query.minFirstSeen
        }

        let response = await axios.get("/data-api/ads", {
            params: params
        })

        if ('rows' in response.data) {
            arg.dispatch(fetchPublisherUIAdTrends({
                ads: response.data.rows,
                query: {
                    ...params
                }
            }))

            response.data.rows = response.data.rows.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendLine: {
                    globalDaysSeen: 0
                }
            }))
        }

        return response.data
    })


const initialState = {
    current: {
        ads: {},
        status: 'idle'
    },
    ownAds: [],
    status: 'idle',
    noData: false
}


const creativesSlice = createSlice({
    name: 'creatives',
    initialState,
    reducers: {
        resetCreatives(state, action) {
            action.asyncDispatch(resetTopAdsDestinationUrls())
            action.asyncDispatch(resetTopAdsPublishers())
            action.asyncDispatch(resetTopAdsChannels())
            return initialState
        },
        resetCurrentAds(state) {
            state.current.ads = {}
            state.current.status = 'idle'
        }
    },
    extraReducers: {
        [fetchPublisherUIOwnCreativesTopAds.rejected]: (state) => {
            state.status = 'done'
            state.noData = true
            state.ownAds = []
        },
        [fetchPublisherUIOwnCreativesTopAds.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchPublisherUIOwnCreativesTopAds.fulfilled]: (state, action) => {
            state.status = 'done'

            if (action.payload.status === 'ok') {
                state.ownAds = action.payload.adHashes
                state.noData = false
            } else {
                state.ownAds = []
                state.noData = true
            }
        },
        [fetchPublisherUiTopAds.pending]: (state, action) => {
            state.current.ads = {}
            state.current.status = 'pending'
        },
        [fetchPublisherUiTopAds.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.current.ads = {}
                state.current.status = 'error'
            } else if ('cancel' in action.payload) {
                state.current.ads = {}
                state.current.status = 'cancel'
            } else {
                state.current.ads = action.payload
                state.current.status = 'done'

                state.current.ads.rows.forEach(item => {
                    item.network = item.network || action.meta.arg.query.network
                })

                state.current.ads.rows = state.current.ads.rows.filter((item) => {
                    return !state.ownAds.includes(item.adHash)
                })
            }
        },
        [fetchPublisherUiTopAds.rejected]: (state, action) => {
            state.current.ads = {}
            if ('message' in action.error) {
                if (action.error.message !== 'cancel') {
                    state.current.status = action.error.message
                }
            }
            else {
                state.current.status = 'error'
            }
        },
        [fetchPublisherUIAdTrends.fulfilled]: (state, action) => {
            if (state.current.ads.rows) {
                state.current.ads.rows = state.current.ads.rows.map(row => {
                    const trendline = action.payload.rows
                        .filter(tr => {
                            return Object.keys(tr.entity).every(function (key) {
                                return tr.entity[key] === row[key];
                            });
                        }).shift() || {
                        globalDaysSeen: 0
                    }

                    let trendlineStatus = 'done'
                    if (!trendline.globalDaysSeen) {
                        trendlineStatus = 'idle'
                    }

                    return {
                        ...row,
                        trendlineStatus,
                        trendLine: trendline
                    }
                })
            }
        }
    }
})

const selectCreativesData = (state) => state.publisherUiCreatives.creatives

export default creativesSlice.reducer

export const {
    resetCreatives, resetCurrentAds
} = creativesSlice.actions

export {
    fetchPublisherUIOwnCreativesTopAds,
    fetchPublisherUiTopAds,
    selectCreativesData
}
