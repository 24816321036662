import platforms from './platformsSlice'
import countries from './countriesSlice'
import networks from './networksSlice'
import adTypes from './adTypeSlice'
import adSizes from './adSizeSlice'
import {combineReducers} from '@reduxjs/toolkit'

export default combineReducers({
    platforms,
    countries,
    networks,
    adTypes,
    adSizes
})