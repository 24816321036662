// eslint-disable-next-line
import selectize from 'selectize'
import jquery from 'jquery'

const getOnInitialize = (options, element) => () => {
    if (!options.showSearch) {
        var next = jquery(element).next();
        next.find('div.selectize-input > input')
            .prop('disabled', 'disabled');
        
        next.find('div.selectize-input')
            .css('cursor', 'pointer');
    }
}

export { getOnInitialize }