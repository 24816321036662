import qs from 'qs'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from '../../../common/axiosRateLimit'

const fetchNetworks = createAsyncThunk('advProfile_fetch_menu_networks',
    async (arg) => {
        const query = {
            ...arg.query,
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
        }

        const response = await axios.get('/menu-api/networks?' + qs.stringify(query))

        return response.data
    })

const networksSlice = createSlice({
    name: 'adv-profile-network-menu',
    initialState: {
        status: 'idle',
        networks: []
    },
    reducers: {
        resetNetworks: (state) => {
            state.status = 'idle'
            state.networks = []
        }
    },
    extraReducers: {
        [fetchNetworks.pending]: (state, action) => {
            state.status = 'pending'
            state.networks = []
        },
        [fetchNetworks.fulfilled]: (state, action) => {
            state.networks = action.payload.rows
            state.status = 'done'
        },
        [fetchNetworks.rejected]: (state, action) => {
            state.networks = []
            state.status = 'failed'
        }
    }
})

const selectNetworks = (state) => state.advertiserProfile.menu.networks

export {fetchNetworks, selectNetworks}
export const {resetNetworks} = networksSlice.actions

export default networksSlice.reducer