function addCategory(categories, category) {
    var children = Array.prototype.slice.call(arguments, 2);

    if (children.length === 0 && !categories[category]) {
        categories[category] = null;
    } else {
        categories[category] = categories[category] || {};
    }

    if (children.length > 0) {            
        addCategory.apply(null, [categories[category]].concat(children));
    }
}

function parseCategories (data) {
    var categories = {};
    data.sort(function (a, b) {
        return a.suggestion.localeCompare(b.suggestion);
    }).forEach(function (c) {
        var parts = c.suggestion.split('/');
    addCategory.apply(null, [categories].concat(parts.slice(1)));
    });
    return categories;
}

function formatCategories(categories, list, parentData) {
    var record = {}, key, currentData;
    parentData = parentData || '';
    
    for (key in categories) {
        if (categories.hasOwnProperty(key)) {
            currentData = parentData + '/' + key;
            record = {
                label: key,
                data: currentData,
                children: []
            };
            
            list.push(record);

            formatCategories(categories[key], record.children, currentData);
        }
    }
}

function convertCategories(categories) {
    var formatted = { data: [] }
    formatCategories(categories, formatted.data)
    return formatted;
}

/**
 * Converts flat category list into a nested structure conforming
 * to curation API's response format
 */
function nestedCategories(data) {
    var categories = parseCategories(data);
    var formatted = convertCategories(categories);
    return formatted.data;
}

export default nestedCategories

