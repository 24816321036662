import {
    currencyIdByCountry,
    useSettings as useSettingsForCurrency
} from '../misc/currency'

let settings
const useSettings = (s) => {
    settings = s
    useSettingsForCurrency(s)
}

const codes = [
    {
        subst: '{ad metric}',
        adSpend: 'ad spend',
        adImpressions: 'impressions',
        sumAdSpend: 'ad spend',
        sumImpressions: 'impressions',
    },
    {
        subst: '{Ad Metric}',
        adSpend: 'Ad Spend',
        adImpressions: 'Impressions',
        sumAdSpend: 'Ad Spend',
        sumImpressions: 'Impressions',
    },
    {
        subst: '{Metric}',
        adSpend: 'Spend',
        adImpressions: 'Impressions',
        sumAdSpend: 'Spend',
        sumImpressions: 'Impressions',
    },
    {
        subst: '{Ad Mtrc}',
        adSpend: 'Spend',
        adImpressions: 'Impr',
        sumAdSpend: 'Spend',
        sumImpressions: 'Impr',
    },
    {
        subst: '{$}',
        adSpend: '$',
        adImpressions: '',
        sumAdSpend: '$',
        sumImpressions: '',
    },
    {
        subst: '{dollars}',
        adSpend: '{currency abbr}',
        adImpressions: 'Impressions',
        sumAdSpend: '{currency abbr}',
        sumImpressions: 'Impressions',
    },
    {
        subst: '{currency abbr}',
        adSpend: currencyIdByCountry,
        adImpressions: '',
        sumAdSpend: currencyIdByCountry,
        sumImpressions: '',
    }
];

const fields = {
    sumAdSpend: {
        adImpressions: 'sumImpressions',
        adSpend: 'sumAdSpend',
        sumAdSpend: 'sumAdSpend',
        sumImpressions: 'sumImpressions'
    },
    adSpend: {
        sumAdSpend: 'adSpend',
        sumImpressions: 'adImpressions'
    }
};

const format = function (value, adMetricId, extraQualifier) {
    var metric = adMetricId || settings.adMetricId, code, i;
    for (i = 0; i < codes.length; i++) {
        code = codes[i];
        if (typeof code[metric] === 'object') {
            value = value.replace(
                code.subst,
                code[metric][extraQualifier]);
        } else {
            value = value.replace(code.subst, code[metric]);
        }
    }
    return value;
}

const field = function (f, adMetricId) {
    if (fields[f]) {
        return fields[f][adMetricId || settings.adMetricId];
    }
    return f;
}
const spendMetrics = Object.keys(fields)


export {format, field, spendMetrics, useSettings}
