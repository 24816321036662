import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchDestinationUrls = createAsyncThunk('fetchTopAdDestinationUrls',
    async (arg) => {
        const response = await axios.get("/data-api/destinationUrls", {
            params: {
                ...arg.query,
            }
        })

        return response.data
    })

const destinationUrlsSlice = createSlice({
    name: "topAdsDestinationUrls",
    initialState: {},
    reducers: {
        resetTopAdsDestinationUrls(state) {
            return {}
        }
    },
    extraReducers: {
        [fetchDestinationUrls.pending]: (state, action) => {
            let key = action.meta.arg.query.adHash + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'pending'
            }
        },
        [fetchDestinationUrls.fulfilled]: (state, action) => {
            let key = action.meta.arg.query.adHash + action.meta.arg.query.network
            if ('errors' in action.payload) {
                state[key] = {
                    rows: [],
                    status: 'error'
                }
            } else {
                state[key] = {
                    rows: action.payload.rows,
                    status: 'done'
                }
            }
        },
        [fetchDestinationUrls.rejected]: (state, action) => {
            let key = action.meta.arg.query.adHash + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'error'
            }
        }
    }
})

export const selectDestinationUrls = (adHash, network) => (state) => {
    let key = adHash + network
    if (key in state.topAds.destinationUrls) {
        return state.topAds.destinationUrls[key]
    } else {
        return {
            rows: [],
            status: 'idle'
        }
    }
}

export const {resetTopAdsDestinationUrls} = destinationUrlsSlice.actions
export {fetchDestinationUrls}
export default destinationUrlsSlice.reducer