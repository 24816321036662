import React, {useEffect} from 'react'
import Products from '../navigation/Products'
import PublisherUISwitch from '../navigation/PublisherUISwitch'
import logo from '../../img/logo.svg'
import GearMenu from './GearMenu'
import Tooltip from "../Tooltip";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchInboxUnread,
    selectInboxCountUnread
} from "../../pages/inbox/inboxSlice";
import UniversalSearch from "../searchbox/UniversalSearch";

const Header = (props) => {
    const dispatch = useDispatch()
    const inboxUnread = useSelector(selectInboxCountUnread)
    const inboxUnreadText = inboxUnread.countUnread > 0 ? Math.min(99, inboxUnread.countUnread) : ''

    useEffect(() => {
        if (inboxUnread.status === 'idle') {
            dispatch(fetchInboxUnread())
        }
    })

    const isSearchPage = window.location.pathname.match(/^\/search/)

    return (
        <header>
            <div id="header-wrapper">
                <div id="logo" className="nav-item">
                    <a className="logo-big left" href="/">
                        <img src={logo}
                             alt="Adbeat logo text" width="80"/>
                    </a>
                </div>
                <div className="separator left"/>
                <Products isSearchShown={props.isSearchShown}/>

                <PublisherUISwitch
                    settings={props.settings}/>

                <div id="tools" className="right">
                    <div id="notifications-button"
                         data-tip
                         data-for={'inbox-title'}
                         className="tool left pointer"
                         onClick={() => {
                             window.location = '/inbox'
                         }}>
                        <div
                            id="num-notifications">{inboxUnread.status !== 'done' ? '..' : inboxUnreadText}</div>
                    </div>

                    <div className="separator left"/>

                    <GearMenu/>
                </div>

                <div id="searchbox" className={"searchbox-control" + (isSearchPage ? "" : " header")}>
                    <UniversalSearch
                        placeholder={
                            isSearchPage
                                ? "Enter advertiser, publisher, keyword or campaign"
                                : "Enter search term"
                        }
                    />
                </div>
            </div>
            <Tooltip
                id="inbox-title"
                place="bottom"
            >
                You
                have {inboxUnread.countUnread ? inboxUnread.countUnread : 'no'} unread
                messages in your Inbox
            </Tooltip>
        </header>
    )
}

export default Header