import advertisers from './advertisers/advertiserGroupsSlice'
import publishers from './publishers/publisherGroupsSlice'
import keywords from './keywords/keywordGroupsSlice'
import networks from './networks/networkGroupsSlice'
import {combineReducers} from '@reduxjs/toolkit'

const groupManagerReducer = combineReducers({
    advertisers,
    publishers,
    keywords,
    networks
})

export default groupManagerReducer
