import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'

const fetchAdvertiserCategories = createAsyncThunk('fetchAdvertiserCategories',
    async () => {
        let path = '/callisto/categories-info-tool/categories';

        const response = await axios.get(path)
        return response.data
    })


const advertiserCategoryToolsSlice = createSlice({
    name: 'advertiserCategoriesTool',
    initialState: {
        status: 'idle',
        categories: [],
        errors: [],
    },
    reducers: {
        resetErrors(state) {
            state.errors = []
        }
    },
    extraReducers: {
        [fetchAdvertiserCategories.rejected]: (state) => {
            state.errors = ["Cannot load categories"]
        },
        [fetchAdvertiserCategories.fulfilled]: (state, action) => {
            state.status = 'done'

            let result = {};
            action.payload.hits.forEach(function (item) {
                result[item.category] = {
                    'advertisers': item.advertisers
                }
            });
            state.categories = result
        }
    }
})


const selectAdvertiserCategoriesState = (state) => state.advertiserCategoriesTool

export {
    selectAdvertiserCategoriesState,
    fetchAdvertiserCategories
}

export const {
    resetErrors
} = advertiserCategoryToolsSlice.actions

export default advertiserCategoryToolsSlice.reducer