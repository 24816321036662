import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchSuggestedSearches = createAsyncThunk('fetchSuggestedSearches',
    async (arg) => {
        const response = await axios.get('/callisto/related-searches')
        return response.data
    })

const suggestedSearchSlice = createSlice({
    name: 'suggestedSearches',
    initialState: {
        status: 'idle',
        searches: []
    },
    extraReducers: {
        [fetchSuggestedSearches.pending]: (state, action) => {
            state.status = 'pending'
            state.searches = []
        },
        [fetchSuggestedSearches.fulfilled]: (state, action) => {
            state.status = 'done'
            state.searches = action.payload.relatedSearches
        }
    }
})

const selectSuggestedSearches = (state) => state.searches.suggestedSearches

export {fetchSuggestedSearches, selectSuggestedSearches}

export default suggestedSearchSlice.reducer