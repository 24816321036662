function networkIsAllByNetwork(network) {
    return ['allByNetwork', 'native', 'standard'].includes(network)
}

function getAllFromAllByNetwork(network) {
    let allNetwork = {
        'allByNetwork': 'all',
        'native': 'allNative',
        'standard': 'allStandard'
    }

    if (allNetwork.hasOwnProperty(network)) {
        return allNetwork[network]
    }
    return network
}

export {networkIsAllByNetwork, getAllFromAllByNetwork}