import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from '../common/axiosRateLimit'
import qs from 'qs'
import _ from 'lodash'

const fetchBookmark = createAsyncThunk('fetchBookmark',
    async (arg) => {
        const response = await axios.get(`/callisto/is-bookmarked/${
            arg.query.type}?path=${
                window.encodeURIComponent(arg.query.link)}`)

        return response.data
    })

const fetchFollowStatus = createAsyncThunk('fetchFollowStatus',
    async (arg) => {
        const response = await axios.get(`/callisto/following/${
            arg.query.profileName}/${arg.query.profile}?history_token=${
                window.encodeURIComponent(arg.query.link)}`)

        return response.data
    })

const addBookmark = createAsyncThunk('addBookmark',
    async (arg) => {
        const response = await axios.post('/callisto/bookmark/add',
            qs.stringify({
                history_token: arg.query.link,
                type: arg.query.type,
                value: arg.query.entity,
                tags: ''
            }))

        if (arg.dispatch) {
            arg.dispatch(resetFollowing())
        }
        return response.data
    })

const deleteBookmark = createAsyncThunk('deleteBookmark',
    async (arg) => {
        const response = await axios.get(`/callisto/delete-bookmark/${
                arg.query.type}?path=${window.encodeURI(arg.query.link)}`)

        if (arg.dispatch) {
            arg.dispatch(resetFollowing())
        }

        return response.data
    })

const bookmarkSlice = createSlice({
    name: 'bookmark',
    initialState: {
        bookmark: {},
        following: {}
    },
    reducers: {
        resetFollowing(state, action) {
            state.following = {}
        },
        resetBookmark(state, action) {
            state.bookmark = {}
        }
    },
    extraReducers: {
        [fetchBookmark.fulfilled]: (state, action) => {
            const bookMarkFor = action.meta.arg.query
            state.bookmark[bookMarkFor.link] = {
                isBookmarked: true,
                status: 'done'
            }
        },
        [fetchFollowStatus.pending]: (state, action) => {
            let key = action.meta.arg.query.profileName + '|' + action.meta.arg.query.profile
            if (state.following.hasOwnProperty(key)) {
                state.following[key]['status'] = 'pending'
            } else {
                state.following[key] = {
                    'status': 'pending',
                    'following': false
                }
            }
        },
        [fetchFollowStatus.rejected]: (state, action) => {
            let key = action.meta.arg.query.profileName + '|' + action.meta.arg.query.profile
            state.following[key] = {
                'status': 'done',
                'following': false
            }
        },
        [fetchFollowStatus.fulfilled]: (state, action) => {
            let key = action.meta.arg.query.profileName + '|' + action.meta.arg.query.profile
            state.following[key] = {
                'status': 'done',
                'following': action.payload.status === 'followed'
            }
        },
        [fetchBookmark.rejected]: (state, action) => {
            const bookMarkFor = action.meta.arg.query
            state.bookmark[bookMarkFor.link] = {
                isBookmarked: false,
                status: 'done'
            }
        },
        [addBookmark.fulfilled]: (state, action) => {
            const bookMarkFor = action.meta.arg.query
            state.bookmark[bookMarkFor.link] = {
                isBookmarked: true,
                status: 'idle'
            }
        },
        [deleteBookmark.fulfilled]: (state, action) => {
            const bookMarkFor = action.meta.arg.query
            state.bookmark[bookMarkFor.link] = {
                isBookmarked: false,
                status: 'idle'
            }
        }
    },
})

const bookmarkSelector = (path) => (state) => {
    if (_.isEmpty(state.bookmark.bookmark) ||
        _.isEmpty(state.bookmark.bookmark[path])) {
        return {
            isBookmarked: false,
            status: 'idle'
        }
    }
    return state.bookmark.bookmark[path]
}

const followStatusSelector = (profileName, profile) => (state) => {
    let key = profileName + '|' + profile
    if (_.isEmpty(state.bookmark.following) ||
        _.isEmpty(state.bookmark.following[key])) {
        return {
            'status': 'idle',
            'following': false
        }
    }
    return state.bookmark.following[key]
}

export const {resetFollowing, resetBookmark} = bookmarkSlice.actions

export {fetchBookmark, addBookmark, deleteBookmark,
    fetchFollowStatus, bookmarkSelector, followStatusSelector}

export default bookmarkSlice.reducer