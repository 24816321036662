import ads from './topAdsSlice'
import advertisers from './topAdvertisersSlice'
import destinationUrls from "./destinationUrlSlice";
import publishers from "./topPublishersSlice";
import channels from './topChannelsSlice'
import {combineReducers} from '@reduxjs/toolkit'

const topAdsReducer = combineReducers({
    ads,
    advertisers,
    destinationUrls,
    publishers,
    channels
})


const topAdsMainReducer = (state, action) => {
    if (action.type === 'topAds/reset') {
        // setting state to undefined clears it
        // it will clear only advertisers state
        state = undefined
    }

    return topAdsReducer(state, action)
}

export default topAdsMainReducer