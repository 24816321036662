class Countries {
    constructor(settings, allowed_countries) {
        function markAllowed(list, allowed) {
            var allowedKeys = {};

            allowed.forEach(function (item) {
                allowedKeys[item.id] = true;
            });

            return list.map(function (item) {
                return {
                    ...item,
                    allowed: allowedKeys[item.id] || false
                }
            });
        }

        this.getNameById = function (id) {
            var items = this.list, i, len;
            for (i = 0, len = items.length; i < len; i++) {
                if (items[i].id === id) {
                    return items[i].name;
                }
            }
            return '';
        }.bind(this);

        this.groups = [{
            'name': 'Popular:',
            'values': ['all', 'us', 'gb', 'de']
        }, {
            'name': 'Other:',
            'values': 'other'
        }];

        this.groupsAllNotPopular = [{
            'name': 'Popular:',
            'values': ['us', 'gb', 'de']
        }, {
            'name': 'Other:',
            'values': 'other'
        }]

        this.getAvailableCountries = function (platformId) {
            var result = null;
            if (platformId === 'desktop' || platformId === 'all') {
                result = settings.desktop_countries.map(function (item) {
                    return item.id;
                });
            }
            else {
                result = settings.mobile_countries.map(function (item) {
                    return item.id;
                });
            }
            return result;
        };

        this.isAllowedCountryForPlatform = function (platformId, countryId, platforms) {
            if (!countryId) {
                return settings.allowed.platforms.some(function (item) {
                    return item.id === platformId;
                });
            }
            if (!platformId) {
                return settings.allowed.countries.some(function (item) {
                    return item.id === countryId;
                }) || settings.allowed.mobile_countries.some(function (item) {
                    return item.id === countryId;
                });
            }

            if (!platforms.isAllowed(platformId)) {
                return false;
            }

            if (countryId === 'all') {
                return settings.adv_profile_country_all;
            }

            if (platformId === 'all') {
                return settings.allowed.countries.some(function (item) {
                    return item.id === countryId;
                }) &&
                    settings.allowed.mobile_countries.length &&
                    (
                        !settings.mobile_countries.some(function (item) {
                            return item.id === countryId;
                        }) ||
                        settings.allowed.mobile_countries.some(function (item) {
                            return item.id === countryId;
                        })
                    );
            }
            if (platformId === 'desktop') {
                return settings.allowed.countries.some(function (item) {
                    return item.id === countryId;
                });
            }
            return settings.allowed.mobile_countries.some(function (item) {
                return item.id === countryId;
            });
        };

        const countries = settings.countries.slice(0)
        countries.sort(function (a, b) {
            return a.id.localeCompare(b.id);
        });
        this.list = countries;
        this.list = markAllowed(this.list, allowed_countries);
    }
}

export default Countries
