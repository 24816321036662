import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchRecentSearches, selectRecentSearches} from "./recentSearchesSlice";
import processSearchResults from './processSearchResults'
import useTooltip from "../../hooks/useTooltip";
import {Scrollbars} from "react-custom-scrollbars";

const RecentSearchesTopLeftWidget = (props) => {
    const tooltip = useTooltip('navigation-recent_searches')
    const searches = useSelector(selectRecentSearches)
    const dispatch = useDispatch()

    let shownSearches = function () {
        let rv = searches.searches;
        return rv.map(processSearchResults)
    }


    useEffect(() => {
        if (searches.status === 'idle') {
            dispatch(fetchRecentSearches())
        }
    })

    return (
        <div className={shownSearches().length > 0 ? '' : 'hidden'}>
            <div className="recent-searches">
                <div className="left">
                    <h2 className="">Recent History</h2>
                </div>

                <Scrollbars
                    autoHeight
                    autoHeightMax={window.innerHeight - 495}
                >
                    <ul className="clear">
                        {shownSearches().map((search, idx) =>
                            <li key={idx}>
                                <a href={search.link}>
                                    <span data-tip data-for={tooltip.path}
                                          className="tooltipstered term_type font-semi-bold">{search.term_type.charAt(0)}</span>
                                    <span
                                        title={search.tooltip}>{search.title}</span>
                                </a>
                                {idx === 0 ? tooltip.component : ""}
                            </li>
                        )}
                    </ul>
                </Scrollbars>
            </div>
        </div>
    );
}

export default RecentSearchesTopLeftWidget;