import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'
import {networkIsAllByNetwork} from "../../misc/allByNetwork";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdTrends = createAsyncThunk('fetchTopAdTrendlines',
    async (arg) => {
        if (!arg.ads.length) {
            return
        }

        const isAllByNetwork = networkIsAllByNetwork(arg.query.network)

        const entities = (ad) => {
            const result = {
                adHash: ad.adHash
            }
            if (isAllByNetwork) {
                result.network = ad.network
            }
            return result
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.ads.map(entities))
        }

        params.metrics += ',globalSeenDates'

        delete params.pageNumber
        delete params.pageSize
        delete params.adSize
        delete params.sortBy
        delete params.sortOrder
        delete params.adType
        delete params.q
        delete params.queryField
        delete params.minFirstSeen

        if (isAllByNetwork) {
            delete params.network
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })

const fetchTopAds = createAsyncThunk('fetchTopAds',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        const params = {
            pageNumber: arg.query.pageNumber,
            pageSize: arg.query.pageSize,
            country: arg.query.country,
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            platform: arg.query.platform,
            metrics: arg.query.metrics,
            sortBy: arg.query.sortBy,
            sortOrder: arg.query.sortOrder,
            network: arg.query.network,
            adType: arg.query.adType,
            adSize: arg.query.adSize
        }

        if (arg.query.q.length) {
            params.q = arg.query.q
            params.queryField = arg.query.queryField
        }

        if (arg.query.advertisers.size) {
            params.advertiser = [...arg.query.advertisers].join(',')
        }

        if (arg.query.minFirstSeen) {
            params.minFirstSeen = arg.query.minFirstSeen
        }

        let response = await axios.get("/data-api/ads", {
            params: params,
            cancelToken: getCancelSource().token
        })

        if ('rows' in response.data) {
            arg.dispatch(fetchAdTrends({
                ads: response.data.rows,
                query: {
                    ...params
                }
            }))

            response.data.rows = response.data.rows.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendLine: {
                    globalDaysSeen: 0
                }
            }))
        }

        return response.data
    })

const topAdsSlice = createSlice({
    name: 'topAds',
    initialState: {
        ads: {},
        status: 'idle'
    },
    reducers: {
        reset(state) {
            state.ads = {}
            state.status = 'idle'
        },
        accessDenied(state) {
            state.ads = {}
            state.status = 'forbidden'
        },
        serverError(state) {
            state.ads = {}
            state.status = 'error'
        }
    },
    extraReducers: {
        [fetchTopAds.pending]: (state, action) => {
            state.ads = {}
            state.status = 'pending'
        },
        [fetchTopAds.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.ads = {}
                state.status = 'error'
            } else {
                state.ads = action.payload
                state.status = 'done'

                state.ads.rows.forEach(item => {
                    item.network = item.network || action.meta.arg.query.network
                })
            }
        },
        [fetchTopAds.rejected]: (state, action) => {
            state.ads = {}
            if ('message' in action.error) {
                if (action.error.message !== 'cancel') {
                    state.status = action.error.message
                }
            }
            else {
                state.status = 'error'
            }
        },
        [fetchAdTrends.fulfilled]: (state, action) => {
            if (state.ads.rows) {
                state.ads.rows = state.ads.rows.map(row => {
                    const trendline = action.payload.rows
                        .filter(tr => {
                            return Object.keys(tr.entity).every(function (key) {
                                return tr.entity[key] === row[key];
                            });
                        }).shift() || {
                        globalDaysSeen: 0
                    }

                    let trendlineStatus = 'done'
                    if (!trendline.globalDaysSeen) {
                        trendlineStatus = 'idle'
                    }

                    return {
                        ...row,
                        trendlineStatus,
                        trendLine: trendline
                    }
                })
            }
        }
    }
})

const selectTopAds = (state) => state.topAds.ads

export const {
    reset, accessDenied, serverError,
    requestCancel
} = topAdsSlice.actions

export {selectTopAds, fetchTopAds}

export default topAdsSlice.reducer