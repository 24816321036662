import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../common/axiosRateLimit'
import qs from "qs";

const fetchAlerts = createAsyncThunk('fetchAlerts',
    async () => {
        let path = '/callisto/alert/list';

        const response = await axios.get(path, {
            params: {
                'rows': 100,
                'start': 0
            }
        })
        return response.data
    })

const removeAlert = createAsyncThunk('removeAlert',
    async (arg) => {
        let path = '/callisto/alert/delete';

        const response = await axios.post(path,
            qs.stringify({advertiser: arg.advertiser})
        )
        return response.data
    })

const removeKeywordAlert = createAsyncThunk('removeAlert',
    async (arg) => {
        let path = '/callisto/keyword-alert/delete';

        const response = await axios.post(path,
            qs.stringify({keyword: arg.keyword})
        )
        return response.data
    })

const fetchSuggestions = createAsyncThunk('fetchSuggestions',
    async () => {
        let path = '/callisto/alerts/suggested-advertisers';

        const response = await axios.get(path)
        return response.data
    })



const alertsSlice = createSlice({
    name: 'alerts',
    initialState: {
        alerts: {
            status: 'idle',
            hits: [],
            numHits: 0,
        },
        suggestions: {
            status: 'idle',
            hits: [],
        },
        removeAlert: {
            status: 'idle',
        },
        removeKeywordAlert: {
            status: 'idle'
        },
        errors: [],
    },
    reducers: {
        resetErrors(state) {
            state.errors = []
        },
        resetAlerts(state) {
            state.alerts.status = 'idle'
            state.suggestions.status = 'idle'
            state.removeAlert.status = 'idle'
            state.removeKeywordAlert.status = 'idle'
        }
    },
    extraReducers: {
        [fetchAlerts.rejected]: (state) => {
            state.errors = ["Cannot load alerts"]
            state.alerts = []
        },
        [fetchAlerts.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.alerts.status = 'done'
                state.alerts.hits = action.payload.hits
                state.alerts.numHits = action.payload.numHits
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
            else {
                state.errors = ['Cannot load alerts']
            }
        },
        [fetchSuggestions.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.suggestions.status = 'done'
                state.suggestions.hits = action.payload.advertisers.map((item) => {
                    return {
                        ...item,
                        key: item.term
                    }
                })
            }
        },
        [removeAlert.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.removeAlert.status = 'done'
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
            else {
                state.errors = ['Occurred problem with removing alert']
            }
        },
        [removeKeywordAlert.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.removeKeywordAlert.status = 'done'
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
            else {
                state.errors = ['Occurred problem with removing alert']
            }
        }
    }
})


const selectAlerts = (state) => state.alerts.alerts
const selectAlertErrors = (state) => state.alerts.errors
const selectAdvertiserSuggestions = (state) => state.alerts.suggestions
const selectRemoveAlertStatus = (state) => state.alerts.removeAlert
const selectRemoveKeywordAlertStatus = (state) => state.alerts.removeKeywordAlert

export {
    selectAlerts, selectAlertErrors, selectAdvertiserSuggestions,
    selectRemoveAlertStatus,
    selectRemoveKeywordAlertStatus,
    fetchAlerts, fetchSuggestions, removeAlert, removeKeywordAlert
}

export const {
    resetErrors, resetAlerts
} = alertsSlice.actions

export default alertsSlice.reducer

