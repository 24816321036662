import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, upgradeModalSelector, showModal } from "../../reducers/upgradeModal";
import UpgradeScreen from "../upgrade/UpgradeScreen";
import _ from 'lodash'

const modalName = 'upgrade-modal'

const UpgradeModal = () => {
    const modal = useSelector(upgradeModalSelector)
    const dispatch = useDispatch()
    const [scrollHeight, setScrollHeight] = useState(document.body.scrollHeight)
    
    const handleClose = () => {
        dispatch(hideModal())
    }

     useEffect(() => {
        const onScroll = _.throttle(() => {
            setScrollHeight(document.body.scrollHeight)
        }, 300)
        window.addEventListener('scroll', onScroll)
    })
    
    if (modal.name === modalName) {
        return <ReactModal
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "fixed",
                    height: scrollHeight
                },
                content: {
                    borderRadius: 0,
                    width: "890px",
                    margin: "0 auto",
                    position: "fixed",
                    padding: 0,
                    overflow: 'hidden',
                    bottom: "auto",
                    inset: "80px 40px auto 40px",
                },
            }}
            isOpen={modal.name === modalName}>
            <div className="upgrade-required-modal">
            <div className="section-header">
                <div className="right close">
                    <i className="fa-light fa-sharp fa-close" onClick={handleClose}></i>
                </div>
            </div>
            <UpgradeScreen {...modal.params}></UpgradeScreen>
            </div>
        </ReactModal>
        }
    return null;
}

const showUpgradeModal = (dispatch, params) => {
    dispatch(showModal({
        name: modalName,
        params
    }))
}
export {showUpgradeModal}
 
export default UpgradeModal;