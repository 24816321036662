import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'
import qs from "qs";


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchAdvertiserATotalSpend = createAsyncThunk('fetchAdvertiserATotalSpend',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        const response = await axios.post("/data-api/advertisers",
            qs.stringify({
                advertiser: arg.params.advertiser,
                network: arg.params.network,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: arg.params.metrics,
                rows: 1
            }), {
                cancelToken: getCancelSource().token
            }
        )
        return response.data
    })


const initialState = {
    advertiserA: {
        status: 'idle',
        data: {
            sumAdSpend: 0,
            uniquePublishers: 0,
            uniqueNetworks: 0
        }
    }
}


const publisherUiHeaderSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        resetHeader(state) {
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()

            return initialState
        },
    },
    extraReducers: {
        [fetchAdvertiserATotalSpend.rejected]: {},
        [fetchAdvertiserATotalSpend.pending]: (state) => {
            state.advertiserA.status = 'pending'
        },
        [fetchAdvertiserATotalSpend.fulfilled]: (state, action) => {
            if ('rows' in action.payload && action.payload.rows.length > 0) {
                state.advertiserA.data = action.payload.rows[0]
                state.advertiserA.status = 'done'
            } else {
                state.advertiserA.status = 'error'
            }
        }
    }
})


const selectPublisherUiHeader = (state) => state.publisherUiOverview.header


export {
    selectPublisherUiHeader,
    fetchAdvertiserATotalSpend
}

export const {
    resetHeader
} = publisherUiHeaderSlice.actions


export default publisherUiHeaderSlice.reducer