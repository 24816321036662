import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingState from "../../components/LoadingState";
import useTooltip from "../../hooks/useTooltip";
import { fetchTopChannels, selectTopChannels } from "./topChannelsSlice";

import { settingsSelector } from "../../reducers/settings"
import { guessFormat } from "../../misc/currency";
import UpgradeScreen from "../../components/upgrade/UpgradeScreen";
import qs from 'qs'
import { showUpgradeModal } from "../../components/modals/UpgradeModal";
import ProfileLink from "../../components/ProfileLink";

const TopChannels = (props) => {
  const tooltip = useTooltip('top_channels')
  const tooltipDownload = useTooltip('download_excel_file_button')
  const settings = useSelector(settingsSelector)
  const dispatch = useDispatch()
  const channels = useSelector(selectTopChannels(props.adHash, props.network))
  const publisher = props.publisher || 'all'

  useEffect(() => {
    if (channels.status === 'idle') {
      const query = {
        publisher,
        adHash: props.adHash,
        startDate: props.startDate,
        endDate: props.endDate,
        pageNumber: 1,
        pageSize: 20,
        metrics: 'sumAdSpend,sumImpressions',
        sortBy: 'sumAdSpend',
        sortOrder: 'desc',
        advertiser: props.advertiser,
        network: props.network,
        platform: props.platform,
        country: props.country
      }
      if (props.campaign) {
        query.campaign = props.campaign
      }
      if (props.brand) {
        query.brand = props.brand
      }
      dispatch(fetchTopChannels({
        query
      }))
    }
  })

  const handleReportClick = () => {
    if (!settings.has_video) {
      showUpgradeModal(dispatch, {
        name: 'adTypeVideo',
        value: true
      })
      return;
    }
    if (settings.exportable_data) {
      const query = {
        country: props.country,
        platform: props.platform,
        advertiser: props.advertiser,
        adHash: props.adHash,
        startDate: props.startDate,
        endDate: props.endDate,
        network: props.network
      }
      if (props.campaign) {
        query.campaign = props.campaign
      }
      if (props.brand) {
        query.brand = props.brand
      }
      if (props.publisher) {
        query.publisher = props.publisher
      }
      window.location.href = '/callisto/reports/adChannels?' +
        qs.stringify(query)
    } else {
      showUpgradeModal(dispatch, {
        name: 'reports',
        value: null
      })
    }
  }

  if (!props.show) {
    return null
  }

  return (
    <div
      className="channels unfoldable"
    >
      {settings.has_video &&
        <LoadingState show={channels.status !== 'done' && channels.status !== 'error'}></LoadingState>}
      <h3 className="left">Top Channels</h3>
      <div
        className="help-icon left tooltipstered"
        data-tip
        data-for={tooltip.path}
      >
        <i className="fa-sharp fa-light fa-circle-question fa-2x"></i>
      </div>
      <div
        className="help-icon left tooltipstered"
        onClick={handleReportClick}
        data-tip
        data-for={tooltipDownload.path}
        data-value={(settings.exportable_data ? '' :
          settings.upgradeRequiredValue) + "exportable-data"}
      >
        <i className="fa-2x fa-light fa-sharp fa-circle-arrow-down"></i>
      </div>
      {!settings.has_video &&
      <div className="upgrade-required-container"><UpgradeScreen name="adTypeVideo" value={true}></UpgradeScreen></div>
      }
      {settings.has_video &&
        <table className="clear">
          <thead>
            <tr>
              <th></th><th>Spend</th><th>Impr</th>
            </tr>
          </thead>
          <tbody>
            {channels.rows.map(channel => (
              <tr key={channel.channel}>
                <td className="publisher">
                  <ProfileLink
                    profileId={channel.channel}
                    profileName="channel"
                    country={props.country}
                    platform={props.platform}
                    profileLinkText={channel.publisherName}
                    showExternalLinkOnHover={true}
                  />
                </td>
                <td className="capitalized">
                  {guessFormat(channel.sumAdSpend, '$0.[0]a', props.country)}</td>
                <td className="capitalized">
                  {guessFormat(channel.sumImpressions, '0.[0]a')}</td>
              </tr>))}
          </tbody>
        </table>}
      {tooltip.component}
      {tooltipDownload.component}
    </div>
  );
}

export default TopChannels;