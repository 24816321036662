import React, {useState, useRef} from "react"
import CountryList from "../CountryList";
import {useSelector} from "react-redux";
import {settingsSelector} from "../../reducers/settings";
import PlatformList from "../PlatformList";
import UniversalSearchSearch from "./UniversalSearchSearch";


const UniversalSearch = (props) => {
    const settings = useSelector(settingsSelector)
    const [country, setCountry] = useState(
        window.localStorage.getItem('searchCountry') ||
        settings.defaultCountry)
    const [platform, setPlatform] = useState(settings.defaultPlatform)

    const headerId = props.headerId || 'header-search'

    const [inputFocus, setInputFocus] = useState(false)
    const [selectInputFocus, setSelectFocus] = useState(false)

    const anyInputFocus = inputFocus || selectInputFocus

    const onFocus = () => {
        setSelectFocus(true)
    }

    const onBlur = () => {
        setSelectFocus(false)
    }

    const onInputFocus = () => {
        setInputFocus(true)
    }

    const onInputBlur = () => {
        setInputFocus(false)
    }

    const searchContainerRef = useRef()

    return (
        <div className="search-wrapper" id={headerId} ref={searchContainerRef}>
            <div className="countryIdMenu">
            </div>
            <div className="searchTerm right filter">
                <UniversalSearchSearch
                    anyInputFocus={anyInputFocus}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    country={country}
                    platform={platform}
                    placeholder={props.placeholder}
                    searchContainerRef={searchContainerRef}
                />
            </div>
            <CountryList
                value={country}
                onChange={(c) => {
                    window.localStorage.setItem('searchCountry', c)
                    setCountry(c)
                }}
                extraClass={"countryId right block filter" + (anyInputFocus ? " active" : "")}
                options={{
                    onFocus: onFocus,
                    onBlur: onBlur,
                    dropdownParent: '#' + headerId + ' .countryIdMenu',
                }}
            />
            <PlatformList
                value={platform}
                onChange={setPlatform}
                extraClass={"platformId right filter block" + (anyInputFocus ? " active" : "")}
                options={{
                    onFocus: onFocus,
                    onBlur: onBlur
                }}
            />
        </div>
    )
}


export default UniversalSearch