import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../common/axiosRateLimit'
import _ from 'lodash'


function javaStringHash(s) {
  var hash = 0, i, chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr   = s.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return (hash + 0x80000000) / 0xFFFFFFFF;
}

function autoLogo(profileId) {
    if (!profileId) {
        return
    }

    var colors = [
        '065471', '0a91ab', '217756', '335c49', '3498db', '3a4874',
        '3cb29a', '583c87', '63b75d', '678c40', '6db3b5', '91ca62',
        '951556', '955a47', 'ab1328', 'b1bd5d', 'bdbe36', 'e45171',
        'f2676a', 'f8aa27', 'f9cc7b', 'ffacac', 'ffc045'
    ];
    var baseURL = 'https://s3.amazonaws.com/adimages.adbeat.com' +
        '/curation-auto-logo/{letter}-{color}.png';

    var letter = profileId[0].toLowerCase();
    var color = colors[Math.floor(javaStringHash(profileId) * colors.length)];

    var url = baseURL.replace('{letter}', letter);
    url = url.replace('{color}', color);
    return url;
}

const fetchAdvertiserInfo = createAsyncThunk('fetchAdvertiserInfo',
    async (arg) => {
        const response = await axios.get(
            `/curation/advertiser_info?advertiserId=${arg.advertiser}`)

        return response.data
    })

const defaultLogos = (profileId) => {
    const logoURLs = []
    logoURLs.push(
        'https://logo.clearbit.com/' + profileId);
    logoURLs.push(autoLogo(profileId));
    return logoURLs
}

const curationSlice = createSlice({
    name: 'curation',
    initialState: {
        advertiserInfo: {},
    },
    extraReducers: {
        [fetchAdvertiserInfo.pending]: (state, action) => {
            const advertiser = action.meta.arg.advertiser
            state.advertiserInfo[advertiser] = {
                data: {},
                fullName: advertiser,
                logoURLs: defaultLogos(advertiser),
                status: 'pending'
            }
        },
        [fetchAdvertiserInfo.fulfilled]: (state, action) => {
            const advertiser = action.meta.arg.advertiser
            const data = action.payload
            let logoURLs = []
            if (data.advertiser.info.logo) {
                logoURLs.push(data.advertiser.info.logo);
            }
            logoURLs = logoURLs.concat(defaultLogos(advertiser))

            const fullName = data.advertiser.info.name ||
                data.advertiser.name;

            state.advertiserInfo[advertiser] = {
                data: action.payload,
                fullName,
                logoURLs,
                status: 'done'
            }
        },
        [fetchAdvertiserInfo.rejected]: (state, action) => {
            const advertiser = action.meta.arg.advertiser
            state.advertiserInfo[advertiser] = {
                data: {},
                fullName: advertiser,
                logoURLs: defaultLogos(advertiser),
                status: 'done'
            }
        }
    },
})

const advertiserInfoSelector = (advertiser) => (state) => {
    if (_.isEmpty(advertiser) ||
        _.isEmpty(state.curation.advertiserInfo) ||
        _.isEmpty(state.curation.advertiserInfo[advertiser])) {
        return {
            data: {},
            fullName: advertiser,
            logoURLs: defaultLogos(advertiser),
            status: 'idle'
        }
    }
    return state.curation.advertiserInfo[advertiser]
}

export {fetchAdvertiserInfo, advertiserInfoSelector, autoLogo}

export default curationSlice.reducer