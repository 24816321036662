import {combineReducers} from '@reduxjs/toolkit'
import recentSearches from './recentSearchesSlice'
import frequentSearches from './frequentSearchesSlice'
import suggestedSearches from './suggestedSearchesSlice'
import rowSpends from './singleRowSearchAdSpend'
import actions from './searchActions'

export default combineReducers({
    recentSearches,
    frequentSearches,
    suggestedSearches,
    actions,
    rowSpends
})