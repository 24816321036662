import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserProfileTotals = createAsyncThunk(
    'advertiserOverviewTotals',
    async (arg) => {
        const request = axios.get("/data-api/advertisers", {
            params: {
                ...arg.query,
                rows: 1,
                metrics: arg.settings.metricsList
            },
            cancelToken: getCancelSource().token
        })

        let query = arg.query
        let entity = {}
        let keys = ['advertiser', 'publisher', 'channel', 'brand', 'campaign']
        keys.forEach(key => {
            if (query.hasOwnProperty(key)) {
                entity[key] = query[key]
                delete query[key]
            }
        })

        const trendlineRequest = axios.get("/data-api/trendlines", {
            params: {
                ...query,
                includeEntities: JSON.stringify(
                    [entity]),
                metrics: 'globalSeenDates',
            },
            cancelToken: getCancelSource().token
        })

        const response = await request
        const trendlineResponse = await trendlineRequest
        let result = response.data
        if (response.data.rows.length > 0 && trendlineResponse.data.rows.length > 0) {
            result.rows[0].globalFirstSeen = trendlineResponse.data.rows[0].globalFirstSeen
            result.rows[0].globalLastSeen = trendlineResponse.data.rows[0].globalLastSeen
        }
        return result
    })


const advertiserProfileTotalsSlice = createSlice({
    name: 'advertiserProfileTotals',
    initialState: {
        status: 'idle',
        data: {},
    },
    reducers: {
        resetTotals(state) {
            state.status = 'idle'
            state.data = {}
        }
    },
    extraReducers: {
        [fetchAdvertiserProfileTotals.rejected]: (state, action) => {
        },
        [fetchAdvertiserProfileTotals.pending]: (state) => {
            state.status = 'loading'
            state.data = {}
        },
        [fetchAdvertiserProfileTotals.fulfilled]: (state, action) => {
            state.status = 'done'
            if (action.payload.rows.length > 0) {
                state.data = action.payload.rows[0]
            }
            else {
                state.data = {}
            }
        }
    }
})


const advProfileTotalsSelector = state => state.advertiserProfile.totals
export { fetchAdvertiserProfileTotals, advProfileTotalsSelector }
export const {resetTotals} = advertiserProfileTotalsSlice.actions
export default advertiserProfileTotalsSlice.reducer
