import {shortenCampaignSuggestion} from '../../common/campaigns'
import Categories from "../../common/categories";
import NetworkListModel from "../../common/networks";

export default function processSearchResults(in_item) {
    let category;
    let settings = window.AdbeatSettings
    let categories = new Categories({has_categories: true})
    const networksList = new NetworkListModel(settings.networks, [], {}, settings)

    let item = {...in_item}

    if (item.term_type === 'category') {
        category = categories.byValue(item.term);
        item.title = category ? category.fullName() : '';
        item.tooltip = item.title;
    } else if (item.term_type === 'campaign') {
        item.title = shortenCampaignSuggestion(item.term, 20);
        item.tooltip = item.term;
    } else {
        item.title = item.term;
        item.tooltip = item.title;
    }

    if (!item.link) {
        let networkItem;

        if (item.term_type === 'network') {
            networkItem = networksList.list.filter(i => i.name === item.term).shift()
            if (networkItem) {
                item.network = networkItem.id
            } else {
                item.network = item.term
            }
        }

        if (item.term_type === 'campaign' || item.term_type === 'brand') {
            let parts = item.term.match(/(.*) \((.+?)\)/)
            if (parts) {
                item[item.term_type] = parts[1]
                item['advertiser'] = parts[2]
            }
            else {
                item[item.term_type] = item.term
                item['advertiser'] = ''
            }
        }
    }

    if (['advertiser', 'publisher', 'campaign'].includes(item.term_type)) {
        item.key = [
            item.term,
            item.platform,
            item.country,
            item.term_type
        ].join('|');
    }
    else {
        item.key = null;
    }

    return item;
}