import React, {useState} from 'react'
import LoadingState from '../../components/LoadingState'
import AdIcon from '../../pages/top-ads/AdIcon'
import {showAdModal} from "../../components/modals/AdModal";
import {useDispatch} from "react-redux";

const SmallAd = (props) => {
    const [previewLoaded, setPreviewLoaded] = useState(false)
    const hasImage = ('preview' in props.ad.adInfo)
    const dispatch = useDispatch()

    const width = () => props.ad.preview.width
    const height = () => props.ad.preview.height
    const ratio = () => width() / height()

    const cropTop = () => (ratio() * height() >= width()) ? 'crop-top' : ''
    const cropLeft = () => (ratio() * height() < width()) ? 'crop-left' : ''

    const handleLoadedPreview = () => {
        setPreviewLoaded(true)
    }

    const handleShowAdModal = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const ad = {...props.ad}
        delete ad.protocolRelativeImageUrl
        delete ad.protocolRelativeVideoUrl

        showAdModal(dispatch, {
            ad,
            country: props.country,
            platform: props.platform,
            publisher: props.publisher,
            channel: props.channel,
            network: props.network,
            startDate: props.startDate,
            endDate: props.endDate,
            campaign: props.campaign,
            brand: props.brand
        })
    }

    return (
        <div className={`${props.isTiny ? 'tiny-ad' : ''} left`}
             data-tip="Click for details about this ad." data-for="generic"
             onClickCapture={handleShowAdModal}
        >
            <LoadingState
                show={hasImage && previewLoaded === false}></LoadingState>

            {hasImage &&
            <>
                <img src={props.ad.adInfo.preview['64x64']}
                     width={props.width}
                     height={props.height}
                     className={`${cropTop} ${cropLeft}`}
                     onLoad={handleLoadedPreview}
                     alt="Ad Thumbnail"/>
                <AdIcon adType={props.ad.adInfo.adType}></AdIcon>
            </>
            }

            {props.ad.adInfo.adType === 'text' &&
            <div className="text-wrapper">
                <p className="ad-title">{props.ad.adInfo.header}</p>
                <p className="ad-body">{props.ad.adInfo.body}</p>
                <p className="ad-display-url">
                    <a href={'http://' + props.ad.adInfo.dispayUrl}>
                        {props.ad.adInfo.dispayUrl}
                    </a>
                </p>
            </div>
            }
            {props.ad.adInfo.adType !== 'text' && !hasImage && (
              <span
                  style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      textAlign: 'center',
                      marginTop: 20,
                      fontSize: 10
                  }}
                  onClick={handleShowAdModal}
                  className="crop-top"
              >Preview Unavailable
              </span>
          )}
        </div>
    )
}

export default SmallAd