import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../../common/axiosRateLimit";
import qs from "qs";
import translateBookmark from "./translateBookmark";
import {rangeFor} from "../../components/DatePicker";
import {daysToDate} from "../../common/crawlDays";


const fetchBookmarkTrendline = createAsyncThunk('fetchBookmarkTrendline',
    async (arg) => {
        let settings = arg.settings
        let bookmark = arg.bookmark

        const [startDate, endDate] = rangeFor(settings.defaultDateRange,
            daysToDate(settings.first_index_date),
            daysToDate(settings.last_index_date))

        let entity = {}

        switch (bookmark.entityType) {
            case 'advertiser':
                entity.advertiser = bookmark.entityId;
                break;
            case 'publisher':
                entity.publisher = bookmark.entityId;
                break;
            case 'campaign':
                entity.advertiser = bookmark.entityId;
                break;
            default:
                entity = null;
        }

        if (!entity) {
            return null;
        }

        let query = {
            metrics: settings.metricsList,
            startDate: startDate,
            endDate: endDate,
            country: bookmark.country,
            platform: bookmark.platform,
            includeEntities: JSON.stringify([entity])
        }

        const response = await axios.get("/data-api/trendlines", {
            params: query,
        })

        let data = response.data
        if (!data.rows || !data.rows.length) {
            return null;
        }

        return data.rows[0]
    })


const fetchBookmarks = createAsyncThunk('fetchBookmarks',
    async (arg) => {
        let path = '/callisto/bookmark/list';

        const response = await axios.get(path)
        response.data.hits = response.data.hits.map((item) => {
            return translateBookmark(item, arg.settings)
        })
        return response.data
    })

const removeBookmark = createAsyncThunk('removeBookmark',
    async (arg) => {
        let path = '/callisto/bookmark/delete';

        const response = await axios.post(path,
            qs.stringify({id: arg.id})
        )
        return response.data
    })

const editBookmark = createAsyncThunk('editBookmark',
    async (arg) => {
        let path = '/callisto/bookmark/edit';

        const response = await axios.post(path,
            qs.stringify(arg.query)
        )
        return response.data
    })


const bookmarksSlice = createSlice({
    name: 'bookmarks',
    initialState: {
        bookmarks: {
            status: 'idle',
            hits: [],
            numHits: 0,
        },
        editBookmark: {
            status: 'idle'
        },
        removeBookmark: {
            status: 'idle'
        },
        requestedBookmarkTrendlines: {},
        errors: [],
    },
    reducers: {
        resetErrors(state) {
            state.errors = []
        }
    },
    extraReducers: {
        [fetchBookmarks.rejected]: (state) => {
            state.errors = ["Cannot load bookmarks"]
            state.bookmarks.hits = []
            state.bookmarks.status = 'done'
        },
        [fetchBookmarks.pending]: (state) => {
            state.bookmarks.status = 'pending'
        },
        [fetchBookmarks.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.bookmarks.status = 'done'
                state.bookmarks.hits = action.payload.hits
                state.bookmarks.numHits = action.payload.numHits
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
            else {
                state.errors = ['Cannot load bookmarks']
            }
        },
        [editBookmark.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.editBookmark.status = 'done'
                state.bookmarks.hits = state.bookmarks.hits.map((item) => {
                    if (item.id === action.meta.arg.query.id) {
                        return {
                            ...item,
                            tags: action.payload.bookmark.tags
                        }
                    }
                    return item
                })
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
            else {
                state.errors = ['Occurred problem with removing bookmark']
            }
        },
        [removeBookmark.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.removeBookmark.status = 'done'
                state.bookmarks.hits = state.bookmarks.hits.filter((item) => {
                    return item.id !== action.meta.arg.id
                })
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
            else {
                state.errors = ['Occurred problem with removing bookmark']
            }
        },
        [fetchBookmarkTrendline.pending]: (state, action) => {
            let bookmark = action.meta.arg.bookmark
            let key = bookmark.entityKey
            state.requestedBookmarkTrendlines[key] = {
                trendlineStatus: 'loading'
            }
        },
        [fetchBookmarkTrendline.fulfilled]: (state, action) => {
            let bookmark = action.meta.arg.bookmark
            let key = bookmark.entityKey
            state.requestedBookmarkTrendlines[key] = {
                trendLine: action.payload,
                trendlineStatus: 'done'
            }
        }
    }
})


const selectBookmarks = (state) => state.bookmarks.bookmarks
const selectBookmarkTrendlines = (entityKey) => (state) => {
    if (state.bookmarks.requestedBookmarkTrendlines.hasOwnProperty(entityKey)) {
        return state.bookmarks.requestedBookmarkTrendlines[entityKey]
    }
    return {
        trendlineStatus: 'idle'
    }
}

export {
    selectBookmarks, selectBookmarkTrendlines,
    fetchBookmarks, editBookmark, removeBookmark, fetchBookmarkTrendline
}

export const {
    resetErrors
} = bookmarksSlice.actions


export default bookmarksSlice.reducer