import GroupSliceFactory from "../../../tools/groupManager/groupSlice";
import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'
import qs from "qs";

const chooseOwnedSet = createAsyncThunk('chooseOwnedSet',
    async (arg) => {
        let response = await axios.get("/callisto/choose-owned-set", {
            params: {
                id: arg.id
            }
        })

        return response.data
    })

class OwnedSitesFactory extends GroupSliceFactory {
    constructor(entity, entities) {
        const fetchGroups = createAsyncThunk('fetchOwnGroups',
            async () => {
                const response = await axios.get("/callisto/owned-publisher-groups")
                return response.data
            })
        const addGroup = createAsyncThunk('addPublisherOwnGroup',
            async (arg) => {
                let path = '/callisto/publisher-groups/add';

                let query = {
                    ...arg.query,
                    is_owned: true,
                    name: 'Unnamed Group'
                }

                query['publishers'] = ''

                const response = await axios.post(path, qs.stringify(query))
                return response.data
            })

        super(entity, entities, {fetchGroups, addGroup})

        this.selectGroupsData = (state) => {
            return state.publisherUiCompetitiveSets.ownedSites
        }
    }
}

const ownedSitesSlice = new OwnedSitesFactory('publisher', 'publishers')

export {
    ownedSitesSlice,
    chooseOwnedSet
}

export default ownedSitesSlice.slice.reducer
