import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchAdTags = createAsyncThunk('fetchTopAdvertiserAdTags',
    async (arg) => {
        const response = await axios.get("/data-api/adPaths", {
            params: {
                ...arg.query,
            }
        })

        return response.data
    })

const adTagsSlice = createSlice({
    name: "topAdsAdTags",
    initialState: {},
    extraReducers: {
        [fetchAdTags.pending]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'pending'
            }
        },
        [fetchAdTags.rejected]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'idle'
            }
        },
        [fetchAdTags.fulfilled]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: action.payload.rows,
                status: 'done'
            }
        },
    }
})

export const selectAdTags = (advertiser, network) => (state) => {
    let key = advertiser + network
    if (key in state.topAdvertisers.adTags) {
        return state.topAdvertisers.adTags[key]
    } else {
        return {
            rows: [],
            status: 'idle'
        }
    }
}

export {fetchAdTags}
export default adTagsSlice.reducer