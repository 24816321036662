import byNetwork from './spend/networksSlice'
import byPublisher from './spend/publisherSlice'
import summaryPublisher from './summary/publisherSlice'
import summary from './summary/summarySlice'
import spend from './spend/spendSlice'
import dma from './dma/dmaSlice'
import {combineReducers} from '@reduxjs/toolkit'

const overviewReducer = combineReducers({
    byNetwork,
    byPublisher,
    summaryPublisher,
    summary,
    spend,
    dma
})


export default overviewReducer
