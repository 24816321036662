import {useEffect, useState} from "react";
import {convert, pickCurrency, symbol, unconvert} from "../../misc/currency";
import {showUpgradeModal} from "../modals/UpgradeModal";
import {useDispatch, useSelector} from "react-redux";
import {settingsSelector} from "../../reducers/settings";
import usePrevious from "../../hooks/usePrevious";
import FormError from "./FormError";

const alertSettings = {
    ad: {spend: 5000, days: 30, eventType: 'newAd'},
    network: {spend: 5000, days: 30, eventType: 'newNetwork'},
    campaign: {spend: 5000, days: 30, eventType: 'newCampaign'},
    publisher: {spend: 5000, days: 30, eventType: 'newPublisher'}
}

const AlertEventSettings = (props) => {
    const settings = useSelector(settingsSelector)
    const dispatch = useDispatch()

    const alertType = props.type
    const modifyEventTypes = props.modifyEventTypes

    const options = alertSettings[alertType]
    const entityTitle = options.eventType.replace('new', '')

    const alert = props.alert

    const [value, setValue] = useState(false)

    const [useSpend, setUseSpend] = useState(false)
    const useSpendPrev = usePrevious(useSpend)

    const [inputSpend, setInputSpend] = useState('')
    const [useDuration, setUseDuration] = useState(false)
    const useDurationPrev = usePrevious(useDuration)

    const [duration, setDuration] = useState('')

    useEffect(() => {
        if (alert) {
            setValue(alert.eventTypes.includes(options.eventType))
            setUseSpend(alert[alertType + '_spend'] !== null)
            setUseDuration(alert[alertType + '_duration'] !== null)
            if (alert[alertType + '_spend']) {
                setInputSpend(alert[alertType + '_spend'])
            }
            if (alert[alertType + '_duration']) {
                setDuration(alert[alertType + '_duration'])
            }
        } else {
            setValue(false)
            setUseSpend(false)
            setUseDuration(false)
            setInputSpend('')
            setDuration('')
        }
    }, [alert, alertType, options.eventType])

    const writeSpend = (value) => {
        setInputSpend(Math.round(unconvert(value)))
    }

    const readSpend = () => {
        return Math.round(convert(inputSpend))
    }

    useEffect(() => {
        if (!value) {
            setUseSpend(false)
            setInputSpend('')
            setUseDuration(false)
            setDuration('')
        }
    }, [value])

    useEffect(() => {
        if (useSpend !== useSpendPrev) {
            writeSpend(useSpend ? inputSpend || options.spend : 0)
        }
    }, [useSpend, options.spend, inputSpend, useSpendPrev])

    useEffect(() => {
        if (useSpend !== useSpendPrev) {
            writeSpend(useSpend ? inputSpend || options.spend : 0)
        }
    }, [useSpend, options.spend, inputSpend, useSpendPrev])

    useEffect(() => {
        if (useDurationPrev !== useDuration) {
            setDuration(useDuration ? duration || options.days : '')
        }
    }, [useDuration, options.days, duration, useDurationPrev])

    const smartAlertDataValue = settings.has_smart_alerts ? 'smart_alerts' :
        'upgrade-required-smart_alerts'

    const alertsLimit = !props.canAddAlerts && alert === null

    const checkLimit = (event) => {
        if (alertsLimit) {
            showUpgradeModal(
                dispatch, {
                    name: 'alerts',
                    value: true
                });
            event.preventDefault();
            this.value(false);
            return false;
        }
        return true;
    }

    const smartAlertClick = function (event) {
        if (!settings.has_smart_alerts) {
            showUpgradeModal(
                dispatch, {
                    name: 'smart_alerts',
                    value: true
                });
            event.preventDefault();
            return false;
        }
        return checkLimit(event)
    }

    const adValueHandler = (event) => {
        if (alertType === 'campaign') {
            if (!settings.has_campaigns) {
                showUpgradeModal(dispatch, {
                    name: 'campaigns',
                    value: true
                });
                event.preventDefault();
                return false
            }
        }

        const {target} = event;
        setValue(target.checked)
        modifyEventTypes(options.eventType, target.checked)
    }

    const setSpendValueHandler = (event) => {
        const {target} = event;
        setUseSpend(target.checked)
    }

    const setDurationValueHandler = (event) => {
        const {target} = event;
        setUseDuration(target.checked)
    }

    const currencySymbol = symbol(settings.currency)
    const currency = pickCurrency(settings.currency, settings.defaultCountry)

    const upgradeDataValue = alertType === 'campaign' && !settings.has_campaigns ? 
        'upgrade-required-campaigns' :
        (alertsLimit ? 'upgrade-required-alerts' : 'alerts')

    return (
        <li data-value={upgradeDataValue}>
            <input type="checkbox" id={"alertOf." + alertType}
                   value={options.eventType}
                   name="alertEventTypes"
                   checked={value}
                   onChange={adValueHandler}/>
            <label htmlFor={"alertOf." + alertType}>
                <span>Alert me when this advertiser has a New {entityTitle}</span>
            </label>
            <ul data-value={smartAlertDataValue}
                className={value ? "" : "hidden"}>
                <li>
                    <input type="checkbox"
                           id={"alertOf." + alertType + '.useSpend'}
                           checked={useSpend}
                           onChange={setSpendValueHandler}
                           disabled={!settings.has_smart_alerts || !value ? "disabled" : ""}/>
                    <label htmlFor={"alertOf." + alertType + '.useSpend'}
                           onClick={smartAlertClick}>
                        <span>And the {alertType} spend exceeds</span>
                    </label>
                    <span>{' '}{currencySymbol}</span>
                    <input name={alertType + '_spend'} className="number"
                           data-min="1" step="500"
                           value={readSpend()}
                           disabled={!settings.has_smart_alerts || !useSpend ? "disabled" : ""}
                           onChange={(event) => writeSpend(event.target.value)}
                    />
                    <span>{' '}{currency}</span>
                    <FormError
                        {...props.saveAlertStatus}
                        name={alertType + '_spend'}
                    />
                </li>
                <li>
                    <input type="checkbox"
                           id={"alertOf." + alertType + '.useDuration'}
                           checked={useDuration}
                           onChange={setDurationValueHandler}
                           disabled={!settings.has_smart_alerts || !value ? "disabled" : ""}/>
                    <label htmlFor={"alertOf." + alertType + '.useDuration'}
                           onClick={smartAlertClick}>
                        <span>And the {alertType} runs more than{' '}</span>
                    </label>
                    <input name={alertType + '_duration'}
                           className="number days" data-min="1"
                           data-max="90"
                           value={duration}
                           onChange={(event) => setDuration(event.target.value)}
                           disabled={!settings.has_smart_alerts || !useDuration ? "disabled" : ""}/> days
                    <FormError
                        {...props.saveAlertStatus}
                        name={alertType + '_duration'}
                    />
                </li>
            </ul>
        </li>
    )
}

export default AlertEventSettings