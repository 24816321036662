import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserCompareTopAds = createAsyncThunk(
    'fetchAdvertiserCompareTopAds',
    async (arg) => {
        const params = {
            ...arg.query,
        }

        const response = await axios.get("/data-api/ads", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const advertiserCompareTopAdsSlice = createSlice({
    name: 'advertiserCreativeTopAds',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetAdvertiserCompareTopAds(state) {
            state.status = 'idle'
            state.data = []
        }
    },
    extraReducers: {
        [fetchAdvertiserCompareTopAds.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvertiserCompareTopAds.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserCompareTopAds = (state) => state.advertiserCompareTool.adTypes
export const {resetAdvertiserCompareTopAds} = advertiserCompareTopAdsSlice.actions
export {selectAdvertiserCompareTopAds, fetchAdvertiserCompareTopAds}
export default advertiserCompareTopAdsSlice.reducer
