import React from "react"

const Error = (props) => {
    const extraClass = props.extraClass || ""
    return (
        <div className={extraClass}>
            <div className="no-data clear">
                <h3>Houston, we've got a problem.</h3>

                <div className="description">
                    Our crack team of engineers has been notified so they can
                    fix it.
                </div>
                <div className="description smaller">
                    While their enormous brains work on the problem, we
                    recommend you try a
                    different search to get things back on track. Sorry for the
                    inconvenience!
                </div>
            </div>
        </div>
     );
}
 
export default Error;