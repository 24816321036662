import settings from "../../reducers/settings";

let parseAdvertiserTopCampaigns = (result, parts) => {
    result.dateRange = parts[6];
    result.network = parts[7];
    result.sortBy = parts[8];
    result.sortOrder = parts[9];
    result.pageNo = parts[10];
}

parseAdvertiserTopCampaigns.section = /advertiser top campaigns/;

let parseProfileTopAds = (result, parts) => {
    result.brandId = parts[6];
    result.dateRange = parts[7];
    result.network = parts[8];
    result.adType = parts[9];
    result.adSize = parts[10];
    result.sortBy = parts[11];
    result.sortOrder = parts[12];
    result.pageNo = parts[13];
}

parseProfileTopAds.section = /\w+ top ads/;

let parseProfileUrl = (url) => {
    let parts = url.split('/').slice(1);
    let result = {};
    result.page = parts[0] + ' profile';
    result.country = parts[1];
    result.platform = parts[2];
    result[parts[0]] = decodeURIComponent(parts[3]);

    if (parts.length > 4) {
        result.section = parts[4].replace(/-/g, ' ');
    }

    if (parseAdvertiserTopCampaigns.section.test(result.section)) {
        parseAdvertiserTopCampaigns(result, parts);
    }

    if (parseProfileTopAds.section.test(result.section)) {
        parseProfileTopAds(result, parts);
    }

    return result;
}


let parseCampaignUrl = (url) => {
    let result = parseProfileUrl(url);

    let parts = url.split('/');

    result.campaignId = decodeURIComponent(parts[4]);
    result.advertiserId = parts[5];
    result.section = undefined;
    result.campaign = 'campaign';

    return result;
}

let parseBrandUrl = (url) => {
    let result = parseProfileUrl(url);

    let parts = url.split('/');

    result.brandId = decodeURIComponent(parts[4]);
    result.section = undefined;
    result.advertiserId = parts[5];
    result.brand = 'brand';

    return result;
}

let parseTopAdsUrl = (url) => {
    let parts = url.split('/').slice(1);
    let result = {};
    result.page = parts[0].replace(/-/g, ' ');
    result.country = parts[1];
    result.platform = parts[2];
    result.searchTerm = (parts[3] || '')
        .split(',')
        .filter(function (term) {
            return term !== 'any' && term !== 'all'
        });
    result.queryField = parts[4];
    result.dateRange = parts[5];
    result.network = parts[6];
    result.adType = parts[7];
    result.adSize = parts[8];
    result.advertiser = (parts[9] || '')
        .split(',').filter(function (term) {
            return term !== 'any' && term !== 'all';
        });
    result.pageNo = parts[12];
    return result;
}

let parseMoversAndShakers = (url) => {
    let parts = url.split('/').slice(1);
    let result = {
        'platform': 'all',
        'page': 'Movers and Shakers'
    };
    if (parts.length > 4) {
        result.country = parts[1];
        result.network = parts[2];
        result.pageNo = parts[4];
    }
    else {
        result.country = 'us';
        result.network = 'all';
        result.pageNo = 'a';
    }
    return result;
}

const parseAdvertiserCompare = (url) => {
    const parts = url.split('/').slice(2);
    let result;
    if (parts.length > 4) {
        result = {
            page: 'Advertiser Compare',
            country: parts[1],
            platform: parts[2],
            advertisers: parts[4],
            dateRangePreset: parts[3]
        }
    } else {
        result = {
            page: 'Advertiser Compare',
            country: settings.defaultCountry,
            platform: settings.defaultPlatform,
            advertisers: 'none',
            dateRangePreset: settings.defaultDateRange
        }
    }
    return result;
}


let parseTopAdvertisersUrl = (url) => {
    let parts = url.split('/').slice(1);
    let result = {};
    result.page = parts[0].replace(/-/g, ' ');
    result.country = parts[1];
    result.platform = parts[2];
    result.dateRange = parts[3];
    result.network = parts[4];
    result.pageNo = parts[7];
    result.categoryId = parts[8];
    return result;
}

let parseBookmark = (url) => {
    let patterns = [
        new RegExp(/^\/(advertiser|publisher|network|channel)/),
        new RegExp(/^\/top-ads/),
        new RegExp(/^\/top-advertisers/),
        new RegExp(/^\/campaign/),
        new RegExp(/^\/brand/),
        new RegExp(/^\/movers-and-shakers/),
        new RegExp(/^\/tools\/advertiser_compare/),
    ];

    let functions = [
        parseProfileUrl,
        parseTopAdsUrl,
        parseTopAdvertisersUrl,
        parseCampaignUrl,
        parseBrandUrl,
        parseMoversAndShakers,
        parseAdvertiserCompare
    ];

    let i, len, pattern;
    for (i = 0, len = patterns.length; i < len; i += 1) {
        pattern = patterns[i];

        if (url.match(pattern)) {
            return functions[i](url);
        }
    }

    return {};
}


export default parseBookmark