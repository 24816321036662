import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserAdTypes = createAsyncThunk(
    'fetchAdvertiserAdTypes',
    async (arg) => {
        const params = {
            ...arg.query,
        }

        const response = await axios.get("/data-api/adTypes", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const advertiserTypesSlice = createSlice({
    name: 'advertiserAdTypes',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetAdvertiserCreativeTypes(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserAdTypes.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvertiserAdTypes.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserCreativeTypes = (state) => state.advertiserProfile.overview.creativeTypes
export const {resetAdvertiserCreativeTypes} = advertiserTypesSlice.actions
export {selectAdvertiserCreativeTypes, fetchAdvertiserAdTypes}
export default advertiserTypesSlice.reducer