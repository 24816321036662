import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchFrequentSearches = createAsyncThunk('fetchFrequentSearches',
    async (arg) => {
        const response = await axios.get('/callisto/favorite-searches')
        return response.data
    })

const frequentSearchSlice = createSlice({
    name: 'frequentSearches',
    initialState: {
        status: 'idle',
        searches: []
    },
    extraReducers: {
        [fetchFrequentSearches.pending]: (state, action) => {
            state.status = 'pending'
            state.searches = []
        },
        [fetchFrequentSearches.fulfilled]: (state, action) => {
            state.status = 'done'
            state.searches = action.payload.favoriteSearches
        }
    }
})

const selectFrequentSearches = (state) => state.searches.frequentSearches

export {fetchFrequentSearches, selectFrequentSearches}

export default frequentSearchSlice.reducer