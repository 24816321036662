import React, {useCallback, useEffect, useRef, useState} from "react"
import {requestSearch, resetSearch} from "./universalSearchSlice";
import UniversalSearchDropDown from "./UniversalSearchDropDown";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash"
import {settingsSelector} from "../../reducers/settings";
import Profile from "../../common/profile"
import { isAdvertiser } from "../../common/tld/effective_tld_names";


const useOutsideClick = (ref, callbackOut, callbackIn) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (ref.current) {
                if (ref.current.contains(event.target)) {
                    callbackIn && callbackIn()
                }
                else {
                    callbackOut && callbackOut()
                }
            }
        }

        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ref, callbackIn, callbackOut]);
}


const UniversalSearchSearch = (props) => {
    const settings = useSelector(settingsSelector)
    const inputRef = useRef()
    const [inputValue, setInputValue] = useState('')
    const [value, setValue] = useState('')
    const dispatch = useDispatch()

    const {country, platform} = props

    useEffect(() => {
        if (props.anyInputFocus && inputRef.current) {
            inputRef.current.focus()
        }
    }, [props.anyInputFocus])

    useEffect(() => {
        if (value.length < 2) {
            dispatch(resetSearch())
        }
        else {
            dispatch(requestSearch({
                settings,
                params: {
                    q: value,
                    country,
                    rows: 4
                }
            }))
        }
    }, [country, platform, value, dispatch, settings])

    const cancelSearch = () => {
        setValue('')
        setInputValue('')
    }

    const debouncedSetValue =
        useCallback(_.debounce(setValue, 300), []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        debouncedSetValue(inputValue)
    }, [inputValue, debouncedSetValue])

    useOutsideClick(props.searchContainerRef, cancelSearch, props.onFocus)

    const handleKeyUp = (e) => {
        if (e.which === 13 && inputValue.length > 1) {
            if (isAdvertiser(inputValue)) {
                Profile.goToAdvertiser(inputValue, country, platform)
            } else {
            Profile.goToKeyword(inputValue, country, platform, 
                settings.defaultDateRange, settings.defaultNetwork)
            }
        }
    }

    return (
        <React.Fragment>
            <input
                ref={inputRef}
                value={inputValue}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onKeyUp={handleKeyUp}
                onChange={(event) => setInputValue(event.target.value)}
                name="searchTerm"
                className="searchTermItem"
                autoComplete="off"
                placeholder={props.placeholder}
            />
            <UniversalSearchDropDown
                show={value.length > 1}
                searchTerm={value}
                country={country}
                platform={platform}
            />
        </React.Fragment>
    )
}


export default UniversalSearchSearch