import { combineReducers } from "redux";
import nativeAdsSlice from "./nativeAdsSlice";
import standardAdsSlice from "./standardAdsSlice";
import videoAdsSlice from "./videoAdsSlice";
import adFilterSettingsSlice from "./adFilterSettingsSlice";

const reducers = combineReducers({
    standard: standardAdsSlice,
    native: nativeAdsSlice,
    video: videoAdsSlice,
    adFilterSettings: adFilterSettingsSlice
})  

export default reducers