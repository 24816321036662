import Categories from "../../../common/categories";
const storagePrefix = 'pub_tools_';

function getVal(key, def) {
    key = storagePrefix + key;
    return window.localStorage.getItem(key) || def; 
}

function setVal(key, val) {
    key = storagePrefix + key;
    window.localStorage.setItem(key, val);
}

function initPubUiFilters(state, action) {
    const settings = action.payload
    let country
    let platform
    let dateRange
    let category
    let network
    let publisherSet
    const defaultCountry = settings.own_countries.length
        ? settings.own_countries[0]
        : settings.defaultCountry
    const defaultCategory = '/'
    const defaultNetwork = 'all'
        
    if (settings.reset_user_profile_defaults) {
        country = defaultCountry
        platform = settings.defaultPlatform;
        dateRange = settings.defaultDateRange;
        category = defaultCategory
        network = defaultNetwork
        publisherSet = ''
    } else {
        country = getVal('country', defaultCountry); 
        platform = getVal('platform', settings.defaultPlatform);
        dateRange = getVal('dateRange', settings.defaultDateRange);
        category = getVal('category', defaultCategory)
        network = getVal('network', defaultNetwork)
        publisherSet = getVal('publisherSet', '')
    }

    state.country = country
    state.platform = platform
    state.network = network
    state.dateRange = dateRange
    state.category = category
    state.categoryName = new Categories(settings).byValue(category).fullName()
    state.publisherSet = publisherSet

    setVal('country', country)
    setVal('platform', platform)
    setVal('network', network)
    setVal('dateRange', dateRange)
    setVal('category', category)
    setVal('publisherSet', publisherSet)
}

export {
    getVal,
    setVal,
    initPubUiFilters
}