import {createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../../common/axiosRateLimit'
import addAdvertiserToDashboard from "./userDashboardThunks/advertiserThunks";
import addPublisherToDashboard from "./userDashboardThunks/publisherThunks";

const fetchIsIncludedInDashboard = createAsyncThunk('fetchIsIncludedInDashboard',
    async () => {
        const response = await axios.get(
            '/callisto/dashboard-advertisers')
        return response.data
    })

const fetchIsPubIncludedInDashboard = createAsyncThunk('fetchIsPubIncludedInDashboard',
    async () => {
        const response = await axios.get(
            '/callisto/dashboard-publishers')
        return response.data
    })


const removeAdvertiserFromDashboard = createAsyncThunk('removeAdvertiserFromDashboard',
    async (arg) => {
        const response = await axios.delete(
            '/callisto/dashboard-advertisers/' + arg.advertiser)
        return response.data
    })
    
const removePublisherFromDashboard = createAsyncThunk('addPublisherToDashboard',
    async (arg) => {
        const response = await axios.delete(
            '/callisto/dashboard-publishers/' + arg.publisher)
        return response.data
    })

const userDashboardSlice = createSlice({
    name: 'followModalUserDashboardSlice',
    initialState: {
        status: 'init',
        data: [],
        statusPub: 'init',
        dataPub: {publishers: []},
        addAdvertiserState: 'init',
        addAdvertiserData: {},
        removeAdvertiserState: 'init',
        addPublisherState: 'init',
        addPublisherData: {},
        removePublisherState: 'init',
        currentError: '',
    },
    reducers: {
        resetState(state) {
            state.status = 'init'
            state.statusPub = 'init'
            state.addAdvertiserState = 'init'
            state.removeAdvertiserState = 'init'
            state.addPublisherState = 'init'
            state.removePublisherState = 'init'
            state.data = []
            state.dataPub = {publishers: []}
        },
        resetPubState(state) {
            state.statusPub = 'init'
            state.dataPub = {publishers: []}
        },
        resetPubManagement(state) {
            state.addPublisherState = 'init';
            state.removePublisherState = 'init';
            state.currentError = '';
        },
        resetAdvertiserManagement(state) {
            state.addAdvertiserState = 'init';
            state.removeAdvertiserState = 'init';
            state.currentError = '';
        }
    },
    extraReducers: {
        [fetchIsIncludedInDashboard.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchIsIncludedInDashboard.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload
        },
        [fetchIsPubIncludedInDashboard.pending]: (state) => {
            state.statusPub = 'pending'
        },
        [fetchIsPubIncludedInDashboard.fulfilled]: (state, action) => {
            state.status = 'done'
            state.dataPub = action.payload
        },
        [addAdvertiserToDashboard.pending]: (state) => {
            state.addAdvertiserState = 'pending'
        },
        [addAdvertiserToDashboard.fulfilled]: (state, action) => {
            state.addAdvertiserState = 'done'
            if ('message' in action.payload) {
                state.currentError = action.payload['message'];
            }
        },
        [removeAdvertiserFromDashboard.pending]: (state) => {
            state.removeAdvertiserState = 'pending'
        },
        [removeAdvertiserFromDashboard.fulfilled]: (state) => {
            state.removeAdvertiserState = 'done'
        },
        [addPublisherToDashboard.pending]: (state, action) => {
            state.addPublisherState = 'pending'
            state.addPublisherData = action.payload;
        },
        [addPublisherToDashboard.fulfilled]: (state, action) => {
            state.addPublisherState = 'done';
            state.addPublisherData = action.payload;
            if ('message' in action.payload) {
                state.currentError = action.payload['message'];
            }
        },
        [removePublisherFromDashboard.pending]: (state) => {
            state.removePublisherState = 'pending'
            state.addPublisherData = {};
        },
        [removePublisherFromDashboard.fulfilled]: (state) => {
            state.removePublisherState = 'done'
        }
    }
})

const selectAdvertiserIncludedInDashboard = state => state.followAdvertiserDashboard
const selectPublishersIncludedInDashboard = state => state.followAdvertiserDashboard

export {
    fetchIsIncludedInDashboard,
    fetchIsPubIncludedInDashboard,
    addAdvertiserToDashboard,
    removeAdvertiserFromDashboard,
    removePublisherFromDashboard,
    selectAdvertiserIncludedInDashboard,
    selectPublishersIncludedInDashboard,
}

export const {
    resetState,
    resetPubState,
    resetPubManagement,
    resetAdvertiserManagement,
    notifyDashboardError,
} = userDashboardSlice.actions

export default userDashboardSlice.reducer
