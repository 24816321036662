import axios from '../../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import qs from "qs";


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchPublisherUiOverviewNewBusinessData = createAsyncThunk('fetchPublisherUiOverviewNewBusinessData',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        let ownAdvertisers = axios.post("/data-api/advertisers",
            qs.stringify({
                publisher: arg.params.ownProperties.join(','),
                network: arg.params.network,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: arg.params.metrics,
                rows: arg.params.rows,
                sortBy: 'sumAdSpend',
                sortOrder: 'desc'
            }), {
                cancelToken: getCancelSource().token
            }
        )
        let competitiveAdvertisers = axios.post("/data-api/advertisers",
            qs.stringify({
                publisher: arg.params.competitiveSet.join(','),
                network: arg.params.network,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: arg.params.metrics,
                rows: arg.params.rows,
                sortBy: 'sumAdSpend',
                sortOrder: 'desc'
            }), {
                cancelToken: getCancelSource().token
            }
        )

        let competitiveAdvertisersByPublisher = axios.post("/data-api/advertisers",
            qs.stringify({
                publisher: arg.params.competitiveSet.join(','),
                network: arg.params.network,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: 'sumAdSpend,uniqueAds',
                sortBy: 'sumAdSpend',
                sortOrder: 'desc',
                rows: arg.params.rows,
                entity: 'publisher'
            }), {
                cancelToken: getCancelSource().token
            }
        )

        Promise.all([
            ownAdvertisers.catch(e => e),
            competitiveAdvertisers.catch(e => e),
            competitiveAdvertisersByPublisher.catch(e => e)
        ])

        return {
            ownAdvertisers: (await ownAdvertisers).data,
            competitiveAdvertisers: (await competitiveAdvertisers).data,
            competitiveAdvertisersByPublisher: (await competitiveAdvertisersByPublisher).data
        }
    })


const initialState = {
    newBusiness: [],
    expansionOpportunities: [],
    competitiveSet: [],
    competitiveSetByPublisher: [],
    status: 'idle',
    noData: false
}


const newBusinessSlice = createSlice({
    name: 'newBusiness',
    initialState,
    reducers: {
        resetNewBusiness(state) {
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()

            return initialState
        }
    },
    extraReducers: {
        [fetchPublisherUiOverviewNewBusinessData.rejected]: (state, action) => {
            state.status = 'done'
            state.noData = true
            state.newBusiness = []
            state.expansionOpportunities = []
            state.competitiveSet = []
            state.competitiveSetByPublisher = []
        },
        [fetchPublisherUiOverviewNewBusinessData.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchPublisherUiOverviewNewBusinessData.fulfilled]: (state, action) => {
            state.status = 'done'

            const ownProperties = new Set(action.meta.arg.params.ownProperties)
            const ownData = action.payload.ownAdvertisers
            const competitive = action.payload.competitiveAdvertisers

            if ('rows' in ownData && 'rows' in competitive && competitive.rows.length > 0) {
                const ownDataSet = new Set(
                    ownData.rows.map((item) => item.advertiser)
                )

                state.noData = false
                state.newBusiness = []
                state.expansionOpportunities = []

                competitive.rows.sort((a, b) => {
                    return b.sumAdSpend - a.sumAdSpend
                }).forEach((item) => {
                    state.competitiveSet.push(item)
                    if (ownProperties.has(item.advertiser)) {
                        return;
                    }
                    if (ownDataSet.has(item.advertiser)) {
                        state.expansionOpportunities.push(item)
                    } else {
                        state.newBusiness.push(item)
                    }
                })

                if ('rows' in action.payload.competitiveAdvertisersByPublisher) {
                    state.competitiveSetByPublisher = action.payload.competitiveAdvertisersByPublisher.rows
                }
            }
            else {
                state.noData = true
            }
        }
    }
})

const selectNewBusinessData = (state) => state.publisherUiOverview.newBusiness

export default newBusinessSlice.reducer

export const {
    resetNewBusiness
} = newBusinessSlice.actions

export {
    fetchPublisherUiOverviewNewBusinessData,
    selectNewBusinessData
}
