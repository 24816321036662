import {createSlice} from "@reduxjs/toolkit";
import { setVal, initPubUiFilters as initPubUiFiltersSource } from "../helpers/pubUiPersistentFilters";


const publisherUiCommonSlice = createSlice({
    name: 'common',
    initialState: {
        country: '',
        platform: '',
        dateRange: '',
        network: '',
        publisherSet: '',
        publisherSetName: ''
    },
    reducers: {
        setValue(state, action) {
            for (const key in action.payload) {
                if (action.payload.hasOwnProperty(key)) {
                    state[key] = action.payload[key]
                }
                setVal(key, action.payload[key])
            }
        },
        initPubUiFilters: initPubUiFiltersSource
    },
})


const selectPublisherUiSettings = (state) => state.publisherUiOverview.common


export {
    selectPublisherUiSettings
}


export const {
    setValue,
    initPubUiFilters
} = publisherUiCommonSlice.actions


export default publisherUiCommonSlice.reducer