import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from '../common/axiosRateLimit'
import _ from 'lodash'

const fetchTooltips = createAsyncThunk('tooltips',
    async () => {
        const response = await axios.get('/callisto/tooltips')
        return response.data
    })

const tooltipsSlice = createSlice({
    name: 'tooltips',
    initialState: {
        tooltips: {},
        status: 'init'
    },
    extraReducers: {
        [fetchTooltips.fulfilled]: (state, action) => {
            state.tooltips = action.payload
            state.status = 'succeeded'
        }
    },
})

const tooltipsSelector = (state) => state.tooltips.tooltips
const pathTooltipSelector = (path) => (state) => {
    const tooltips = tooltipsSelector(state)

    if (!_.isEmpty(tooltips)) {
        let tooltip = tooltips.tooltips
                    .filter(t => t.path === path && t.is_published)
                    .pop()
        if (!tooltip) {
            return ""
        }
        return tooltip.content
    }
}

export {fetchTooltips, tooltipsSelector, pathTooltipSelector}

export default tooltipsSlice.reducer