import React, { useState } from 'react'
import EscapeOutside from 'react-escape-outside'
import {useSelector} from 'react-redux'
import { settingsSelector } from "../../reducers/settings";

const PublisherUISwitch = (props) => {
    const settings = useSelector(settingsSelector)
    const isPublisherUI = settings.show_publisher_ui
    const [isDropdownShown, setIsDropdownShown] = useState(false)

    const toggleDropdown = () => {
        setIsDropdownShown(!isDropdownShown)
    }

    const hideDropdown = () => {
        setIsDropdownShown(false)
    }

    if (settings.user.publisher_tools !== 'on') {
        return null
    }

    return (
      <EscapeOutside onEscapeOutside={hideDropdown}>
        <div className={`left publisher-ui-switch pointer ${isDropdownShown ? 'active' : ''}`}>
          <div className="for-publishers" onClick={toggleDropdown}>
            {isPublisherUI && <span>For Publishers</span>}
            {!isPublisherUI && <span>For Advertisers</span>}
            <i className="fa-solid fa-sharp fa-sort-down fa-xs"></i>
          </div>
          {isDropdownShown && (
            <div className="for-advertisers">
              {isPublisherUI && (
                <a href="/use-advertiser-ui">For Advertisers</a>
              )}
              {!isPublisherUI && <a href="/use-publisher-ui">For Publishers</a>}
            </div>
          )}
        </div>
      </EscapeOutside>
    );
}

export default PublisherUISwitch