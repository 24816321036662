import {useEffect} from "react"
import {useSelector} from "react-redux";
import {settingsSelector} from "../reducers/settings";

const Intercom = (props) => {
    const settings = useSelector(settingsSelector)

    useEffect(() => {
        window.intercomSettings = {
            app_id: settings.intercom.appId,
            email: settings.user.email,
            name: `${settings.user.firstname} ${settings.user.lastname}`,
            user_id: `${settings.user.id}${ settings.intercom.suffix }`,
            created_at: Date.parse(settings.user.date.replace(' ', 'T')) / 1000 + "",
            'Adbeat Active': 'Yes',
            'Business Type': settings.user.onboarding_business_type ? settings.user.onboarding_business_type : settings.user.business_type,
            widget: {
                activator: "#IntercomDefaultWidget"
            }
        }

        let w = window;
        let ic = w.Intercom;
        if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', window.intercomSettings);
        } else {
            let d = document;
            let i = function () {
                i.c(arguments)
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args)
            };
            w.Intercom = i;

            let l = () => {
                let s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = `https://widget.intercom.io/widget/${settings.intercom.appId}`;
                let x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            }

            l()
        }
    }, [settings.intercom, settings.user])

    return null;
}


export default Intercom