import common from './publisherUiCommonSlice'
import totals from './metaSlice'
import overview from '../overview/overviewReducer'
import {combineReducers} from '@reduxjs/toolkit'

const publisherUiAdvertiserOverviewReducer = combineReducers({
    common,
    totals,
    overview
})


export default publisherUiAdvertiserOverviewReducer
