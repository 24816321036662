import axios from '../../../common/axiosRateLimit'
import qs from 'qs'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'


const fetchCountries = createAsyncThunk('fetch_menu_countries',
    async (arg) => {
        let query = {
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            country: arg.query.country,
            network: arg.query.network,
            platform: arg.query.platform,
        }
        if (arg.query.brand) {
            query.brand = arg.query.brand
        }
        if (arg.query.campaign) {
            query.campaign = arg.query.campaign
        }
        if (arg.query.advertiser) {
            query.advertiser = arg.query.advertiser
        }
        if (arg.query.publisher) {
            query.publisher = arg.query.publisher
        }
        if (arg.query.channel) {
            query.channel = arg.query.channel
        }
        const response = await axios.get('/menu-api/countries?' + qs.stringify(query))
        
        return response.data
    })

const countriesSlice = createSlice({
    name: 'country-menu',
    initialState: {
        status: 'idle',
        countries: []
    },
    reducers: {
        resetCountries: (state) => {
            state.status = 'idle'
            state.countries = []
        }
    },
    extraReducers: {
        [fetchCountries.pending]: (state, action) => {
            state.status = 'pending'
            state.countries = []
        },
        [fetchCountries.fulfilled]: (state, action) => {
            state.countries = action.payload.rows
            state.status = 'done'
        },
        [fetchCountries.rejected]: (state, action) => {
            state.countries = []
            state.status = 'failed'
        }
    }
})

const selectCountries = (state) => state.menu.topAds.countries

export {fetchCountries, selectCountries}
export const {resetCountries} = countriesSlice.actions

export default countriesSlice.reducer
