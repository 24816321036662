import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdCountByDate = createAsyncThunk(
    'fetchAdvertiserAdsByDate',
    async (arg) => {
        const params = {
            ...arg.query,
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const advertiserAdsCountByDate = createSlice({
    name: 'advertiserAdSizes',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetAdvertiserAdsCountByDate(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdCountByDate.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdCountByDate.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserAdCountByDate = (state) => state.advertiserProfile.overview.adCountByDate
export const {resetAdvertiserAdsCountByDate} = advertiserAdsCountByDate.actions
export {selectAdvertiserAdCountByDate, fetchAdCountByDate}
export default advertiserAdsCountByDate.reducer