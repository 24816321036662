import useTooltip from "../../hooks/useTooltip"

const Features = (props) => {

    const programmatic = useTooltip('display-and-programmatic')
    const networksContent = useTooltip('upgrade-networks-content-data')
    const networksDirect = useTooltip('upgrade-networks-direct-data')
    const byCountry = useTooltip('ads-advertisers-and-publishers-by-country')
    const desktopAndMobile = useTooltip('desktop-and-mobile')
    const publisherPlacements = useTooltip('publisher-placements')
    const reports = useTooltip('upgrade-reports')
    const mobileData = useTooltip('upgrade-flow-mobile-data')
    const unlimitedResults = useTooltip('unlimited-results-advanced')
    const adSpend = useTooltip('upgrade-ad-spend-data')
    const videoAds = useTooltip('upgrade-video-ads')
    const advReporting = useTooltip('upgrade-adv-reporting')
    const adTags = useTooltip('upgrade-ad-tags-data')
    const brands = useTooltip('upgrade-brands-data')
    const campaigns = useTooltip('upgrade-campaigns')
    const categories = useTooltip('upgrade-categories')
    const twoYears = useTooltip('upgrade-2-years-data')

    if (props.id === 'upgrade-screen-intro-to-advanced') {
        return <>
            <div>You can only see 30 days of data</div>
            <div>You're limited to 500 rows of results</div>
            <div>You can't filter ads, advertisers, and publishers by country</div>
            <div>You can't filter by Desktop and Mobile devices</div>
            <div>You can't run reports or export data</div>
        </>
    }

    if (props.id === 'upgrade-screen-google_only-to-standard') {
        return <>
            <div>You're missing out on a lot of ads!</div>
            <div>Adbeat has data on 90+ more Display, Programmatic, & Native ad networks.</div>
            <div>You don't have Mobile data</div>
        </>
    }

    if (props.id === 'upgrade-screen-google_only-to-advanced') {
        return <>
            <div>You're missing out on a lot of ads!</div>
            <div>Adbeat has data on 90+ more Display, Programmatic, & Native ad networks.</div>
            <div>You don't have Mobile data</div>
        </>
    }

    if (props.id === 'upgrade-screen-google_mobile-to-advanced') {
        return <>
            <div>You're missing out on a lot of ads!</div>
            <div>Adbeat has data on 90+ more Display, Programmatic, & Native ad networks.</div>
            <div>Upgrade to get all of this and more</div>
        </>
    }

    if (props.id === 'upgrade-screen-mobile_only-current') {
        return <>
            <div>You don't have Desktop Data</div>
        </>
    }

    if (props.id === 'upgrade-screen-standard-to-advanced') {
        return <>
            <div>You don't have Direct Buy data</div>
            <div>You can't filter ads, advertisers, and publishers by country</div>
            <div>You can't filter by Desktop and Mobile devices</div>
            <div>You don't have unlimited results per search</div>
            <div>You only have 90 days of data</div>
        </>
    }

    if (props.id === 'upgrade-screen-standard-to-enterprise') {
        return <>
            <div>You don't have Direct Buy data</div>
            <div>You can't filter ads, advertisers, and publishers by country</div>
            <div>You can't filter by Desktop and Mobile devices</div>
            <div>You don't have unlimited results per search</div>
            <div>You only have 90 days of data</div>
        </>
    }

    if (props.id === 'upgrade-screen-standard-new') {
        return <>
            <div>See all{' '}
    <span className="tooltip-link" data-tip data-for={programmatic.path}>
        Display, Programmatic,
    </span>
    {' '}&{' '}
    <span className="tooltip-link" data-tip data-for={networksContent.path}>
                    Native ads
    </span>
            </div>
            <div>See{' '}
    <span className="tooltip-link" data-tip data-for={desktopAndMobile.path}>
                    Desktop & Mobile Ads
    </span>
    {' '}in 26 countries
  </div>
            <div>
                Filter by Date Range and Ad Network
  </div>
            <div>See 90 days of of data</div>
            <div>Plus all Intro Plan features</div>
            {programmatic.component}
            {networksContent.component}
            {desktopAndMobile.component}
        </>
    }
    

    if (props.id === 'upgrade-screen-standard-new-from-intro') {
        return <>
            <div>See 90 days of data</div>
            <div>See 1000 rows of results</div>
            <div>View{' '}
    <span className="tooltip-link" data-tip data-for={publisherPlacements.path}>
                    Publisher Placement URLs
    </span>
            </div>
            <div>Create & Export{' '}
    <span className="tooltip-link" data-tip data-for={reports.path}>
                    Reports
    </span>
            </div>
            <div>Plus all Intro Plan features</div>
            {publisherPlacements.component}
            {reports.component}
        </>
    }

    if (props.id === 'upgrade-screen-google_mobile-new') {
        return <>
            <div>Get access to <span className="tooltip-link" data-tip data-for={mobileData.path}>Mobile Data</span></div>
            {mobileData.component}
        </>
    }

    if (props.id === 'upgrade-screen-advanced-current') {
        return <>
            <div>You don't have Ad Spend and Video Ads</div>
            <div>You don't have Ad Tags and Brands</div>
            <div>You don't have Campaigns and Categories</div>
            <div>You don't have 3 years of data</div>
        </>
    }

    if (props.id === 'upgrade-screen-advanced-new') {
        return <>
            <div>See <span className="tooltip-link" data-tip data-for={networksDirect.path}>Direct Buy data</span></div>
            <div>Filter{' '}
    <span className="tooltip-link" data-tip data-for={byCountry.path}>
                    Ads, Advertisers, and Publishers by country
    </span>
            </div>
            <div>See{' '}
    <span className="tooltip-link" data-tip data-for={unlimitedResults.path}>
                    Unlimited Results
    </span>
    {' '}per search
  </div>
            <div>See 1 year of data</div>
            <div>Plus all Standard Plan Features</div>
            {byCountry.component}
            {networksDirect.component}
            {unlimitedResults.component}
        </>
    }

    if (props.id === 'upgrade-screen-enterprise-current') {
        return <>
            <div>
                See{' '}
    <span className="tooltip-link" data-tip data-for={adSpend.path}>Ad Spend</span>
    {' and '}
    <span className="tooltip-link" data-tip data-for={videoAds.path}>Video Ads</span>
            </div>
            <div>
                Get{' '}
    <span className="tooltip-link" data-tip data-for={advReporting.path}>
                    Advanced Reporting Tools
    </span>
            </div>
            <div>
                See{' '}<span className="tooltip-link" data-tip data-for={adTags.path}>Ad Tags</span>,{' '}
    <span className="tooltip-link" data-tip data-for={brands.path}>Brands</span>,{' '}
    and{' '}
    <span className="tooltip-link" data-tip data-for={campaigns.path}>Campaigns</span>
            </div>
            <div>
                See{' '}
    <span className="tooltip-link" data-tip data-for={categories.path}>Categories</span>{' '}&amp;{' '}
    <span className="tooltip-link" data-tip data-for={twoYears.path}>3 years of data</span>
            </div>
            <div>Plus all Advanced Plan Features</div>
            {adSpend.component}
            {videoAds.component}
            {advReporting.component}
            {adTags.component}
            {brands.component}
            {campaigns.component}
            {categories.component}
            {twoYears.component}
        </>
    }
}

export default Features;
