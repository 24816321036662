import { useSelector } from "react-redux";
import { settingsSelector } from "../../reducers/settings"
import Pluralize from 'react-pluralize'

const TrialError = (props) => {
    const settings = useSelector(settingsSelector)

    return (
        <>
            <div className="section-body report-trial-error">
                <h2>Woops! Your account is limited to a maximum of
                    {' '}
                    <Pluralize singular="report"
                        plural="reports"
                        count={settings.reports}></Pluralize>
                    {' '}
          during your trial period</h2>
            </div>
            <div className="section-footer clear">
                <button className="save-alert"
                    onClick={props.onClick}
                    type="submit">OK</button>
            </div>
        </>
    );
}

export default TrialError;