import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'
import qs from "qs";


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchPublisherUITopAdTrends = createAsyncThunk('fetchPublisherUITopAdTrends',
    async (arg) => {
        if (!arg.ads.length) {
            return
        }

        const entities = (ad) => {
            return {
                adHash: ad.adHash
            }
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.ads.map(entities))
        }

        delete params.pageNumber
        delete params.pageSize
        delete params.category
        delete params.sortBy
        delete params.sortOrder
        delete params.rows

        params.metrics += ',globalSeenDates'

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


const fetchPublisherUITopAds = createAsyncThunk('fetchPublisherUITopAds',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        let request = axios.post("/data-api/ads",
            qs.stringify({
                publisher: arg.params.publisherSet,
                network: arg.params.network,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: arg.params.metrics,
                sortBy: 'sumAdSpend',
                sortOrder: 'desc',
                rows: 100
            }), {
                cancelToken: getCancelSource().token
            }
        )

        let requestOwn = axios.post("/data-api/ads",
            qs.stringify({
                publisher: arg.params.ownProperties,
                network: arg.params.network,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: arg.params.metrics,
                sortBy: 'sumAdSpend',
                sortOrder: 'desc',
                noAdInfo: 'true',
                rows: 2500
            }), {
                cancelToken: getCancelSource().token
            }
        )

        Promise.all([
            request.catch(e => e),
            requestOwn.catch(e => e)
        ])

        let response = await request
        let responseOwn = await requestOwn

        let data = []

        if ('rows' in response.data && 'rows' in responseOwn.data) {
            let ownSet = new Set(responseOwn.data.rows.map((item) => item.adHash))
            data = response.data.rows.filter((item) => !ownSet.has(item.adHash)).slice(0, 10)
        }

        if (data.length > 0) {
            arg.dispatch(fetchPublisherUITopAdTrends({
                ads: data,
                query: {
                    publisher: arg.params.publisherSet,
                    network: arg.params.network,
                    country: arg.params.country,
                    platform: arg.params.platform,
                    startDate: arg.params.startDate,
                    endDate: arg.params.endDate,
                    metrics: arg.params.metrics,
                }
            }))

            data = data.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendLine: {
                    globalDaysSeen: 0
                }
            }))
        }

        return {
            ...response.data,
            rows: data
        }
    })


const initialState = {
    status: 'idle',
    data: []
}


const publisherUiCreativesSlice = createSlice({
    name: 'creatives',
    initialState,
    reducers: {
        resetCreatives(state) {
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()

            return initialState
        },
    },
    extraReducers: {
        [fetchPublisherUITopAds.rejected]: (state) => {},
        [fetchPublisherUITopAds.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchPublisherUITopAds.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.data = action.payload.rows
                state.status = 'done'
            } else {
                state.status = 'error'
            }
        },
        [fetchPublisherUITopAdTrends.rejected]: {},
        [fetchPublisherUITopAdTrends.fulfilled]: (state, action) => {
            state.data = state.data.map((ad) => {
                let trendline = action.payload.rows.filter((item) => {
                    return item.entity.adHash === ad.adHash
                }).shift()

                return {
                    ...ad,
                    trendLine: trendline,
                    trendlineStatus: 'done'
                }
            })
        }
    }
})


const selectPublisherUiCreatives = (state) => state.publisherUiOverview.creatives


export {
    selectPublisherUiCreatives,
    fetchPublisherUITopAds
}

export const {
    resetCreatives
} = publisherUiCreativesSlice.actions


export default publisherUiCreativesSlice.reducer
