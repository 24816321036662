import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, modalSelector } from "../../reducers/modal";
import { AddPublisherToDashboard } from "./AddAdvertiserToDashboard";
import { useRef, useState } from "react";
import useTooltip from "../../hooks/useTooltip";
import LoadingState from "../LoadingState";
import { showModal } from "../../reducers/modal";
import { addBookmark, deleteBookmark, fetchBookmark, bookmarkSelector } from "../../reducers/bookmark";
import addPublisherToDashboard from "./userDashboardThunks/publisherThunks";
import { 
    fetchIsPubIncludedInDashboard, 
    removePublisherFromDashboard, 
    selectPublishersIncludedInDashboard,
    resetPubManagement,
    resetPubState } from "./userDashboardSlice";
import { useEffect } from "react";
import FormError from "./FormError";

const FollowPublisherModal = props => {
    const publisher = props.publisher;
    const dispatch = useDispatch();
    const [addToBookmarks, setAddToBookmarks] = useState(false);
    const [isAddedToDashboard, setIsAddedToDashboard] = useState(false);
    const publisherModalForm = useRef();
    const tooltip = useTooltip('follow-publisher-modal');
    const bookmark = useSelector(bookmarkSelector(window.location.pathname));
    const dashboardPublishers = useSelector(selectPublishersIncludedInDashboard);

    useEffect(() => {
        if (dashboardPublishers.statusPub === 'init') {
            dispatch(fetchIsPubIncludedInDashboard())            
        }
    }, [dashboardPublishers.statusPub, dispatch])

    useEffect(() => {
        if (dashboardPublishers.status === 'done') {
            setIsAddedToDashboard(dashboardPublishers.dataPub.publishers
                .map(d => d.publisher)
                .some(d => props.publisher === d))
        }
    }, [dashboardPublishers.status,
        props.publisher,
        dashboardPublishers.dataPub.publishers])

    useEffect(() => {
        if (bookmark.status === 'idle') {
            dispatch(fetchBookmark({
                query: {
                    type: 'publisher',
                    link: window.location.pathname
                }
            }))
        }
    }, [bookmark.status, dispatch, props.publisher])

    useEffect(() => {
        setAddToBookmarks(bookmark.isBookmarked)
    }, [bookmark.isBookmarked])

    const handlerAddToBookmarks = (event) => {
        const { target } = event
        setAddToBookmarks(target.checked)
    }

    useEffect(() => {
        if (
            dashboardPublishers.removePublisherState === 'done' ||
            (dashboardPublishers.addPublisherState === 'done' &&
            dashboardPublishers.currentError.length === 0)
        ) {
            handleClose();
        }
    });

    const followPublisher = event => {
        event.preventDefault()

        const _addToBookmarks = event.target.querySelector('#addToSavedSearches').checked
        setAddToBookmarks(_addToBookmarks)
        if (_addToBookmarks) {
            dispatch(addBookmark({
                query: {
                    type: 'publisher',
                    link: window.location.pathname,
                    entity: props.publisher 
                }, 
                dispatch
            }))
        } else {
            dispatch(deleteBookmark({
                query: {
                    type: 'publisher',
                    link: window.location.pathname,
                },
                dispatch
            }))
        }

        const _addToDashboard = event.target.querySelector('#add-publisher-to-dashboard').checked
        setIsAddedToDashboard(_addToDashboard)
        if (_addToDashboard) {
            dispatch(addPublisherToDashboard({
                publisher,
                last_seen_pinot: props.globalLastSeen
            }));
        } else {
            dispatch(removePublisherFromDashboard({
                publisher
            }));
        }

        return false;
    }

    const handleClose = () => {
        props.handleClose && props.handleClose()
        dispatch(resetPubManagement())
        dispatch(resetPubState())
        dispatch(hideModal())
    }

    return (
        <>
            <ReactModal
                onRequestClose={handleClose}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                style={{
                    overlay: {
                        background: "rgba(0,0,0,0.8)",
                        zIndex: 99,
                        position: "absolute",
                        height: document.documentElement.scrollHeight
                    },
                    content: {
                        bottom: 'auto',
                        top: (window.scrollY || window.pageYOffset) + 20,
                        borderRadius: 0,
                        width: "50%",
                        minWidth: 650,
                        margin: "0 auto",
                        position: "absolute",
                        padding: 0,
                        overflow: 'none',
                        inset: ((window.scrollY || window.pageYOffset) + 20) +
                            "px 40px auto 40px",
                    },
                }}
                isOpen={props.isOpen}>
                <div className="follow-advertiser-template">
                    <form method="post"
                        onSubmit={followPublisher}
                        ref={publisherModalForm}
                    >
                        <input type="hidden" name="publisher"
                            value={publisher} />
                        <div className="section-header">
                            <h1 className="left">Follow {publisher}</h1>
                            <div className="help-icon left"
                                data-tip
                                data-for={tooltip.path}>
                                <i
                                    className="fa-sharp fa-light fa-circle-question fa-2x"
                                />
                            </div>
                            <div className="right close"
                                onClick={handleClose}>
                                <i className="fa-light fa-sharp fa-close" />
                            </div>
                        </div>

                        <div className="section-body">
                            <LoadingState
                                show={dashboardPublishers.status !== 'done' || bookmark.status !== 'done'} />
                            <p className="font-semi-bold">How would you like to
                                follow this publisher?</p>

                            <div className="follow-options">
                                <ul>
                                    <AddPublisherToDashboard 
                                        onDashboardStatusChange={(event) => {
                                            const {target} = event;
                                            setIsAddedToDashboard(target.checked)
                                        }}
                                        isAdded={isAddedToDashboard}/>

                                <li>
                                    <input
                                        type="checkbox"
                                        id="addToSavedSearches"
                                        name="addToSavedSearches"
                                        value="1"
                                        checked={addToBookmarks}
                                        onChange={handlerAddToBookmarks}
                                    />
                                    <label htmlFor="addToSavedSearches">
                                        <span>Add this publisher to my bookmarks</span>
                                    </label>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="section-footer">
                        <button type="submit" className="save-alert">
                            Save settings
                        </button>
                        {dashboardPublishers.currentError.length > 0 &&
                            <FormError errors={{'exceeded': [dashboardPublishers.currentError]}}
                                hasErrors={true}
                                name='exceeded' />
                        }
                    </div>
                </form>
            </div>
        </ReactModal>
    </> )
}

const modalName = 'follow-publisher'

const Wrapper = (props) => {
    const modal = useSelector(modalSelector)
    if (modal.name === modalName) {
        return (<FollowPublisherModal
            {...modal.params}
            isOpen={modal.name === modalName}
        />)
    }
    return null
}


const showFollowPublisherModal = (dispatch, params) => {
    dispatch(resetPubState())
    dispatch(showModal({
        name: modalName,
        params
    }))
}


export { showFollowPublisherModal }
export default Wrapper;
