import React from 'react'
import {useSelector} from 'react-redux'
import {settingsSelector} from "../../reducers/settings";

const UserProfileBase = props => {
    const settings = useSelector(settingsSelector)

    const handleMenuClick = e => {
        const link = e.target.dataset.href
        if (link) {
            window.location = link
        }
    }

    return <div className="main profile left">
        <div className="filters profile-header" id="user-profile-header">
            <div className="title left clear">
                <div className="page-name">
                    <h1 className="left">User Profile</h1>
                </div>
                <div className="breadcrumbs clear">
                    <div className="plan">
                        <span className="product">
                            Enterprise
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="content">
            <div className="left scroll-menu" onClick={handleMenuClick}>
                <ul className="small-menu">
                    <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/overview`} data-tooltip="Overview" className="tooltipstered">●</li>
                            <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/update_name`} data-tooltip="Update Name" className="tooltipstered">●</li>
                                
                    <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/update_phone`} data-tooltip="Update Phone" className="tooltipstered">●</li>
                    <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/update_email`} data-tooltip="Update Email" className="tooltipstered">●</li>

                                <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/update_address`} data-tooltip="Update Address" className="tooltipstered">●</li>
                                    
                    <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/update_password`} data-tooltip="Update Password" className="tooltipstered">●</li>
                        
                            <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/update_credit_card`} data-tooltip="Update Credit Card" className="tooltipstered">●</li>
                    <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/users`} data-tooltip="Users" className="tooltipstered">●</li>


                    <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/pref_app`} data-tooltip="App Settings" className="tooltipstered active">●</li>

                    <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/billing_history`} data-tooltip="Billing History" className="tooltipstered">●</li>
                    <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/upgrade`} data-tooltip="Change Plan" className="tooltipstered">●</li>

                            <li data-href-type="all" data-href={`https://${settings.domain}/user-profile/cancel`} data-tooltip="Cancel" className="tooltipstered">●</li>
                </ul>
                <ul className="big-menu">
                    <li>
                        <span className="goto-section" data-href-type="all" data-href={`https://${settings.domain}/user-profile/overview`}>Account</span>

                    </li>


                    <li className="active">
                        <span className="goto-section" data-href-type="all" data-href={`https://${settings.domain}/user-profile/pref_app`}>Preferences</span>

                        <ul className="font-light">
                            <li >
                                <a href={`https://${settings.domain}/user-profile/pref_app`}>App Settings</a>
                            </li>
                            <li className="active">
                                <a href={`/user-profile-v2/dashboard-settings`}>Dashboard</a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <span className="goto-section" data-href-type="all" data-href={`https://${settings.domain}/user-profile/private_labeling/overview`}>Private Labeling</span>

                    </li>


                    <li>
                        <span className="goto-section" data-href-type="all" data-href={`https://${settings.domain}/user-profile/billing_history`}>Billing &amp; Plans</span>

                    </li>
                </ul>
            </div>
            <div className='overview settings'>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    </div>
}

export default UserProfileBase