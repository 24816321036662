import axios from '../../../common/axiosRateLimit'
import qs from 'qs'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'


const fetchNetworks = createAsyncThunk('fetch_menu_networks',
    async (arg) => {
        const response = await axios.get('/menu-api/networks?' + qs.stringify({
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            country: arg.query.country,
            platform: arg.query.platform
        }))

        return response.data
    })

const networksSlice = createSlice({
    name: 'network-menu',
    initialState: {
        status: 'idle',
        networks: []
    },
    reducers: {
        resetNetworks: (state) => {
            state.status = 'idle'
            state.networks = []
        }
    },
    extraReducers: {
        [fetchNetworks.pending]: (state, action) => {
            state.status = 'pending'
            state.networks = []
        },
        [fetchNetworks.fulfilled]: (state, action) => {
            state.networks = action.payload.rows
            state.status = 'done'
        },
        [fetchNetworks.rejected]: (state, action) => {
            state.networks = []
            state.status = 'failed'
        }
    }
})

const selectNetworks = (state) => state.menu.topAds.networks

export {fetchNetworks, selectNetworks}
export const {resetNetworks} = networksSlice.actions

export default networksSlice.reducer