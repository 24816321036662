import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchRecentSearches = createAsyncThunk('fetchRecentSearches',
    async (arg) => {
        const response = await axios.get('/callisto/recent-searches')
        return response.data
    })

const recentSearchSlice = createSlice({
    name: 'recentSearches',
    initialState: {
        status: 'idle',
        searches: []
    },
    reducers: {
        resetRecentSearch(state) {
            state.status = 'idle'
        }
    },
    extraReducers: {
        [fetchRecentSearches.pending]: (state, action) => {
            state.status = 'pending'
            state.searches = []
        },
        [fetchRecentSearches.fulfilled]: (state, action) => {
            state.status = 'done'
            state.searches = action.payload.recentSearches
        }
    }
})

const selectRecentSearches = (state) => state.searches.recentSearches

export {fetchRecentSearches, selectRecentSearches}
export const {resetRecentSearch} = recentSearchSlice.actions

export default recentSearchSlice.reducer