import React from 'react';
import { capitalize } from 'lodash';


const AddToDashboard = props => {

    return (
        <li>
            <input type="checkbox" id={`add-${props.profileName}-to-dashboard`}
                   value="add-to-dashboard"
                   name={`add${capitalize(props.profileName)}ToDashboard`}
                   checked={props.isAdded}
                   onChange={props.onDashboardStatusChange}/>
            <label htmlFor={`add-${props.profileName}-to-dashboard`}>
                <span>Add this {props.profileName} to my Dashboard</span>
            </label>
        </li>
    );
}

const AddPublisherToDashboard = props => {
    return <AddToDashboard profileName="publisher" {...props} />
}

const AddAdvertiserToDashboard = props => {
    return <AddToDashboard profileName="advertiser" {...props} />
}

export default AddAdvertiserToDashboard;
export {
    AddPublisherToDashboard
}