import _ from "lodash";
import { daysToDate } from "../common/crawlDays";

window.analytics = window.analytics || {
  track: function () {},
  identify: function () {},
};

const trackAdvertiserProfile = _.once(function (advertiser, country) {
  try {
    window.analytics.track("viewed advertiser profile", {
      advertiser_name: advertiser,
      advertiser_country: country,
      app_type: "Paid",
    });
  } catch (e) {}
});

const hitPlanLimit = function (featureName, suggestedProduct) {
  try {
    window.analytics.identify({
      email: window.AdbeatSettings.user.email,
      feature_name: featureName,
      plan_name: window.AdbeatSettings.current_product_name,
      plan_recommendation: suggestedProduct,
    });

    window.analytics.track("hit plan limit", {
      email: window.AdbeatSettings.user.email,
      feature_name: featureName,
      plan_name: window.AdbeatSettings.current_product_name,
      plan_recommendation: suggestedProduct,
    });
  } catch (e) {}
};

const performedSearch = (query, termType) => {
  try {
    window.analytics.track("performed search", {
      search_query: query,
      search_page: window.location.pathname,
      search_type: termType,
      app_type: "Paid",
    });
  } catch (e) {}
};

const savedAd = (ad, collection) => {
  try {
    window.analytics.track("saved ad", {
      email: window.AdbeatSettings.user.email,
      collection_name: collection.name,
      ad_hash: ad.adHash,
      first_seen: daysToDate(ad.trendLine.firstSeen),
      last_seen: daysToDate(ad.trendLine.lastSeen),
      ad_size: ad.adInfo.adSize,
      ad_type: ad.adInfo.adType,
    });
  } catch (e) {}
};

export { trackAdvertiserProfile, hitPlanLimit, performedSearch, savedAd };
