import axios from '../../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";


const fetchPredefinedPublishers = createAsyncThunk('fetchPredefinedPublishers',
    async () => {
        let response = await axios.get("/callisto/publisher-sets/default-sets")

        return response.data
    })


const initialState = {
    groups: [],
    status: 'idle',
}


const predefinedPublishersSlice = createSlice({
    name: 'predefinedSets',
    initialState,
    extraReducers: {
        [fetchPredefinedPublishers.fulfilled]: (state, action) => {
            state.status = 'done'
            state.groups = []
            for (const key in action.payload) {
                if (!action.payload.hasOwnProperty(key)) {
                    continue
                }
                let groupContent = action.payload[key].split(',')
                state.groups.push({
                    name: key,
                    country: groupContent[0],
                    publishers: groupContent.slice(1)
                })
            }
        }
    }
})

const selectPredefinedPublishersData = (state) => state.publisherUiCompetitiveSets.predefinedSets

export default predefinedPublishersSlice.reducer


export {
    fetchPredefinedPublishers,
    selectPredefinedPublishersData
}
