import {useEffect, useRef} from "react";
// eslint-disable-next-line
import jquery from 'jquery'
import {getOnInitialize} from '../common/selectizeHideInput'
import orderSelectizeOptions from "../common/orderSelectizeOptions";

const SelectMenu = (props) => {
    const selectElement = useRef(null)
    const value = props.value

    const handleChange = (value) => {
        props.onChange(value)
    }

    const options = props.options

    useEffect(() => {
        if (selectElement.current) {
            const selectizeOptions = Object.assign({
                labelField: 'text',
                valueField: 'value'
            }, props.selectizeOptions || {})

            selectizeOptions.onInitialize = getOnInitialize(
                selectizeOptions, selectElement.current)

            const selector = jquery(selectElement.current)
                .selectize(selectizeOptions)[0].selectize

            selector.clearOptions()
            selector.addOption(orderSelectizeOptions(options))
            selector.setValue(value, true)

            // Immediate change of current option label
            let node = options.filter((item) => item.value === value).shift()
            if (node) {
                let displayedOption = selector.getElementWithValue(value, selector.$control.children())
                displayedOption.text(node.text)
            }

            selector.off('change')
            selector.on('change', handleChange)
        }
    })

    return (
        <>
            <select
                name={props.name}
                multiple={props.multiple || false}
                placeholder={props.placeholder || ""}
                ref={selectElement}>
            </select>
            <div className="is-initialized"/>
        </>
    );
}

export default SelectMenu;