import { useEffect, useRef } from "react";
// eslint-disable-next-line
import selectize from 'selectize'
import jquery from 'jquery'
import {getOnInitialize} from '../../common/selectizeHideInput'
import orderSelectizeOptions from "../../common/orderSelectizeOptions";

const MetricSelect = (props) => {
    const selectElement = useRef(null)

    const handleChange = (metric) => {
        props.onChange(metric)
    }

    useEffect(() => {
        if (selectElement.current) {
            const selectizeOptions = {
                labelField: 'label',
                valueField: 'value'
            }

            selectizeOptions.onInitialize = getOnInitialize(
                    selectizeOptions, selectElement.current)
            
            const selector = jquery(selectElement.current)
                .selectize(selectizeOptions)[0].selectize
            
            selector.clearOptions()
            selector.addOption(orderSelectizeOptions([
                {value: "sumAdSpend", label: 'Ad Spend'},
                {value: "sumImpressions", label: 'Ad Impressions'}
            ]))
            selector.setValue(props.metric, true)
            selector.off('change')
            selector.on('change', handleChange)
        }
    })

    return (
        <div className="metric-property">
            <div className="property no-border tight-arrow">
                <select name="adMetricId" ref={selectElement}>
                </select>
            </div>
        </div>
    );
}

export default MetricSelect;