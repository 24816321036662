import React, {useEffect, useRef} from "react";
import { times } from "lodash";
import Trend from './Trend'
import {useSelector} from 'react-redux'
import LoadingState from './LoadingState'
import {daysToDate} from '../common/crawlDays'
import * as d3 from 'd3'
import { settingsSelector } from "../reducers/settings"
import { add, format } from "date-fns";


const TrendLineChart = (props) => {
    const element = useRef(null)

    const fixedCountBars = (inBars) => {
        if (!inBars.length) {
            return []
        }

        const barCount = props.maxBars || Math.max(Math.min(inBars.length, 30), 7)
        const increment = inBars.length / barCount

        return times(barCount, i => {
            const start = Math.floor(increment * i)
            const end = Math.floor(increment * (i + 1))

            if (start === end) {
                return inBars.slice(start)[0]
            }
            return inBars.slice(start, end).reduce((a, b) => a + b) / (end - start)
        })
    }

    const bars = fixedCountBars(props.metrics);

    const margin = {top: 0, right: 0, bottom: 0, left: 0}

    const width = (props.width || 150) - margin.left - margin.right,
        height = (props.height || 30) - margin.top - margin.bottom;

    useEffect(() => {
        d3.select(element.current).select('svg').remove()

        var svg = d3.select(element.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        var x = d3.scaleBand()
            .rangeRound([0, width], 0, 0);

        var y = d3.scaleLinear()
                .range([height, 0]);

        x.domain(bars.map((b, idx) => idx));
        y.domain([0, Math.max.apply(null, bars) || 1]);

        svg.selectAll(".bar")
            .data(bars)
            .enter().append("rect")
            .attr("class", function (d) {
                var className = 'bar';
                return className;
            })
            .attr("x", function (d, i) {
                return x(i);
            })
            .attr("width", x.bandwidth() - 1)
            .attr("y", function (d) {
                return Math.min(Math.floor(y(d)), height - 1);
            })
            .attr("class", function (d) {
                return 'bar color-' + (props.color || 0);
            })
            .attr("height", function (d) {
                return Math.max(Math.ceil(height - y(d)), 1);
            });
    })

    return (
        <div className={`trend-line ${props.extraClass || ''}`} ref={element}>
        </div>
    )
}

const TrendLine = (props) => {
    const settings = useSelector(settingsSelector)
    const data = props.data
    const hideTrend = props.hideTrend || false
    const width = props.width || 150
    const height = props.height || 30
    const containerHeight = props.containerHeight || 70

    let dateRange = 0
    try {
        dateRange = data.trendLine.metrics[settings.metricField].length
    }
    catch(e) {
        dateRange = 0
    }

    return (
        <>
        {(!hideTrend && data.trendlineStatus === 'done' && [30, 90].includes(dateRange)) &&
          <Trend trend={data.trendLine.trends[settings.metricField]}></Trend>}

        <div className="bar-chart" style={{width: width, height: containerHeight}}>
          <LoadingState show={data.trendlineStatus !== 'done'}
            left={27}></LoadingState>
          {data.trendlineStatus === 'done' &&
          <>
          <div className="chart-scales">
            <div className="start">
              {format(daysToDate(data.trendLine.firstDate), 'MMM d, yyyy')}
            </div>
            <div className="end">
              {format(add(
                  daysToDate(data.trendLine.firstDate),
                    {days: dateRange - 1}),
                'MMM d, yyyy')}
            </div>
          </div>
          <TrendLineChart metrics={data.trendLine.metrics[settings.metricField]}
            {...props}
            width={width}
            height={height}
            color={props.colorIdx || 0}></TrendLineChart>
          </>}
        </div>
        </>
     );
}

export {TrendLineChart}

export default TrendLine
