import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: 'modalSlice',
    initialState: {
        name: null,
        params: {}
    },
    reducers: {
        showModal(state, action) {
            state.name = action.payload.name
            state.params = action.payload.params
        },
        hideModal(state) {
            state.name = null
            state.params = {}
        }
    }
})

const modalSelector = (state) => state.modal
export {modalSelector}
export default modalSlice.reducer
export const {showModal, hideModal} = modalSlice.actions