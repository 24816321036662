import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'
import qs from "qs";


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchAdvertiserTrendlines = createAsyncThunk('fetchAdvertiserTrendlines',
    async (arg) => {
        let query = {
            country: arg.query.country,
            platform: arg.query.platform,
            network: arg.query.network,
            publisher: arg.query.publisherSet,
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            metrics: arg.query.metrics,
            includeEntities: JSON.stringify(arg.entities)
        }

        const response = await axios.post(
            '/data-api/trendlines', qs.stringify(query), {
                cancelToken: getCancelSource().token
            })

        return response.data
    })


const publisherUiAdvertiserTrendlines = createSlice({
    name: 'publisherUiAdvertiserTrendline',
    initialState: {
        requestedAdvertises: {},
        advertisers: {},
        querySettings: {
            network: '',
            platform: '',
            country: '',
            startDate: 0,
            endDate: 0,
            publisherSet: ''
        }
    },
    reducers: {
        requestAdvertiserTrendlines(state, action) {
            let keys = [
                'network',
                'platform',
                'country',
                'startDate',
                'endDate',
                'publisherSet'
            ]

            let payloadQuery = action.payload.query

            const queryChanged = keys.some((key) => {
                return state.querySettings[key] !== payloadQuery[key]
            })

            if (queryChanged) {
                state.querySettings = payloadQuery
                state.requestedAdvertises = {}
                state.advertisers = {}
                cancelSource.cancel()
                cancelSource = axios.CancelToken.source()
            }

            let requestEntities = []
            action.payload.entities.forEach((entity) => {
                let publisher = entity.publisher || 'all'
                let advertiser = entity.advertiser

                const key = advertiser + '|' + publisher

                if (!state.requestedAdvertises[key]) {
                    requestEntities.push(entity)
                }
                state.requestedAdvertises[key] = true
            })

            if (requestEntities.length > 0) {
                action.asyncDispatch(fetchAdvertiserTrendlines({
                    query: payloadQuery,
                    entities: requestEntities
                }))
            }
        }
    },
    extraReducers: {
        [fetchAdvertiserTrendlines.fulfilled]: (state, action) => {
            action.payload.rows.forEach((item) => {
                const publisher = item.entity.publisher || 'all'
                const key = item.entity.advertiser + '|' + publisher

                state.advertisers[key] = {
                    trendLine: item,
                    trendlineStatus: 'done'
                }
            })
        }
    }
})

const selectPublisherUiAdvertiserTrendline = (advertiser, publisher) => state => {
    const key = advertiser + '|' + publisher

    if (state.publisherUi.advertiserTrendline.advertisers.hasOwnProperty(key)) {
        return state.publisherUi.advertiserTrendline.advertisers[key]
    }
    return {
        trendlineStatus: 'loading',
        trendLine: {}
    }
}

export const {requestAdvertiserTrendlines} = publisherUiAdvertiserTrendlines.actions
export {selectPublisherUiAdvertiserTrendline}

export default publisherUiAdvertiserTrendlines.reducer
