import axios from '../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchDashboardAdvEventDetails = createAsyncThunk(
    'fetchDashboardAdvEventDetails',
    async (arg) => {
        const request = axios.get("/data-api/ads", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        const response = await request

        let result = response.data
        return result
    });


const userDashboardAdvEventDetailsSlice = createSlice({
    name: 'userDashboardAdvEventDetailsSlice',
    initialState: {
        status: "idle",
        data: [],
    },
    reducers: {
        reset(state) {
            state.status = "idle";
            state.data = [];
        },
    },
    extraReducers: {
        [fetchDashboardAdvEventDetails.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchDashboardAdvEventDetails.fulfilled]: (state, action) => {
            state.status = 'done'
            if (action.payload.rows.length > 0) {
                state.data = action.payload.rows
            }
            else {
                state.data = [] 
            }
        },
    }
})


const userDashboardAdvEventDetailsSelector = state => state.userDashboard.advEventDetails
export const {
    reset
} = userDashboardAdvEventDetailsSlice.actions
export { fetchDashboardAdvEventDetails,
    userDashboardAdvEventDetailsSelector }

export default userDashboardAdvEventDetailsSlice.reducer
