import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchTopPublishers = createAsyncThunk('fetchTopAdvertiserPublishers',
    async (arg) => {
        const response = await axios.get("/data-api/publishers", {
            params: {
                ...arg.query
            }
        })

        return response.data
    })

const topPublishersSlice = createSlice({
    name: 'topAdvertisersPublishers',
    initialState: {},
    extraReducers: {
        [fetchTopPublishers.pending]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'pending'
            }
        },
        [fetchTopPublishers.fulfilled]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: action.payload.rows,
                status: 'done'
            }
        }
    }
})

export const selectTopPublishers = (advertiser, network) => (state) => {
    let key = advertiser + network
    if (key in state.topAdvertisers.publishers) {
        return state.topAdvertisers.publishers[key]
    } else {
        return {
            rows: [],
            status: 'idle'
        }
    }
}

export {fetchTopPublishers}
export default topPublishersSlice.reducer