import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'
import qs from "qs";


const postPublisherUiReport = createAsyncThunk('postPublisherUiReport',
    async (arg) => {
        const response = await axios.post("/callisto/reports/generate-publisher-tools-report",
            qs.stringify(arg.query)
        )
        return response.data
    })


const canGenerateReport = createAsyncThunk('canGenerateReport',
    async () => {
        const response = await axios.get("/callisto/reports/can-generate-report")
        return response.data
    })


const publisherUiReportSlice = createSlice({
    name: 'report',
    initialState: {
        canGenerateReport: true,
        canGenerateReportStatus: 'idle',
        status: 'idle',
        errors: [],
        reply: {}
    },
    reducers: {
        resetReportState(state) {
            state.errors = []
            state.reply = {}
            state.status = 'idle'
        }
    },
    extraReducers: {
        [postPublisherUiReport.pending]: (state) => {
            state.status = 'pending'
            state.errors = []
        },
        [postPublisherUiReport.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.status = 'error'
                state.errors = action.payload.errors
                state.reply = action.payload
                return
            }
            state.status = 'done'
        },
        [postPublisherUiReport.rejected]: (state, action) => {
            state.status = 'error'
            state.errors = ["Failed to submit report. Try again later or contact support"]
        },
        [canGenerateReport.fulfilled]: (state, action) => {
            state.canGenerateReport = action.payload.canGenerate
            state.canGenerateReportStatus = 'done'
        }
    }
})


const selectReportStatus = (state) => state.publisherUi.report


export {
    selectReportStatus
}

export {
    postPublisherUiReport,
    canGenerateReport
}

export const {
    resetReportState
} = publisherUiReportSlice.actions


export default publisherUiReportSlice.reducer