import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchTopPublishers = createAsyncThunk('fetchTopAdPublishers',
    async (arg) => {
        const response = await axios.get("/data-api/publishers", {
            params: {
                ...arg.query
            }
        })

        return response.data
    })

const topPublishersSlice = createSlice({
    name: 'topAdsPublishers',
    initialState: {},
    reducers: {
        resetTopAdsPublishers(state) {
            return {}
        }
    },
    extraReducers: {
        [fetchTopPublishers.pending]: (state, action) => {
            let key = action.meta.arg.query.adHash + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'pending'
            }
        },
        [fetchTopPublishers.fulfilled]: (state, action) => {
             if ('errors' in action.payload) {
                let key = action.meta.arg.query.adHash + action.meta.arg.query.network
                state[key] = {
                    rows: [],
                    status: 'error'
                }
            } else {
                let key = action.meta.arg.query.adHash + action.meta.arg.query.network
                state[key] = {
                    rows: action.payload.rows,
                    status: 'done'
                }
            }
        },
        [fetchTopPublishers.rejected]: (state, action) => {
            let key = action.meta.arg.query.adHash + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'error'
            }
        }
    }
})

export const selectTopPublishers = (adHash, network) => (state) => {
    let key = adHash + network
    if (key in state.topAds.publishers) {
        return state.topAds.publishers[key]
    } else {
        return {
            rows: [],
            status: 'idle'
        }
    }
}

export const {resetTopAdsPublishers} = topPublishersSlice.actions
export {fetchTopPublishers}
export default topPublishersSlice.reducer