import { createSlice } from "@reduxjs/toolkit";


 const setUpProducts = (settings) => {
        let list = [];
        const upgradesTo = settings
                .all_products[settings.current_product].upgradesTo;
        const len = upgradesTo.length;

        const addProductToList = function(p) {
            list.push({
                productId: p,
                name: settings.all_products[p].name
            });
        };

        const addMoreSeatsToList = function() {
            list.push({
                productId: null,
                name: "Add more seats"
            });
        };

        upgradesTo.forEach(productId => {
            addProductToList(productId)  
        })
        
        if (len === 0 && !settings.isWinmoUser) {
            addMoreSeatsToList();
        }

        return list
    }

const setUpProductName = (settings) => {
    return settings.all_products[settings.current_product].name
}

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        currentProductName: '',
        areProductsShown: false,
        status: 'idle'
    },
    reducers: {
        initialize(state, action) {
            state.products = setUpProducts(action.payload)
            state.currentProductName = setUpProductName(action.payload)
            state.status = 'done'
        },
        show(state) {
            state.areProductsShown = true
        },
        hide(state) {
            state.areProductsShown = false
        }
    }
})

const selectProducts = (state) => state.headerProducts

export { selectProducts }
export const { initialize, show, hide } = productsSlice.actions

export default productsSlice.reducer