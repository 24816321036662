import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingState from "../../components/LoadingState";
import useTooltip from "../../hooks/useTooltip";
import {
  fetchDestinationUrls,
  selectDestinationUrls
} from "./destinationUrlSlice";
import { guessFormat } from "../../misc/currency";
import { settingsSelector } from '../../reducers/settings'
import qs from 'qs'
import { showUpgradeModal } from "../../components/modals/UpgradeModal";

const DestinationUrls = (props) => {
  const tooltip = useTooltip('destination_urls')
  const downloadTooltip = useTooltip('download_excel_file_button')
  const settings = useSelector(settingsSelector)
  const dispatch = useDispatch()
  const destinationUrls = useSelector(selectDestinationUrls(props.adHash, props.network))
  const publisher = props.publisher || 'all'

  useEffect(() => {
    if (destinationUrls.status === 'idle' && props.show) {
      const query = {
        adHash: props.adHash,
        startDate: props.startDate,
        endDate: props.endDate,
        publisher,
        pageNumber: 1,
        pageSize: 20,
        metrics: settings.metricsList,
        sortBy: settings.metricField,
        sortOrder: 'desc',
        advertiser: props.advertiser,
        network: props.network,
        platform: props.platform,
        country: props.country
      }
      if (props.channel) {
        query.channel = props.channel
      }
      if (props.publisher) {
        query.publisher = props.publisher
      }
      if (props.campaign) {
        query.campaign = props.campaign
      }
      if (props.brand) {
        query.brand = props.brand
      }
      dispatch(fetchDestinationUrls({
        query
      }))
    }
  })

  const handleReportClick = () => {
    const query = {
      country: props.country,
      platform: props.platform,
      advertiser: props.advertiser,
      adHash: props.adHash,
      startDate: props.startDate,
      endDate: props.endDate,
      network: props.network
    }
    if (props.channel) {
      query.channel = props.channel
    }
    if (props.publisher) {
      query.publisher = props.publisher
    }
    if (props.campaign) {
      query.campaign = props.campaign
    }
    if (props.brand) {
      query.brand = props.brand
    }

    if (settings.exportable_data) {
      window.location.href = '/callisto/reports/destinationUrls?' +
        qs.stringify(query)
    } else {
      showUpgradeModal(dispatch, {
        name: 'reports',
        value: null
      })
    }
  }

  if (!props.show) {
    return null
  }

  return (
    <div
      className="destination-urls unfoldable"
      data-bind="visible: areDestUrlsVisible"
    >
      <LoadingState show={destinationUrls.status !== 'done' && destinationUrls.status !== 'error'}></LoadingState>
      <h3 className=" left">Landing Pages</h3>
      <div
        className="help-icon left tooltipstered"
        data-tip
        data-for={tooltip.path}
      >
        <i className="fa-sharp fa-light fa-circle-question fa-2x"></i>
      </div>
      <div
        className="help-icon left"
        data-tip
        data-for={downloadTooltip.path}
        onClick={handleReportClick}
        data-value={(settings.exportable_data ?
          '' : settings.upgraderRequiredValue) + "exportable-data"}
      >
        <i className="fa-light fa-sharp fa-2x fa-circle-arrow-down"></i>
      </div>
      <table className="clear">
        <thead>
          <tr>
            <th></th>{settings.hasSpend && <th>Spend</th>}<th>Impr</th>
          </tr>
        </thead>
        <tbody>
          {destinationUrls.rows.map(destUrl => (
            <tr key={destUrl.destinationUrl}>
              <td className="url">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={destUrl.destinationUrl}
                >
                  {destUrl.destinationUrl}
                </a>
              </td>
              {settings.hasSpend &&
                <td className="capitalized">{guessFormat(destUrl.sumAdSpend, '$0.[0]a', props.country)}</td>}
              <td className="capitalized">{guessFormat(destUrl.sumImpressions, '0.[0]a')}</td>
            </tr>))}
        </tbody>
      </table>
      {tooltip.component}
      {downloadTooltip.component}
    </div>
  );
}

export default DestinationUrls;