import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../common/axiosRateLimit'
import * as d3 from 'd3'

const fetchPathTypes = createAsyncThunk('pathTypes',
    async () => {
        const response = await axios.get('/path_types.csv')
        return response.data
    })

const pathTypesSlice = createSlice({
    name: 'pathTypes',
    initialState: {
        pathTypes: {
            status: 'idle'
        },
        status: 'init'
    },
    extraReducers: {
        [fetchPathTypes.fulfilled]: (state, action) => {
            state.status = 'succeeded'

            let pathTypeMap = {
                'Network': 'network',
                'Ad Server': 'adServer',
                'Ad Server/Network': 'adServerOrNetwork',
                'Publisher Ad Server/Network': 'publisherAdServerOrNetwork',
                'Exchange': 'exchange',
                'Unknown': 'unknown',
                'Ignore': 'ignore',
                'Unmatched': 'unmatched',
                'SSP': 'ssp',
                'RTB': 'rtb'
            };

            let result = {};
            let mapping = {};

            d3.csvParseRows(action.payload).forEach(function (item) {
                result[item[0]] = {
                    pathType: pathTypeMap[item[1]],
                    pathTypeName: item[1]
                };
                result[item[2]] = {
                    pathType: pathTypeMap[item[1]],
                    pathTypeName: item[1]
                };
                if (item[2]) {
                    mapping[item[0]] = item[2];
                }
            });
            state.pathTypes = {
                nameToType: pathTypeMap,
                pathTypes: result,
                mapping: mapping,
                status: 'loaded'
            }
        }
    },
})

const pathTypesSelector = (state) => state.pathTypes.pathTypes

export {fetchPathTypes, pathTypesSelector}

export default pathTypesSlice.reducer