import axios from '../../common/axiosRateLimit'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

const fetchUpgradeProduct = createAsyncThunk('fetchUpgradeProduct',
    async (arg) => {
        const result = await axios.get(
            '/marketing-api/v1/billing/upgrade.json?product_tag=' +
            arg.query.product_tag)

        return result.data
    }
)

const upgradeSlice = createSlice({
    name: 'upggrade-flow',
    initialState: {
        status: 'idle',
        product: {}
    },
    reducers: {
        resetProduct(state) {
            state.status = 'idle'
            state.product = {}
        }  
    },
    extraReducers: {
        [fetchUpgradeProduct.pending]: (state, action) => {
            state.status = 'pending'
        },
        [fetchUpgradeProduct.fulfilled]: (state, action) => {
            state.status = 'done'
            state.product = action.payload
        },
        [fetchUpgradeProduct.rejected]: (state, action) => {
            state.status = 'failed'
        }
    }
})

const selectUpgradeProduct = (state) => state.upgradeFlow

export {fetchUpgradeProduct, selectUpgradeProduct}
export const { resetProduct } = upgradeSlice.actions

export default upgradeSlice.reducer