import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../common/axiosRateLimit'
import qs from "qs";

let cancelSourceUp = axios.CancelToken.source()
let cancelSourceDown = axios.CancelToken.source()
const getCancelSource = (direction) => direction === 'up' ? cancelSourceUp : cancelSourceDown
const setCancelSource = (source, direction) => {
    if (direction === 'up') {
        cancelSourceUp = source
    } else {
        cancelSourceDown = source
    }
}

const fetchPeriods = createAsyncThunk('fetchPeriods',
    async () => {
        let path = '/callisto/movers-and-shakers/periods';

        const response = await axios.get(path)
        return response.data
    })


const requestMoversReport = createAsyncThunk('requestMoversReport',
    async (arg) => {
        const response = await axios.post(
            '/callisto/reports/moversAndShakers',
            qs.stringify(arg.query)
        )

        return response.data
    })

const fetchScheduleMoversReport = createAsyncThunk('requestScheduleMoversReport',
    async (arg) => {
        const response = await axios.post(
            '/callisto/reports/scheduleMoversAndShakers',
            qs.stringify(arg.query)
        )

        return response.data
    })

const fetchMoversAndShakersData = createAsyncThunk('fetchMoversAndShakersData',
    async (arg) => {
        getCancelSource(arg.query.direction).cancel('cancel')
        setCancelSource(axios.CancelToken.source(), arg.query.direction)
        
        let path = '/callisto/movers-and-shakers/' + arg.query.resourceType;

        let category = arg.query.category || ""
        if (category.length <= 1) {
            category = 'all'
        }

        let query = {
            adMetric: arg.query.adMetric,
            start: arg.query.start,
            rows: arg.query.rows,
            period: arg.query.period,
            network: arg.query.network,
            category: category,
            country: arg.query.country,
            direction: arg.query.direction,
        }

        const response = await axios.get(path, {
            params: query,
            cancelToken: getCancelSource(arg.query.direction).token
        })

        return response.data
    })


const moversAndShakersSlice = createSlice({
    name: 'moversAndShakers',
    initialState: {
        upData: {
            'hits': [],
            'numHits': 0,
            'status': 'idle',
            'query': {}
        },
        downData: {
            'hits': [],
            'numHits': 0,
            'status': 'idle',
            'query': {}
        },
        errors: [],
        periods: {
            data: {},
            status: 'idle'
        },
        report: {
            status: 'idle',
            scheduleStatus: 'idle'
        }
    },
    reducers: {
        resetDataOnly(state, action) {
            let direction = action.payload
            state[direction + 'Data'].status = 'idle'
            state.errors = []
        },
        resetData(state, action) {
            let direction = action.payload
            state[direction + 'Data'].status = 'idle'
            state[direction + 'Data'].numHits = 0
            state.errors = []
        },
        resetErrors(state) {
            state.errors = []
        },
        resetReportState(state) {
            state.report.status = 'idle'
            state.report.scheduleStatus = 'idle'
        }
    },
    extraReducers: {
        [fetchPeriods.fulfilled]: (state, action) => {
            state.periods.data = action.payload
            state.periods.status = 'done'
        },
        [fetchMoversAndShakersData.pending]: (state, action) => {
            let direction = action.meta.arg.query.direction

            state[direction + 'Data'].status = 'pending'
            state[direction + 'Data'].hits = []
            state[direction + 'Data'].query = action.meta.arg.query
        },
        [fetchMoversAndShakersData.fulfilled]: (state, action) => {
            let direction = action.meta.arg.query.direction
            state[direction + 'Data'].hits = action.payload.result
            state[direction + 'Data'].numHits = action.payload.count
            state[direction + 'Data'].status = 'done'
            state[direction + 'Data'].query = action.meta.arg.query

            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = [action.payload.message]
            }
        },
        [fetchMoversAndShakersData.rejected]: (state, action) => {
            if (action.error.message !== 'cancel') {
                state.errors = ["Cannot load data"]
            }
        },
        [requestMoversReport.pending]: (state) => {
            state.report.status = 'pending'
        },
        [requestMoversReport.rejected]: (state) => {
            state.errors = ["Cannot generate report"]
            state.report.status = 'failed'
        },
        [fetchScheduleMoversReport.pending]: (state) => {
            state.report.scheduleStatus = 'pending'
        },
        [fetchScheduleMoversReport.rejected]: (state) => {
            state.errors = ["Cannot schedule report"]
            state.report.scheduleStatus = 'failed'
        },
        [requestMoversReport.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.report.status = 'done'
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
                state.report.status = 'fail'
            }
            else {
                state.errors = ['Cannot generate report']
                state.report.status = 'fail'
            }
        },
        [fetchScheduleMoversReport.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.report.scheduleStatus = 'done'
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
                state.report.scheduleStatus = 'fail'
            }
            else {
                state.errors = ['Cannot schedule report']
                state.report.scheduleStatus = 'fail'
            }
        }
    }
})

const selectMoversAndShakersPeriods = (state) => state.moversAndShakers.periods
const selectMoversAndShakersData = (direction) => (state) => state.moversAndShakers[direction + 'Data']
const selectMoversAndShakersErrors = (state) => state.moversAndShakers.errors
const selectReportState = (state) => state.moversAndShakers.report

export const {
    resetData, resetErrors, resetDataOnly, resetReportState
} = moversAndShakersSlice.actions

export {
    selectMoversAndShakersPeriods,
    selectMoversAndShakersData,
    selectMoversAndShakersErrors,
    selectReportState,
    fetchPeriods,
    fetchMoversAndShakersData,
    fetchScheduleMoversReport,
    requestMoversReport
}

export default moversAndShakersSlice.reducer
