import { useDispatch, useSelector } from "react-redux";
import upgradeApi, { getMissedFeatureId, getSuggestedProduct, useSettings } from "../../misc/upgradeApi";
import { settingsSelector } from "../../reducers/settings"
import handleViewport from 'react-in-viewport'
import { selectUpgradeProduct, fetchUpgradeProduct, resetProduct } from './upgradeSlice'
import { useEffect } from "react";
import _ from "lodash";
import Features from "./Features";
import { goToUserProfileUpgrade } from "../../common/Adbeat";
import {hitPlanLimit} from '../../misc/effinSegment'
import usePrevious from "../../hooks/usePrevious";


const UpgradeScreen = (props) => {
    const product = useSelector(selectUpgradeProduct)
    const dispatch = useDispatch()
    const settings = useSelector(settingsSelector)
    useSettings(settings)
    var missingFeature = getMissedFeatureId(props.name, props.value);
    var suggestedProductName = getSuggestedProduct(missingFeature, props.value);

    const handleUpgrade = () => {
        goToUserProfileUpgrade(suggestedProductTexts.button ? suggestedProductName : '')
    }

    let priceDiff = null;
    var suggestedProduct = { ...settings.all_products[suggestedProductName] };
    const prevSuggestedProductName = usePrevious(suggestedProductName)
    var currentProduct = { ...settings.all_products[settings.current_product] };
    var featureName = upgradeApi.featuresDescription[missingFeature].name;
    var featureDescription = upgradeApi
        .featuresDescription[missingFeature].description_name ||
        upgradeApi.featuresDescription[missingFeature].name;

    if (typeof featureName === 'function') {
        featureName = featureName(props.value, suggestedProduct);
    }

    if (typeof featureDescription === 'function') {
        featureDescription = featureDescription(props.value, suggestedProduct);
    }

    useEffect(() => {
        if (product.status === 'idle') {
            dispatch(fetchUpgradeProduct({ query: { product_tag: suggestedProductName } }))
        }
    }, [product.status, dispatch, suggestedProductName])

    useEffect(() => {
        if (suggestedProductName !== prevSuggestedProductName) {
            dispatch(resetProduct())
        }
    }, [suggestedProductName, prevSuggestedProductName, dispatch])

    if (!_.isEmpty(product)) {
        currentProduct = {
            ...currentProduct,
            ...product.product.current_product
        }
        suggestedProduct = {
            ...suggestedProduct,
            ...product.product.new_product
        }
        priceDiff = product.product.upgrade_price
    }

    const currentProductTexts = suggestedProduct ?
        upgradeApi.getCurrentPlanText(
            settings.current_product,
            suggestedProduct.name
        ) : ''

    const suggestedProductTexts = upgradeApi.getSuggestedProductText(
        settings.current_product,
        suggestedProductName
    )

    hitPlanLimit(featureName, suggestedProductName)
        
    return (
        <div className="upgrade-required-block">
            <div className="title">
                <h3><b>Upgrade your Adbeat subscription to <span>{featureName}</span></b></h3>
                <div>
                    Get <b> {featureDescription} </b>
                    and additional premium features with Adbeat <span>{suggestedProduct.name}</span>
                </div>
            </div>

            <div className="plan current-plan left">
                <div className="plan-title">Current plan</div>
                <div className="plan-price">
                    You have the <b><span>{currentProduct.name}</span> plan</b><br />
                    for $<span>{currentProduct.price}</span> per <span>{currentProduct.interval}</span>
                </div>

                <div className="h-line"></div>

                <div className="features">
                    <Features id={currentProductTexts}></Features>
                </div>
            </div>

            <div className="migrate-arrow left"></div>
            <div className="plan new-plan left">
                <div className="plan-title">Recommended plan</div>
                <div className="plan-price">
                    We recommend the <b><span>{suggestedProduct.name}</span> plan</b>
                    {priceDiff &&
                        <>
                            <br />
          for $<span>{priceDiff}</span> more per <span>{suggestedProduct.interval}</span>
                        </>
                    }
                    {!priceDiff &&
                        <>
                            <br />
          for businesses like yours
          </>
                    }
                </div>

                <div className="h-line"></div>

                <div className="features">
                    <Features id={suggestedProductTexts.features}></Features>
                    </div>

                <div className="button" onClick={handleUpgrade}>
                    { suggestedProductTexts.button &&
                    <span>{suggestedProductTexts.button}</span>
                    }
                    {!suggestedProductTexts.button &&
                    <>
          Get Adbeat <span>{suggestedProduct.name}</span>
                    </>
                    }
                </div>
            </div>
            <div className="clear bottom"></div>
        </div>
    );
}

export default handleViewport(UpgradeScreen);