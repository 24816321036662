import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchPubSimilarProfile = createAsyncThunk(
    'pubSimilarProfiles',
    async (arg) => {

        const response = await axios.get("/data-api/publisherInfo", {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        const advertisers = response.data.recommendedAdvertisers
            .slice(0, arg.maxItems).map(a => a.advertiser )


        const publisherQuery = { ...arg.query }
        delete publisherQuery.publisher

        arg.dispatch(fetchAdvertiserMetrics({
            ...arg,
            query: {
                ...publisherQuery,
                advertiser: advertisers.join(','),
                rows: arg.maxItems,
            },
        }))

        advertisers.map(advertiser =>
            arg.dispatch(fetchAdvertiserTopAds({
                ...arg,
                query: {
                    ...publisherQuery,
                    advertiser
                },
            })))

        const publishers = response.data.similarPublishers
            .slice(0, arg.maxItems)

        arg.dispatch(fetchPublisherMetrics({
            ...arg,
            query: {
                ...publisherQuery,
                publisher: publishers.join(','),
                rows: arg.maxItems,
            },
        }))

        publishers.map(publisher =>
            arg.dispatch(fetchPublisherTopAds({
                ...arg,
                query: {
                    ...publisherQuery,
                    publisher
                },
            })))

        return response.data
    })

const fetchAdvertiserMetrics = createAsyncThunk(
    'pubSimilarProfilesAdvertiserMetrics',
    async (arg) => {
        const response = await axios.get("/data-api/advertisers", {
            params: {
                ...arg.query,
                startDate: arg.startDate,
                endDate: arg.endDate,
                metrics: arg.settings.metricsList
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const fetchAdvertiserTopAds = createAsyncThunk(
    'pubSimilarProfilesAdvertiserAds',
    async (arg) => {
        const response = await axios.get("/data-api/ads", {
            params: {
                ...arg.query,
                startDate: arg.startDate,
                endDate: arg.endDate,
                metrics: arg.settings.metricsList,
                sortBy: arg.settings.metricField,
                sortOrder: 'desc',
                rows: 4
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    }
)

const fetchPublisherMetrics = createAsyncThunk(
    'pubSimilarProfilesPublisherMetrics',
    async (arg) => {
        const response = await axios.get("/data-api/publishers", {
            params: {
                ...arg.query,
                startDate: arg.startDate,
                endDate: arg.endDate,
                metrics: arg.settings.metricsList
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const fetchPublisherTopAds = createAsyncThunk(
    'pubSimilarProfilesPublisherAds',
    async (arg) => {
        const response = await axios.get("/data-api/ads", {
            params: {
                ...arg.query,
                startDate: arg.startDate,
                endDate: arg.endDate,
                metrics: arg.settings.metricsList,
                sortBy: arg.settings.metricField,
                sortOrder: 'desc',
                rows: 4
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    }
)


const pubSimilarSlice = createSlice({
    name: 'pubSimilarProfiles',
    initialState: {
        status: 'idle',
        data: {
            similarPublishers: [],
            recommendedAdvertisers: [],
            phrases: []
        },
    },
    reducers: {
        resetAdvOverviewSimilarProfiles(state) {
            state.status = 'idle'
            state.data = {
                similarPublishers: [],
                recommendedAdvertisers: [],
                phrases: []
            }
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchPubSimilarProfile.pending]: (state) => {
            state.status = 'loading'
            state.data = {
                similarPublishers: [],
                recommendedAdvertisers: [],
                phrases: []
            }
        },
        [fetchPubSimilarProfile.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = {
                ...action.payload,
                similarPublishers: action.payload
                    .similarPublishers.map(publisher => ({
                        publisher,
                        ads: [],
                        sumAdSpend: 0,
                        sumImpressions: 0
                    })),
                recommendedAdvertisers: action.payload
                    .recommendedAdvertisers.map(a => ({
                        ...a,
                        ads: [],
                        sumAdSpend: 0,
                        sumImpressions: 0
                    })),
                phrases: action.payload.phrases
            }
        },
        [fetchAdvertiserMetrics.fulfilled]: (state, action) => {
            state.data.recommendedAdvertisers = state.data.recommendedAdvertisers
                .map(a => {
                    const metrics = action.payload.rows
                        .filter(r => r.advertiser === a.advertiser).pop()
                    if (metrics) {
                        return {
                            ...metrics,
                            ads: a.ads
                        }
                    }
                    return a
                })
        },
        [fetchAdvertiserTopAds.fulfilled]: (state, action) => {
            state.data.recommendedAdvertisers = state.data.recommendedAdvertisers
                .map(a => {
                    if (action.meta.arg.query.advertiser === a.advertiser) {
                        return {
                            ...a,
                            ads: action.payload.rows
                        }
                    }
                    return a
                })
        },
        [fetchPublisherMetrics.fulfilled]: (state, action) => {
            state.data.similarPublishers = state.data.similarPublishers
                .map(a => {
                    const metrics = action.payload.rows
                        .filter(r => r.publisher === a.publisher).pop()
                    if (metrics) {
                        return {
                            ...metrics,
                            ads: a.ads
                        }
                    }
                    return a
                })
        },
        [fetchPublisherTopAds.fulfilled]: (state, action) => {
            state.data.similarPublishers = state.data.similarPublishers
                .map(a => {
                    if (action.meta.arg.query.publisher === a.publisher) {
                        return {
                            ...a,
                            ads: action.payload.rows
                        }
                    }
                    return a
                })
        }
    }
})


const pubSimilarProfilesSelector = state => state.publisherProfile.similarProfiles
export {
    fetchPubSimilarProfile, pubSimilarProfilesSelector,
    fetchPublisherTopAds, fetchPublisherMetrics
}
export const { resetAdvOverviewSimilarProfiles } = pubSimilarSlice.actions
export default pubSimilarSlice.reducer