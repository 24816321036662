import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'


const fetchSOVData = createAsyncThunk('fetchSOVData',
    async (arg) => {
        const response = await axios.get("/data-api/advertisers", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(','),
                entity: 'country,platform',
                rows: arg.advertisers.length * 50,
                sortBy: 'sumImpressions'
            }
        })
        return response.data
    })


const advertiserShareOfVoiceSlice = createSlice({
    name: 'advertiserShareOfVoice',
    initialState: {
        sov: {
            status: 'idle',
            data: []
        }
    },
    reducers: {
        setValue(state, action) {
            let {comparisonType, key, value} = action.payload
            state[comparisonType][key] = value
        },
        resetData(state) {
            for (const key in state) {
                if (!state.hasOwnProperty(key)) {
                    continue;
                }
                if (state[key].hasOwnProperty('status')) {
                    state[key]['status'] = 'idle'
                }
                if (state[key].hasOwnProperty('data')) {
                    state[key]['data'] = []
                }
            }
        }
    },
    extraReducers: {
        [fetchSOVData.pending]: (state) => {
            state.sov.status = 'pending'
        },
        [fetchSOVData.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.sov.status = 'error'
                return
            }
            state.sov.status = 'done'
            state.sov.data = action.payload.rows
        }
    }
})


const selectDataComparisonType = (comparisonType) => (state) => state.advertiserCompareTool.advertiserShareOfVoice[comparisonType]
const selectDataComparisonTypeKey = (comparisonType, key) => (state) => state.advertiserCompareTool.advertiserShareOfVoice[comparisonType][key]


export {
    selectDataComparisonType,
    selectDataComparisonTypeKey
}

export {
    fetchSOVData
}

export const {
    setValue,
    resetData
} = advertiserShareOfVoiceSlice.actions


export default advertiserShareOfVoiceSlice.reducer