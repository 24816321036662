import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from '../../../common/axiosRateLimit'

const addPublisherToDashboard = createAsyncThunk('addPublihserToDashboard',
    async (arg) => {
        const response = await axios.post(
            '/callisto/dashboard-publishers',
            {
                ...arg
            })
        return response.data
    })

export default addPublisherToDashboard;