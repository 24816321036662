import _ from "lodash"
import NetworkListModel from "./networks";

const processSettings = (settings) => {
    var show_all = true;

    settings.isWinmoUser = settings.user.winmo_data != null;

    settings.defaultCreativesView = settings.default_creatives_view === 'tiled' ?
        'tiled' : 'listed';

    if (settings.fastMode) {
        settings.defaultPageSize = 6;
        settings.similarProfilesCount = 6;
        settings.defaultLargePageSize = 10;
    }
    else {
        settings.defaultPageSize = 10;
        settings.defaultLargePageSize = 20;
        settings.similarProfilesCount = 10;
    }
    settings.profileListingPageSize = 5;

    settings.profileLink = 'https://' + settings.domain + '/user-profile';
    if (settings.isWinmoUser) {
        settings.profileLink = 'https://' + settings.domain +
            '/winmo-user-profile';
    }

    if (!settings.platforms.length) {
        window.open(settings.profileLink, '_self', false);
        return;
    }

    settings.standardTypes = 'html5,image,flash,text,textimg';
    settings.nonVideoTypes = settings.standardTypes;

    settings.upgradeRequiredValue = 'upgrade-required-';

    settings.adTypeMapping = {};

    settings.ad_types.forEach(function (type) {
        settings.adTypeMapping[type.id] = type.name;
    });

    settings.brand_countries = ['us', 'gb'];

    settings.allowed = {
        'countries': settings.countries,
        'mobile_countries': settings.mobile_countries,
        'platforms': settings.platforms,
        'networks': settings.networks,
        'periods': settings.periods
    };

    if (show_all) {
        settings.countries = settings.all_countries;
        settings.mobile_countries = settings.all_countries;
        settings.platforms = settings.all_platforms;
        settings.networks = settings.all_networks;
        settings.periods = settings.all_periods;
    }

    settings.faviconBaseUrl = '//s3.amazonaws.com/favicon.adbeat.com/%s.png';

    settings.desktop_countries = settings.countries;

    var uniqueAllowedCountries = {};

    var allAllowedCountries = settings.allowed.countries.slice(0);
    settings.allAllowedCountries = allAllowedCountries

    settings.allowed.countries.forEach(function (item) {
        uniqueAllowedCountries[item.id] = true;
        allAllowedCountries.push(item);
    });

    settings.allowed.mobile_countries.forEach(function (item) {
        if (!uniqueAllowedCountries[item.id]) {
            allAllowedCountries.push(item);
        }
    });

    settings.allowed.mobile_platforms =
        settings.allowed.platforms.filter(function (platform) {
            return ['desktop', 'mobile', 'all']
                .indexOf(platform.id) < 0;
        })
            .map(function (platform) {
                return platform.id;
            });

    settings.allowed.countryIds = settings.allowed.countries.map(item => item.id)
    settings.allowed.platformIds = settings.allowed.platforms.map(item => item.id)

    settings.defaultPlatform = settings.default_platform;

    settings.defaultTopAdvertisersPlatform = settings.defaultPlatform;
    
    settings.hasMobile = settings.allowed.platforms.map(p => p.id).includes('mobile') ||
        settings.allowed.platforms.map(p => p.id).includes('all')

    settings.hasCountryAll = false;

    var orderDefaultCountries = [
        settings.default_country, 'us', 'gb', 'all'];

    settings.defaultCountry = null;

    allAllowedCountries.some(function (item) {
        if (item.id === 'all') {
            settings.hasCountryAll = true;
            return true;
        }
        return false
    });

    orderDefaultCountries.some(function (defItem) {
        return allAllowedCountries.some(function (item) {
            if (item.id === defItem) {
                settings.defaultCountry = defItem;
                return true;
            }
            return false;
        });
    });

    if (
        ['app-dev-uk', 'app-uk']
            .indexOf(window.location.host.split('.')[0]) >= 0) {
        settings.defaultCountry = 'gb';
        settings.defaultCampaignCountries = ['all'];
    } else {
        settings.defaultCampaignCountries = ['us'];
        if (settings.defaultCountry === 'all') {
            settings.defaultCampaignCountries = ['all'];
        }
    }
    if (window.location.pathname.startsWith('/campaign/')) {
        settings.defaultCountry = settings.defaultCampaignCountries[0];
    }

    // Allow campaigns and brands in all countries
    settings.defaultCampaignCountries = settings.countries.map(item => item.id)
    if (settings.adv_profile_country_all) {
        settings.defaultCampaignCountries.push('all');
    }
    settings.brand_countries = settings.countries.map(item => item.id)

    var lastPeriod = settings.allowed.periods[
        settings.allowed.periods.length - 1]

    settings.maxPeriod = lastPeriod;

    settings.defaultDateRange = String(Math.min(
        settings.default_date_range, lastPeriod));

    settings.dateRange180 = settings.defaultDateRange;

    settings.dateRange365 = String(Math.min(365, lastPeriod));

    settings.dateRange730 = String(Math.min(730, lastPeriod));

    settings.campaignDateRange = settings.dateRange730;

    settings.dateRange90 = String(Math.min(90, lastPeriod));

    settings.dmaDateRange = settings.dateRange180;

    settings.minMetricDateRange = '30';

    settings.topAdsInViewPortOffset = 130;

    const networks = new NetworkListModel(
        settings.networks,
        settings.allowed.networks, {}, settings)
    settings.defaultAllNetwork = networks.theOnlyNetwork
        ? networks.onlyNetworksList[0].id
        : 'all';

    settings.defaultNetwork = networks.theOnlyNetwork
        ? networks.onlyNetworksList[0].id
        : 'allByNetwork';

    settings.defaultTopAdvertisersNetwork = settings.has_categories ?
        'all' : settings.defaultNetwork;

    settings.hasDirect = settings.allowed.networks.find(d => d.id === 'direct');

    settings.maxDateRange = Math.max.apply(
        null,
        settings.allowed.periods.map(function (item) {
            return parseInt(item, 10);
        })
            .filter(n => !Number.isNaN(n))
    ).toString();

    settings.adMetricId = settings.metric;
    settings.hasSpend = settings.adMetricId === 'adSpend'
    settings.metricField = settings.hasSpend ?
        'sumAdSpend' : 'sumImpressions';
    settings.metricsList = settings.hasSpend ?
        'sumAdSpend,sumImpressions' : 'sumImpressions';

    settings.adSizesOrder = {
        "": 20,
        "300x250": 30,
        "728x90": 40,
        "160x600": 50,
        "300x600": 60,
        "336x280": 70,
        "468x60": 80,
        "120x600": 90,
        "250x250": 100,
        "200x200": 110,
        "125x125": 130,
        "interstitial": 133,
        "takeover": 135,
        "180x150": 138,
        "320x50": 140,
        "300x100": 150,
        "234x60": 160,
        "120x240": 170,
        "300x50": 180,
        "240x400": 190,
        "300x75": 200,
        "168x42": 210,
        "720x300": 220,
        "216x54": 230,
        "192x53": 240,
        "216x36": 250,
        "168x28": 260,
        "Other": 1000,
        "other": 1000
    };

    settings.allowedAdSizes = settings.ad_sizes.filter(function (item) {
        var id = item.id;
        if (
            /\d/.test(item.id) ||
            ['Other', 'other', 'unknown'].indexOf(item.id) >= 0
        ) {
            id = 'regular';
        }
        return settings.has_ad_sizes.indexOf(id) >= 0;
    }).map(function (item) { return item.id; });
    settings.allowedAdSizes.push('all')

    settings.adSizeMap = {};
    settings.adSizes = _.sortBy(settings.ad_sizes, function (item) {
        settings.adSizeMap[item.id] = item.name;
        return settings.adSizesOrder[item.id] || 500;
    });
    settings.adSizeMap.Other = 'Other';

    settings.canShowPrivateLabelingReminder = settings.has_private_labeling &&
        settings.private_label.company_name === 'Adbeat' &&
        /logo\.png$/.test(settings.private_label.logo_file);

    settings.eventTypes = (function () {
        if (!settings.has_campaigns) {
            return settings.event_types.filter(function (e) {
                return e.id !== 'newCampaign';
            });
        }
        return settings.event_types;
    }())

    settings.keywordEventTypes = [
        {
            id: 'destination_url',
            name: 'Keyword in Destination URL'
        },
        {
            id: 'display_url',
            name: 'Keyword in Display URL'
        },
        {
            id: 'landing_page',
            name: 'Keyword in Landing Page'
        },
        {
            id: 'creative',
            name: 'Keyword in Creative'
        },
    ]

    settings.ownPropertiesWithoutCountry =
        settings.own_properties_without_country

    window.AdbeatSettings = settings;

    if (/\/login/.test(document.referrer) ||
        /\/test_users/.test(document.referrer) ||
        settings.reset_user_profile_defaults) {

        window.localStorage.setItem('pub_tools_countryId',
            settings.defaultCountry);
        window.localStorage.setItem('pub_tools_dateRange',
            settings.defaultDateRange);
        window.localStorage.setItem('pub_tools_platformId',
            settings.defaultPlatform);
    }

    return settings;
}

export default processSettings
