import axios from '../../common/axiosRateLimit'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import qs from 'qs'
import {searchQueryToRecord, getSearchLink} from '../../common/Adbeat'

const saveSearch = createAsyncThunk('callisto/add-search',
    async (arg) => {
        const query = searchQueryToRecord(arg.search);

        const result = await axios.post('/callisto/add-search',
            qs.stringify(query))

        return result.data
    })

const searchSlice = createSlice({
    name: "searches",
    initialState: {
        addSearchStatus: 'idle',
    },
    extraReducers: {
        [saveSearch.pending]: (state, action) => {
            state.addSearchStatus = 'pending'
        },
        [saveSearch.fulfilled]: (state, action) => {
            state.addSearchStatus = 'done'
            const path = getSearchLink(action.meta.arg.search);
            window.open(path, '_self');
        }
    }
})


export {saveSearch}

export default searchSlice.reducer