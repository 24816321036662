import axios from '../../../common/axiosRateLimit'
import qs from 'qs'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'


const fetchPlatforms = createAsyncThunk('fetch_menu_platforms',
    async (arg) => {
        const response = await axios.get('/menu-api/platforms?' + qs.stringify({
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            country: arg.query.country,
            network: arg.query.network,
        }))

        return response.data
    })

const platformsSlice = createSlice({
    name: 'platform-menu',
    initialState: {
        status: 'idle',
        platforms: []
    },
    reducers: {
        resetPlatforms: (state) => {
            state.status = 'idle'
            state.platforms = []
        }
    },
    extraReducers: {
        [fetchPlatforms.pending]: (state, action) => {
            state.status = 'pending'
            state.platforms = []
        },
        [fetchPlatforms.fulfilled]: (state, action) => {
            state.platforms = action.payload.rows
            state.status = 'done'
        },
        [fetchPlatforms.rejected]: (state, action) => {
            state.platforms = []
            state.status = 'failed'
        }
    }
})

const selectPlatforms = (state) => state.menu.topAds.platforms

export {fetchPlatforms, selectPlatforms}
export const {resetPlatforms} = platformsSlice.actions

export default platformsSlice.reducer