import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'
import { showUpgradeModal } from "../../../../components/modals/UpgradeModal";


const fetchByDateAdSpendData = createAsyncThunk('fetchByDateAdSpendData',
    async (arg) => {
        let entities = arg.advertisers.map((advertiser) => {
                return {advertiser}
            }
        )
        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(entities)
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params
        })
        return response.data
    })


const fetchByNetwork = createAsyncThunk('fetchByNetwork',
    async (arg) => {
        const response = await axios.get("/data-api/advertisers", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(','),
                entity: 'network',
                rows: arg.advertisers.length * 50,
                sortBy: 'sumImpressions'
            }
        })
        return response.data
    })


const fetchAdvertiserRankings = createAsyncThunk('fetchAdvertiserRankings',
    async (arg) => {
        let entities = arg.advertisers.map((advertiser) => {
                return {advertiser}
            }
        )
        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(entities)
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params
        })
        return response.data
    })


const fetchAdTypesData = createAsyncThunk('fetchAdTypesData',
    async (arg) => {
        const response = await axios.get("/data-api/advertisers", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(','),
                entity: 'adType',
                rows: arg.advertisers.length * 10,
                sortBy: 'sumImpressions'
            }
        })
        return response.data
    })


const fetchPlatformData = createAsyncThunk('fetchPlatformData',
    async (arg) => {
        const response = await axios.get("/data-api/advertisers", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(','),
                entity: 'platform',
                rows: arg.advertisers.length * 10,
                sortBy: 'sumImpressions'
            }
        })
        return response.data
    })


const fetchPublisherData = createAsyncThunk('fetchPublisherData',
    async (arg) => {
        const response = await axios.get("/data-api/advertisers", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(','),
                entity: 'publisher',
                rows: arg.advertisers.length * 250,
                sortBy: 'sumImpressions'
            }
        })
        return response.data
    })


const fetchChannelData = createAsyncThunk('fetchChannelData',
    async (arg) => {
        const response = await axios.get("/data-api/channels", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(','),
                entity: 'advertiser',
                rows: arg.advertisers.length * 200,
                sortBy: 'sumImpressions'
            }
        })
        return response.data
    })


const fetchPublisherCategoryData = createAsyncThunk('fetchPublisherCategoryData',
    async (arg) => {
        const response = await axios.get("/data-api/publisherCategories", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(','),
                entity: 'advertiser',
                rows: arg.advertisers.length * 50,
                sortBy: 'sumImpressions'
            }
        })
        return response.data
    })


const createCompareTrendlineReport = createAsyncThunk(
        'createAdvCompareTrendlineReport',
        (arg) => {
            var framename = 'trendline-downloads-frame';
            var iframe = document.createElement('iframe');
            iframe.name = framename;
            iframe.style.display = 'none';
    
            var form = document.createElement('form');
            form.id = 'trendline-download'
            form.action = '/callisto/reports/compare-trendline';
            form.method = 'POST';
            form.target = framename;
    
            function hiddenInput(name) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = name;
                form.appendChild(input);
                return input;
            }
    
            var json = hiddenInput('json');
            const startDate = hiddenInput('startDate');
            const endDate = hiddenInput('endDate');
            const advertisers = hiddenInput('advertisers');
            const platform = hiddenInput('platform');
            const country = hiddenInput('country');
            const chartDays = hiddenInput('chartDays');
            const adMetricIdField = hiddenInput('adMetricId');
    
            iframe.appendChild(form);
            document.body.appendChild(iframe);
    
            function makeDownload(adMetricId) {
                adMetricId = adMetricId || arg.settings.adMetricId;
                return function () {
                    if (!arg.settings.exportable_data) {
                        arg.dispatch(showUpgradeModal({
                            name: 'reports',
                            value: null
                        }))
                        return;
                    }
    
                    json.value = arg.trendline
                    startDate.value = arg.startDate
                    endDate.value = arg.endDate
                    advertisers.value = arg.advertisers
                    platform.value = arg.platform
                    country.value = arg.country
                    chartDays.value = arg.chartDays
                    adMetricIdField.value = adMetricId

                    form.submit();
                };
            }

            makeDownload(arg.adMetric)()
        }
    )

const advertiserSpendSlice = createSlice({
    name: 'advertiserSpend',
    initialState: {
        common: {
            adMetric: '',
        },
        date: {
            period: 'day',
            status: 'idle',
            data: []
        },
        network: {
            metric: 'value',
            status: 'idle',
            data: []
        },
        advertiser_ranking: {
            period: 'week',
            status: 'idle',
            data: []
        },
        spending_shifts: {
            period: 'week',
            status: 'idle',
            data: []
        },
        ad_type: {
            adType: 'image',
            status: 'idle',
            data: []
        },
        device: {
            metric: 'value',
            status: 'idle',
            data: []
        },
        publisher: {
            metric: 'value',
            status: 'idle',
            data: []
        },
        channel: {
            status: 'idle',
            data: []
        },
        category: {
            metric: 'value',
            status: 'idle',
            data: []
        }
    },
    reducers: {
        setValue(state, action) {
            let {comparisonType, key, value} = action.payload
            state[comparisonType][key] = value
        },
        resetData(state) {
            for (const key in state) {
                if (!state.hasOwnProperty(key)) {
                    continue;
                }
                if (state[key].hasOwnProperty('status')) {
                    state[key]['status'] = 'idle'
                }
                if (state[key].hasOwnProperty('data')) {
                    state[key]['data'] = []
                }
            }
        }
    },
    extraReducers: {
        [fetchByDateAdSpendData.pending]: (state) => {
            state.date.status = 'pending'
        },
        [fetchByDateAdSpendData.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.date.status = 'error'
                return
            }
            state.date.status = 'done'
            state.date.data = action.payload.rows
        },
        [fetchByNetwork.pending]: (state) => {
            state.network.status = 'pending'
        },
        [fetchByNetwork.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.network.status = 'error'
                return
            }
            state.network.status = 'done'
            state.network.data = action.payload.rows
        },
        [fetchAdvertiserRankings.pending]: (state) => {
            state.advertiser_ranking.status = 'pending'
            state.spending_shifts.status = 'pending'
        },
        [fetchAdvertiserRankings.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.advertiser_ranking.status = 'error'
                state.spending_shifts.status = 'error'
                return
            }

            state.advertiser_ranking.status = 'done'
            state.spending_shifts.status = 'done'

            state.advertiser_ranking.data = action.payload.rows
            state.spending_shifts.data = action.payload.rows
        },
        [fetchAdTypesData.pending]: (state) => {
            state.ad_type.status = 'pending'
        },
        [fetchAdTypesData.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.ad_type.status = 'error'
                return
            }

            state.ad_type.status = 'done'
            state.ad_type.data = action.payload.rows
        },
        [fetchPlatformData.pending]: (state) => {
            state.device.status = 'pending'
        },
        [fetchPlatformData.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.device.status = 'error'
                return
            }

            state.device.status = 'done'
            state.device.data = action.payload.rows
        },
        [fetchPublisherData.pending]: (state) => {
            state.publisher.status = 'pending'
        },
        [fetchPublisherData.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.publisher.status = 'error'
                return
            }

            state.publisher.status = 'done'
            state.publisher.data = action.payload.rows
        },
        [fetchPublisherCategoryData.pending]: (state) => {
            state.category.status = 'pending'
        },
        [fetchPublisherCategoryData.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.category.status = 'error'
                return
            }

            state.category.status = 'done'
            state.category.data = action.payload.rows
        },
        [fetchChannelData.pending]: (state) => {
            state.channel.status = 'pending'
        },
        [fetchChannelData.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.channel.status = 'error'
                return
            }

            state.channel.status = 'done'
            state.channel.data = action.payload.rows
        }
    }
})


const selectDataComparisonType = (comparisonType) => (state) => state.advertiserCompareTool.advertiserSpend[comparisonType]
const selectDataComparisonTypeKey = (comparisonType, key) => (state) => state.advertiserCompareTool.advertiserSpend[comparisonType][key]


export {
    selectDataComparisonType,
    selectDataComparisonTypeKey
}

export {
    fetchByDateAdSpendData,
    fetchByNetwork,
    fetchAdvertiserRankings,
    fetchAdTypesData,
    fetchPlatformData,
    fetchPublisherData,
    fetchPublisherCategoryData,
    fetchChannelData, 
    createCompareTrendlineReport
}

export const {
    setValue,
    resetData
} = advertiserSpendSlice.actions


export default advertiserSpendSlice.reducer