import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'
import {accessDenied, serverError} from "./topAdvertisersSlice";


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdTrends = createAsyncThunk('fetchAdvertiserTopAdsTrendlines',
    async (arg) => {
        if (!arg.ads.length) {
            return
        }

        const entities = (ad) => {
            return {
                adHash: ad.adHash
            }
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.ads.map(entities))
        }

        delete params.pageNumber
        delete params.pageSize
        delete params.category
        delete params.sortBy
        delete params.sortOrder
        delete params.rows
        delete params.channel

        params.metrics += ',globalSeenDates'

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


const fetchTopAds = createAsyncThunk('fetchTopAdvertiserAds',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        let response
        try {
            response = await axios.get("/data-api/ads", {
                params: {
                    ...arg.query
                }
            })
        } catch (e) {
            if ('response' in e) {
                if (e.response.status === 403) {
                    arg.dispatch(accessDenied())
                }
                if (e.response.status === 500) {
                    arg.dispatch(serverError())
                }
            } else if (e.constructor === axios.Cancel) {
                return {cancel: true}
            } else {
                throw e
            }
        }

        if ('rows' in response.data) {
            arg.dispatch(fetchAdTrends({
                ads: response.data.rows,
                query: {
                    ...arg.query
                }
            }))

            response.data.rows = response.data.rows.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendLine: {
                    globalDaysSeen: 0
                }
            }))
        }

        return response.data
    })

const topAdsSlice = createSlice({
    name: 'topAdvertisersAds',
    initialState: {},
    extraReducers: {
        [fetchTopAds.pending]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'pending'
            }
        },
        [fetchTopAds.fulfilled]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: action.payload.rows,
                status: 'done'
            }
        },
        [fetchAdTrends.fulfilled]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network

            if (state[key] && state[key].rows) {
                state[key].rows = state[key].rows.map(
                    row => {
                        const trendline = action.payload.rows
                            .filter(tr => {
                                return Object.keys(tr.entity).every(function (key) {
                                    return tr.entity[key] === row[key];
                                });
                            }).shift() || {
                            globalDaysSeen: 0
                        }

                        let trendlineStatus = 'done'
                        if (!trendline.globalDaysSeen) {
                            trendlineStatus = 'idle'
                        }

                        return {
                            ...row,
                            trendlineStatus,
                            trendLine: trendline
                        }
                    }
                )
            }
        }
    }
})

export const selectTopAds = (advertiser, network) => (state) => {
    let key = advertiser + network
    if (key in state.topAdvertisers.ads) {
        return state.topAdvertisers.ads[key]
    } else {
        return {
            rows: [],
            status: 'idle'
        }
    }
}

export {fetchTopAds}
export default topAdsSlice.reducer