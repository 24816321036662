import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'
import qs from "qs";
import * as d3 from "d3";


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchPublisherUITopCategories = createAsyncThunk('fetchPublisherUITopCategories',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        const response = await axios.post("/data-api/categories",
            qs.stringify({
                publisher: arg.params.publisherSet,
                network: arg.params.network,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: arg.params.metrics,
                sortBy: 'sumAdSpend',
                rows: 10
            }), {
                cancelToken: getCancelSource().token
            }
        )
        return response.data
    })


const initialState = {
    status: 'idle',
        data: []
}


const publisherUiCategoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        resetCategories(state) {
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()

            return initialState
        },
    },
    extraReducers: {
        [fetchPublisherUITopCategories.rejected]: () => {
        },
        [fetchPublisherUITopCategories.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchPublisherUITopCategories.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                let total = action.payload.rows.reduce((s, item) => {
                    return s + item.sumAdSpend
                }, 0) || 1

                let count = action.payload.rows.length
                state.data = action.payload.rows.map((item, index) => {
                    let t = 1 - index / (count - 0.999)
                    return {
                        ...item,
                        name: item.category,
                        share: item.sumAdSpend / total,
                        color: d3.interpolateSpectral(t * 0.8 + 0.1)
                    }
                })

                state.status = 'done'
            } else {
                state.status = 'error'
            }
        }
    }
})


const selectPublisherUiCategories = (state) => state.publisherUiOverview.categories


export {
    selectPublisherUiCategories,
    fetchPublisherUITopCategories
}

export const {
    resetCategories
} = publisherUiCategoriesSlice.actions


export default publisherUiCategoriesSlice.reducer