import axios from '../../../common/axiosRateLimit'
import qs from 'qs'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'


const fetchAdSizes = createAsyncThunk('fetch_menu_adSizes',
    async (arg) => {
        const response = await axios.get('/menu-api/adSizes?' + qs.stringify({
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            country: arg.query.country,
            network: arg.query.network,
        }))

        return response.data
    })

const adSizesSlice = createSlice({
    name: 'platform-menu',
    initialState: {
        status: 'idle',
        adSizes: []
    },
    reducers: {
        resetAdSizes: (state) => {
            state.status = 'idle'
            state.adSizes = []
        }
    },
    extraReducers: {
        [fetchAdSizes.pending]: (state, action) => {
            state.status = 'pending'
            state.adSizes = []
        },
        [fetchAdSizes.fulfilled]: (state, action) => {
            state.adSizes = action.payload.rows
            state.status = 'done'
        },
        [fetchAdSizes.rejected]: (state, action) => {
            state.adSizes = []
            state.status = 'failed'
        }
    }
})

const selectAdSizes = (state) => state.menu.topAds.adSizes

export {fetchAdSizes, selectAdSizes}
export const {resetAdSizes} = adSizesSlice.actions

export default adSizesSlice.reducer