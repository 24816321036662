import {getDomain} from "tldts"

function publicsuffix_lookup(domain) {
    return getDomain(domain) || ""
}

const isAdvertiser = (name) => {
    return publicsuffix_lookup(name).indexOf('.') !== -1
}

export {publicsuffix_lookup, isAdvertiser}