class ReportModalData {
    constructor() {
        this.prepareData = (data) => {
            data.title = data.title || "Advertiser Vertical Report"
            data.vertical = data.vertical || "My Selected Advertisers"

            let common = {
                numAdvertisers: data.advertiser_2 ? 3 : 2,
                vertical: data.vertical,
                title: data.title,
                report_month: data.report_month,
                report_prev_month: data.report_prev_month
            }
            return {
                source: data,
                common: common,
                title: {
                    title_a: data.title_a,
                    title_b: data.title_b,
                },
                preamble: {
                    ...common,
                    preamble_a: data.preamble_a,
                    preamble_b: data.preamble_b,
                    preamble_c: data.preamble_c,
                    preamble_d: data.preamble_d,
                },
                sovSummary: {
                    ...common,
                    sov_summary_a: data.sov_summary_a,
                    sov_summary_b: data.sov_summary_b,
                    sov_summary_c: data.sov_summary_c,
                    sov_summary_d: data.sov_summary_d,
                    sov_summary_0: data.sov_summary_0
                },
                sovTrend: {
                    ...common,
                    sov_trends: data.sov_trends
                },
                ads: data.ads,
                profilesData: data.profilesData,
                "top-ads-0": {
                    ...common,
                    selected: data.a0_checked_ads
                },
                "top-ads-1": {
                    ...common,
                    selected: data.a1_checked_ads
                },
                "top-ads-2": {
                    ...common,
                    selected: data.a2_checked_ads
                },
                "creatives-0": {
                    ...common,
                    creatives_c: data.a0_creatives_c,
                    creatives_d: data.a0_creatives_d
                },
                "creatives-1": {
                    ...common,
                    creatives_c: data.a1_creatives_c,
                    creatives_d: data.a1_creatives_d
                },
                "creatives-2": {
                    ...common,
                    creatives_c: data.a2_creatives_c,
                    creatives_d: data.a2_creatives_d
                },
                landing_pages: data.landing_pages,
                "landing-pages-0": {
                    ...common,
                },
                "landing-pages-1": {
                    ...common,
                },
                "landing-pages-2": {
                    ...common,
                },
                "publishers-0": {
                    ...common,
                    publisher_a: data.a0_publisher_a,
                    publisher_b: data.a0_publisher_b
                },
                "publishers-1": {
                    ...common,
                    publisher_a: data.a1_publisher_a,
                    publisher_b: data.a1_publisher_b
                },
                "publishers-2": {
                    ...common,
                    publisher_a: data.a2_publisher_a,
                    publisher_b: data.a2_publisher_b
                },
                publishers: data.publishers,
                final: {
                    ...common,
                    final_b: data.final_b,
                    final_c: data.final_c,
                    final_d: data.final_d,
                }
            }
        }

        this.prepareQuery = (verticalReportData) => {
            let sourceDefault = {...verticalReportData.source}

            let fieldsToRemove = [
                'ads',
                'ads_0',
                'ads_1',
                'ads_2',
                'advertisers',
                'advertiser_names',
                'profilesData',
                'publisherShifts',
                'unique_ads',
                'unique_publishers',
                'info',
                'countryName',
                'platformName',
                'creatives_0',
                'creatives_1',
                'creatives_2',
                'publishers_0',
                'publishers_1',
                'publishers_2',
                'publishers',
                'spendShifts',
                'display_country',
                'display_platform',
                'sov_summary_0',
                'sov_trends',
                'countries',
                'mobile_countries',
                'platforms'
            ];

            fieldsToRemove.forEach(function (f) {
                delete sourceDefault[f];
            });

            let result = {
                ...sourceDefault,
                title_a: verticalReportData.title.title_a,
                title_b: verticalReportData.title.title_b,
                preamble_a: verticalReportData.preamble.preamble_a,
                preamble_b: verticalReportData.preamble.preamble_b,
                preamble_c: verticalReportData.preamble.preamble_c,
                preamble_d: verticalReportData.preamble.preamble_d,
                sov_summary_a: verticalReportData.sovSummary.sov_summary_a,
                sov_summary_b: verticalReportData.sovSummary.sov_summary_b,
                sov_summary_c: verticalReportData.sovSummary.sov_summary_c,
                sov_summary_d: verticalReportData.sovSummary.sov_summary_d,
                a0_checked_ads: verticalReportData['top-ads-0'].selected,
                a0_creatives_c: verticalReportData['creatives-0'].creatives_c,
                a0_creatives_d: verticalReportData['creatives-0'].creatives_d,
                a0_publisher_a: verticalReportData['publishers-0'].publisher_a,
                a0_publisher_b: verticalReportData['publishers-0'].publisher_b,
                a1_checked_ads: verticalReportData['top-ads-1'].selected,
                a1_creatives_c: verticalReportData['creatives-1'].creatives_c,
                a1_creatives_d: verticalReportData['creatives-1'].creatives_d,
                a1_publisher_a: verticalReportData['publishers-1'].publisher_a,
                a1_publisher_b: verticalReportData['publishers-1'].publisher_b,
                a2_checked_ads: verticalReportData['top-ads-2'].selected,
                a2_creatives_c: verticalReportData['creatives-2'].creatives_c,
                a2_creatives_d: verticalReportData['creatives-2'].creatives_d,
                a2_publisher_a: verticalReportData['publishers-2'].publisher_a,
                a2_publisher_b: verticalReportData['publishers-2'].publisher_b,
                final_b: verticalReportData.final.final_b,
                final_c: verticalReportData.final.final_c,
                final_d: verticalReportData.final.final_d,
                vertical: verticalReportData.source.vertical,
                year: verticalReportData.source.year,
                numAdvertisers: verticalReportData.common.numAdvertisers,
                reportType: verticalReportData.reportType
            }

            verticalReportData.advertisers.forEach((item, index) => {
                result['advertiser_' + index + '_logo'] = item.logo
            })

            return result
        }
    }
}


export default ReportModalData