import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'
import {isAdvertiser} from "../../../common/tld/effective_tld_names";
import ReportModalData from "./ReportData";
import {getSupportLink} from "../../../common/Adbeat";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const verticalReportAdvertiserSuggestions = createAsyncThunk('verticalReportAdvertiserSuggestions',
    async (arg) => {
        let path = '/data-api/search/advertisers';

        const response = await axios.get(path, {
            params: arg.query,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


const fetchVerticalReportData = createAsyncThunk('fetchVerticalReportData',
    async (arg) => {
        let path = '/callisto/vertical_report/data';

        const response = await axios.get(path, {
            params: arg.query
        })
        return response.data
    })


const submitVerticalReportData = createAsyncThunk('submitVerticalReportData',
    async (arg) => {
        let path = '/callisto/vertical_report/write';

        const response = await axios.post(path,
            {'data': arg.query}
        )
        return response.data
    })


const fetchPreparedVerticalReportData = createAsyncThunk('verticalReportData',
    async (arg) => {
        let path = '/callisto/vertical_report/report-data/' + arg.reportId;

        const response = await axios.get(path)
        return response.data
    })


const getValidAdvertisers = (form) => {
    let advertisers = []
    let errors = {}
    let hasErrors = false

    let result = form.advertisers.map((item, index) => {
        if (item.advertiser && isAdvertiser(item.advertiser)) {
            if (advertisers.includes(item.advertiser)) {
                errors[index] = 'Duplicate advertiser'
                hasErrors = true
                return null
            }
            advertisers.push(item.advertiser)
            return item
        }
        return null
    })

    return {
        advertisers: result.filter((item) => item !== null),
        errors,
        hasErrors
    }
}


let formInitialData = {
    advertisers: [
        {
            advertiser: '',
            name: '',
            logo: '',
            suggestions: [],
            error: ''
        },
        {
            advertiser: '',
            name: '',
            logo: '',
            suggestions: [],
            error: ''
        },
        {
            advertiser: '',
            name: '',
            logo: '',
            suggestions: [],
            error: ''
        }
    ],
    title: 'Advertiser Vertical Report',
    description: '',
    reportType: 'pdf',
    country: 'us',
    platform: 'all',
}


const verticalReportToolSlice = createSlice({
    name: 'verticalReportTool',
    initialState: {
        form: formInitialData,
        formStatus: 'idle',
        reportGeneratedDataStatus: 'idle',
        reportSubmitStatus: 'idle',
        verticalReportData: {
            data: null,
        },
        mode: 'form',
        errors: []
    },
    reducers: {
        resetErrors(state) {
            state.errors = []
        },
        resetSuggestions(state, action) {
            state.form.advertisers[action.payload.index].suggestions = []
        },
        setModalLogoData(state, action) {
            state.form.advertisers[action.payload.index] = action.payload.logo
        },
        setAdvertiserErrors(state, action) {
            for (const key in action.payload) {
                if (action.payload.hasOwnProperty(key)) {
                    state.form.advertisers[key].error = action.payload[key]
                }
            }
        },
        setAdvertiser(state, action) {
            let index = action.payload.index
            state.form.advertisers[index].advertiser = action.payload.value
            state.form.advertisers[index].error = ''
        },
        setAdvertiserLogo(state, action) {
            let advertiser = action.payload.advertiser

            state.form.advertisers.forEach((item, idx) => {
                if (item.advertiser === advertiser) {
                    item.logo = action.payload.value
                }
            })
            if (state.verticalReportData && state.verticalReportData.advertisers) {
                state.verticalReportData.advertisers.forEach((item, idx) => {
                    if (item.advertiser === advertiser) {
                        item.logo = action.payload.value
                    }
                })
            }
        },
        setAdvertiserName(state, action) {
            let index = action.payload.index
            state.form.advertisers[index].name = action.payload.value
        },
        setDescription(state, action) {
            state.form.description = action.payload.value
        },
        setReportType(state, action) {
            state.form.reportType = action.payload.value
        },
        setPlatform(state, action) {
            state.form.platform = action.payload.value
        },
        setCountry(state, action) {
            state.form.country = action.payload.value
        },
        resetViewToForm(state) {
            state.mode = 'form'
            state.verticalReportData = null
            state.form = formInitialData
        },
        setVerticalReportDataSection(state, action) {
            let {section, key, value} = action.payload
            state.verticalReportData[section][key] = value
        },
        resetReportSubmitStatus(state) {
            state.reportSubmitStatus = 'idle'
        }
    },
    extraReducers: {
        [verticalReportAdvertiserSuggestions.fulfilled]: (state, action) => {
            let index = action.meta.arg.index
            let totalScore = action.payload.rows.reduce((sum, suggetion) => sum + suggetion.score, 0) || 1

            state.form.advertisers[index].suggestions = action.payload.rows.map(function (suggestion) {
                suggestion.weight = suggestion.score / totalScore;
                suggestion.label = suggestion.suggestion
                return suggestion;
            })
        },
        [fetchVerticalReportData.fulfilled]: (state, action) => {
            state.formStatus = 'done'

            const processAdvertiserError = (advertisers) => {
                state.form.advertisers.forEach((item, index) => {
                    if (advertisers.includes(state.form.advertisers[index].advertiser)) {
                        state.form.advertisers[index].error = 'No advertiser data in this platform/country'
                    }
                })
            }

            const prepareModalData = function (data) {
                let reportData = new ReportModalData().prepareData(data);

                reportData.advertisers = getValidAdvertisers(state.form).advertisers
                reportData.country = state.form.country;
                reportData.platform = state.form.platform;
                reportData.reportType = state.form.reportType;

                return reportData;
            }

            if (action.payload.error) {
                if (action.payload.error === 'no advertiser data') {
                    processAdvertiserError(action.payload.advertisers)
                }
                else {
                    state.errors = [action.payload.error]
                }
            }
            else {
                state.mode = 'report'
                state.verticalReportData = prepareModalData(
                    action.payload
                )
            }
        },
        [fetchPreparedVerticalReportData.pending]: (state) => {
            state.reportGeneratedDataStatus = 'pending'
        },
        [fetchPreparedVerticalReportData.fulfilled]: (state, action) => {
            state.reportGeneratedDataStatus = 'done'

            if ('error' in action.payload) {
                state.errors = [action.payload.error]
                return;
            }

            const prepareModalData = function (data) {
                let reportData = new ReportModalData().prepareData(data);

                reportData.advertisers = []
                for (let i = 0; i < reportData.common.numAdvertisers; i++) {
                    let advertiser = {
                        advertiser: data['advertiser_' + i],
                        name: data['advertiser_' + i + '_name'],
                        logo: data['advertiser_' + i + '_logo'],
                    }
                    reportData.advertisers.push(advertiser)
                }
                reportData.country = state.form.country;
                reportData.platform = state.form.platform;
                reportData.reportType = state.form.reportType;

                return reportData;
            }
            state.verticalReportData = prepareModalData(
                action.payload
            )
        },
        [fetchVerticalReportData.pending]: (state) => {
            state.formStatus = 'pending'
        },
        [fetchVerticalReportData.rejected]: (state) => {
            state.formStatus = 'done'
            state.errors = ['Failed to load vertical report data']
        },
        [submitVerticalReportData.pending]: (state) => {
            state.reportSubmitStatus = 'pending'
        },
        [submitVerticalReportData.rejected]: (state) => {
            state.reportSubmitStatus = 'error'
            state.errors = ['Oops. Something went wrong and we are unable to ' +
            'generate your report. ' +
            'Please try again or contact ' +
            '<a target="_blank" href="' + getSupportLink() + '">support</a>']
        },
        [submitVerticalReportData.fulfilled]: (state, action) => {
            if ('error' in action.payload) {
                state.reportSubmitStatus = 'error'
                state.errors = ['Oops. Something went wrong and we are unable to ' +
                'generate your report. ' +
                'Please try again or contact ' +
                '<a target="_blank" href="' + getSupportLink() + '">support</a>']
            }
            else {
                state.reportSubmitStatus = 'success'
            }
        }
    }
})


const selectAdvertiserData = (index) => (state) => state.verticalReportTool.form.advertisers[index]
const selectFormData = (state) => state.verticalReportTool.form
const selectVerticalReportDataSection = (section) => (state) => state.verticalReportTool.verticalReportData[section]
const selectVerticalReportData = (state) => state.verticalReportTool.verticalReportData
const selectErrors = (state) => state.verticalReportTool.errors
const selectFormStatus = (state) => state.verticalReportTool.formStatus
const selectReportSubmitStatus = (state) => state.verticalReportTool.reportSubmitStatus
const selectReportGeneratedDataStatus = (state) => state.verticalReportTool.reportGeneratedDataStatus
const selectMode = (state) => state.verticalReportTool.mode

export {
    selectAdvertiserData,
    selectVerticalReportData,
    selectFormData,
    selectVerticalReportDataSection,
    selectReportSubmitStatus,
    selectReportGeneratedDataStatus,
    verticalReportAdvertiserSuggestions,
    fetchVerticalReportData,
    submitVerticalReportData,
    fetchPreparedVerticalReportData,
    getValidAdvertisers,
    selectErrors,
    selectFormStatus,
    selectMode
}

export const {
    resetSuggestions,
    setModalLogoData,
    resetErrors,
    setAdvertiser,
    setAdvertiserLogo,
    setAdvertiserName,
    setReportType,
    setCountry,
    setPlatform,
    setDescription,
    setAdvertiserErrors,
    resetViewToForm,
    setVerticalReportDataSection,
    resetReportSubmitStatus
} = verticalReportToolSlice.actions

export default verticalReportToolSlice.reducer
