import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../common/axiosRateLimit'
import {resetFollowing} from "../../reducers/bookmark";

const fetchFollowAdvertiserOptions = createAsyncThunk('fetchFollowAdvertiserOptions',
    async (arg) => {
        const response = await axios.get(
            '/callisto/get-follow-advertiser-options', {
                params: {
                    advertiser: arg.advertiser
                }
            })
        return response.data
    })

const saveFollowOptions = createAsyncThunk('saveFollowOptions',
    async (arg) => {
        const response = await axios.post(
            '/callisto/save-follow-advertiser-options', arg.formData)
        return response.data
    })


const followAdvertiserSlice = createSlice({
    name: 'followAdvertiser',
    initialState: {
        alertOptions: {
            status: 'idle',
            alert: null,
            savedSearch: false,
        },
        saveAlertStatus: {
            status: 'idle',
            errors: [],
            hasErrors: false
        },
        errors: [],
        updateStatus: 'idle',
        canAddAlerts: true
    },
    reducers: {
        clearErrors(state) {
            state.errors = []
        },
        resetState(state) {
            state.alertOptions.status = 'idle'
            state.alert = null
            state.alertOptions.savedSearch = false
            state.errors = []
            state.updateStatus = 'idle'
            state.saveAlertStatus.status = 'idle'
            state.saveAlertStatus.errors = {}
            state.saveAlertStatus.hasErrors = false
        }
    },
    extraReducers: {
        [fetchFollowAdvertiserOptions.fulfilled]: (state, action) => {
            const settings = action.meta.arg.settings
            state.alertOptions.status = 'done'

            if (action.payload.status === 'error') {
                state.errors = action.payload.errors
                return
            }

            state.canAddAlerts = action.payload.alerts < settings.alerts
            state.alertOptions.alert = action.payload.alert
            state.alertOptions.savedSearch = action.payload.savedSearch
        },
        [fetchFollowAdvertiserOptions.rejected]: (state) => {
            state.errors = ['Failed to load alert settings']
        },
        [saveFollowOptions.rejected]: (state, action) => {
            state.errors = ['Failed to submit form']
            state.saveAlertStatus.status = 'idle'
            state.saveAlertStatus.hasErrors = false
            state.saveAlertStatus.errors = {}

            action.asyncDispatch(resetFollowing())
        },
        [saveFollowOptions.pending]: (state) => {
            state.saveAlertStatus.status = 'pending'
            state.saveAlertStatus.hasErrors = false
            state.saveAlertStatus.errors = {}
        },
        [saveFollowOptions.fulfilled]: (state, action) => {
            action.asyncDispatch(resetFollowing())

            state.saveAlertStatus.status = 'done'
            if (action.payload.status === 'error') {
                state.saveAlertStatus.errors = action.payload.errors
                state.saveAlertStatus.hasErrors = true
            }
            else {
                state.saveAlertStatus.errors = {}
                state.saveAlertStatus.hasErrors = false
            }
        },
    }
})

const selectFollowAdvertiserState = (state) => state.followAdvertiser

export {
    fetchFollowAdvertiserOptions,
    saveFollowOptions,
    selectFollowAdvertiserState
}

export const {
    clearErrors, resetState, setError
} = followAdvertiserSlice.actions

export default followAdvertiserSlice.reducer
