import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchDmaData = createAsyncThunk('advertiserOverviewDMA',
    async (arg) => {
        const params = {
            entity: 'country',
            rows: 200,
            ...arg.query
        }

        const response = await axios.get('/data-api/DMAs', {
            params: params,
            cancelToken: getCancelSource().token
        })
        
        return response.data
    })

const dmaSlice = createSlice({
    name: 'advertiserDMA',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetDmaData(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchDmaData.pending]: (state) => {
            state.status = 'loading'
            state.data = []
        },
        [fetchDmaData.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload
        }
    }
})


const dmaSelector = state => state.publisherUiAdvertiserOverview.overview.dma
export {fetchDmaData, dmaSelector}
export const {resetDmaData} = dmaSlice.actions
export default dmaSlice.reducer
