import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchNewCampaignTopAds = createAsyncThunk('advertiserNewCampaignAds',
    async (arg) => {
        const response = await axios.get('/data-api/ads', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const fetchNewCampaignTrendlines = createAsyncThunk('advertiserNewCampaignTrendline',
    async (arg) => {
        const response = await axios.get('/data-api/trendlines', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const fetchNewCampaigns = createAsyncThunk('advertiserNewCampaigns',
    async (arg) => {
        const response = await axios.get('/data-api/campaigns', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        response.data.rows.slice(0, arg.numCampaignsShowingAds).forEach(c => {
            arg.dispatch(fetchNewCampaignTopAds({
                query: {
                    ...arg.query,
                    campaign: c.campaign,
                    rows: 2
                }
            }))
        });

        const trendlineQuery = { ...arg.query }
        delete trendlineQuery.rows
        delete trendlineQuery.sortBy
        delete trendlineQuery.sortOrder

        arg.dispatch(fetchNewCampaignTrendlines({
            query: {
                ...trendlineQuery,
                metrics: 'globalSeenDates',
                includeEntities: JSON.stringify(
                    response.data.rows.map(c => ({
                        campaign: c.campaign
                    }))
                )
            }
        }))

        return response.data
    })

const newCampaignsSlice = createSlice({
    name: 'advertiserNewCampaigns',
    initialState: {
        status: 'idle',
        topAdsStatus: 'idle',
        trendlineStatus: 'idle',
        data: [],
    },
    reducers: {
        resetNewCampaigns(state) {
            state.status = 'idle'
            state.topAdsStatus = 'idle'
            state.trendlineStatus = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchNewCampaigns.pending]: (state) => {
            state.status = 'loading'
            state.data = []
        },
        [fetchNewCampaigns.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows.map(
                c => ({ ...c, ads: [], trendline: {} }))
        },
        [fetchNewCampaignTopAds.pending]: (state) => {
            state.topAdsStatus = 'loading'
        },
        [fetchNewCampaignTopAds.fulfilled]: (state, action) => {
            state.topAdsStatus = 'done'
            state.data = state.data.map(c => {
                const campaign = action.meta.arg.query.campaign
                if (campaign === c.campaign) {
                    return {
                        ...c,
                        ads: action.payload.rows
                    }
                }
                return c
            })
        },
        [fetchNewCampaignTrendlines.pending]: (state) => {
            state.trendlineStatus = 'loading'
        },
        [fetchNewCampaignTrendlines.fulfilled]: (state, action) => {
            state.trendlineStatus = 'done'
            state.data = state.data.map(c => {
                const row = action.payload.rows
                    .filter(r => r.entity.campaign === c.campaign)
                    .pop()

                return {
                    ...c,
                    trendline: row
                }
            })
        },
    }
})


const newCampaignsSelector = state => state.advertiserProfile.overview.newCampaigns
export const {resetNewCampaigns} = newCampaignsSlice.actions
export { fetchNewCampaigns, newCampaignsSelector }
export default newCampaignsSlice.reducer