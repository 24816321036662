import axios from '../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import qs from "qs";
import Profile from '../../common/profile'
import {daysToDate} from "../../common/crawlDays";
import {rangeFor} from "../../components/DatePicker";


const fetchReportTrendline = createAsyncThunk('fetchReportTrendline',
    async (arg) => {
        let settings = arg.settings
        let report = arg.report

        const [startDate, endDate] = rangeFor(settings.defaultDateRange,
            daysToDate(settings.first_index_date),
            daysToDate(settings.last_index_date))

        let entity = {}

        switch (report.entity_type) {
            case 'advertiser':
                entity.advertiser = report.advertiser;
                break;
            case 'publisher':
                entity.publisher = report.publisher;
                break;
            case 'ad':
                entity.adHash = report.adHash;
                break;
            default:
                entity = null;
        }

        if (!entity) {
            return null;
        }

        let query = {
            metrics: settings.metricsList,
            startDate: startDate,
            endDate: endDate,
            country: report.country,
            platform: report.platform,
            includeEntities: JSON.stringify([entity])
        }

        const response = await axios.get("/data-api/trendlines", {
            params: query,
        })

        let data = response.data
        if (!data.rows || !data.rows.length) {
            return null;
        }

        return data.rows[0]
    })


const fetchReports = createAsyncThunk('fetchReports',
    async (arg) => {
        let path = '/callisto/reports/list?';
        let query = new URLSearchParams(arg.query).toString();

        const response = await axios.get(path + query)

        let settings = arg.settings
        let dispatch = arg.dispatch

        response.data.hits.forEach(report => {
            report.trendlineStatus = 'idle'
            dispatch(fetchReportTrendline({report, settings}))
        })

        return response.data
    })


const removeReport = createAsyncThunk('removeReport',
    async (arg) => {
        const response = await axios.post('/callisto/reports/delete',
            qs.stringify({
                id: arg.id
            }))
        return response.data
    })


const setReportTags = createAsyncThunk('setReportTags',
    async (arg) => {
        const response = await axios.post('/callisto/reports/set-tags',
            qs.stringify({
                id: arg.id,
                tags: arg.tags.join('|')
            }))
        return response.data
    })


const setLoadingStatus = function (report) {
    report.loading = report.status === 'in-progress' || report.status === 'init'
    return report;
}


const addEntityId = function (report) {
    report.advertiserList = report.advertisers || []

    switch (report.entity_type) {
        case 'advertiser':
            report.entityId = report.advertiser;
            break;
        case 'publisher':
            report.entityId = report.publisher;
            break;
        case 'campaign':
            report.entityId = report.campaign;
            break;
        case 'ad':
            report.entityId = report.advertiser;
            break;
        case 'network':
            report.entityId = report.network;
            break;
        case 'vertical_report':
            report.entityId = null;
            break;
        case 'top_advertisers':
        case 'top_ads':
        case 'old':
        case 'adv_data_enrich':
        case 'advertisers':
        case 'brand':
        case 'movers_and_shakers':
        case 'new_creatives':
            report.entityId = null;
            break;
        default:
            throw new Error('Invalid entityType ' + report.entity_type)
    }
    return report;
}


const addIconType = function (report) {
    switch (report.type) {
        case 'excel':
            report.icon = 'fa-file-excel-o';
            break;
        case 'pdf':
            report.icon = 'fa-file-pdf-o';
            break;
        case 'pptx':
            report.icon = 'fa-file-powerpoint-o';
            break;
        default:
            report.icon = 'fa-image';
    }
    return report;
};


const addProfileUrl = function (report) {
    report.profileURL = null

    if (report.name === 'vertical_report') {
        report.profileURL = '/tools/vertical_report';
        return report;
    }

    if (/new_business/.test(report.name)) {
        report.profileURL = '/new_business';
        return report;
    }
    if (/expansion_opportunities/.test(report.name)) {
        report.profileURL = '/expansion_opportunities';
        return report;
    }

    if (report.site_link) {
        report.profileURL = report.site_link;
        return report;
    }

    if (!report.entityId) {
        report = addEntityId(report);
    }

    if (
        report.entity_type !== 'advertiser' &&
        report.entity_type !== 'publisher'
    ) {
        report.profileURL = null;
        return report;
    }

    report.profileURL = Profile.url(
        report.entity_type,
        report.country,
        report.platform,
        report.entityId,
    )

    return report;
};


const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        reports: {
            'hits': [],
            'numHits': 0,
            'groups': {},
            'name_groups': {},
            'generic_scheduled_count': 0,
            'tags': [],
            'total': 0,
        },
        trendlines: {},
        errors: [],
        status: 'idle'
    },
    reducers: {
        resetReportsList(state) {
            state.status = 'idle'
            state.errors = []
        },
        resetAll(state) {
            state.errors = []
            state.status = 'idle'
            state.reports.total = 0
            state.reports.groups = {}
            state.reports.name_groups = {}
        },
        resetErrors(state) {
            state.errors = []
        }
    },
    extraReducers: {
        [fetchReports.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchReports.fulfilled]: (state, action) => {
            state.status = 'done'
            state.reports.hits = action.payload.hits
                .map(setLoadingStatus)
                .map(addIconType)
                .map(addEntityId)
                .map(addProfileUrl)

            state.reports.hits = state.reports.hits.map(item => {
                if (state.trendlines.hasOwnProperty(item.id)) {
                    item.trendlineStatus = 'done'
                    item.trendLine = state.trendlines[item.id]
                }
                return item
            })

            state.reports.groups = action.payload.groups
            state.reports.name_groups = action.payload.name_groups
            state.reports.tags = action.payload.tags
            state.reports.generic_scheduled_count = action.payload.generic_scheduled_count
            state.reports.total = state.reports.total || action.payload.total
            state.reports.numHits = action.payload.numHits || state.reports.numHits
        },
        [removeReport.rejected]: (state) => {
            state.errors = ['Report wasn\'t removed']
        },
        [removeReport.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.errors = action.payload.errors
            }
            state.status = 'idle'
        },
        [setReportTags.rejected]: (state, action) => {
            let reportId = action.meta.arg.id

            state.reports.hits.forEach((item) => {
                if (item.id === reportId) {
                    setLoadingStatus(item)
                }
            })

            state.errors = ['Failed to update tags']
        },
        [setReportTags.fulfilled]: (state, action) => {
            let reportId = action.meta.arg.id

            state.reports.hits.forEach((item) => {
                if (item.id === reportId) {
                    setLoadingStatus(item)
                }
            })

            if ('errors' in action.payload) {
                state.errors = action.payload.errors
            }
            state.status = 'idle'
        },
        [fetchReportTrendline.fulfilled]: (state, action) => {
            let report = action.meta.arg.report

            let row = state.reports.hits.filter((item) => item.id === report.id).shift()
            if (row && action.payload) {
                row.trendlineStatus = 'done'
                row.trendLine = action.payload
            }
            state.trendlines[report.id] = action.payload
        }
    }
})

const selectReports = (state) => state.reports

export default reportsSlice.reducer

export const {
    resetReportsList, resetAll,
    resetErrors
} = reportsSlice.actions

export {
    fetchReports,
    removeReport,
    setReportTags,
    selectReports
}
