import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchUserDashSettings = createAsyncThunk('fetchUserDashSettings',
    async (arg) => {
        const response = await axios.get("/callisto/user-dashboard/settings")

        return response.data
    })

const saveUserDashSettings = createAsyncThunk('saveUserDashSettings',
    async (arg) => {
        const response = await axios.post("/callisto/user-dashboard/settings", arg) 

        return response.data
    })

const userDashboardSettingsSlice = createSlice({
    name: 'topAdsPublishers',
    initialState: {
        status: 'idle',
        data: {},
        statusDelete: 'idle',
    },
    reducers: {
        resetDashboardSettings(state) {
            return {
                status: 'idle',
                data: {},
                statusDelete: 'idle',
            }
        }
    },
    extraReducers: {
        [fetchUserDashSettings.pending]: (state) => {
            state.status = 'pending'
            state.data = {}
        },
        [fetchUserDashSettings.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload
        },
        [saveUserDashSettings.pending]: (state) => {
            state.statusDelete = 'pending'
        },
        [saveUserDashSettings.fulfilled]: (state, action) => {
            state.statusDelete = 'done'
        },
    }
})

const selectDashboardSettings = state => state.userDashboard.settings
export const {resetDashboardSettings} = userDashboardSettingsSlice.actions
export {fetchUserDashSettings, 
    saveUserDashSettings,
    selectDashboardSettings}
export default userDashboardSettingsSlice.reducer