import ReactTooltip from 'react-tooltip'

const Tooltip = (props) => {
    return (
        <ReactTooltip
            id={props.id}
            type="light"
            effect="solid"
            textColor="#7f8fa4"
            delayHide={props.delayHide || 500}
            delayUpdate={500}
            clickable={true}
            offset={props.offset}
            afterShow={props.afterShow}
            place={props.place}
            className={
                "tooltipster-base tooltipster-html react-tooltip-adbeat " +
                (props.extraClassName || '')}>
            <div className="tooltipster-content">
                {props.children}
            </div>
        </ReactTooltip>
    );
}

export default Tooltip;