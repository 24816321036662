import axios from '../../../common/axiosRateLimit'
import qs from 'qs'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'


const fetchAdTypes = createAsyncThunk('fetch_menu_adTypes',
    async (arg) => {
        const response = await axios.get('/menu-api/adTypes?' + qs.stringify({
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            country: arg.query.country,
            network: arg.query.network,
        }))

        return response.data
    })

const adTypesSlice = createSlice({
    name: 'platform-menu',
    initialState: {
        status: 'idle',
        adTypes: []
    },
    reducers: {
        resetAdTypes: (state) => {
            state.status = 'idle'
            state.adTypes = []
        }
    },
    extraReducers: {
        [fetchAdTypes.pending]: (state, action) => {
            state.status = 'pending'
            state.adTypes = []
        },
        [fetchAdTypes.fulfilled]: (state, action) => {
            state.adTypes = action.payload.rows
            state.status = 'done'
        },
        [fetchAdTypes.rejected]: (state, action) => {
            state.adTypes = []
            state.status = 'failed'
        }
    }
})

const selectAdTypes = (state) => state.menu.topAds.adTypes

export {fetchAdTypes, selectAdTypes}
export const {resetAdTypes} = adTypesSlice.actions

export default adTypesSlice.reducer