import addImgUrl from "./addImgUrls";

const prepareAd =  (ad) => {
    let resultAd = {
        ...ad,
        ...ad.adInfo
    }

    addImgUrl(resultAd)
    return resultAd
}

export default prepareAd
