import UserProfileBase from './UserProfileBase'
import LoadingState from '../../components/LoadingState'
import GetMoreData from './GetMoreData';
import { useDispatch, useSelector } from 'react-redux';
import { settingsSelector } from '../../reducers/settings';
import { pickCurrency, symbol, useSettings } from '../../misc/currency';
import { useEffect, useState } from 'react';
import { format, useSettings as useSettingsForFormat } from '../../common/formatMetric';
import { fetchUserDashSettings, selectDashboardSettings } from './userDashboardSettingsSlice';
import { saveUserDashSettings } from './userDashboardSettingsSlice';
import {isEmpty} from 'lodash'

const EventLimit = props => {
    return <>
        <div className='grid-cell first-column'>
            <label htmlFor={props.for}>{props.label}</label>
        </div>
        <div className='grid-cell event-limit second-column'>
            {props.currencySym || ''}{' '}<input type="number"
                min={0}
                name={props.for}
                step={1}
                value={props.value || 0}
                onChange={e => props.updateLimits(props.for, e.target.value)}
                id={props.for}></input>
        </div>
    </>
}

const UserProfileDashboardSettings = props => {
    const defaultLimit = 25
    const dispatch = useDispatch()
    const dashSettings = useSelector(selectDashboardSettings)
    const settings = useSelector(settingsSelector)
    useSettings(settings)
    useSettingsForFormat(settings)
    
    const symb = symbol(pickCurrency())
    const [limits, setLimits] = useState({metric: settings.adMetricId})

    useEffect(() => {
        if (dashSettings.status === 'idle') {
            dispatch(fetchUserDashSettings())
        }
    }, [dispatch, dashSettings.status])

    useEffect(() => {
        if (!isEmpty(dashSettings.data)) {
            setLimits(dashSettings.data)
        }
    }, [dashSettings.data, settings.adMetricId])

    const onSubmit = e => {
        e.preventDefault()
        dispatch(saveUserDashSettings(limits))
    }

    const updateLimits = (limit, val) => {
        const newLimits = {...limits}
        newLimits[limit] = val
        setLimits(newLimits)
    }

    return <UserProfileBase>
        <div className='left overview-block'>
            <LoadingState show={dashSettings.status === 'pending' || dashSettings.statusDelete === 'pending'} />
            <div className='section-header'>
                <h1>Dashboard Settings</h1> 
            </div>
            <div className='clear'></div>
            <div className='section-body left'>
                <form onSubmit={onSubmit}>
                    <div className='container'>
                        <div className='grid-cell header-row first-column'>
                            <h2>Metric</h2>
                        </div>
                        <div className='grid-cell header-row second-column'>
                        </div>
                        { settings.hasSpend && 
                            <div className='grid-cell first-column'>
                                <input type="radio" id="adSpend"
                                    name="metric"
                                    value="adSpend"
                                    checked={limits.metric === 'adSpend'}
                                    onChange={e => updateLimits('metric', e.target.value)}></input>
                                <label htmlFor="adSpend">Spend (USD)</label>
                            </div>
                        }
                        <div className='grid-cell first-column'>
                            <input type="radio" id="adImpressions"
                                name="metric"
                                checked={limits.metric === 'adImpressions'}
                                onChange={e => updateLimits('metric', e.target.value)}
                                value="adImpressions"></input>
                            <label htmlFor="adImpressions">Impressions</label>
                        </div>
                        <div className='grid-cell header-row first-column'>
                            <h2>Advertiser Events</h2>
                        </div>
                        <div className='grid-cell header-row second-column'>
                            <h2>Minimum {format('{Metric}', limits.metric)}</h2>
                        </div>
                        <EventLimit for="newAd"
                            updateLimits={updateLimits}
                            label="New Ad"
                            value={limits.newAd || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <EventLimit for="newPublisher"
                            updateLimits={updateLimits}
                            label="New Publisher"
                            value={limits.newPublisher || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <EventLimit for="newNetwork"
                            updateLimits={updateLimits}
                            label="New Ad Network"
                            value={limits.newNetwork || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <EventLimit for="newCampaign"
                            updateLimits={updateLimits}
                            label="New Campaign"
                            value={limits.newCampaign || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <EventLimit for="newCountry"
                            updateLimits={updateLimits}
                            label="New Country"
                            value={limits.newCountry || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <EventLimit for="newDMA"
                            updateLimits={updateLimits}
                            label="New U.S. DMA"
                            value={limits.newDMA || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <div className='grid-cell header-row first-column'>
                            <h2>Publisher Events</h2>
                        </div>
                        <div className='grid-cell header-row second-column'>
                            <h2>Minimum {format('{Metric}', limits.metric)}</h2>
                        </div>

                        <EventLimit for="newAdvertiser"
                            updateLimits={updateLimits}
                            label="New Advertiser"
                            value={limits.newAdvertiser || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <EventLimit for="newPubNetwork"
                            updateLimits={updateLimits}
                            label="New Ad Network"
                            value={limits.newPubNetwork || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <EventLimit for="newCategory"
                            updateLimits={updateLimits}
                            label="New Advertiser Category"
                            value={limits.newCategory || defaultLimit}
                            currencySym={limits.metric === 'adSpend' ? symb : ''} />

                        <div className='grid-cell hr'></div>

                        <div className='grid-cell submit-container second-column'>
                            <input type="submit" value="Update" />
                        </div>

                    </div>
                </form>
            </div>
            <div className='section-body right'>
                <GetMoreData />
            </div>
        </div>
    </UserProfileBase>
}

export default UserProfileDashboardSettings;