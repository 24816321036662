import { combineReducers } from "redux";
import byNetwork from "./spend/networksSlice";
import channelsSlice from "./spend/channelsSlice";
import spendSlice from "./spend/spendSlice";
import categorySlice from "./spend/categorySlice";
import publisherSlice from "./spend/publisherSlice";
import advertiserSlice from "./spend/advertiserSlice";
import pubChannelsSlice from "./spend/pubChannelsSlice";
import dmaSlice from "./dma/dmaSlice";
import creativeSizeSlice from "./creative/creativeSizeSlice";
import creativeTypeSlice from "./creative/creativeTypeSlice";
import byDateSlice from "./creative/byDateSlice";
import topAdsSlice from "./creative/topAdsSlice";
import newCampaignsSlice from "./campaigns/campaignsSlice";
import publishersSlice from "./publishers/publishersSlice";
import newPublishersSlice from "./publishers/newPublishersSlice";
import similarSlice from "./similar-profiles/similarSlice";


const reducers = combineReducers({
    spend: spendSlice,
    channels: channelsSlice,
    byNetwork: byNetwork,
    byCategory: categorySlice,
    byPublisher: publisherSlice,
    byAdvertiser: advertiserSlice,
    byChannels: pubChannelsSlice,
    dma: dmaSlice,
    creativeSizes: creativeSizeSlice,
    creativeTypes: creativeTypeSlice,
    adCountByDate: byDateSlice,
    topAds: topAdsSlice,
    newCampaigns: newCampaignsSlice,
    publishers: publishersSlice,
    newPublishers: newPublishersSlice,
    similarProfiles: similarSlice
})

export default reducers

