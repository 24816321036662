class Platforms {
    constructor(platforms, allowed_platforms, settings) {
        var self = this;

        function markAllowed(list, allowed) {
            var allowedKeys = {};

            allowed.forEach(function (item) {
                allowedKeys[item.id] = true;
            });

            return list.map(function (item) {
                return {
                    ...item,
                    allowed: allowedKeys[item.id] || false
                }
            });
        }

        // Return list of platforms, mobile and desktop which are allowed
        this.getAllowedPlatformsMobile = function (countryId) {

            var isDesktopAllowed = false;
            var isMobileAllowed = false;

            settings.allowed.countries.forEach(function (item) {
                if (item.id === countryId) {
                    isDesktopAllowed = true;
                }
            });

            settings.allowed.mobile_countries.forEach(function (item) {
                if (item.id === countryId) {
                    isMobileAllowed = true;
                }
            });

            var result;
            var rv = [];

            if (isDesktopAllowed) {
                rv.push('desktop');
            }

            if (isMobileAllowed) {
                rv.push('mobile');
            }
            result = rv.join(',');

            return result;
        };

        this.getAvailablePlatforms = function (countryId) {

            var rv = [];

            settings.desktop_countries.forEach(function (item) {
                if (item.id === countryId) {
                    rv.push('desktop');
                }
            });

            settings.mobile_countries.forEach(function (item) {
                if (item.id === countryId) {
                    self.list().forEach(function (item) {
                        if (item.id !== 'desktop') {
                            rv.push(item.id);
                        }
                    });
                }
            });

            if (rv.length) {
                rv.push('all');
            }

            return rv;
        };

        this.isAllowed = function (platformId) {
            return settings.allowed.platforms.some(function (platform) {
                return platform.id === platformId;
            });
        };

        this.list = platforms;

        this.list = markAllowed(this.list, allowed_platforms);

        this.platformIds = (function (list) {
            return list.map(function (platform) {
                return platform.id;
            });
        } (this.list));

        this.platformAllQueryWrapper = function (substPlatformId) {
            return function (resultList) {
                resultList.forEach(function (item) {
                    if (item.key === 'platformId' &&
                        item.value === 'all') {
                        item.value = substPlatformId;
                    }
                });
                return resultList;
            };
        };

        this.platformIdToName = function (id) {
            var p = self.list;
            var i, len;
            for (i = 0, len = p.length; i < len; i += 1) {
                if (p[i].id === id) {
                    return p[i].name;
                }
            }
            return '';
        };

        this.sortFunc = function (a, b) {
            var pIds = this.platformIds();
            return pIds.indexOf(a.platformId) -
                pIds.indexOf(b.platformId);
        }.bind(this);
    }
}

export default Platforms