import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'
import {networkIsAllByNetwork} from "../../misc/allByNetwork";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserTrends = createAsyncThunk('fetchTopAdvertiserTrendlines',
    async (arg) => {
        if (!arg.advertisers.length) {
            return
        }

        const isAllByNetwork = networkIsAllByNetwork(arg.query.network)

        const entities = (advertiser) => {
            const result = {
                advertiser: advertiser.advertiser
            }
            if (isAllByNetwork) {
                result.network = advertiser.network
            }
            return result
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.advertisers.map(entities))
        }

        params.metrics += ',globalSeenDates'

        delete params.pageNumber
        delete params.pageSize
        delete params.category
        delete params.sortBy
        delete params.sortOrder
        delete params.channel

        if (isAllByNetwork) {
            delete params.network
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


const fetchAdvertiserPlatformTrends = createAsyncThunk('fetchTopAdvertiserPlatformTrendlines',
    async (arg) => {
        const entities = [{
            'platform': 'desktop'
        }, {
            'platform': 'mobile'
        }]

        const params = {
            ...arg.query,
            includeEntities: JSON.stringify(entities)
        }

        delete params.pageNumber
        delete params.pageSize
        delete params.category
        delete params.sortBy
        delete params.sortOrder
        delete params.platform
        delete params.channel

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


// This is only for checking if advertiser has categories
// You may question yourself why not load categories data all together
// and omit next requests when user wants to see categories
// I cannot easily do this with one request if user gets
// Categories with "All By Network" filter for example,
// logic will be too complex. Also I decided to not request data if
// user already queried for particular category. So this method will be
// used only if no category queries.
const fetchAdvertiserHasCategories = createAsyncThunk('fetchAdvertiserHasCategories',
    async (arg) => {
        if (!arg.advertisers.length) {
            return
        }

        const isAllByNetwork = networkIsAllByNetwork(arg.query.network)


        let params = {
            ...arg.query,
            entity: 'advertiser',
            advertiser: arg.advertisers.map(item => item.advertiser).join(",")
        }

        delete params.pageNumber
        delete params.pageSize
        delete params.category
        delete params.sortBy
        delete params.sortOrder
        delete params.channel
        params.rows = arg.advertisers.length * 6

        if (isAllByNetwork) {
            delete params.network
        }

        const response = await axios.get("/data-api/categories", {
            params: params,
            cancelToken: getCancelSource().token
        })

        return response.data
    }
)


const fetchTopAdvertisers = createAsyncThunk('fetchTopAdvertisers',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()
        
        const params = {
            pageNumber: arg.query.pageNumber,
            pageSize: arg.query.pageSize,
            country: arg.query.country,
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            platform: arg.query.platform,
            metrics: arg.query.metrics,
            sortBy: arg.query.sortBy,
            sortOrder: arg.query.sortOrder,
            network: arg.query.network,
            category: arg.query.category
        }

        if (arg.query.publisher) {
            params.publisher = arg.query.publisher
        }

        let hasCategories = false
        if (arg.query.category && arg.query.category.length > 1) {
            params.category = arg.query.category
            hasCategories = true
        }

        if (arg.query.channel && arg.query.channel.length > 1) {
            params.channel = arg.query.channel
        }

        const response = await axios.get("/data-api/advertisers", {
            params: params,
            cancelToken: getCancelSource().token
        })

        if ('rows' in response.data) {
            arg.dispatch(fetchAdvertiserTrends({
                advertisers: response.data.rows,
                query: {
                    ...params
                }
            }))

            if (!hasCategories) {
                arg.dispatch(fetchAdvertiserHasCategories({
                    advertisers: response.data.rows,
                    query: {
                        ...params
                    }
                }))
            }

            response.data.rows = response.data.rows.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendlinePlatformStatus: 'idle',
                hasCategories,
                trendLine: {
                    globalDaysSeen: 0
                }
            }))
        }

        return response.data
    })

const topAdvertisersSlice = createSlice({
    name: 'topAdvertisers',
    initialState: {
        advertisers: {},
        status: 'idle'
    },
    reducers: {
        resetTopAdvertisers(state) {
            state.advertisers = {}
            state.status = 'idle'
        },
        accessDenied(state) {
            state.advertisers = {}
            state.status = 'forbidden'
        },
        serverError(state) {
            state.advertisers = {}
            state.status = 'error'
        }
    },
    extraReducers: {
        [fetchTopAdvertisers.pending]: (state, action) => {
            state.advertisers = {}
            state.status = 'pending'
        },
        [fetchTopAdvertisers.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.advertisers = {}
                state.status = 'error'
            } else {
                state.advertisers = action.payload
                state.advertisers.rows.forEach(item => {
                    item.network = item.network || action.meta.arg.query.network
                })
                state.status = 'done'
            }
        },
        [fetchTopAdvertisers.rejected]: (state, action) => {
            state.advertisers = {}
            if ('message' in action.error) {
                if (action.error.message !== 'cancel') {
                    state.status = action.error.message
                }
            }
            else {
                state.status = 'error'
            }
        },
        [fetchAdvertiserTrends.fulfilled]: (state, action) => {
            if (state.advertisers.rows) {
                state.advertisers.rows = state.advertisers.rows.map(row => {
                    const trendline = action.payload.rows
                        .filter(tr => {
                            return Object.keys(tr.entity).every(function (key) {
                                return tr.entity[key] === row[key];
                            });
                        }).shift() || {
                        globalDaysSeen: 0
                    }

                    let trendlineStatus = 'done'
                    if (!trendline.globalDaysSeen) {
                        trendlineStatus = 'idle'
                    }

                    return {
                        ...row,
                        trendlineStatus,
                        trendLine: trendline
                    }
                })
            }
        },
        [fetchAdvertiserHasCategories.fulfilled]: (state, action) => {
            if (state.advertisers.rows) {
                state.advertisers.rows = state.advertisers.rows.map(row => {
                    let hasCategories = action.payload.rows
                        .filter(item => item.advertiser === row.advertiser).length > 0

                    return {
                        ...row,
                        hasCategories
                    }
                })
            }
        },
        [fetchAdvertiserPlatformTrends.fulfilled]: (state, action) => {
            if (state.advertisers.rows && action.payload.rows) {
                const network = action.meta.arg.query.network
                const advertiser = action.meta.arg.query.advertiser

                let advertiser_row = state.advertisers.rows.filter(
                    row => row.advertiser === advertiser &&
                        row.network === network
                ).shift()

                if (!advertiser_row) {
                    return;
                }

                let resultsByPlatform = {}

                action.payload.rows.forEach(trRow => {
                    resultsByPlatform[trRow.entity.platform] = trRow
                })

                advertiser_row.trendlinePlatformStatus = 'done'
                advertiser_row.trendLinePlatforms = resultsByPlatform
            }
        }
    }
})

const selectTopAdvertisers = (state) => state.topAdvertisers.advertisers

export const {
    accessDenied, serverError,
    resetTopAdvertisers,
    requestCancel
} = topAdvertisersSlice.actions

export {
    selectTopAdvertisers,
    fetchTopAdvertisers,
    fetchAdvertiserPlatformTrends
}

export default topAdvertisersSlice.reducer