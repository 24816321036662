import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchPublisherTopAdTrends = createAsyncThunk('fetchPublisherTopAdTrends',
    async (arg) => {
        if (!arg.ads.length) {
            return
        }

        const entities = (ad) => {
            return {
                adHash: ad.adHash
            }
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.ads.map(entities))
        }

        delete params.pageNumber
        delete params.pageSize
        delete params.category
        delete params.sortBy
        delete params.sortOrder
        delete params.rows

        params.metrics += ',globalSeenDates'

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


const fetchPublisherTopAds = createAsyncThunk('advPublisherTopAds',
    async (arg) => {
        const response = await axios.get('/data-api/ads', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        if ('rows' in response.data) {
            arg.dispatch(fetchPublisherTopAdTrends({
                ads: response.data.rows,
                query: {
                    ...arg.query
                }
            }))

            response.data.rows = response.data.rows.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendLine: {
                    globalDaysSeen: 0
                }
            }))
        }

        return response.data
    })

const fetchPublisherTrendline = createAsyncThunk('advPublisherTrendline',
    async (arg) => {
        const response = await axios.get('/data-api/trendlines', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const fetchNetworkPublishers = createAsyncThunk(
    'fetchNetworkPublishers',
    async (arg) => {
        const params = {
            ...arg.query
        }

        const response = await axios.get("/data-api/publishers", {
            params: params,
            cancelToken: getCancelSource().token
        })

        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()
        response.data.rows.forEach(p => {
            arg.dispatch(fetchPublisherTopAds({
                dispatch: arg.dispatch,
                query: {
                    ...arg.query,
                    publisher: p.publisher,
                    pageNumber: '1',
                    pageSize: '2'
                }
            }))
        });

        const trendlineQuery = {...arg.query}
        delete trendlineQuery.rows
        delete trendlineQuery.sortBy
        delete trendlineQuery.sortOrder
        delete trendlineQuery.pageNumber
        delete trendlineQuery.pageSize

        arg.dispatch(fetchPublisherTrendline({
            query: {
                ...trendlineQuery,
                metrics: arg.settings.metricField + ',globalSeenDates',
                includeEntities: JSON.stringify(
                    response.data.rows.map(p => ({
                        publisher: p.publisher
                    }))
                )
            }
        }))
        return response.data
    }
)

const publishersSlice = createSlice({
    name: 'networkProfilePublishers',
    initialState: {
        status: 'idle',
        topAdsStatus: 'idle',
        trendlineStatus: 'idle',
        data: []
    },
    reducers: {
        resetPublisherData(state) {
            state.data = []
            state.trendlineStatus = 'idle'
            state.topAdsStatus = 'idle'
            state.status = 'idle'
        }
    },
    extraReducers: {
        [fetchNetworkPublishers.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchNetworkPublishers.fulfilled]: (state, action) => {
            state.status = 'done'
            state.extraPagesAvailable = action.payload.extraPagesAvailable
            state.data = action.payload.rows.map(r => ({
                ...r,
                ads: [],
                trendline: {}
            }))
        },
        [fetchPublisherTopAds.pending]: (state) => {
            state.topAdsStatus = 'loading'
        },
        [fetchPublisherTopAds.fulfilled]: (state, action) => {
            state.topAdsStatus = 'done'
            state.data = state.data.map(p => {
                const publisher = action.meta.arg.query.publisher
                if (publisher === p.publisher) {
                    return {
                        ...p,
                        ads: action.payload.rows
                    }
                }
                return p
            })
        },
        [fetchPublisherTrendline.pending]: (state) => {
            state.trendlineStatus = 'loading'
        },
        [fetchPublisherTrendline.fulfilled]: (state, action) => {
            state.trendlineStatus = 'done'
            state.data = state.data.map(p => {
                const row = action.payload.rows
                    .filter(r => r.entity.publisher === p.publisher)
                    .pop()

                return {
                    ...p,
                    trendline: row
                }
            })
        },
        [fetchPublisherTopAdTrends.fulfilled]: (state, action) => {
            state.data = state.data.map(p => {
                const publisher = action.meta.arg.query.publisher
                if (publisher === p.publisher) {
                    let ads = p.ads.map((ad) => {
                        let trendline = action.payload.rows.filter((item) => {
                            return item.entity.adHash === ad.adHash
                        }).shift()

                        return {
                            ...ad,
                            trendLine: trendline,
                            trendlineStatus: 'done'
                        }
                    })

                    return {
                        ...p,
                        ads: ads
                    }
                }
                return p
            })
        }
    }
})

const selectNetworkProfilePublishers = (state) => state.networkProfile.publishers
export const {resetPublisherData} = publishersSlice.actions
export {selectNetworkProfilePublishers, fetchNetworkPublishers}

export default publishersSlice.reducer