import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdChannels = createAsyncThunk(
    'fetchAdvertiser',
    async (arg) => {
        const response = await axios.get('/data-api/networks', {
            params: {
                ...arg.query,
                entity: 'adType',
                rows: 500
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    }
)

const channelsSlice = createSlice({
    name: 'advertiserAdChannels',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetChannels(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdChannels.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdChannels.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserChannels = (state) => state.advertiserProfile.overview.channels
export const {resetChannels} = channelsSlice.actions
export {selectAdvertiserChannels, fetchAdChannels}
export default channelsSlice.reducer