import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvProfileBrands = createAsyncThunk(
    'advProfileBrands',
    async (arg) => {
        const response = await axios.get("/data-api/brands", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const advProfileBrandsSlice = createSlice({
    name: 'advProfileBrands',
    initialState: {
        status: 'idle',
        data: [],
    },
    extraReducers: {
        [fetchAdvProfileBrands.pending]: (state) => {
            state.status = 'loading'
            state.data = []
        },
        [fetchAdvProfileBrands.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})


const advProfileBrandsSelector = state => state.advertiserProfile.brands
export {fetchAdvProfileBrands, advProfileBrandsSelector}
export default advProfileBrandsSlice.reducer