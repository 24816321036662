import axios from '../../common/axiosRateLimit'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const fetchCollections = createAsyncThunk('fetchAdLockerCollections',
    async (arg) => {
        let path = '/ad-locker/v1.0/ad-collections?';
        let query = 'userId=' + arg.settings.user.id;

        if (arg.query.q !== undefined &&
            arg.query.q.constructor === String) {
            query += '&q=' + window.encodeURIComponent(arg.query.q);
        }

        if (arg.query.withCount !== undefined) {
            query += '&withCount=1';
        }

        if (typeof arg.query.ads === 'number') {
            query += '&ads=' + arg.query.ads;
        }

        query += '&page=' + (arg.query.page || 0);
        query += '&pageSize=' + (arg.query.pageSize || 5);
        query += '&includePaid=true';

        const response = await axios.get(path + query)
        
        return response.data
    })

const fetchCollection = createAsyncThunk('fetchCollection',
    async (arg) => {
        const result = await axios.get(
            `/ad-locker/v1.0/ad-collections/${
                arg.query.id}?userId=${arg.settings.user.id}`)

        return result.data
    })

const fetchCollectionAds = createAsyncThunk('fetchCollectionAds',
    async (arg) => {
        const result = await axios.get(
            `/ad-locker/v1.0/ad-collections/${
                arg.query.id}/ads?page=${
                    arg.query.page}&pageSize=${
                        arg.query.pageSize}&includePaid=true&userId=${
                            arg.settings.user.id}`
        )

        return result.data
    })

const updateCollection = createAsyncThunk('updateAdLockerCollection', 
    async (arg) => {
        const result = await axios.put(
            `/ad-locker/v1.0/ad-collections/${
                arg.query.id}`,
                {
                    ...arg.collection,
                    userId: arg.settings.user.id
                })

        return result.data
    })

const createCollection = createAsyncThunk('createAdLockerCollection',
    async (arg) => {       
        const response = await axios.post('/ad-locker/v1.0/ad-collections', {
            name: arg.query.name,
            userId: arg.settings.user.id
        })
        
        return response.data
    })

const removeCollection = createAsyncThunk('removeAdLockerCollection',
    async (arg) => {
        const response = await axios.delete(
            '/ad-locker/v1.0/ad-collections/' + arg.query.id + 
            '?userId=' + arg.settings.user.id)
        return response.data
    })

const removeAd = createAsyncThunk('removeAdLockerAd',
    async (arg) => {
        const response = await axios.delete(
            '/ad-locker/v1.0/ad-collections/ad/' + arg.query.id +
            '?userId=' + arg.settings.user.id)
        return response.data    
    })

const addToCollection = createAsyncThunk('addAdLockerAd',
    async (arg) => {
        function adData(ad, coll) {
            coll = coll || {};
            const trendline = {
                ...ad.trendLine,
            }

            const result = {
                ...ad,
                trendline,
                country: arg.country,
                platform: arg.platform,
                adCollectionId: coll.uri,
                userId: arg.settings.user.id
            }

            delete result.trendLine

            return result
        }

        const response = await axios.post(
            '/ad-locker/v1.0/ad-collections/ad',
            adData(arg.ad, arg.collection))

        arg.dispatch(resetCollectionList())
        return response.data
    })

const fetchCollectionTotals = createAsyncThunk('fetchAdLockerCollectionTotals',
    async (arg) => {
        const response = await axios.get(
            '/ad-locker/v1.0/ad-collection-totals?&includePaid=true&userId=' +
            arg.settings.user.id)

        return response.data
    })

const adLockerSlice = createSlice({
    name: 'adLocker',
    initialState: {
        collections: [],
        status: 'idle',
        collection: {},
        collectionStatus: 'idle',
        updateCollectionStatus: 'idle',
        collectionAds: [],
        collectionAdsStatus: 'idle',
        totals: { ads: 0, collections: 0 },
        totalsStatus: 'idle',
        createCollectionStatus: 'idle',
        createCollectionResult: {},
        removeCollectionStatus: 'idle',
        removeAdStatus: 'idle',
        addAdStatus: 'idle',
        addAdError: ''
    },
    reducers: {
        resetCollectionList(state) {
            state.status = 'idle'
            state.collections = []
        },
        resetCollection(state) {
            state.collectionStatus = 'idle'
            state.collection = {}
        },
        resetCollectionAds(state) {
            state.collectionAdsStatus = 'idle'
            state.collectionAds = []
        },
        resetCreateCollection(state) {
            state.createCollectionStatus = 'idle'
        },
        resetAddAd(state) {
            state.addAdError = ''
            state.addAdStatus = 'idle'
        }
    },
    extraReducers: {
        [fetchCollections.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchCollections.fulfilled]: (state, action) => {
            state.status = 'done'
            state.collections = action.payload.collections
        },
        [fetchCollection.pending]: (state) => {
            state.collectionStatus = 'pending'
        },
        [fetchCollection.fulfilled]: (state, action) => {
            state.collectionStatus = 'done'
            state.collection = action.payload
        },
        [fetchCollectionAds.pending]: (state) => {
            state.collectionAdsStatus = 'pending'
        },
        [fetchCollectionAds.fulfilled]: (state, action) => {
            state.collectionAdsStatus = 'done'
            state.collectionAds = action.payload
        },
        [updateCollection.pending]: (state) => {
            state.updateCollectionStatus = 'pending'
        },
        [updateCollection.fulfilled]: (state, action) => {
            state.updateCollectionStatus = 'done'
            state.collectionStatus = 'idle'
        },
        [fetchCollectionTotals.pending]: (state) => {
            state.totalsStatus = 'pending'
        },
        [fetchCollectionTotals.fulfilled]: (state, action) => {
            state.totalsStatus = 'done'
            state.totals = action.payload
        },
        [createCollection.pending]: (state) => {
            state.createCollectionStatus = 'pending'
            state.createCollectionResult = {}
        },
        [createCollection.fulfilled]: (state) => {
            state.createCollectionStatus = 'done'
            state.totalsStatus = 'idle'
        },
        [createCollection.rejected]: (state, action) => {
            state.createCollectionStatus = 'failed'
            state.createCollectionResult = action.error.message
        },
        [removeCollection.pending]: (state) => {
            state.removeCollectionStatus = 'pending'
        },
        [removeCollection.fulfilled]: (state, action) => {
            state.removeCollectionStatus = 'done'
            state.totalsStatus = 'idle'
        },
        [removeAd.pending]: (state) => {
            state.removeAdStatus = 'pending'
        },
        [removeAd.fulfilled]: (state, action) => {
            state.removeAdStatus = 'done'
            state.collectionAdsStatus = 'idle'
        },
        [addToCollection.pending]: (state) => {
            state.addAdStatus = 'pending'
        },
        [addToCollection.fulfilled]: (state) => {
            state.addAdStatus = 'done'
            state.collectionAdsStatus = 'idle'
        },
        [addToCollection.rejected]: (state, action) => {
            state.addAdStatus = 'fail'
            state.addAdError = action.error.message
        }
    }
})

const selectAdLocker = (state) => state.adLocker

export default adLockerSlice.reducer
export const {
    resetCollectionList,
    resetCollection,
    resetAddAd,
    resetCollectionAds,
    resetCreateCollection} = adLockerSlice.actions
export {
    fetchCollections,
    fetchCollectionTotals,
    fetchCollectionAds,
    fetchCollection,
    createCollection,
    removeCollection,
    removeAd,
    addToCollection,
    updateCollection,
    selectAdLocker
}