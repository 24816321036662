import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../../common/axiosRateLimit'
import qs from "qs";


const fetchSummaryOverviewTopPublishers = createAsyncThunk(
    'fetchSummaryOverviewTopPublishers',
    async (arg) => {
        const params = {
            ...arg.query,
            metrics: 'uniquePublishers',
            rows: 1
        }
        const publisherSetParams = {
            ...arg.query,
            metrics: 'uniquePublishers',
            publisher: arg.publisherSet,
            rows: 1
        }

        const request = axios.post("/data-api/advertisers",
            qs.stringify(params)
        )

        const requestPublisherSet = axios.post("/data-api/advertisers",
            qs.stringify(publisherSetParams))

        const response = await request
        const responsePublisherSet = await requestPublisherSet

        let total = 'rows' in response.data && response.data.rows.length > 0
            ? response.data.rows[0].uniquePublishers
            : 0

        let publisherSet = (
            'rows' in responsePublisherSet.data &&
            responsePublisherSet.data.rows.length > 0)
            ? responsePublisherSet.data.rows[0].uniquePublishers
            : 0

        return {
            total, publisherSet
        }
    }
)

const initialState = {
    status: 'idle',
    data: {
        total: 0,
        publisherSet: 0
    }
}

const publishersSlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
        resetSummaryStats(state) {
            return initialState
        }
    },
    extraReducers: {
        [fetchSummaryOverviewTopPublishers.pending]: (state) => {
            state.status = 'pending'
            state.data = {
                total: 0,
                publisherSet: 0
            }
        },
        [fetchSummaryOverviewTopPublishers.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload
        }
    }
})

const selectSummaryStats = (state) => state.publisherUiAdvertiserOverview.overview.summary
export const {resetSummaryStats} = publishersSlice.actions
export {
    selectSummaryStats,
    fetchSummaryOverviewTopPublishers
}
export default publishersSlice.reducer