import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from '../../../common/axiosRateLimit'

const addAdvertiserToDashboard = createAsyncThunk('addAdvertiserToDashboard',
    async (arg) => {
        const response = await axios.post(
            '/callisto/dashboard-advertisers',
            {
                ...arg
            })
        return response.data
    })


export default addAdvertiserToDashboard;