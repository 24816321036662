import {findAll} from "highlight-words-core";
import React from "react"


const HighlightText = (props) => {
    const chunks = findAll({
        searchWords: props.term.split(/\W+/).filter(item => item),
        textToHighlight: props.text,
        caseSensitive: false
    });

    const renderChunk = (chunk, idx) => {
        const {end, highlight, start} = chunk;
        const text = props.text.substr(start, end - start);
        if (highlight) {
            return (<span key={idx} className="font-semi-bold">{text}</span>);
        } else {
            return text;
        }
    }

    return (
        <React.Fragment>
            {chunks.map(renderChunk)}
        </React.Fragment>
    );
}


export default HighlightText