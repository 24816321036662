import {useEffect, useState} from "react"
import TrialError from "./TrialError";
import ReactModal from "react-modal"
import {useDispatch, useSelector} from "react-redux"
import {hideModal, modalSelector} from "../../reducers/modal"
import { settingsSelector } from "../../reducers/settings"
import {
    selectReportState,
    selectMoversAndShakersErrors,
    requestMoversReport,
    fetchScheduleMoversReport,
    resetReportState
} from '../../pages/movers-and-shakers/moversAndShakersSlice'
import {
    selectGenerateReport,
    fetchCanGenerateReport,
    clearErrors,
} from "./reportsSlice";
import {notifyInfo} from "../../reducers/notification";
import LoadingState from "../LoadingState";
import { showUpgradeModal } from "../modals/UpgradeModal";
import useTooltip from "../../hooks/useTooltip";

const ScheduledMoversAndShakersDialog = (props) => {
    const tooltip = useTooltip('generic-scheduled-report')
    const title = 'Movers and Shakers'
    const generateReport = useSelector(selectGenerateReport)
    const generateMoverReport = useSelector(selectReportState)
    const errors = useSelector(selectMoversAndShakersErrors)
    const dispatch = useDispatch()
    const settings = useSelector(settingsSelector);
    const [reportType, setReportType] = useState('instant')
    const frequency = props.query.period.startsWith('week') ?
        'weekly' :
        (props.query.period.match(/Q/) ? 'quarterly' : 'monthly')

    const handleUpgradeFlow = () => {
        showUpgradeModal(dispatch, {
            name: 'scheduledReports',
            value: true
        })
    }

    useEffect(() => {
        if (generateReport.status === 'idle') {
            dispatch(fetchCanGenerateReport())
        }
    }, [dispatch, generateReport.status])

    useEffect(() => {
        if (generateMoverReport.status === 'done' && !errors.length) {
            dispatch(notifyInfo('Your report is being generated and will be saved on your Reports page'))
            dispatch(resetReportState())
            dispatch(hideModal())
        }
    }, [dispatch, generateMoverReport.status, errors.length])

    useEffect(() => {
        if (generateMoverReport.scheduleStatus === 'done' && !errors.length) {
            dispatch(notifyInfo('Your report is being generated and will be saved on your Reports page'))
            dispatch(resetReportState())
            dispatch(hideModal())
        }
    }, [dispatch, generateMoverReport.scheduleStatus, errors.length])


    const showTrialError = !generateReport.canGenerateReport && settings.user.is_trial
    const handleSubmit = (e) => {
        e.preventDefault()

        if (reportType === 'instant') {
            dispatch(requestMoversReport({
                query: {
                    ...props.query,
                    title
                }
            }))
        }

        if (reportType === 'scheduled') {
            dispatch(fetchScheduleMoversReport({query: {
                ...props.query,
                title,
                frequency
            }}))
        }
    }

    return (
        <ReactModal 
            onRequestClose={props.handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            isOpen={props.isOpen}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "fixed",
                    height: document.documentElement.scrollHeight
                },
                content: {
                    bottom: 'auto',
                    borderRadius: 0,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "fixed",
                    padding: 0,
                    overflow: 'none',
                    inset: "80px 40px auto 40px",
                },
            }}
            >
           <div className="query-based-report">
                <LoadingState show={generateMoverReport.status === 'pending'}/>
                <LoadingState show={generateMoverReport.scheduleStatus === 'pending'}/>
                <form method="post" onSubmit={handleSubmit}>
                    <div className="section-header">
                        <h1 className="left">{title}</h1>
                        <div className="right close" onClick={props.handleClose}>
                            <i className="fa-light fa-sharp fa-close"></i>
                        </div>
                    </div>
                    <div className="section-body filters profile-header report-type">
                        <div>
                            <h2 className="title left">Select the type of report you would like to generate</h2>
                            <div className="help-icon left"
                                    data-tip
                                    data-for={tooltip.path}>
                                <i className="fa-sharp fa-light fa-circle-question fa-2x"/>
                            </div>
                        </div>

                        <h2 className="buttons">
                            <div className={`report-button ${reportType === 'instant' ? 'active' : ''}`}
                                onClick={() => setReportType('instant')}>
                                <div>
                                    <i className="fa-regular fa-light fa-sharp fa-3x fa-cloud-download"></i>
                                </div>
                                <div>
                                    <span>Instant</span>
                                </div>
                            </div>
                            
                            <div className={`report-button ${reportType === 'scheduled' ? 'active' : ''}`}
                                data-value={!settings.scheduled_reports ? settings.upgradeRequiredValue + 'scheduledReports' : ''}
                                onClick={() => settings.scheduled_reports ? 
                                    setReportType('scheduled') :
                                    handleUpgradeFlow()}>
                                <div>
                                    <i className="fa-regular fa-light fa-sharp fa-3x fa-calendar"></i>
                                </div>
                                <div>
                                    <span>Scheduled</span>
                                </div>
                            </div>

                            <div className="clear"></div>
                        </h2>
                        {reportType === 'scheduled' &&
                        <div className="frequency-description">
                            {frequency === 'weekly' && <span>This report will run every week.</span>}
                            {frequency === 'monthly' && <span>This report will run every month.</span>}
                            {frequency === 'quarterly' && <span>This report will run every quarter.</span>}
                        </div>
                        }
                    </div>
                    <div className="section-footer clear">
                        <button className="save-alert">
                            {reportType === 'scheduled' ? 'Schedule ' : 'Generate ' } 
                            Report
                        </button>

                        <span className={
                            "error" + (errors.length > 0 ? "" : " hidden")
                        }>
                            {errors.map((e, idx) =>
                            <div key={idx}>
                                <span
                                    className="pointer"
                                    onClick={() => dispatch(clearErrors())}
                                    dangerouslySetInnerHTML={{__html: e}}
                                ></span><br />
                            </div>
                            )}
                        </span>
                    </div>
                    {showTrialError &&
                        <TrialError
                        onClick={() => dispatch(clearErrors())}
                        ></TrialError>
                    }
                </form>
                {tooltip.component}
            </div>
        </ReactModal>
    )
}

const modalName = 'report-schedule-movers-dialog'

const Wrapper = () => {
    const modal = useSelector(modalSelector) 
    const dispatch = useDispatch()

    if (modal.name === modalName) {
        return (
           <ScheduledMoversAndShakersDialog {...modal.params}
                isOpen={modal.name === modalName}
                handleClose={() => dispatch(hideModal())} />
        )
    }
    return null

}

export default Wrapper;
