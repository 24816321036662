import RecentSearches from '../navigation/RecentSearches'
import {useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {settingsSelector} from "../../reducers/settings";
import {showUpgradeModal} from "../modals/UpgradeModal";
import {selectPublisherUiSettings} from "../../pages/publisher_ui/overview/publisherUiCommonSlice";

const NavItem = (props) => {
    const dispatch = useDispatch()
    const upgrade = (event) => {
        if (props.upgradeRequiredValue) {
            event.preventDefault();

            showUpgradeModal(
                dispatch,
                {
                    name: props.upgradeRequiredValue,
                    value: true
                }
            )
        }
    }
    return (
        <a className={`nav-item ${props.active ? 'active' : ''}`}
           onClick={upgrade}
           data-value={
               props.upgradeRequiredValue
                   ? 'upgrade-required-' + props.upgradeRequiredValue
                   : ''
           }
           href={props.href}>
            <div className="icon left text-center">
                <i className={`${props.iconClass}`}></i>
            </div>
            <span className="font-light nav-text">{props.title}</span>
        </a>
    )
}


const AdvertiserNav = (props) => {
    const settings = useSelector(settingsSelector)
    const location = useLocation()
    const activePageName = location.pathname.split('/')[1]

    return (
        <nav>
            <div className="padding"></div>
            <div className="container">
                <NavItem
                    active={['dashboard', 'search', 'advertiser', 
                        'publisher', 'campaign', 'network', 'channel', 'brand'].includes(activePageName)}
                    href="/dashboard"
                    iconClass="search fa-sharp fa-light fa-gauge-high"
                    title="Dashboard"></NavItem>
                <NavItem
                    active={['tools', 'adv_data_enrich', 'group_manager', 'top_advertisers_dma_reports', 'advertiser_category_explorer'].includes(activePageName)}
                    href="/tools"
                    iconClass="left block fa-2x fa-sharp fa-light fa-wrench"
                    title="Tools"></NavItem>
                <NavItem 
                    active={activePageName === 'top-advertisers'}
                    href="/top-advertisers"
                    iconClass="left block fa-2x fa-sharp fa-light fa-megaphone"
                    title="Top Advertisers" />
                <NavItem
                    active={activePageName === 'top-ads'}
                    href="/top-ads"

                    iconClass="fa-rectangle-ad fa-sharp fa-light fa-2x block left"
                    title="Top Ads"></NavItem>
                <NavItem
                    upgradeRequiredValue={
                        !settings.movers_and_shakers_months
                            ? 'movers-and-shakers'
                            : null
                    }
                    active={activePageName === 'movers-and-shakers'}
                    href="/movers-and-shakers"
                    iconClass="fa-2x block reports left fa-sharp fa-light fa-display-chart-up"
                    title="Movers & Shakers"></NavItem>
                <NavItem
                    active={activePageName === 'reports'}
                    href="/reports"
                    iconClass="reports fa-sharp fa-light fa-file-arrow-down fa-2x" style={{color: "#afb1b7"}}
                    title="Reports"></NavItem>
                <RecentSearches></RecentSearches>
            </div>
        </nav>
    )
}


const PublisherNav = (props) => {
    const location = useLocation()
    const activePageName = location.pathname.split('/')[1]
    const publisherUiSettings = useSelector(selectPublisherUiSettings)
    const pubSetPath = publisherUiSettings.publisherSet ? 
        '/' + publisherUiSettings.publisherSet : ''

    return (
        <nav>
            <div className="padding"></div>
            <div className="container">
                <NavItem
                    active={['search', 'advertiser_overview', 'publisher', 'campaign', 'network', 'channel', 'brand'].includes(activePageName)}
                    href="/search"
                    iconClass="search fa-sharp fa-light fa-search"
                    title="Search"></NavItem>
                <NavItem
                    active={['publisher_competitive_overview'].includes(activePageName)}
                    href={"/publisher_competitive_overview" + pubSetPath}
                    iconClass="fa-light fa-sharp fa-2x fa-binoculars"
                    title="Overview"></NavItem>
                <NavItem
                    active={['new_business', 'expansion_opportunities', 'new_creatives',
                        'advertisers_by_impressions', 'advertisers_by_creative',
                        'advertisers_by_spend', 'advertisers_by_creatives_spend'
                    ].includes(activePageName)}
                    href={"/new_business" + pubSetPath}
                    iconClass="fa-2x fa-money-bill fa-sharp fa-light"
                    title="Prospecting"></NavItem>
                <NavItem
                    active={activePageName === 'scheduled_reports'}
                    href="/scheduled_reports"
                    iconClass="fa-2x fa-calendar fa-sharp fa-light"
                    title="Scheduled Reports"></NavItem>
                <NavItem
                    active={activePageName === 'competitive_sets'}
                    href="/competitive_sets"
                    iconClass="fa-sharp fa-light fa-2x fa-crosshairs"
                    title="Publisher Sets"></NavItem>
                <NavItem
                    active={activePageName === 'reports'}
                    href="/reports"
                    iconClass="reports fa-sharp fa-light fa-file-arrow-down fa-2x" style={{color: "#afb1b7"}}
                    title="Reports"></NavItem>
                <RecentSearches></RecentSearches>
            </div>
        </nav>
    )
}


const Nav = (props) => {
    const settings = useSelector(settingsSelector)
    const isPublisherUI = settings.show_publisher_ui

    if (isPublisherUI) {
        return (<PublisherNav/>)
    }
    return (<AdvertiserNav/>)
}

export default Nav