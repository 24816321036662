import advertisers from './topAdvertisersSlice'
import adTags from "./adTagsSlice";
import publishers from "./topPublishersSlice";
import categories from './topCategoriesSlice'
import ads from './topAdsSlice'
import placementUrls from './topPlacementUrlsSlice'
import {combineReducers} from '@reduxjs/toolkit'

const topAdvertisersReducer = combineReducers({
    advertisers,
    adTags,
    publishers,
    categories,
    ads,
    placementUrls
})


const topAdvertisersMainReducer = (state, action) => {
    if (action.type === 'topAdvertisers/reset') {
        // setting state to undefined clears it
        // it will clear only advertisers state
        state = undefined
    }

    return topAdvertisersReducer(state, action)
}

export default topAdvertisersMainReducer