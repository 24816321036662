import axios from 'axios'
import _ from 'lodash'
import {performanceEvent} from "../reducers/performance";

const MAX_REQUESTS_COUNT = 10
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const api = axios.create({})


const debouncedPageComplete = _.debounce(() => {
    window.AdbeatReduxStore.dispatch(performanceEvent({
        name: 'complete',
        time: Date.now() - 1000
    }))
}, 1000)


const requestPageStart = () => {
    window.AdbeatReduxStore.dispatch(performanceEvent({
        name: 'start',
        time: Date.now()
    }))
}


/**
 * Axios Request Interceptor
 */
api.interceptors.request.use(function (config) {
    return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
            if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                if (PENDING_REQUESTS === 0) {
                    requestPageStart()
                }
                PENDING_REQUESTS++
                clearInterval(interval)
                resolve(config)
            }
        }, INTERVAL_MS)
    })
})

/**
 * Axios Response Interceptor
 */
api.interceptors.response.use(function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    if (PENDING_REQUESTS === 0) {
        debouncedPageComplete()
    }
    return Promise.resolve(response)
}, function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    if (PENDING_REQUESTS === 0) {
        debouncedPageComplete()
    }
    return Promise.reject(error)
})

api.CancelToken = axios.CancelToken

export default api
