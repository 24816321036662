import axios from '../../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import qs from "qs";


const fetchLargestAdvertisersData = createAsyncThunk('fetchLargestAdvertisersData',
    async (arg) => {
        let query = {
            publisher: arg.params.competitiveSet.join(','),
            network: arg.params.network,
            country: arg.params.country,
            platform: arg.params.platform,
            startDate: arg.params.startDate,
            endDate: arg.params.endDate,
            metrics: 'sumAdSpend,uniqueAds',
            sortBy: 'sumAdSpend',
            sortOrder: 'desc',
            rows: arg.params.rows,
            entity: 'publisher'
        }
        if (arg.params.category !== '/') {
            query['category'] = arg.params.category
        }

        let competitiveAdvertisersByPublisher = await axios.post("/data-api/advertisers",
            qs.stringify(query)
        )

        return competitiveAdvertisersByPublisher.data
    })


const initialState = {
    competitiveSet: [],
    competitiveSetByCreatives: [],
    status: 'idle',
    noData: false
}


const largestAdvertisersSlice = createSlice({
    name: 'largestAdvertisers',
    initialState,
    reducers: {
        resetLargestAdvertisers(state) {
            return initialState
        }
    },
    extraReducers: {
        [fetchLargestAdvertisersData.rejected]: (state, action) => {
            state.status = 'done'
            state.noData = true
            state.competitiveSet = []
            state.competitiveSetByCreatives = []
        },
        [fetchLargestAdvertisersData.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchLargestAdvertisersData.fulfilled]: (state, action) => {
            state.status = 'done'

            const competitive = action.payload

            if ('rows' in competitive && competitive.rows.length > 0) {
                state.noData = false
                state.competitiveSet = competitive.rows.slice()

                state.competitiveSetByCreatives = state.competitiveSet.slice()
                state.competitiveSetByCreatives.sort((a, b) => {
                    return (b.uniqueAds - a.uniqueAds) || (b.sumAdSpend - a.sumAdSpend)
                })
            }
            else {
                state.noData = true
            }
        }
    }
})

const selectLargestAdvertisersData = (state) => state.publisherUiLargestAdvertisers.creatives

export default largestAdvertisersSlice.reducer

export const {
    resetLargestAdvertisers
} = largestAdvertisersSlice.actions

export {
    fetchLargestAdvertisersData,
    selectLargestAdvertisersData
}
