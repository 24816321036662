import common from './publisherUiCommonSlice'
import newBusiness from './newBusinessSlice'
import {combineReducers} from '@reduxjs/toolkit'

const publisherUiNewBusinessReducer = combineReducers({
    common,
    newBusiness
})


export default publisherUiNewBusinessReducer
