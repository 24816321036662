import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchPublisherTopAdvertisers = createAsyncThunk(
    'fetchPublisherAdvertisers',
    async (arg) => {
        const params = {
            'rows': 50,
            'sortOrder': 'desc',
            ...arg.query
        }

        const response = await axios.get("/data-api/advertisers", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const advertisersSlice = createSlice({
    name: 'PublisherTopAdvertisers',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetPublisherAdvertisers(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchPublisherTopAdvertisers.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchPublisherTopAdvertisers.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectPublisherTopAdvertisers = (state) => state.advertiserProfile.overview.byAdvertiser
export const {resetPublisherAdvertisers} = advertisersSlice.actions
export {selectPublisherTopAdvertisers, fetchPublisherTopAdvertisers}
export default advertisersSlice.reducer