import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source();
const getCancelSource = () => cancelSource;

const fetchAdvSimilarProfile = createAsyncThunk(
  "advSimilarProfiles",
  async (arg) => {
    const response = await axios.get("/data-api/advertiserInfo", {
      params: arg.query,
      cancelToken: getCancelSource().token,
    });

    const advertisers = response.data.similarAdvertisers.slice(0, arg.maxItems);

    arg.dispatch(
      fetchAdvertiserMetrics({
        ...arg,
        query: {
          ...arg.query,
          advertiser: advertisers.join(","),
          rows: arg.maxItems,
        },
      })
    );

    advertisers.map((advertiser) =>
      arg.dispatch(
        fetchAdvertiserTopAds({
          ...arg,
          query: {
            ...arg.query,
            advertiser,
          },
        })
      )
    );

    const publishers = response.data.recommendedPublishers
      .slice(0, arg.maxItems)
      .map((p) => p.publisher);

    const publisherQuery = { ...arg.query };
    delete publisherQuery.advertiser;

    arg.dispatch(
      fetchPublisherMetrics({
        ...arg,
        query: {
          ...publisherQuery,
          publisher: publishers.join(","),
          rows: arg.maxItems,
        },
      })
    );

    publishers.map((publisher) =>
      arg.dispatch(
        fetchPublisherTopAds({
          ...arg,
          query: {
            ...publisherQuery,
            publisher,
          },
        })
      )
    );

    if (response.data.recommendedChannels) {
      const channels = response.data.recommendedChannels
        .slice(0, arg.maxItems)
        .map((p) => p.channel);

      arg.dispatch(
        fetchChannelMetrics({
          ...arg,
          query: {
            ...publisherQuery,
            channel: channels.join(","),
            rows: arg.maxItems,
          },
        })
      );

      channels.map((channel) =>
        arg.dispatch(
          fetchChannelTopAds({
            ...arg,
            query: {
              ...publisherQuery,
              channel: channel,
            },
          })
        )
      );
    }
    return response.data;
  }
);

const fetchAdvertiserMetrics = createAsyncThunk(
  "advSimilarProfilesAdvertiserMetrics",
  async (arg) => {
    const response = await axios.get("/data-api/advertisers", {
      params: {
        ...arg.query,
        startDate: arg.startDate,
        endDate: arg.endDate,
        metrics: arg.settings.metricsList,
      },
      cancelToken: getCancelSource().token,
    });

    return response.data;
  }
);

const fetchAdvertiserTopAds = createAsyncThunk(
  "advSimilarProfilesAdvertiserAds",
  async (arg) => {
    const response = await axios.get("/data-api/ads", {
      params: {
        ...arg.query,
        startDate: arg.startDate,
        endDate: arg.endDate,
        metrics: arg.settings.metricsList,
        sortBy: arg.settings.metricField,
        sortOrder: "desc",
        rows: 4,
      },
      cancelToken: getCancelSource().token,
    });

    return response.data;
  }
);

const fetchPublisherMetrics = createAsyncThunk(
  "advSimilarProfilesPublisherMetrics",
  async (arg) => {
    const response = await axios.get("/data-api/publishers", {
      params: {
        ...arg.query,
        startDate: arg.startDate,
        endDate: arg.endDate,
        metrics: arg.settings.metricsList,
      },
      cancelToken: getCancelSource().token,
    });

    return response.data;
  }
);

const fetchPublisherTopAds = createAsyncThunk(
  "advSimilarProfilesPublisherAds",
  async (arg) => {
    const response = await axios.get("/data-api/ads", {
      params: {
        ...arg.query,
        startDate: arg.startDate,
        endDate: arg.endDate,
        metrics: arg.settings.metricsList,
        sortBy: arg.settings.metricField,
        sortOrder: "desc",
        rows: 4,
      },
      cancelToken: getCancelSource().token,
    });

    return response.data;
  }
);

const fetchChannelMetrics = createAsyncThunk(
  "advSimilarProfilesChannelMetrics",
  async (arg) => {
    const response = await axios.get("/data-api/channels", {
      params: {
        ...arg.query,
        startDate: arg.startDate,
        endDate: arg.endDate,
        metrics: arg.settings.metricsList,
      },
      cancelToken: getCancelSource().token,
    });

    return response.data;
  }
);

const fetchChannelTopAds = createAsyncThunk(
  "advSimilarProfilesChannelAds",
  async (arg) => {
    const response = await axios.get("/data-api/ads", {
      params: {
        ...arg.query,
        startDate: arg.startDate,
        endDate: arg.endDate,
        metrics: arg.settings.metricsList,
        sortBy: arg.settings.metricField,
        sortOrder: "desc",
        rows: 4,
      },
      cancelToken: getCancelSource().token,
    });

    return response.data;
  }
);

const advSimilarSlice = createSlice({
  name: "advSimilarProfiles",
  initialState: {
    status: "idle",
    data: {
      similarAdvertisers: [],
      recommendedPublishers: [],
      recommendedChannels: [],
      phrases: [],
    },
  },
  reducers: {
    resetAdvOverviewSimilarProfiles(state) {
      state.status = "idle";
      state.data = {
        similarAdvertisers: [],
        recommendedPublishers: [],
        recommendedChannels: [],
      };
      cancelSource.cancel();
      cancelSource = axios.CancelToken.source();
    },
  },
  extraReducers: {
    [fetchAdvSimilarProfile.pending]: (state) => {
      state.status = "loading";
      state.data = {
        similarAdvertisers: [],
        recommendedPublishers: [],
        recommendedChannels: [],
        phrases: [],
      };
    },
    [fetchAdvSimilarProfile.fulfilled]: (state, action) => {
      state.status = "done";
      state.data = {
        ...action.payload,
        similarAdvertisers: action.payload.similarAdvertisers.map(
          (advertiser) => ({
            advertiser,
            ads: [],
            sumAdSpend: 0,
            sumImpressions: 0,
          })
        ),
        recommendedPublishers: action.payload.recommendedPublishers.map(
          (p) => ({
            ...p,
            ads: [],
            sumAdSpend: 0,
            sumImpressions: 0,
          })
        ),
        recommendedChannels: (action.payload.recommendedChannels || []).map((p) => ({
          ...p,
          ads: [],
          sumAdSpend: 0,
          sumImpressions: 0,
        })),
        phrases: action.payload.phrases,
      };
    },
    [fetchAdvSimilarProfile.rejected]: (state, action) => {
      state.status = 'failed'
      state.data = {
        similarAdvertisers: [],
        recommendedPublishers: [],
        recommendedChannels: [],
        phrases: [],
      }
    },
    [fetchAdvertiserMetrics.fulfilled]: (state, action) => {
      state.data.similarAdvertisers = state.data.similarAdvertisers.map((a) => {
        const metrics = action.payload.rows
          .filter((r) => r.advertiser === a.advertiser)
          .pop();
        if (metrics) {
          return {
            ...metrics,
            ads: a.ads,
          };
        }
        return a;
      });
    },
    [fetchAdvertiserTopAds.fulfilled]: (state, action) => {
      state.data.similarAdvertisers = state.data.similarAdvertisers.map((a) => {
        if (action.meta.arg.query.advertiser === a.advertiser) {
          return {
            ...a,
            ads: action.payload.rows,
          };
        }
        return a;
      });
    },
    [fetchPublisherMetrics.fulfilled]: (state, action) => {
      state.data.recommendedPublishers = state.data.recommendedPublishers.map(
        (a) => {
          const metrics = action.payload.rows
            .filter((r) => r.publisher === a.publisher)
            .pop();
          if (metrics) {
            return {
              ...metrics,
              ads: a.ads,
            };
          }
          return a;
        }
      );
    },
    [fetchPublisherTopAds.fulfilled]: (state, action) => {
      state.data.recommendedPublishers = state.data.recommendedPublishers.map(
        (a) => {
          if (action.meta.arg.query.publisher === a.publisher) {
            return {
              ...a,
              ads: action.payload.rows,
            };
          }
          return a;
        }
      );
    },
    [fetchChannelMetrics.fulfilled]: (state, action) => {
      state.data.recommendedChannels = state.data.recommendedChannels.map(
        (a) => {
          const metrics = action.payload.rows
            .filter((r) => r.channel === a.channel)
            .pop();
          if (metrics) {
            return {
              ...metrics,
              ads: a.ads,
            };
          }
          return a;
        }
      );
    },
    [fetchChannelTopAds.fulfilled]: (state, action) => {
      state.data.recommendedChannels = state.data.recommendedChannels.map(
        (a) => {
          if (action.meta.arg.query.channel === a.channel) {
            return {
              ...a,
              ads: action.payload.rows,
            };
          }
          return a;
        }
      );
    },
  },
});

const advSimilarProfilesSelector = (state) =>
  state.advertiserProfile.overview.similarProfiles;
export {
  fetchAdvSimilarProfile,
  advSimilarProfilesSelector,
  fetchPublisherTopAds,
  fetchPublisherMetrics,
};
export const { resetAdvOverviewSimilarProfiles } = advSimilarSlice.actions;
export default advSimilarSlice.reducer;
