import axios from '../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import _ from 'lodash';
import { preProcessEvents } from '../publishers/userDashboardPublisherSlice';

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const orderListKey = 'user_dashboard_advertiser_list' 
const saveAdvOrder = newAdvertisers => {
    window.localStorage.setItem(
        orderListKey,
        newAdvertisers.join(',')
    );
};
const customOrder = (advertisersToOrder) => {
    let advertisers = [...advertisersToOrder];
    const advList = (window.localStorage
        .getItem(orderListKey) || '').split(',');
    advertisers.sort((a, b) => advList.findIndex(d => d === a) - 
        advList.findIndex(d => d === b));
    return advertisers; 
};

const fetchDashboardAdvertisers = createAsyncThunk(
    'fetchDashboardAdvertisers',
    async () => {
        const request = axios.get("/callisto/dashboard-advertisers", {
            cancelToken: getCancelSource().token
        })

        const response = await request

        let result = response.data
        return result
    })

const deleteDashboardAdvertiser = createAsyncThunk(
    'deleteDashboardAdvertiser',
    async (arg) => {
        const request = axios.delete(
            "/callisto/dashboard-advertisers/" + arg.advertiser, {
                cancelToken: getCancelSource().token
            })

        const response = await request

        let result = response.data
        return result
    })

const fetchDashboardAdvertiserEvents = createAsyncThunk(
    'fetchDashboardAdvertiserEvents',
    async (arg) => {
        
        const request = axios.get("/callisto/user-dashboard/advertiser-events", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        const response = await request

        let result = response.data
        return result
    });



const userDashboardAdvertiserSlice = createSlice({
    name: 'userDashboardAdvertiserSlice',
    initialState: {
        advertisers: {},
        orderBy: 'latest',
        orderedAdvertisers: [],
        deleteAdvertiser: {
            status: 'idle'
        },
        advertiserList: {
            data: [],
            status: 'idle'
        },
        advertiserSummary: {}
    },
    reducers: {
        reset(state) {
            state.advertisers = {} 
            state.advertiserSummary = {}
            state.advertiserList = {
                data: [],
                status: 'idle'
            }
        },
        resetAdvertiser(state, action) {
            const advertiser = action.payload;
            state.advertisers[advertiser] = {
               data: [],
               status: 'idle'
            };
            state.advertiserSummary[advertiser] = {
                data: {},
                status: 'idle'
            }
        },
        resetList(state) {
            state.advertiserList = {
                data: [],
                status: 'idle'
            }
        },
        setOrderBy(state, action) {
            state.orderBy = action.payload;
            userDashboardAdvertiserSlice.caseReducers.orderAdvertisers(state, action);
        },
        orderAdvertisers(state) {
            if (state === undefined) {
                return;
            }
            let advertisers = [...current(state).orderedAdvertisers];
            if (advertisers.length === 0) {
                return;
            }

            if (state.orderBy === 'name') {
                advertisers.sort()
            } else if (state.orderBy === 'custom') {
                advertisers = customOrder(advertisers);
            } else if (state.orderBy === 'latest') {
                const eventData = current(state).advertisers;
                const latestEvents = {};
                Object.keys(eventData)
                .forEach(advertiserId => {
                    latestEvents[advertiserId] = eventData[advertiserId].data?.[0]?.firstSeen ?? 0;
                });
                if (!_.isEmpty(latestEvents)) {
                    advertisers.sort((a, b) => latestEvents[b] - latestEvents[a]);
                }
            }
            state.orderedAdvertisers = advertisers;
        },
        setOrderedAdvertisers(state, action) {
            state.orderedAdvertisers = action.payload;
        }
    },
    extraReducers: {
        [fetchDashboardAdvertisers.pending]: (state) => {
            state.advertiserList.status = 'pending' 
        },
        [fetchDashboardAdvertisers.fulfilled]: (state, action) => {
            state.advertiserList.data = action.payload.advertisers; 
            state.advertiserList.status = 'done';
            userDashboardAdvertiserSlice.caseReducers.orderAdvertisers();
        },
        [deleteDashboardAdvertiser.pending]: (state) => {
            state.deleteAdvertiser.status = 'pending' 
        },
        [deleteDashboardAdvertiser.fulfilled]: (state) => {
            state.deleteAdvertiser.status = 'idle'
            state.advertiserList = {
                data: [],
                status: 'idle'
            }
        },
        [fetchDashboardAdvertiserEvents.pending]: (state, action) => {
            const advertiser = action.meta.arg.advertiser;
            state.advertisers[advertiser] = {
                data: [],
                status: 'pending'
            }
            state.advertiserSummary[advertiser] = {
                status: 'pending',
                data: {}
            }
        },
        [fetchDashboardAdvertiserEvents.fulfilled]: (state, action) => {
            const advertiser = action.meta.arg.advertiser;

            if (action.payload.length > 0) {
                const data = preProcessEvents(action.payload
                    .filter(d => {
                        if ('dma' in d && d.dma === '(no DMA specified)') {
                           return false; 
                        }
                        return true;
                    }))

                state.advertisers[advertiser] = state.advertisers[advertiser] || {
                    data: [], status: 'pending',
                }
                state.advertisers[advertiser].data = data;
                state.advertisers[advertiser].status = 'done';

                state.advertiserSummary[advertiser] = state.advertiserSummary[advertiser] || {
                    data: {}, status: 'pending',
                }
                state.advertiserSummary[advertiser].status = 'done';
                const adEvents = data.filter(d => d.hasOwnProperty('adHash'))
                const sumMetric = (d, metric) => d.filter(d => !d.hasOwnProperty('shifts'))
                    .map(d => d[metric])
                    .reduce((a, b) => a + b, 0)
                state.advertiserSummary[advertiser].data = {
                    events: data.length,
                    spend: sumMetric(data, 'sumAdSpend'),
                    impressions: sumMetric(data, 'sumImpressions'),
                    ads: adEvents.length, 
                    adsSpend: sumMetric(adEvents, 'sumAdSpend'),
                    adsImpressions: sumMetric(adEvents, 'sumImpressions'),
                }
            }
            else {
                state.advertisers[advertiser].data = [] 
                state.advertisers[advertiser].status = 'done';
                state.advertiserSummary[advertiser].data = {
                    events: 0,
                    spend: 0,
                    impressions: 0,
                    ads: 0,
                    adsSpend: 0,
                    adsImpressions: 0
                }
                state.advertiserSummary[advertiser].status = 'done';
            }
            userDashboardAdvertiserSlice.caseReducers.orderAdvertisers(state);
        }
    }
})

const orderedAdvertisersSelector = state => state.userDashboard.events.orderedAdvertisers;
const orderBySelector = state => state.userDashboard.events.orderBy;

const userDashboardAdvertiserSelector = advertiser => state => {
    if (_.isEmpty(advertiser) ||
        _.isEmpty(state.userDashboard.events.advertisers) ||
        _.isEmpty(state.userDashboard.events.advertisers[advertiser])) {
        
        return {
           data: [],
           status: 'idle'
        };
    }
    return state.userDashboard.events.advertisers[advertiser];
}

const userDashboardAdvertisersSummarySelector = state => state.userDashboard.events.advertiserSummary;

const userDashboardAdvertiserListSelector = state => state.userDashboard.events.advertiserList

export const {
    reset,
    resetAdvertiser,
    resetList,
    setOrderedAdvertisers,
    orderAdvertisers,
    setOrderBy,
} = userDashboardAdvertiserSlice.actions
export { fetchDashboardAdvertiserEvents, 
    fetchDashboardAdvertisers,
    deleteDashboardAdvertiser,
    orderedAdvertisersSelector,
    userDashboardAdvertiserListSelector,
    userDashboardAdvertiserSelector,
    orderBySelector,
    userDashboardAdvertisersSummarySelector,
    saveAdvOrder
}
export default userDashboardAdvertiserSlice.reducer
