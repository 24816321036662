import {createSlice} from "@reduxjs/toolkit";
import {getMetricValue} from "../../reducers/singleRowAdSpend";


const singleRowAdSpendSlice = createSlice({
    name: 'singleRowAdSpend',
    initialState: {},
    extraReducers: {
        [getMetricValue.pending]: (state, action) => {
            state[action.meta.arg.dataRow.key] = {
                'status': 'pending',
                'result': ""
            }
        },
        [getMetricValue.fulfilled]: (state, action) => {
            state[action.meta.arg.dataRow.key] = {
                'status': 'done',
                'result': action.payload
            }
        },
        [getMetricValue.rejected]: (state, action) => {
            state[action.meta.arg.dataRow.key] = {
                'status': 'failed',
                'result': ''
            }
        }
    }
})

export const selectSingleRowAdSpend = (key) => (state) => {
    if (!key) {
        return {
            'result': "",
            'status': 'done'
        }
    }

    if (key in state.searches.rowSpends) {
        return state.searches.rowSpends[key]
    } else {
        return {
            result: "",
            status: 'idle'
        }
    }
}

export {getMetricValue}

export default singleRowAdSpendSlice.reducer