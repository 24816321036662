import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../common/axiosRateLimit";
import _ from 'lodash';

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserChannels = createAsyncThunk(
    'fetchUserDashboardAdvertiserChannels',
    async (arg) => {
        const response = await axios.get('/data-api/networks', {
            params: {
                ...arg.query,
                entity: 'adType',
                rows: 500
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    }
)

const channelsSlice = createSlice({
    name: 'userDashboardAdvertiserAdChannels',
    initialState: {
        advertisers: {}
    },
    reducers: {
        resetChannels(state, action) {
            const advertiser = action.payload;
            state.advertisers[advertiser] = {
                data: [],
                status: 'idle',
            }
        },
    },
    extraReducers: {
        [fetchAdvertiserChannels.pending]: (state, action) => {
            const advertiser = action.meta.arg.advertiser;
            state.advertisers[advertiser] = {
                data: [],
                status: 'pending'
            }
        },
        [fetchAdvertiserChannels.fulfilled]: (state, action) => {
            const advertiser = action.meta.arg.advertiser;
            const data = action.payload.rows

            if (data.length > 0) {
                state.advertisers[advertiser].data = data
                state.advertisers[advertiser].status = 'done'
            }
            else {
                state.advertisers[advertiser].data = [] 
            }
        }
    }
})

const selectAdvertiserChannels = advertiser => state => {
    if (_.isEmpty(advertiser) ||
        _.isEmpty(state.userDashboard.channels.advertisers) ||
        _.isEmpty(state.userDashboard.channels.advertisers[advertiser])) {
        
        return {
           data: [],
           status: 'idle'
        };
    }
    return state.userDashboard.channels.advertisers[advertiser]
}
export const {resetChannels} = channelsSlice.actions
export {selectAdvertiserChannels, fetchAdvertiserChannels}
export default channelsSlice.reducer
