import axios from '../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import qs from 'qs'

const fetchCanGenerateReport = createAsyncThunk('fetchCanGenerateReport',
    async () => {
        const response = await axios.get(
            '/callisto/reports/can-generate-report')
        return response.data
    })

const fetchGenerateReport = createAsyncThunk('generateReport',
    async (arg) => {
        const response = await axios.post(
            '/callisto/reports/generate-report',
            qs.stringify(arg.query)
        )

        return response.data
    })

const fetchScheduleReport = createAsyncThunk('scheduleReport',
    async (arg) => {
        const response = await axios.post(
            '/callisto/reports/schedule-report',
            qs.stringify(arg.query)
        )

        return response.data
    })

const fetchGenerateProfileReport = createAsyncThunk('generateProfileReport',
    async (arg) => {
        const response = await axios.post(
            '/callisto/reports/generate-profile-report',
            qs.stringify(arg.query)
        )

        return response.data
    })
    
const fetchScheduleProfileReport = createAsyncThunk('scheduleProfileReport',
    async (arg) => {
        const response = await axios.post(
            '/callisto/reports/schedule-profile-report',
            qs.stringify(arg.query))
        return response.data
    })


const reportSlice = createSlice({
    name: 'generateReportSlice',
    initialState: {
        status: 'idle',
        errors: [],
        generateStatus: 'idle',
        scheduleStatus: 'idle',
        canGenerateReport: false
    },
    reducers: {
        clearErrors(state) {
            state.errors = []
        },
        resetGenerateState(state) {
            state.generateStatus = 'idle'
            state.scheduleStatus = 'idle'
            state.errors = []
            state.status = 'idle'
        }
    },
    extraReducers: {
        [fetchCanGenerateReport.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchCanGenerateReport.fulfilled]: (state, action) => {
            state.canGenerateReport = action.payload.canGenerate
            state.status = 'done'
        },
        [fetchGenerateReport.pending]: (state) => {
            state.generateStatus = 'pending'
            state.errors = []
        },
        [fetchScheduleReport.pending]: (state) => {
            state.scheduleStatus = 'pending'
        },
        [fetchScheduleProfileReport]: (state) => {
            state.scheduleStatus = 'pending'
        },
        [fetchGenerateProfileReport.pending]: (state) => {
            state.generateStatus = 'pending'
            state.errors = []
        },
        [fetchGenerateReport.rejected]: (state) => {
            state.generateStatus = 'failed'
            state.errors = ['Failed to generate report. Please try again or contact support']
        },
        [fetchScheduleReport.rejected]: (state) => {
            state.scheduleStatus = 'failed'
            state.errors = ['Failed to schedule report. Please try again or contact support']
        },
        [fetchScheduleProfileReport.rejected]: (state) => {
            state.scheduleStatus = 'failed'
            state.errors = ['Failed to schedule report. Please try again or contact support']
        },
        [fetchGenerateProfileReport.rejected]: (state) => {
            state.generateStatus = 'failed'
            state.errors = ['Failed to generate report. Please try again or contact support']
        },
        [fetchGenerateReport.fulfilled]: (state, action) => {
            state.generateStatus = 'done'
            if (action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
        },
        [fetchScheduleReport.fulfilled]: (state, action) => {
            state.scheduleStatus = 'done'
            if (action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
        },
        [fetchScheduleProfileReport.fulfilled]: (state, action) => {
            state.scheduleStatus = 'done'
            if (action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
        },
        [fetchGenerateProfileReport.fulfilled]: (state, action) => {
            state.generateStatus = 'done'
            if (action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
        }
    }
})

const selectGenerateReport = (state) => state.generateReport

export {
    fetchCanGenerateReport, selectGenerateReport, fetchGenerateReport,
    fetchGenerateProfileReport, fetchScheduleReport,
    fetchScheduleProfileReport
}

export const {clearErrors, resetGenerateState} = reportSlice.actions

export default reportSlice.reducer
