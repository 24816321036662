import axios from '../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchDashboardAdEventDetails = createAsyncThunk(
    'fetchDashboardAdEventDetails',
    async (arg) => {
        const request = axios.get("/data-api/trendlines", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        const response = await request

        let result = response.data
        return result
    });



const userDashboardAdEventDetailsSlice = createSlice({
    name: 'userDashboardAdEventDetailsSlice',
    initialState: {
        status: "idle",
        data: {}
    },
    reducers: {
        reset(state) {
            state.status = "idle";
            state.data = {};
        },
    },
    extraReducers: {
        [fetchDashboardAdEventDetails.pending]: (state) => {
            state.status = 'pending'
            state.data = {}
        },
        [fetchDashboardAdEventDetails.fulfilled]: (state, action) => {
            state.status = 'done'
            if (action.payload.rows.length > 0) {
                state.data = action.payload.rows[0]
            }
            else {
                state.data = {} 
            }
        }
    }
})


const userDashboardAdEventDetailsSelector = state => state.userDashboard.adEventDetails
export const {
    reset
} = userDashboardAdEventDetailsSlice.actions
export { fetchDashboardAdEventDetails, userDashboardAdEventDetailsSelector }

export default userDashboardAdEventDetailsSlice.reducer
