import jquery from 'jquery'
import selectize from 'selectize'
// eslint-disable-next-line
import tooltipster from 'tooltipster'

const hook = {};
const marginOffsetPx = 12;

hook.after = function (self, method, fn) {
	var original = self[method];
	self[method] = function () {
	    var result = original.apply(self, arguments);
	    fn.apply(self, arguments);
	    return result;
    };
};

selectize.define('column_groups', function (options) {
    var self = this;

    options = jquery.extend({
        columnGroups: null,         // Column groups data
        columnGroupsWidth: 100,     // Max column width
        groupSize: 8,              // Amount of items in column
        maxGroups: 4,                // Max columns
        valuePrefix: ''
    }, options);

    // How to get the most closest option
    this.getAdjacentOption = function ($option, direction) {
        var $options = $option.closest('[data-items-group]')
                .find('[data-selectable]');
        var index    = $options.index($option) + direction;

        return index >= 0 &&
            index < $options.length ? $options.eq(index) : jquery();
    };

    // Get width of the system scrollbar
    var getScrollbarWidth = function () {
        var div;
        var width = getScrollbarWidth.width;
        var doc = document;

        if (width === undefined) {
            div = doc.createElement('div');
            div.innerHTML = '<div style="width:50px;height:50px;' +
                'position:absolute;left:-50px;top:-50px;overflow:auto;">' +
                '<div style="width:1px;height:100px;"></div></div>';
            div = div.firstChild;
            doc.body.appendChild(div);
            width = getScrollbarWidth.width =
                div.offsetWidth - div.clientWidth;
            doc.body.removeChild(div);
        }
        return width;
    };

    var getColumnGroups = function () {
        return (typeof options.columnGroups === 'function')
                ? options.columnGroups()
                : options.columnGroups;
    };

    var fixPosition = function (width) {
        var left;
        var position = self.$wrapper.offset();
        var viewportWidth = jquery(window).width();
        
        if (position.left + width > viewportWidth) {
            left = viewportWidth - width -
                position.left - marginOffsetPx;
            // But don't do negative window offset as well
            if (left + position.left < 100) {
                left = 100 - position.left;
            }
            self.$dropdown.css({'left': left + 'px'});
        }
    };

    // We regroup item inside of dropdown HTML, add multi-columns interfaces
    // Together with groups
    var regroupItems = function () {
        var optionsList, itemsCount, groupSize,
            columnsCount, groupKeys, itemGroups, groups, othersGroup,
            width, columnGroups;

        // This works only if we do have groups
        if (options.columnGroups) {
            columnGroups = getColumnGroups();
            
            // We calculate offset by left corner
            //of select element which we relative to

            if (self.$dropdown_content.find('[data-items-group]').length) {
                // We already generated HTML, no need to rebuild
                width = self.$dropdown.data('width')
                        || self.$dropdown.width();
                fixPosition(width);
                self.$dropdown.width(width);
                return;
            }

            optionsList = self.$dropdown_content
                .find('[data-selectable]')
                .map(function (index, item) {
                    /*jslint unparam: true */
                    return {
                        'item': item,
                        'value': jquery(item).data('value').toString()
                            .replace(options.valuePrefix, '')
                    };
                });

            // comma separated values are treated as Views: (group index 0)
            if (optionsList[0].value.indexOf(',') > 0) {
                columnGroups[0].values[3] = optionsList[0].value;
            }

            // Estimated items count.
            //We don't need exact number, we need to know groupSize
            itemsCount = optionsList.length + columnGroups.length;

            // We rather honor maxGroups than groupSize if more items
            if (options.groupSize * options.maxGroups < itemsCount) {
                groupSize = Math.ceil(itemsCount / options.maxGroups);
            }
            else {
                groupSize = options.groupSize;
            }

            // Get keys, that used by groups
            groupKeys = {};
            columnGroups.forEach(function (group) {
                if (group.values === 'other') {
                    return;
                }
                group.values.forEach(function (value) {
                    groupKeys[value] = true;
                });
            });

            // Get items that used by groups, build others group
            itemGroups = {};
            othersGroup = [];
            optionsList.each(function (index, item) {
                /*jslint unparam: true */
                if (groupKeys[item.value]) {
                    itemGroups[item.value] = {
                        'item': item.item
                    };
                } else {
                    othersGroup.push(item.item);
                }
            });

            // Create groups with items inside
            groups = [];
            columnGroups.forEach(function (group) {
                var currentGroup = {
                    'values': [],
                    'name': group.name
                };

                if (group.values !== 'other') {
                    group.values.forEach(function (value) {
                        if (itemGroups[value]) {
                            currentGroup.values
                                .push(itemGroups[value].item);
                        }
                    });
                }
                else {
                    currentGroup.values = othersGroup;
                }
                groups.push(currentGroup);
            });

            // Append items inside groups
            var currentIndex = 0;
            var currentGroupItem = null;
            columnsCount = 0;

            var checkAddGroup = function () {
                if (currentIndex % groupSize === 0) {
                    currentGroupItem = jquery('<div data-items-group>')
                        .css({
                            'width': options.columnGroupsWidth,
                            'float': 'left'
                        });
                    self.$dropdown_content.append(currentGroupItem);
                    columnsCount++;
                }
            };

            // Add groups to columns
            groups
                .filter(function (group) {
                    return group.values.length > 0;
                })
                .forEach(function (group) {
                    var option = {};

                    // Append group title
                    if (group.name) {
                        checkAddGroup();
                        option[self.settings.labelField] = group.name;
                        option.value = '';

                        var currentItem = jquery(
                                self.render('option', option).outerHTML
                                    .replace(
                                        'data-selectable', ''));
                        currentItem.addClass('group');
                        currentGroupItem.append(currentItem);

                        currentIndex++;
                    }

                    group.values.forEach(function (item) {
                        checkAddGroup();
                        // Append options
                        currentGroupItem.append(item);
                        currentIndex++;
                    });
                });

            // Scroll top to avoid small scroll if
            // item in the first page
            self.$dropdown_content.scrollTop(0);
            self.setActiveOption(self.$activeOption);

            // Set width of the dropdown
            width = options.columnGroupsWidth *
                columnsCount + getScrollbarWidth() + marginOffsetPx;

            // Store width & set
            self.$dropdown.data('width', width);
            self.$dropdown.width(width);
            self.$dropdown.find('.selectize-dropdown-content').width(width);

            fixPosition(width);

            self.$wrapper.find('[title]').tooltipster({
                theme: 'tooltipster-title',
                delay: 500
            });
        }
    };

    if (options.columnGroups) {
        var columnGroups = getColumnGroups();
        if (columnGroups.length === 0) {
            // Default empty group
            options.columnGroups = [{'name': '', 'values': 'other'}];
        }
        // Each time dropdown is redrawn
        hook.after(self, 'positionDropdown', regroupItems);
        hook.after(self, 'refreshOptions', regroupItems);
    }
});
