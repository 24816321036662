import _ from "lodash"
import parseBookmark from "./parseBookmark"
import NetworkListModel from "../../common/networks";
import Platforms from "../../common/platforms";
import {differenceInDays, parse} from "date-fns"
import {shortenCampaignSuggestion} from "../../common/campaigns";
import AdTypesListModel from "../../common/adtypes";
import Categories from "../../common/categories";


const titleize = (str) => {
    return _.startCase(_.toLower(str))
}

const translateBookmark = (bookmark, settings) => {
    const networkList = new NetworkListModel(settings.networks, [], {}, settings)
    const platforms = new Platforms(
        settings.platforms, settings.allowed.platforms, settings);
    const adTypes = new AdTypesListModel()
    const categories = new Categories(settings)

    function translatePage(page) {
        return titleize(page) + ' - ';
    }

    function translateAdvertiser(advertiser) {
        if (Array.isArray(advertiser)) {
            return advertiser.join(', ');
        }
        return advertiser;
    }

    function translatePublisher(publisher) {
        return publisher;
    }

    function translateCampaign(campaign, bookmark) {
        return bookmark.campaignId + ' (' + bookmark.advertiserId + ')';
    }

    function translateDateRange(dateRange) {
        return `Last ${dateRange} Day` + (dateRange === 1 ? '' : 's')
    }

    function translateSearchTerm(searchTerm) {
        if (searchTerm.length) {
            return `"${decodeURIComponent(searchTerm)}"`
        }
        return ''
    }

    function translateCountry(country) {
        return country.toUpperCase();
    }

    function translatePlatform(platform) {
        return platforms.platformIdToName(platform);
    }

    function translateNetwork(network) {
        if (network === 'all') {
            return 'All Networks';
        }
        return titleize(networkList.mapping[network]);
    }

    function translateAdType(adTypeId) {
        if (['any', 'all'].indexOf(adTypeId) >= 0) {
            return 'Ad Types';
        }
        return titleize(adTypes.mapping[adTypeId]);
    }

    function translateAdSize(adSizeId) {
        if (['any', 'all'].indexOf(adSizeId) >= 0) {
            return 'Sizes';
        }
        return adSizeId;
    }

    function translateSection(section) {
        return titleize(section);
    }

    function translateBrand(brand) {
        return titleize(brand.replace(/_/g, ' '));
    }

    function translatePageNo(pageNo) {
        return 'page ' + pageNo;
    }

    function translateCategory(category) {
        category = window.decodeURIComponent(
            categories.decodeValue(category)
        );

        let cat = categories.byValue(category);
        return cat ? cat.fullName() : '';
    }

    function translateAdvertisers(advertisers) {
        return advertisers.split(',').join(', ')
    }

    const propsOrder = [
        'page',
        'advertiser',
        'publisher',
        'channel',
        'campaign',
        'dateRange',
        'searchTerm',
        'country',
        'platform',
        'network',
        'brandId',
        'section',
        'adType',
        'adSize',
        'pageNo',
        'categoryId',
        'advertisers'
    ];

    const propsFuncs = [
        translatePage,
        translateAdvertiser,
        translatePublisher,
        translatePublisher,
        translateCampaign,
        translateDateRange,
        translateSearchTerm,
        translateCountry,
        translatePlatform,
        translateNetwork,
        translateBrand,
        translateSection,
        translateAdType,
        translateAdSize,
        translatePageNo,
        translateCategory,
        translateAdvertisers
    ];

    let result = [];

    let data = parseBookmark(bookmark.historyToken);

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            bookmark[key] = data[key];
        }
    }

    bookmark.country = bookmark.country || ''
    bookmark.network = bookmark.network || '';

    if (!bookmark.network) {
        bookmark.networkTitle = 'No Network';
    }
    else if (bookmark.network === 'all') {
        bookmark.networkTitle = 'All Networks';
    }
    else {
        bookmark.networkTitle = titleize(networkList.mapping[bookmark.network]);
    }

    bookmark.countryName = bookmark.country.toUpperCase();

    if (bookmark.platform !== 'desktop') {
        let platformName = platforms.platformIdToName(bookmark.platform)
        if (platformName) {
            bookmark.countryName += ', ' + platformName;
        }
    }

    propsOrder.forEach(function (propName, i) {
        if (data[propName] !== undefined) {
            result.push(propsFuncs[i](data[propName], bookmark));
        }
    });

    bookmark.entityId = ''
    bookmark.entityKey = ''

    if (bookmark.advertiser) {
        bookmark.entityType = 'advertiser';
        let advertiser = bookmark.advertiser
        if (Array.isArray(advertiser)) {
            advertiser = advertiser[0]
        }
        bookmark.entityId = advertiser
    }
    else if (bookmark.publisher) {
        bookmark.entityType = 'publisher';
        bookmark.entityId = bookmark.publisher
    }
    else if (bookmark.campaign) {
        bookmark.entityType = 'campaign';
        bookmark.entityId = bookmark.advertiser
    }
    else {
        bookmark.entityType = '';
    }

    if (bookmark.entityId) {
        bookmark.entityKey = bookmark.entityType + '|' + bookmark.entityId
    }

    bookmark.daysCreated = differenceInDays(
        new Date(),
        parse(bookmark.date + ', Z', 'yyyy-MM-dd HH:mm:ss, X', new Date())
    );

    if (bookmark.campaign) {
        bookmark.title = result[0] +
            shortenCampaignSuggestion(result[1], 25) + ', ' +
            result.slice(2).join(', ');
        bookmark.tooltip = result[0] + result.slice(1).join(', ');
    } else if (bookmark.type === 'brand') {
        bookmark.title = result[0] + result.slice(1).join(', ') +
            ', ' + bookmark.advertiserId ;
        bookmark.tooltip = bookmark.title;
    } else {
        bookmark.title = result[0] + result.slice(1)
            .filter(d => d.length > 0).join(', ');
        bookmark.tooltip = bookmark.title;
    }

    return bookmark;
}


export default translateBookmark