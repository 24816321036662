import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserTopAds = createAsyncThunk(
    'fetchAdvertiserTopAds',
    async (arg) => {
        const params = {
            ...arg.query,
        }

        const response = await axios.get("/data-api/ads", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const advertiserTopAdsSlice = createSlice({
    name: 'advertiserCreativeTopAds',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetAdvertiserCreativeTopAds(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserTopAds.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvertiserTopAds.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserTopAds = (state) => state.advertiserProfile.overview.topAds
export const {resetAdvertiserCreativeTopAds} = advertiserTopAdsSlice.actions
export {selectAdvertiserTopAds, fetchAdvertiserTopAds}
export default advertiserTopAdsSlice.reducer