import _ from 'lodash'
import upgrade from './upgradeRequired'
import convertCategories from '../misc/categoryConverter'

const categoryList = [
  { score: 1, suggestion: "/art and entertainment" },
  { score: 1, suggestion: "/art and entertainment/architecture" },
  { score: 1, suggestion: "/art and entertainment/art museums and galleries" },
  { score: 1, suggestion: "/art and entertainment/books and literature" },
  {
    score: 1,
    suggestion: "/art and entertainment/entertainment news and gossip",
  },
  { score: 1, suggestion: "/art and entertainment/event tickets" },
  { score: 1, suggestion: "/art and entertainment/humor" },
  { score: 1, suggestion: "/art and entertainment/movies and tv" },
  { score: 1, suggestion: "/art and entertainment/music" },
  { score: 1, suggestion: "/art and entertainment/podcasts" },
  { score: 1, suggestion: "/art and entertainment/radio" },
  { score: 1, suggestion: "/art and entertainment/shows and events" },
  { score: 1, suggestion: "/art and entertainment/visual art and design" },
  { score: 1, suggestion: "/automotive and vehicles" },
  { score: 1, suggestion: "/automotive and vehicles/auto parts" },
  {
    score: 1,
    suggestion:
      "/automotive and vehicles/automotive and vehicles news and info",
  },
  { score: 1, suggestion: "/automotive and vehicles/bicycles and accessories" },
  { score: 1, suggestion: "/automotive and vehicles/boats and watercraft" },
  { score: 1, suggestion: "/automotive and vehicles/campers and rvs" },
  { score: 1, suggestion: "/automotive and vehicles/dealership" },
  { score: 1, suggestion: "/automotive and vehicles/motorcycles" },
  { score: 1, suggestion: "/automotive and vehicles/tires" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/acura" },
  {
    score: 1,
    suggestion: "/automotive and vehicles/vehicle brands/alfa romeo",
  },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/audi" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/bentley" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/bmw" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/buick" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/cadillac" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/chevrolet" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/chrysler" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/dodge" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/ferrari" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/fiat" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/ford" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/honda" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/hyundai" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/infiniti" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/jeep" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/kia" },
  {
    score: 1,
    suggestion: "/automotive and vehicles/vehicle brands/land rover",
  },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/lexus" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/maserati" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/mazda" },
  {
    score: 1,
    suggestion: "/automotive and vehicles/vehicle brands/mercedes-benz",
  },
  {
    score: 1,
    suggestion: "/automotive and vehicles/vehicle brands/mitsubishi",
  },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/nissan" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/porsche" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/subaru" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/suzuki" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/toyota" },
  {
    score: 1,
    suggestion: "/automotive and vehicles/vehicle brands/volkswagen",
  },
  { score: 1, suggestion: "/automotive and vehicles/vehicle brands/volvo" },
  { score: 1, suggestion: "/automotive and vehicles/vehicle rental" },
  { score: 1, suggestion: "/business and industrial" },
  {
    score: 1,
    suggestion: "/business and industrial/advertising and marketing",
  },
  { score: 1, suggestion: "/business and industrial/aerospace and defense" },
  { score: 1, suggestion: "/business and industrial/agriculture and forestry" },
  { score: 1, suggestion: "/business and industrial/biomedical industry" },
  { score: 1, suggestion: "/business and industrial/business news" },
  { score: 1, suggestion: "/business and industrial/business operations" },
  { score: 1, suggestion: "/business and industrial/construction" },
  { score: 1, suggestion: "/business and industrial/energy" },
  { score: 1, suggestion: "/business and industrial/human resources" },
  { score: 1, suggestion: "/business and industrial/logistics" },
  { score: 1, suggestion: "/business and industrial/pharmaceutical industry" },
  { score: 1, suggestion: "/careers" },
  { score: 1, suggestion: "/careers/career advice and planning" },
  { score: 1, suggestion: "/careers/job search" },
  { score: 1, suggestion: "/careers/nursing" },
  { score: 1, suggestion: "/careers/resume writing and advice" },
  { score: 1, suggestion: "/education" },
  { score: 1, suggestion: "/education/college" },
  { score: 1, suggestion: "/education/K-12 school" },
  { score: 1, suggestion: "/education/language learning" },
  { score: 1, suggestion: "/education/online learning" },
  { score: 1, suggestion: "/education/test prep and tutoring" },
  { score: 1, suggestion: "/education/trade school" },
  { score: 1, suggestion: "/family and parenting" },
  { score: 1, suggestion: "/family and parenting/babies" },
  { score: 1, suggestion: "/family and parenting/child care and preschool" },
  { score: 1, suggestion: "/family and parenting/children" },
  { score: 1, suggestion: "/family and parenting/eldercare and senior living" },
  { score: 1, suggestion: "/family and parenting/motherhood" },
  { score: 1, suggestion: "/family and parenting/pregnancy" },
  { score: 1, suggestion: "/finance" },
  { score: 1, suggestion: "/finance/accounting and bookkeeping" },
  { score: 1, suggestion: "/finance/bank" },
  { score: 1, suggestion: "/finance/credit" },
  { score: 1, suggestion: "/finance/debt management" },
  { score: 1, suggestion: "/finance/financial news" },
  { score: 1, suggestion: "/finance/financial newsletters" },
  { score: 1, suggestion: "/finance/grants, scholarships and financial aid" },
  { score: 1, suggestion: "/finance/insurance" },
  { score: 1, suggestion: "/finance/investing" },
  { score: 1, suggestion: "/finance/investing/precious metals" },
  { score: 1, suggestion: "/finance/lending" },
  { score: 1, suggestion: "/finance/tax preparation" },
  { score: 1, suggestion: "/food and drink" },
  { score: 1, suggestion: "/food and drink/alcoholic beverages" },
  { score: 1, suggestion: "/food and drink/beverages" },
  { score: 1, suggestion: "/food and drink/food" },
  { score: 1, suggestion: "/food and drink/food and grocery retailers" },
  { score: 1, suggestion: "/food and drink/food news and recipes" },
  { score: 1, suggestion: "/food and drink/meal delivery" },
  { score: 1, suggestion: "/food and drink/online food delivery" },
  { score: 1, suggestion: "/food and drink/restaurants" },
  {
    score: 1,
    suggestion: "/food and drink/restaurants/fast casual restaurants",
  },
  {
    score: 1,
    suggestion: "/food and drink/restaurants/quick serve restaurants",
  },
  { score: 1, suggestion: "/health and fitness" },
  { score: 1, suggestion: "/health and fitness/addiction" },
  { score: 1, suggestion: "/health and fitness/alternative medicine" },
  { score: 1, suggestion: "/health and fitness/dental care" },
  { score: 1, suggestion: "/health and fitness/disease" },
  { score: 1, suggestion: "/health and fitness/exercise" },
  { score: 1, suggestion: "/health and fitness/home fitness" },
  { score: 1, suggestion: "/health and fitness/men's health" },
  { score: 1, suggestion: "/health and fitness/mental health" },
  { score: 1, suggestion: "/health and fitness/nutrition" },
  { score: 1, suggestion: "/health and fitness/pharmaceutical" },
  { score: 1, suggestion: "/health and fitness/weight loss" },
  { score: 1, suggestion: "/health and fitness/women's health" },
  { score: 1, suggestion: "/hobbies and interests" },
  { score: 1, suggestion: "/hobbies and interests/arts and crafts" },
  { score: 1, suggestion: "/hobbies and interests/astrology" },
  { score: 1, suggestion: "/hobbies and interests/games" },
  { score: 1, suggestion: "/hobbies and interests/music and instruments" },
  { score: 1, suggestion: "/hobbies and interests/survival" },
  { score: 1, suggestion: "/home and garden" },
  { score: 1, suggestion: "/home and garden/appliances" },
  { score: 1, suggestion: "/home and garden/bed and bath" },
  { score: 1, suggestion: "/home and garden/gardening and landscaping" },
  { score: 1, suggestion: "/home and garden/home furnishings" },
  { score: 1, suggestion: "/home and garden/home improvement and repair" },
  { score: 1, suggestion: "/home and garden/laundry" },
  { score: 1, suggestion: "/home and garden/pest control" },
  { score: 1, suggestion: "/law, govt and politics" },
  { score: 1, suggestion: "/law, govt and politics/armed forces" },
  { score: 1, suggestion: "/law, govt and politics/government" },
  { score: 1, suggestion: "/law, govt and politics/law enforcement" },
  { score: 1, suggestion: "/law, govt and politics/legal" },
  { score: 1, suggestion: "/law, govt and politics/politics" },
  {
    score: 1,
    suggestion: "/law, govt and politics/poltical news and commentary",
  },
  { score: 1, suggestion: "/medical and healthcare" },
  { score: 1, suggestion: "/medical and healthcare/hospitals" },
  { score: 1, suggestion: "/medical and healthcare/pharma" },
  { score: 1, suggestion: "/news" },
  { score: 1, suggestion: "/news/local news" },
  { score: 1, suggestion: "/pets" },
  { score: 1, suggestion: "/pets/veterinary medicine" },
  { score: 1, suggestion: "/real estate" },
  { score: 1, suggestion: "/real estate/apartments" },
  { score: 1, suggestion: "/real estate/buying and selling homes" },
  { score: 1, suggestion: "/religion and spirituality" },
  { score: 1, suggestion: "/science" },
  { score: 1, suggestion: "/science/ecology and recycling" },
  { score: 1, suggestion: "/science/weather" },
  { score: 1, suggestion: "/shopping" },
  { score: 1, suggestion: "/shopping/auctions and classifieds" },
  { score: 1, suggestion: "/shopping/comparison engines" },
  { score: 1, suggestion: "/shopping/coupons and rebates" },
  { score: 1, suggestion: "/shopping/gifts and flowers" },
  { score: 1, suggestion: "/shopping/loyalty programs" },
  { score: 1, suggestion: "/shopping/retail" },
  { score: 1, suggestion: "/social science" },
  { score: 1, suggestion: "/social science/genealogy" },
  { score: 1, suggestion: "/social science/history" },
  { score: 1, suggestion: "/society" },
  { score: 1, suggestion: "/society/charities and non-profits" },
  { score: 1, suggestion: "/society/crime prevention" },
  { score: 1, suggestion: "/society/dating" },
  { score: 1, suggestion: "/society/divorce" },
  { score: 1, suggestion: "/society/home security" },
  { score: 1, suggestion: "/society/marriage" },
  { score: 1, suggestion: "/society/weddings" },
  { score: 1, suggestion: "/sports" },
  { score: 1, suggestion: "/sports/golf" },
  { score: 1, suggestion: "/sports/hunting and fishing" },
  { score: 1, suggestion: "/sports/sports news" },
  { score: 1, suggestion: "/style and fashion" },
  { score: 1, suggestion: "/style and fashion/accessories" },
  { score: 1, suggestion: "/style and fashion/beauty" },
  { score: 1, suggestion: "/style and fashion/clothing" },
  { score: 1, suggestion: "/style and fashion/eyewear" },
  { score: 1, suggestion: "/style and fashion/footwear" },
  { score: 1, suggestion: "/style and fashion/jewelry" },
  { score: 1, suggestion: "/style and fashion/skin care" },
  {
    score: 1,
    suggestion: "/style and fashion/style and fashion news and info",
  },
  { score: 1, suggestion: "/technology and computing" },
  { score: 1, suggestion: "/technology and computing/computer hardware" },
  { score: 1, suggestion: "/technology and computing/computer security" },
  { score: 1, suggestion: "/technology and computing/consumer electronics" },
  { score: 1, suggestion: "/technology and computing/internet technology" },
  { score: 1, suggestion: "/technology and computing/software" },
  { score: 1, suggestion: "/technology and computing/software/antivirus" },
  {
    score: 1,
    suggestion: "/technology and computing/software/collaboration tools",
  },
  {
    score: 1,
    suggestion: "/technology and computing/software/email marketing",
  },
  {
    score: 1,
    suggestion: "/technology and computing/software/web site builders",
  },
  { score: 1, suggestion: "/technology and computing/tech news and info" },
  { score: 1, suggestion: "/technology and computing/telecom" },
  { score: 1, suggestion: "/technology and computing/web hosting" },
  { score: 1, suggestion: "/travel" },
  { score: 1, suggestion: "/travel/air travel" },
  { score: 1, suggestion: "/travel/comparison sites" },
  { score: 1, suggestion: "/travel/hotels" },
  { score: 1, suggestion: "/travel/public transportation" },
  { score: 1, suggestion: "/travel/sea travel" },
  { score: 1, suggestion: "/travel/tourist destinations" },
  { score: 1, suggestion: "/travel/tours" },
  { score: 1, suggestion: "/travel/train travel" },
  { score: 1, suggestion: "/travel/travel news and info" },
  { score: 1, suggestion: "/travel/vacation rentals" },
];

const categoryByValue = {}

class Category {
    constructor(category, parent) {
        
        this.text = _.startCase(_.toLower(category.label))
            .replace(' And ', ' & ')
            .replace(/\bTv\b/, 'TV');
        this.value = category.data;
        this.children = [];
        this.parent = parent || null;
        this.siblings = category.siblings || null;

        if (!categoryByValue.hasOwnProperty(this.value)) {
            categoryByValue[this.value] = this;
        }

        var hasChildren = category.children !== undefined &&
            category.children.length;

        if (hasChildren) {
            this.children.push(new Category({
                label: 'All Sub Categories',
                data: this.value
            }, this));
            category.children.forEach(function (child) {
                var c = new Category(child, this);
                this.children.push(c);
            }, this);
        }

        this.fullName = function () {
            var name = [this.text];

            var p = this.parent;

            while (p) {
                name.push(p.text);
                p = p.parent;
            }
            name.reverse();

            return name.join(' > ');
        }.bind(this);
    }
}

class Categories {
    constructor(settings) {
        var self = this;

        this.hasCategories = function (countryId) {
            return countryId === 'us' || countryId === 'all';
        };

        this.tree = [];

        this.onClick = function (val) {
            return function () {
                if (settings.has_campaigns) {
                    var path = '/top-advertisers/us/all/180/all/categoryScoreThenSpend/desc/a/';
                    path += self.encodeValue(val);
                    window.location.pathname = path;
                } else {
                    upgrade.showModal('categoryId', true);
                }
            };
        };

        this.encodeValue = function (categoryValue) {
            if (categoryValue === undefined) {
                return '';
            }
            return categoryValue.replace(/\//g, '--');
        };

        this.decodeValue = function (categoryValue) {
            if (categoryValue === undefined) {
                return '';
            }
            return categoryValue.replace(/--/g, '/');
        };

        this.byValue = function (value) {
            if (value === '/') {
                value = ''
            }
            return categoryByValue[value];
        };

        this._getSiblings = function (category) {
            return category.parent ? category.parent.children : this.tree;
        };

        this._getLayoutItem = function (category) {
            return {
                selected: category.value,
                options: this._getSiblings(category).map(function (d) {
                    return {
                        text: d.text,
                        value: settings.has_categories || d.value === '' ?
                            d.value :
                            settings.upgradeRequiredValue + d.value
                    };
                })
            };
        };

        this.categoryLayout = function (value) {
            var category = this.byValue(value || '');
            if (category === undefined) {
                category = this.byValue('');
            }
            var child = category.children[0];

            var list = [];

            if (child) {
                list.push(this._getLayoutItem(child));
            }

            list.push(this._getLayoutItem(category));

            var parent = category.parent;

            while (parent) {
                list.push(this._getLayoutItem(parent));
                parent = parent.parent;
            }

            list.reverse();
            return list;
        };

        this.tree.push(new Category({
            label: 'All Categories',
            data: ''
        }));

        convertCategories(categoryList)
            .forEach(function (category) {
                var c = new Category(category);
                this.tree.push(c);
            }, this);
    }
}

export default Categories