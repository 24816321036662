import axios from '../common/axiosRateLimit'
import numeral from 'numeral'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {rangeFor} from "../components/DatePicker";
import {daysToDate} from "../common/crawlDays";
import {
    format as applyFormat,
    field as formatField,
    useSettings as useSettingsForFormat} from '../common/formatMetric'
import {guessFormat} from "../misc/currency";

let valueCache = {}

const getMetricValue = createAsyncThunk('singleRowAdSpend',
    async (args) => {
        let dataRow = args.dataRow
        let settings = args.settings

        if (!dataRow.key) {
            return '';
        }

        useSettingsForFormat(settings)

        const dateRangePreset = settings.dateRange365
        const [startDate, endDate] = rangeFor(dateRangePreset,
            daysToDate(settings.first_index_date),
            daysToDate(settings.last_index_date))

        let key = dataRow.key;

        let requestForMetricValue = async function () {
            let url, data;
            let dataValue, formatString = '{value} {ad metric}';
            let field = formatField('sumAdSpend');
            let fields = field;
            if (settings.hasSpend) {
                fields = 'sumAdSpend,sumImpressions';
            }

            if (dataRow.term_type === 'advertiser') {
                url = '/data-api/advertisers';
                data = {
                    advertiser: dataRow.term,
                    platform: dataRow.platform,
                    country: dataRow.country,
                    startDate: startDate,
                    endDate: endDate,
                    metrics: fields,
                    rows: 1
                };
            }

            if (dataRow.term_type === 'campaign') {
                (function () {
                    let campaignId = decodeURIComponent(dataRow.link.split('/')[4]);
                    let advertiserId = decodeURIComponent(dataRow.link.split('/')[5]);

                    url = '/data-api/campaigns';
                    data = {
                        campaign: campaignId,
                        advertiser: advertiserId,
                        platform: dataRow.platform,
                        country: dataRow.country,
                        startDate: startDate,
                        endDate: endDate,
                        metrics: fields,
                        rows: 1
                    };
                }());
            }

            if (dataRow.term_type === 'publisher') {
                url = '/data-api/publishers';
                data = {
                    publisher: dataRow.term,
                    platform: dataRow.platform,
                    country: dataRow.country,
                    startDate: startDate,
                    endDate: endDate,
                    metrics: fields,
                    rows: 1
                };
            }

            function getStringForMetric(response, metric) {
                metric = metric || settings.adMetricId;
                try {
                    if (response.data.rows && response.data.rows.length > 0) {
                        dataValue = response.data.rows[0][metric];
                        dataValue = guessFormat(
                            dataValue,
                            applyFormat('{$}0.[0]a', metric), dataRow.country)
                            .toUpperCase();

                        valueCache[key] = applyFormat(formatString, metric)
                            .replace('{value}', dataValue);
                        return valueCache[key];
                    }
                }
                catch (e) {

                }
                dataValue = numeral(0).format('0.[0]a').toUpperCase();
                return applyFormat(formatString, metric).replace('{value}', dataValue);
            }

            if (url) {
                const response = await axios.get(url, {
                    'params': data
                });
                return {
                    sumAdSpend: getStringForMetric(response, 'sumAdSpend'),
                    sumImpressions: getStringForMetric(response, 'sumImpressions'),
                }
            }

            return {sumAdSpend: "", sumImpressions: ""} 
        };

        if (valueCache.hasOwnProperty(key)) {
            return valueCache[key]
        }
        return await requestForMetricValue();
    }
)

export {getMetricValue}