import { useEffect, useRef, useState } from "react";
import LoadingState from "../components/LoadingState";
import _ from 'lodash'

function toNeareastTen(num) {
    return Math.floor(num / 10) * 10;
}

function loadingStr(percent) {
    return 'loaded-' + percent + '-percent';
}

var framePattern = /(\d{3})\.png$/;
var framePlaceholder = '{frame}';
var frameRate = 300;
var progressTimeout = 200;

function adjustableFrameRate(numFrames) {
    if (numFrames < 5) {
        return 1000;
    }

    if (numFrames < 10) {
        return 500;
    }
    
    return frameRate;
}


function ProgressBar(container) {
    var bar = document.createElement('progress');
    bar.max = 100;

    container.appendChild(bar);

    this.setProgress = function (percent) {
        bar.value = percent;
    };
}

function ProgressCSS(container) {
    this.setProgress = _.throttle(function (percent) {
        percent = toNeareastTen(percent);
        
        this._unsetProgress(percent);
        
        var classStr = loadingStr(percent);
        
        container.classList.add(classStr);
    }, progressTimeout, this);

    this._unsetProgress = function (upTo) {
        var percent;
        upTo = toNeareastTen(upTo || 100);
        
        for (percent = 0; percent < upTo; percent += 10) {
            container.classList
                .remove(loadingStr(percent));
        }
    };

}

function PlaybackControls(container, notifyState, notifyPosition) {
    var self = this;
    
    var template = '<div class="pause playing">' +
            '<i class="fa-light fa-sharp fa-play"></i>' +
            '<i class="fa-light fa-sharp fa-pause"></i>' +
        '</div>' +
        '<input class="seek" type="range" min="0" step="0.005" ' +
               'max="1" value="0" />';

    var playbackEl = document.createElement('div');
    playbackEl.classList.add('playback-controls');
    container.appendChild(playbackEl);

    playbackEl.innerHTML = template;

    var pause = playbackEl.querySelector('.pause');

    var seek = playbackEl.querySelector('.seek');

    this._isPlaying = false;

    this.showState = function (isPlaying) {
        this._isPlaying = isPlaying;
        
        if (isPlaying) {
            pause.classList.remove('playing');
        } else {
            pause.classList.add('playing');
        }
    };

    this.showPosition = function (position) {
        seek.value = position;
    };

    this.togglePlayback = function () {
        this._isPlaying = !this._isPlaying;
    };

    const handlePause = pause.addEventListener('click', function () {
        self.togglePlayback();
        self.showState(self._isPlaying);
        notifyState(self._isPlaying);
    });

    function onSeekSlide(value) {
        self._isPlaying = false;
        
        self.showState(self._isPlaying);
        
        notifyState(self._isPlaying);
        notifyPosition(value);
    }

    function onSeekUpdate(value) {
        self._isPlaying = false;
        
        notifyState(self._isPlaying);
        notifyPosition(value);           
    }
    
    const handleInput = seek.addEventListener('input', function () {
        onSeekSlide(this.value);
    });

    const handleChange = seek.addEventListener('change', function () {
        onSeekUpdate(this.value);
    });

    this.cleanup = function () {
        seek.removeEventListener('input', handleInput)
        seek.removeEventListener('change', handleChange)
        pause.removeEventListener('click', handlePause)
    }
}

function frameUrl(frameNo, baseUrl) {
    var frameNoStr = '000'
            .slice(0, (3 - String(frameNo).length % 3) % 3) + frameNo;
    
    return baseUrl.replace(framePlaceholder, frameNoStr);
}


function AnimatedPreview(
    previewContainer, data, progressType, hoverPlayback
) {

    var lastFrameUrl = data.imageUrl
                .replace(/^https?:\//, '//s3.amazonaws.com');

    hoverPlayback = hoverPlayback || false;
    progressType = progressType || 'css';

    if (progressType === 'css') {
        this.progressDisplay = new ProgressCSS(previewContainer);
    } else {
        this.progressDisplay = new ProgressBar(previewContainer);
    }
    
    var self = this;

    var numFrames = window
            .parseInt(
                lastFrameUrl.match(framePattern)[1], 10
            ) + 1; // zero based
    var numLoadedFrames = 0;

    var previewFrameNo = numFrames - 1;
    
    var baseUrl = lastFrameUrl
            .replace(framePattern, framePlaceholder + '.png');

    var canvas = document.createElement('canvas');
    previewContainer.appendChild(canvas);
    var context = canvas.getContext('2d');
    
    var frames = new Array(numFrames), currentFrame = 0;

    this.getFrames = function () {
        return frames;
    };

    this.setFrames = function (fr) {
        frames = fr;
        numLoadedFrames = fr.length;
    };

    function advanceCurrentFrame() {
        currentFrame = (currentFrame + 1) % numFrames;
    }

    this._isAnimated = false;

    this.setCurrentFrameFromProgress = function (progress) {
        currentFrame = Math.round(progress * previewFrameNo);
        self.showCurrentFrame();
    };

    this.setAnimation = function (isAnimated) {
        if (isAnimated) {
            self.startAnimation();
        } else {
            self.stopAnimation();
        }
    };

    this.controls = new PlaybackControls(
        previewContainer,
        this.setAnimation,
        this.setCurrentFrameFromProgress
    );

         
    this.showCurrentFrame = function () {
        var img = frames[currentFrame];

        if (img === undefined) {
            return false;
        }
        
        context.clearRect(
            0, 0,
            context.canvas.width, context.canvas.height
        );
        context.drawImage(img, 0, 0,
                          parseInt(data.creativeWidth), // img.width, 
                          parseInt(data.creativeHeight)); // img.height);

        return true;
    };

    this.showFrameNo = function (frameNo) {
        currentFrame = frameNo;
        this.showCurrentFrame();
    };

    this.doAnimation = function () {
        if (!self._isAnimated) {
            return;
        }
        
        window.setTimeout(function () {
            self.showCurrentFrame();
            advanceCurrentFrame();
            self.controls.showPosition(currentFrame / previewFrameNo);
            window.requestAnimationFrame(self.doAnimation);
        }, adjustableFrameRate(numFrames));
    };
    
    this.startAnimation = function () {
        if (this.progress() === 1 && !this._isAnimated) {
            this._isAnimated = true;
            this.controls.showState(this._isAnimated);
            window.requestAnimationFrame(this.doAnimation);
        }
    };

    this.stopAnimation = function () {
        this._isAnimated = false;
        this.controls.showState(this._isAnimated);
    };

    this.setPreviewCSS = function () {
        previewContainer.classList.add('preview-loaded');
    };

    this.setLoadedCSS = function () {
        previewContainer.classList.add('fully-loaded');
        previewContainer.classList.remove('in-progress');
    };

    this.setProgressCSS = function () {
        previewContainer.classList.add('in-progress');
    };
    
    this.progress = function () {
        return numLoadedFrames / numFrames;
    };

    this.showPreviewFrame = function () {
        this.showFrameNo(previewFrameNo);
    };

    var previewLoadedCallback;
    
    this.previewLoadedCallback = function (fn) {
        previewLoadedCallback = fn;
    };

    this.onPreviewLoaded = function () {
        canvas.width = parseInt(data.creativeWidth); // frames[previewFrameNo].width;
        canvas.height = parseInt(data.creativeHeight); // frames[previewFrameNo].height;

        this.setPreviewCSS();
        this.showPreviewFrame();

        if (previewLoadedCallback) {
            previewLoadedCallback();
        }
    };

    var allLoadedCallback;

    this.allLoadedCallback = function (fn) {
        allLoadedCallback = fn;  
    };

    this.onAllLoaded = function () {
        this.setLoadedCSS();
        this.startAnimation();

        if (allLoadedCallback) {
            allLoadedCallback();
        }
    };
            
    this.onImgLoad = function () {
        numLoadedFrames = Math.min(numLoadedFrames + 1, numFrames);

        var progress = self.progress();

        self.progressDisplay.setProgress(progress * 100);
        
        if (progress === 1) {
            self.onAllLoaded();
        } else if (frames[previewFrameNo]) {
            self.onPreviewLoaded();
        }
    };

    this.initFrame = function (frameNo) {
        if (frames[frameNo] !== undefined) {
            this.onImgLoad();
            return;
        }
        
        this.progressDisplay.setProgress(this.progress() * 100);
        
        var frame = new Image();
        frames[frameNo] = frame;
        frame.onload = this.onImgLoad;
        frame.src = frameUrl(frameNo, baseUrl);
    };
    
    this.initPreview = function () {
        this.initFrame(previewFrameNo);
    };

    this.init = this.initPreview;

    let _isLoading = false;

    this.stopLoadingAndAnimation = function () {
        _isLoading = false;
        this.stopAnimation();
    };

    this.resumeLoadingAndAnimation = function () {
        _isLoading = true;

        if (this.progress() < 1) {
            this.initFramesAndStartAnimation();
        } else {
            this.startAnimation();
        }
    };

    var lastLoadedFrameIndex = 0;
    
    this.initFramesAndStartAnimation = function () {
        var simultaneousFrames = 6, batchTimeout = 200;

        this.setProgressCSS();


        function loadBatch(start, end) {
            return function () {
                if (_isLoading) {
                    lastLoadedFrameIndex = Math.min(end, numFrames) - 1;
                    
                    var i;
                    
                    for (i = start; i < end && i < numFrames; i += 1) {
                        self.initFrame(i);
                    }

                    if (end < numFrames) {
                        window.setTimeout(
                            loadBatch(end, end + simultaneousFrames),
                            batchTimeout
                        );
                    }
                }
            };
        }
        
        window.setTimeout(
            loadBatch(
                lastLoadedFrameIndex,
                lastLoadedFrameIndex + simultaneousFrames
            ),
            batchTimeout
        );
    };

    if (hoverPlayback) {
        previewContainer.addEventListener('mouseenter', function () {
            self.resumeLoadingAndAnimation();
        });
        
        previewContainer.addEventListener('mouseleave', function () {
            self.stopLoadingAndAnimation();
        });
    }

    this.cleanup = () => {
        this.controls.cleanup()
    }
}

const Html5Preview = (props) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const element = useRef(null)
    const data = props.ad
    data.imageUrl = props.ad.adInfo.creativeUrl    

    useEffect(() => {
        if (element.current && element.current.querySelector('canvas')) {
            return
        }

        const framesCache = {}
        var preview = new AnimatedPreview(element.current, data, 'bar');

        function lockerdomeHack() {
            if (data.networkId === 'lockerdome') {
                preview.stopAnimation();
                preview.setCurrentFrameFromProgress(100);
            }
        }

        function saveToCache() {
            if (!framesCache[data.adHash]) {
                framesCache[data.adHash] = preview.getFrames();
            }
        }

        function populateFromCache() {
            if (framesCache[data.adHash]) {
                preview.setFrames(framesCache[data.adHash]);
            }
        }

        function startAnimation() {
            setIsLoaded(true)
            preview.setLoadedCSS();
            preview.onPreviewLoaded();
            preview.startAnimation();

            lockerdomeHack();
        }
    
        function allLoadedCallback() {
            saveToCache();
            startAnimation();

            if (data.networkId === 'lockerdome') {
                preview.stopAnimation();
                preview.setCurrentFrameFromProgress(100);
            }
        }


        preview.allLoadedCallback(allLoadedCallback);
    
        populateFromCache();

        preview.initPreview();

        preview.resumeLoadingAndAnimation();

        lockerdomeHack();

        props.setPreview(preview)

        return preview.cleanup
    })

    return (
        <>
        <LoadingState show={!isLoaded}
            left={38}></LoadingState>
        <div ref={element} className="type-html5"></div>
        </>
    );
}
 
export default Html5Preview;