import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'

const submitAdvertiserEnrichForm = createAsyncThunk('submitAdvertiserEnrichForm',
    async (arg) => {
        let path = '/enterprise_tools/upload-advertisers-list';

        const response = await axios({
            method: "post",
            url: path,
            data: arg.formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return response.data
    })


const advertiserEnrichToolsSlice = createSlice({
    name: 'advertiserEnrichTool',
    initialState: {
        status: 'idle',
        reply: {},
        errors: [],
    },
    reducers: {
        resetErrors(state) {
            state.errors = []
        }
    },
    extraReducers: {
        [submitAdvertiserEnrichForm.rejected]: (state, action) => {
            state.errors = [action.payload.message]
        },
        [submitAdvertiserEnrichForm.pending]: (state) => {
            state.errors = []
            state.status = 'pending'
        },
        [submitAdvertiserEnrichForm.fulfilled]: (state, action) => {
            state.status = 'done'
            if ('error' in action.payload) {
                state.errors = [action.payload.error]
                return
            }

            state.reply = action.payload
        }
    }
})


const selectAdvertiserEnrichState = (state) => state.advertiserEnrichTool

export {
    selectAdvertiserEnrichState,
    submitAdvertiserEnrichForm
}

export const {
    resetErrors
} = advertiserEnrichToolsSlice.actions

export default advertiserEnrichToolsSlice.reducer