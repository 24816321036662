import React from "react";
import RecentSearchesTopLeftWidget
    from "../../pages/search/RecentSearchesTopLeftWidget";

const RecentSearches = (props) => {
    return (
        <div className="recent-search-container">
            <RecentSearchesTopLeftWidget/>
        </div>
    )
}

export default RecentSearches