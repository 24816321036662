import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const searchAdTerm = createAsyncThunk('searchAdTerm',
    async (arg) => {
        const response = await axios.get("/data-api/search/adTerms", {
            params: arg.params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const searchAdvertisers = createAsyncThunk('searchAdvertisers',
    async (arg) => {
        const response = await axios.get("/data-api/search/advertisers", {
            params: arg.params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const searchPublishers = createAsyncThunk('searchPublishers',
    async (arg) => {
        const response = await axios.get("/data-api/search/publishers", {
            params: arg.params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const searchBrands = createAsyncThunk('searchBrands',
    async (arg) => {
        const response = await axios.get("/data-api/search/brands", {
            params: arg.params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const searchNetworks = createAsyncThunk('searchNetworks',
    async (arg) => {
        const response = await axios.get("/data-api/search/networks", {
            params: arg.params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const searchYtChannels = createAsyncThunk('searchYtChannels',
    async (arg) => {
        const response = await axios.get("/data-api/search/yt-channels", {
            params: arg.params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const searchCategories = createAsyncThunk('searchCategories',
    async (arg) => {
        const response = await axios.get("/data-api/search/categories", {
            params: arg.params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const searchCampaigns = createAsyncThunk('searchCampaigns',
    async (arg) => {
        const response = await axios.get("/data-api/search/campaigns", {
            params: arg.params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const initialState = {
    keywords: [],
    advertisers: [],
    publishers: [],
    networks: [],
    categories: [],
    brands: [],
    campaigns: [],
    ytChannels: []
}

const universalSearchSlice = createSlice({
    name: 'universalSearch',
    initialState,
    reducers: {
        requestSearch(state, action) {
            getCancelSource().cancel('abort')
            cancelSource = axios.CancelToken.source()

            action.asyncDispatch(searchAdTerm({
                params: action.payload.params
            }))
            action.asyncDispatch(searchAdvertisers({
                params: action.payload.params
            }))
            action.asyncDispatch(searchPublishers({
                params: action.payload.params
            }))
            if (action.payload.settings.has_video) {
                action.asyncDispatch(searchYtChannels({
                    params: action.payload.params
                }))
            }
            if (action.payload.settings.network_dashboard) {
                action.asyncDispatch(searchNetworks({
                    params: action.payload.params
                }))
            }
            if (action.payload.settings.has_categories) {
                action.asyncDispatch(searchCategories({
                    params: action.payload.params
                }))
            }
            if (action.payload.settings.brands) {
                action.asyncDispatch(searchBrands({
                    params: action.payload.params
                }))
            }
            if (action.payload.settings.has_campaigns) {
                action.asyncDispatch(searchCampaigns({
                    params: action.payload.params
                }))
            }
        },
        resetSearch(state) {
            getCancelSource().cancel('abort')
            cancelSource = axios.CancelToken.source()
            return initialState
        }
    },
    extraReducers: {
        [searchAdTerm.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.keywords = action.payload.rows
            }
            else {
                state.keywords = []
            }
        },
        [searchAdvertisers.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.advertisers = action.payload.rows
            }
            else {
                state.advertisers = []
            }
        },
        [searchPublishers.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.publishers = action.payload.rows
            }
            else {
                state.publishers = []
            }
        },
        [searchBrands.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.brands = action.payload.rows
            }
            else {
                state.brands = []
            }
        },
        [searchNetworks.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.networks = action.payload.rows
            }
            else {
                state.networks = []
            }
        },
        [searchYtChannels.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.ytChannels = action.payload.rows
            }
            else {
                state.networks = []
            }
        },
        [searchCategories.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.categories = action.payload.rows.map(item => {
                    let categoryName = item.suggestion.replace(/.*\//, '')
                    return {
                        ...item,
                        category: item.suggestion,
                        suggestion: categoryName
                    }
                })
            }
            else {
                state.categories = []
            }
        },
        [searchCampaigns.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                state.campaigns = action.payload.rows
            }
            else {
                state.campaigns = []
            }
        }
    },
})

const universalSearchSelector = (state) => state.universalSearch

export {universalSearchSelector}
export const {requestSearch, resetSearch} = universalSearchSlice.actions

export default universalSearchSlice.reducer