import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../../../common/axiosRateLimit'

const fetchAdvertiserOverviewTotals = createAsyncThunk(
    'fetchAdvertiserOverviewTotals',
    async (arg) => {
        const request = await axios.get("/data-api/advertisers", {
            params: {
                ...arg.query,
                rows: 1
            }
        })

        let query = arg.query
        let entity = {}
        let keys = ['advertiser']
        keys.forEach(key => {
            if (query.hasOwnProperty(key)) {
                entity[key] = query[key]
                delete query[key]
            }
        })

        const trendlineRequest = axios.get("/data-api/trendlines", {
            params: {
                ...query,
                includeEntities: JSON.stringify(
                    [entity]),
                metrics: 'globalSeenDates',
            }
        })

        const response = await request
        const trendlineResponse = await trendlineRequest

        let result = response.data
        if (response.data.rows.length > 0 && trendlineResponse.data.rows.length > 0) {
            result.rows[0].globalFirstSeen = trendlineResponse.data.rows[0].globalFirstSeen
        }
        return result
    })


const advertiserProfileTotalsSlice = createSlice({
    name: 'totals',
    initialState: {
        status: 'idle',
        data: {},
    },
    reducers: {
        resetTotals(state) {
            state.status = 'idle'
        }
    },
    extraReducers: {
        [fetchAdvertiserOverviewTotals.pending]: (state) => {
            state.status = 'loading'
            state.data = {}
        },
        [fetchAdvertiserOverviewTotals.fulfilled]: (state, action) => {
            state.status = 'done'
            if (action.payload.rows.length > 0) {
                state.data = action.payload.rows[0]
            }
            else {
                state.data = {}
            }
        }
    }
})


const advProfileTotalsSelector = state => state.publisherUiAdvertiserOverview.totals
export {fetchAdvertiserOverviewTotals, advProfileTotalsSelector}
export const {resetTotals} = advertiserProfileTotalsSlice.actions
export default advertiserProfileTotalsSlice.reducer