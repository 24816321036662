import { createSlice } from "@reduxjs/toolkit";

const upgradeModalSlice = createSlice({
    name: 'upgradeModalSlice',
    initialState: {
        name: null,
        params: {}
    },
    reducers: {
        showModal(state, action) {
            state.name = action.payload.name
            state.params = action.payload.params
        },
        hideModal(state) {
            state.name = null
            state.params = {}
        }
    }
})

const upgradeModalSelector = (state) => state.upgradeModal
export {upgradeModalSelector}
export default upgradeModalSlice.reducer
export const {showModal, hideModal} = upgradeModalSlice.actions