import advertiserGroup from './advertiserGroups/advertiserGroupSlice'
import advertiserSpend from './adSpend/advertiserSpendSlice'
import advertiserCreative from './creatives/advertiserCreativeSlice'
import adTypes from "./adType/compareAdTypeSlice";
import advertiserShareOfVoice from './share/advertiserShareOfVoiceSlice'
import report from './report/advertiserCompareReportSlice'
import {combineReducers} from '@reduxjs/toolkit'

const advertiserCompareReducer = combineReducers({
    advertiserGroup,
    advertiserSpend,
    advertiserCreative,
    advertiserShareOfVoice,
    adTypes,
    report
})

export default advertiserCompareReducer
