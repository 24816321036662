import _ from "lodash";
import React, {useEffect, useState} from "react";
import ReactModal from "react-modal";
import {useDispatch, useSelector} from "react-redux"
import {hideModal, modalSelector, showModal} from "../../reducers/modal";
import {settingsSelector} from "../../reducers/settings"
import {showUpgradeModal} from "../modals/UpgradeModal";
import {
    clearErrors,
    fetchCanGenerateReport,
    fetchGenerateProfileReport,
    resetGenerateState,
    selectGenerateReport
} from "./reportsSlice";
import TrialError from "./TrialError";
import {notifyInfo} from "../../reducers/notification";
import PlatformList from "../PlatformList";
import CountryList from "../CountryList";
import DatePicker, {rangeFor} from "../DatePicker";
import SelectMenu from "../SelectMenu";
import {daysToDate} from "../../common/crawlDays";
import {generateProfileReport} from "../../reducers/uiStats";
import LoadingState from "../LoadingState";


const GenerateProfileReportModal = (props) => {
    const settings = useSelector(settingsSelector)
    const generateReport = useSelector(selectGenerateReport)
    const dispatch = useDispatch()

    const [country, setCountry] = useState(props.country)
    const [platform, setPlatform] = useState(props.platform)
    const [dateRange, setDateRange] = useState(props.dateRangePreset)

    const [startDate, endDate] = rangeFor(dateRange,
        daysToDate(settings.first_index_date),
        daysToDate(settings.last_index_date))
    const [metrics, setMetrics] = useState(
        settings.hasSpend ? 'adSpend,adImpressions' : settings.adMetricId
    )

    let availableReportTypes = {
        'pdf': {
            value: 'pdf',
            text: 'PDF',
            icon: 'fa-file-pdf-o',
            allowed: true,
            visible: true
        },
        'pptx': {
            value: 'pptx',
            text: 'PowerPoint',
            icon: 'fa-file-powerpoint-o',
            allowed: settings.pptx.allowed,
            visible: settings.pptx.visible
        },
        'excel': {
            value: 'excel',
            text: 'Excel',
            icon: 'fa-file-excel-o',
            allowed: true,
            visible: true
        }
    };

    const metricOptions = [
        {
            'value': 'adSpend,adImpressions',
            'text': 'Ad Spend & Impressions'
        },
        {
            'value': 'adImpressions',
            'text': 'Ad Impressions'
        }
    ]

    const reportTypes =
        props.types
            .map(function (type) {
                return availableReportTypes[type];
            })
            .filter(d => d)
            .filter(d => d.visible)

    const [reportType, setReportType] = useState(reportTypes[0])

    const setReportTypeValue = (rt) => {
        if (!rt.allowed) {
            showUpgradeModal(dispatch, {
                name: 'reports-pptx',
                value: null
            })
        }
        else {
            setReportType(rt)
        }
    }

    useEffect(() => {
        if (generateReport.status === 'idle') {
            dispatch(fetchCanGenerateReport())
        }
    }, [dispatch, generateReport.status])

    useEffect(() => {
        if (generateReport.generateStatus === 'done' && !generateReport.errors.length) {
            dispatch(notifyInfo('Your report is being generated and will be saved on your Reports page'))
            dispatch(hideModal())
        }
    }, [dispatch, generateReport.generateStatus, generateReport.errors.length])

    if (!settings.exportable_data ||
        (generateReport.status === 'done' &&
            !generateReport.canGenerateReport &&
            !settings.user.is_trial)) {
        showUpgradeModal(dispatch, {
            name: 'reports',
            value: null
        })

        return null
    }

    const modalTitle = props.title
    const {reportTitle, reportName} = props

    const showTrialError = !generateReport.canGenerateReport && settings.user.is_trial

    const errors = generateReport.errors

    const countries = props.profileName === 'campaign' ? settings.defaultCampaignCountries : null

    const handleSubmit = (e) => {
        e.preventDefault()
        if (props.canBeScheduled) {
            dispatch(hideModal())
            const modalParams = {
                ...props.query,
                query: props.query,
                startDate: startDate,
                endDate: endDate,
                country,
                platform,
                metrics,
                profileName: props.profileName,
                profileId: props.profileId,
                reportType: reportType.value,
                title: props.title,
                link: window.location.pathname
            }
            delete modalParams['handleClose']
            dispatch(showModal({
                name: 'report-schedule-profile-dialog',
                params: modalParams
            }))
        } else {
            dispatch(fetchGenerateProfileReport({
                query: {
                    ...props.query,
                    startDate: startDate,
                    endDate: endDate,
                    country,
                    platform,
                    metrics,
                    profileName: props.profileName,
                    profileId: props.profileId,
                    reportType: reportType.value,
                    title: props.title,
                    link: window.location.pathname
                }
            }))

            dispatch(generateProfileReport({
                link: window.location.pathname,
                platform,
                country,
                dateRange
            }))
        }
    }

    return (
        <ReactModal
            onRequestClose={props.handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "fixed"
                },
                content: {
                    bottom: 'auto',
                    borderRadius: 0,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "fixed",
                    padding: 0,
                    overflow: 'none',
                    inset: "80px 40px auto 40px",
                },
            }}
            isOpen={props.isOpen}>
            <div className="query-based-report">
                <LoadingState show={generateReport.generateStatus === 'pending'}/>
                <form method="post"
                      onSubmit={handleSubmit}>

                    {_.map(props.query, (value, name) =>
                        <input type="hidden" value={value} name={name}
                               key={name}/>
                    )}
                    <input type="hidden" name="name" value={reportName}/>
                    <input type="hidden" name="type" value={reportType.value}/>
                    <input type="hidden" name="title" value={reportTitle}/>
                    <input type="hidden" name="link" data-bind=""/>

                    <div className="section-header">
                        <h1 className="left">{modalTitle}</h1>
                        <div className="right close"
                             onClick={props.handleClose}>
                            <i className="fa-light fa-sharp fa-close"></i>
                        </div>
                    </div>

                    {!showTrialError &&
                    <>
                        <div
                            className="section-body filters profile-header report-type">
                            <h2 className="title">Which report format would you
                                like to generate?</h2>

                            <div className="filters">
                                <div className="property no-border">
                                    <div className="tight-arrow">
                                        <PlatformList
                                            onChange={setPlatform}
                                            value={platform}
                                        />
                                    </div>
                                </div>
                                <div className="property no-border">
                                    <div>
                                        <CountryList
                                            countries={countries}
                                            onChange={setCountry}
                                            value={country}
                                        />
                                    </div>
                                </div>
                                <div className="date-range property no-border">
                                    <DatePicker 
                                        key={dateRange}
                                        preset={dateRange}
                                        onChange={(d) => {
                                            setDateRange(d.dateRangePreset)
                                        }}/>
                                </div>

                                {settings.hasSpend && (
                                    <div className="property no-border">
                                        <div className="tight-arrow"
                                             style={{minWidth: 182}}>
                                            <SelectMenu
                                                name="adMetricId"
                                                options={metricOptions}
                                                value={metrics}
                                                onChange={setMetrics}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <h2 className="buttons">
                                {reportTypes.map(rt =>
                                    <div
                                        className={"report-button " + (reportType.value === rt.value ? 'active' : 'inactive')}
                                        onClick={() => setReportTypeValue(rt)}
                                        key={rt.value}
                                        data-value={rt.allowed ? '' : settings.upgradeRequiredValue + 'pptx'}
                                    >
                                        <div>
                                            <i className={"fa-light fa-sharp fa-3x " + rt.icon}></i>
                                        </div>
                                        <div>
                                            <span>{rt.text}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="clear"></div>
                            </h2>
                        </div>

                        <div className="section-footer clear">
                            {props.canBeScheduled &&
                                <button className="save-alert">Next</button>
                            }
                            {!props.canBeScheduled &&
                                <button className="save-alert">Generate Report</button>
                            }
                            <span className={
                                "error" + (errors.length > 0 ? "" : " hidden")
                            }>
                            {errors.map(e =>
                                <>
                                    <span
                                        className="pointer"
                                        onClick={() => dispatch(clearErrors())}
                                        dangerouslySetInnerHTML={{__html: e}}
                                    ></span><br/>
                                </>
                            )}
                        </span>
                        </div>
                    </>}

                    {showTrialError &&
                    <TrialError
                        onClick={() => dispatch(clearErrors())}
                    ></TrialError>
                    }
                </form>
            </div>
        </ReactModal>
    );
}

const modalName = 'profileReport'

const Wrapper = () => {
    const modal = useSelector(modalSelector)
    const dispatch = useDispatch()

    if (modal.name === modalName) {
        return <GenerateProfileReportModal {...modal.params}
               isOpen={modal.name === modalName}
               handleClose={() => dispatch(hideModal())}></GenerateProfileReportModal>
    }
    return null
}


const showProfileReportModal = (dispatch, params) => {
    dispatch(resetGenerateState())
    dispatch(showModal({
        name: modalName,
        params
    }))
}
export {showProfileReportModal}
export default Wrapper;
