class AdTypesListModel {
    constructor() {
        this.adTypesList = [
            {id: 'all', name: 'Ad Types'},
            {id: 'html5,image,text,textimg', name: 'Ad Types'},
            {id: 'html5', name: 'HTML5'},
            {id: 'image', name: 'Image'},
            {id: 'text', name: 'Text'},
            {id: 'textimg', name: 'Text/Image'},
            {id: 'video', name: 'Video'}
        ]

        this.adTypeIds = this.adTypesList
            .map(item => item.id)
            .filter(item => item.indexOf(',') === -1)

        let mapping = {}
        this.adTypesList.forEach(item => {
            mapping[item.id] = item.name
        })

        this.mapping = mapping
    }
}

export default AdTypesListModel