import ReactModal from "react-modal";
import Scrollbars from 'react-custom-scrollbars'
import useTooltip from "../../hooks/useTooltip";
import { useDispatch, useSelector } from 'react-redux'
import { settingsSelector } from "../../reducers/settings"
import Platforms from '../../common/platforms'
import Networks from '../../common/networks'
import { daysToDate } from '../../common/crawlDays'
import Trend from '../Trend'
import TrendLine from "../TrendLine"
import DestinationUrls from "../../pages/top-ads/DestinationUrls"
import TopPublishers from '../../pages/top-ads/TopPublishers';
import TopChannels from '../../pages/top-ads/TopChannels';
import React, { useEffect, useState } from "react";
import Html5Preview from "../../common/html5preview";
import {
    advertiserInfoSelector,
    fetchAdvertiserInfo
} from "../../reducers/curation";
import {
    guessFormat,
    useSettings as useSettingsWithCurrency
} from "../../misc/currency";
import { format } from "date-fns";
import { hideModal, modalSelector, showModal } from "../../reducers/modal";
import { showAddToCollectionModal } from "./AdLocker";
import { showConfirmationModal } from "./ConfirmAction";
import {
    removeAd,
    resetCollectionAds
} from "../../pages/ad-locker/adLockerSlice";
import axios from "../../common/axiosRateLimit";
import LoadingState from "../LoadingState";
import AppearsOn from "../AppearsOn";
import firstSeenAdjustedForPermissions from '../../misc/firstSeenAdjustedForPermissions'

function adSizeName(ad, settings) {
    if (ad.adInfo.adType === 'text' || ad.adInfo.adType === 'textimg') {
        return settings.adTypeMapping[ad.adInfo.adType];
    }
    if (ad.adInfo.adSize) {
        return settings.adSizeMap[ad.adInfo.adSize];
    }
    return ad.adInfo.adSize
}

function secondsToDuration(seconds) { 
    var mm = Math.floor(seconds / 60);
    var ss = Math.round(seconds % 60);

    if (mm < 10) {
        mm = '0' + mm;
    }
    if (ss < 10) {
        ss = '0' + ss;
    }
    return { ss, mm }
}

ReactModal.setAppElement("#root");

const Modal = (props) => {
    const settings = useSelector(settingsSelector)
    const dispatch = useDispatch()
    useSettingsWithCurrency(settings)

    const [html5preview, setHtml5preview] = useState(null)

    const platforms = new Platforms(
        settings.platforms, settings.allowed.platforms, settings)
    const networks = new Networks(
        settings.networks, settings.allowed.networks, {}, settings)
    const tooltip = useTooltip("ad-modal-tooltip");
    const [videoDuration, setVideoDuration] = useState('00:00')

    let ad = props.ad
    const advertiser = ad && ad.adInfo ? ad.adInfo.advertiser : null
    const advertiserInfo = useSelector(advertiserInfoSelector(advertiser))

    useEffect(() => {
        if (advertiser && advertiserInfo.status === 'idle') {
            dispatch(fetchAdvertiserInfo({ advertiser: ad.adInfo.advertiser }))
        }
    })

    const [trend, setTrend] = useState(
        ad.trendlineStatus === 'done'
            ? {
                trendLine: ad.trendLine,
                trendlineStatus: 'done'
            }
            : {
                trendLine: null,
                trendlineStatus: 'idle'
            }
    )

    useEffect(() => {
        const fetchAdTrend = (query, adHash) => {
            setTrend({
                trendlineStatus: 'pending',
                trendLine: null
            })
            let params = {
                ...query,
                includeEntities: JSON.stringify([{ adHash }])
            }

            axios.get("/data-api/trendlines", {
                params
            }).then(response => {
                setTrend({
                    trendLine: response.data.rows[0],
                    trendlineStatus: 'done'
                })
            })
        }

        if (trend.trendlineStatus === 'idle') {
            fetchAdTrend(
                {
                    country: props.country,
                    platform: props.platform,
                    startDate: props.startDate,
                    endDate: props.endDate,
                    network: props.network,
                    metrics: settings.metricsList + ',globalSeenDates'
                },
                props.ad.adHash
            )
        }
    }, [trend.trendlineStatus, props, settings])

    const handleDurationChange = (e) => {
        const seconds = e.target.duration || 0

        const {mm, ss} = secondsToDuration(seconds)

        setVideoDuration(mm + ':' + ss)
    }


    const [areChannelsShown, setAreChannelsShown] = useState(false)
    const [arePublishersShown, setArePublishersShown] = useState(
        props.hasAppearsOn === true)
    const [areDestinationUrlsShown, setAreDestinationUrlsShown] = useState(
        props.hasAppearsOn ? false : true)

    const toggleChannels = () => {
        setAreChannelsShown(!areChannelsShown)
        setAreDestinationUrlsShown(false)
        setArePublishersShown(false)
    }

    const togglePublishers = () => {
        setArePublishersShown(!arePublishersShown)
        setAreChannelsShown(false)
        setAreDestinationUrlsShown(false)
    }

    const toggleDestinationUrls = () => {
        setAreDestinationUrlsShown(!areDestinationUrlsShown)
        setAreChannelsShown(false)
        setArePublishersShown(false)
    }


    let scaleFactor,
        scaleFactorWidth = 1,
        scaleFactorHeight = 1,
        maxWidth = 440,
        maxHeight = 250;

    ad = {
        ...ad,
        imageWidth: ad.adInfo.preview ? ad.adInfo.preview.width : undefined,
        imageHeight: ad.adInfo.preview ? ad.adInfo.preview.height : undefined,
    };

    if (ad.imageWidth && ad.imageWidth > maxWidth) {
        scaleFactorWidth = maxWidth / ad.imageWidth;
    }
    if (ad.imageHeight && ad.imageHeight > maxHeight) {
        scaleFactorHeight = maxHeight / ad.imageHeight;
    }
    scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

    ad = {
        ...ad,
        creativeWidth:
            ad.imageWidth !== undefined ? ad.imageWidth * scaleFactor + "px" : "auto",
        creativeHeight:
            ad.imageHeight !== undefined
                ? ad.imageHeight * scaleFactor + "px"
                : "auto",
        negHalfCreativeHeight:
            ad.imageHeight !== undefined
                ? ad.imageHeight * scaleFactor * -0.5 + "px"
                : "-80px",
    };

    function _adSizeName(ad) {
        return  adSizeName(ad, settings);
    }

    const attachmentPath = [
        '/callisto/attachment',
        ad.adInfo.advertiser,
        (ad.adInfo.httpsCreativeUrl || '').split('/').pop()
    ].join('/');

    function platformName(platform) {
        return platforms.platformIdToName(platform);
    }

    function networkName(network) {
        return networks.mapping[network];
    }

    const handleClose = () => {
        if (html5preview) {
            html5preview.stopLoadingAndAnimation()
        }
        props.handleClose()
    }

    const handleSaveToLocker = () => {
        showAddToCollectionModal(dispatch,
            {
                ad: {
                    ...ad,
                    ...trend
                },
                country: props.country,
                platform: props.platform
            })
    }

    const handleRemoveFromLocker = () => {
        showConfirmationModal(dispatch,
            {
                message: 'Are you sure you want to delete this ad?',
                button: "Yes, delete it!",
            },
            () => {
                dispatch(removeAd({
                    query: {
                        id: ad.uri.split('/').pop()
                    },
                    settings
                }))
                dispatch(resetCollectionAds())
                dispatch(hideModal())
            })
    }

    const textContent = <>
        <p className="ad-title clear"
            dangerouslySetInnerHTML={{ __html: `${ad.adInfo.header}` }}></p>
        <p className="ad-body"
            dangerouslySetInnerHTML={{ __html: `${ad.adInfo.body}` }}></p>
        <p className="ad-display-url">
            <a href={"http://" + ad.adInfo.displayUrl}
                target="_blank"
                rel="noreferrer">{ad.adInfo.displayUrl}</a>
        </p>
    </>

    return (
        <ReactModal
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "absolute",
                    height: document.documentElement.scrollHeight,
                },
                content: {
                    bottom: "auto",
                    top: (window.scrollY || window.pageYOffset) + 20,
                    borderRadius: 0,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "absolute",
                    padding: 0,
                    inset: ((window.scrollY || window.pageYOffset) + 20) +
                            "px 40px auto 40px",
                },
            }}
            isOpen={props.isOpen}
        >
            <Scrollbars
                autoHeight
                autoHeightMax={window.innerHeight - 100}>
                <div className="ad-modal">
                    <div className="section-header">
                        <h1 className="left">{advertiserInfo.fullName}</h1>
                        <div className="help-icon left" data-tip
                            data-for={tooltip.path}>
                            <i className="fa-sharp fa-light fa-circle-question fa-2x"></i>
                        </div>
                        <div className="right close"
                            onClick={props.handleClose}>
                            <i className="fa-light fa-sharp fa-close"></i>
                        </div>
                    </div>
                    <div className="section-body" style={{ position: "relative" }}>
                        <LoadingState show={trend.trendlineStatus === 'pending'} />

                        <div className="creative-and-details-container"
                            ad-hash={ad.adHash}>
                            <div className="row creative-and-details">
                                <div
                                    className={
                                        "creative-container " +
                                        (new Map([
                                            ["text", "text-ad"],
                                            ["textimg", "text-img-ad"],
                                        ]).get(ad.adInfo.adType) || "")
                                    }
                                    style={{ width: ad.creativeWidth }}
                                >
                                    <div
                                        className="creative"
                                        style={{
                                            width: ad.creativeWidth,
                                            height: ad.creativeHeight,
                                            marginTop: ad.adInfo.adType === 'textimg' ? 0 : ad.negHalfCreativeHeight
                                        }}
                                    >
                                        {ad.adInfo.adType === 'video' &&
                                            <video
                                                onDurationChange={handleDurationChange}
                                                src={ad.adInfo.httpsCreativeUrl}
                                                style={{
                                                    width: ad.creativeWidth,
                                                    height: ad.creativeHeight
                                                }}
                                                controls></video>}

                                        {ad.adInfo.adType === 'html5' &&
                                            <Html5Preview ad={ad}
                                                setPreview={setHtml5preview}></Html5Preview>
                                        }

                                        {ad.adInfo.adType === 'text' &&
                                            <div className="type-text">
                                                {textContent}
                                            </div>}

                                        {(ad.adInfo.adType === 'image' || ad.adInfo.adType === 'textimg') &&
                                            <div className="type-image">
                                                <div
                                                    className="tooltip-image-wrapper">
                                                    <img
                                                        src={ad.adInfo.preview.full}
                                                        width={ad.creativeWidth}
                                                        alt="ad" />
                                                </div>
                                            </div>}
                                    </div>

                                    {ad.adInfo.adType === 'textimg' &&
                                        <div className="textimg">
                                            {textContent}
                                        </div>}
                                </div>
                                <div
                                    className={"ad-details " + (ad.adInfo.adType === 'textimg' ? 'text-img-ad' : '')}
                                >
                                    <div className="details">
                                        <p className="advertiserId">
                                            <span
                                                className="profile-link-container">
                                                <a
                                                    className="profile-external-link togglable"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={ad.adInfo.advertiser}
                                                >
                                                    <i className="fa-light fa-sharp fa-external-link"></i>
                                                </a>
                                                <a
                                                    className="profile-link pointer"
                                                    href={`/advertiser/${props.country}/${props.platform}/${ad.adInfo.advertiser}`}
                                                >
                                                    {ad.adInfo.advertiser}
                                                </a>
                                            </span>
                                        </p>
                                        <p>
                                            {ad.adInfo.adType === 'video' &&
                                                <>
                                                    <span>Video </span>
                                                    <span>
                                                        ({videoDuration})&nbsp;
                                                    </span>
                                                </>}
                                            <span className="size">
                                                {_adSizeName(ad)}
                                            </span>
                                            <span> {platformName(props.platform)}</span>
                                            <span> ad </span>
                                            {trend.trendlineStatus === 'done' &&
                                                trend.trendLine &&
                                                trend.trendLine.trends &&
                                                trend.trendLine.trends.hasOwnProperty(settings.metricField) &&
                                                [30, 90].includes(trend.trendLine.trends[settings.metricField].length) &&
                                                <Trend trend={trend.trendLine.trends[settings.metricField]}></Trend>}
                                        </p>
                                        <p>
                                            {settings.hasSpend &&
                                                <span className="ad-metric">
                                                    <span
                                                        className="title">Spend </span>
                                                    <span
                                                        className="value capitalized">
                                                        {guessFormat(ad.sumAdSpend, "$0.[0]a", props.country)}
                                                    </span>
                                                </span>}
                                            <span className="ad-metric">
                                                <span
                                                    className="title">{settings.hasSpend ? "Impr" : "Impressions"} </span>
                                                <span
                                                    className="value capitalized"
                                                >
                                                    {guessFormat(ad.sumImpressions, "0.[0]a")}
                                                </span>
                                            </span>
                                            <span className="separator"></span>

                                            {props.network && (
                                                <span
                                                    className="networks">Networks: <span
                                                        className="value tooltipstered"
                                                        data-bind="text: networkName(), title: networkName()"
                                                    >
                                                        {networkName(props.network)}
                                                    </span>
                                                </span>
                                            )}
                                        </p>
                                        <div className="date-range">
                                            {trend.trendlineStatus === 'done' &&
                                                <>
                                                    <p>Seen from </p>
                                                    <p>
                                                        <span
                                                            className="first-seen"
                                                        >
                                                            {format(daysToDate(
                                                                firstSeenAdjustedForPermissions(
                                                                    trend.trendLine.globalFirstSeen, settings)), 'MMMM dd, yyyy')}
                                                        </span>
                                                        <span> to </span>
                                                        <span
                                                            className="last-seen"
                                                        >
                                                            {format(daysToDate(trend.trendLine.globalLastSeen), 'MMMM dd, yyyy')}
                                                        </span>
                                                    </p>
                                                </>}
                                        </div>
                                        <div className="bar-chart-wrapper">
                                            {trend.trendlineStatus === 'done' &&
                                                <TrendLine
                                                    hideTrend={true}
                                                    data={trend}
                                                    width={265}
                                                    height={31}
                                                    containerHeight={35}></TrendLine>}
                                        </div>

                                        {props.hasAppearsOn && <AppearsOn ad={ad}
                                            publisher={props.publisher}
                                            network={props.network}></AppearsOn>}

                                        <div className="controls">
                                            {['image', 'textimg', 'video'].includes(ad.adInfo.adType) &&

                                                <a href={attachmentPath} download>
                                                    <div
                                                        className="button download-creative">
                                                        Download Ad
                                                    </div>
                                                </a>
                                            }
                                            {props.forLocker === undefined &&
                                                <div
                                                    onClick={handleSaveToLocker}
                                                    className="button save-to-ad-locker"
                                                >
                                                    Save this Ad
                                                </div>}
                                            {props.forLocker === true &&
                                                <div
                                                    onClick={handleRemoveFromLocker}
                                                    className="button inactive remove-from-ad-locker"
                                                >
                                                    Remove this Ad
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>

                        <div className="row unfoldables-container">
                            <div
                                className="unfoldable-links details"
                            >
                                <p>
                                    <span>
                                        <a href="/" onClick={(e) => {
                                            e.preventDefault();
                                            toggleDestinationUrls();
                                        }}>
                                            <span></span> Landing Pages
                                        </a>
                                    </span>
                                    <span className="separator"></span>
                                    <span>
                                        <a href="/" onClick={(e) => {
                                            e.preventDefault();
                                            togglePublishers()
                                        }}>
                                            <span></span> {(props.publisher && (props.publisher !== 'all')) ? 'All' : 'Top'} Publishers
                                        </a>
                                    </span>
                                    {ad.adInfo.adType === 'video' &&
                                        <>
                                            <span className="separator"></span>
                                            <span>
                                                <a href="/" onClick={(e) => {
                                                    e.preventDefault();
                                                    toggleChannels()
                                                }}>
                                                    <span></span> Channels
                                                </a>
                                            </span>
                                        </>}
                                </p>
                            </div>
                            <div
                                className="unfoldables"
                            >
                                <DestinationUrls show={areDestinationUrlsShown}
                                    adHash={ad.adHash}
                                    channel={props.channel}
                                    publisher={props.publisher}
                                    advertiser={ad.adInfo.advertiser}
                                    platform={props.platform}
                                    country={props.country}
                                    network={props.network}
                                    startDate={props.startDate}
                                    endDate={props.endDate}
                                    campaign={props.campaign}
                                    brand={props.brand}
                                    setCurrentModal={props.setCurrentModal}></DestinationUrls>
                                <TopPublishers show={arePublishersShown}
                                    adHash={ad.adHash}
                                    channel={props.channel}
                                    publisher={props.publisher}
                                    advertiser={ad.adInfo.advertiser}
                                    platform={props.platform}
                                    network={props.network}
                                    country={props.country}
                                    startDate={props.startDate}
                                    endDate={props.endDate}
                                    campaign={props.campaign}
                                    brand={props.brand}
                                    setCurrentModal={props.setCurrentModal}></TopPublishers>
                                <TopChannels show={areChannelsShown}
                                    adHash={ad.adHash}
                                    publisher={props.publisher}
                                    advertiser={ad.adInfo.advertiser}
                                    platform={props.platform}
                                    country={props.country}
                                    network={props.network}
                                    startDate={props.startDate}
                                    endDate={props.endDate}
                                    campaign={props.campaign}
                                    brand={props.brand}
                                    setCurrentModal={props.setCurrentModal}></TopChannels>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
            {tooltip.component}
        </ReactModal>
    );
};

const modalName = 'ad-modal'

const Wrapper = (props) => {
    const modal = useSelector(modalSelector)
    const dispatch = useDispatch()

    if (modal.name === modalName) {
        return <Modal {...modal.params}
            isOpen={modal.name === modalName}
            handleClose={() => dispatch(hideModal())}></Modal>
    }
    return null
}

const showAdModal = (dispatch, params) => {
    dispatch(showModal({
        name: modalName,
        params
    }))
}
export { showAdModal, adSizeName, secondsToDuration }

export default Wrapper;
