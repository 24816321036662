import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchTopPlacementUrls = createAsyncThunk('fetchTopPlacementUrls',
    async (arg) => {
        const response = await axios.get("/data-api/placementUrls", {
            params: {
                ...arg.query
            }
        })

        return response.data
    })

const TopPlacementUrlsSlice = createSlice({
    name: 'topAdvertisersPublishers',
    initialState: {},
    extraReducers: {
        [fetchTopPlacementUrls.pending]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: [],
                status: 'pending'
            }
        },
        [fetchTopPlacementUrls.fulfilled]: (state, action) => {
            let key = action.meta.arg.query.advertiser + action.meta.arg.query.network
            state[key] = {
                rows: action.payload.rows,
                status: 'done'
            }
        }
    }
})

export const selectTopPlacementUrls = (advertiser, network) => (state) => {
    let key = advertiser + network
    if (key in state.topAdvertisers.placementUrls) {
        return state.topAdvertisers.placementUrls[key]
    } else {
        return {
            rows: [],
            status: 'idle'
        }
    }
}

export {fetchTopPlacementUrls}
export default TopPlacementUrlsSlice.reducer