import { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux';
import {
    createCollection, fetchCollections,
    addToCollection,
    fetchCollectionTotals,
    resetCollectionList,
    resetCreateCollection,
    selectAdLocker,
    resetAddAd
} from '../../pages/ad-locker/adLockerSlice'
import { hideModal, modalSelector, showModal } from '../../reducers/modal';
import { notifyInfo } from '../../reducers/notification';
import { settingsSelector } from '../../reducers/settings';
import SmallAd from "../../pages/top-advertisers/SmallAd";
import usePrevious from '../../hooks/usePrevious'
import LoadingState from '../LoadingState';
import { showUpgradeModal } from './UpgradeModal';
import _ from 'lodash'
import { savedAd } from '../../misc/effinSegment';
import '../../styles/_ad_locker.scss'
import prepareAd from '../../common/prepareAd';


const CreateCollection = (props) => {
    const [collectionName, setCollectionName] = useState('')
    const [error, setError] = useState('')
    const [scrollHeight, setScrollHeight] = useState(document.body.scrollHeight)
    const modal = useSelector(modalSelector)
    const settings = useSelector(settingsSelector)
    const adLocker = useSelector(selectAdLocker)
    const dispatch = useDispatch()
    const isOpen = modal.name === 'create-collection'

    const needsToUpgrade = settings.ad_locker_collection_limit <=
        adLocker.totals.collections;

    const handleClose = useCallback(() => {
        if (modal.params.showSaveAd) {
            showAddToCollectionModal(dispatch, modal.params.showSaveAd)
        }
        else {
            dispatch(hideModal())
        }
        setError('')
    }, [dispatch, modal.params])

    const saveCollection = () => {
        dispatch(createCollection({
            query: {
                name: collectionName,
            },
            settings
        }))
    }

    useEffect(() => {
        setError('')
    }, [collectionName])

    useEffect(() => {
        const onScroll = _.throttle(() => {
            setScrollHeight(document.body.scrollHeight)
        }, 300)
        window.addEventListener('scroll', onScroll)
    })

    useEffect(() => {
        if (isOpen && adLocker.createCollectionStatus === 'done') {
            dispatch(notifyInfo('Your new ad collection has been created'))
            dispatch(resetCreateCollection())
            dispatch(resetCollectionList())
            handleClose()
        }
    }, [dispatch, handleClose, isOpen,
        adLocker.createCollectionStatus,
        adLocker.createCollectionResult])

    useEffect(() => {
        if (adLocker.createCollectionStatus === 'failed') {
            setError(adLocker.createCollectionResult)
        }
    }, [adLocker.createCollectionResult,
    adLocker.createCollectionStatus])

    if (isOpen && needsToUpgrade) {
        showUpgradeModal(dispatch, {
            name: 'adCollections',
            value: true
        })
        return null;
    }

    return (
        <ReactModal
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "fixed",
                    height: scrollHeight,
                },
                content: {
                    borderRadius: 0,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "fixed",
                    padding: 0,
                    overflow: 'hidden',
                    inset: "80px 40px auto 40px",
                },
            }}
            isOpen={isOpen}>
            <div className="add-collection-modal">
                <div className="section-header">
                    <h1 className="left">Create a new collection</h1>
                    <div className="right close"
                        onClick={handleClose}>
                        <i className="fa-light fa-sharp fa-close"></i>
                    </div>
                </div>

                <div className="section-body">
                    <div className="row">
                        <label htmlFor="collection_name">Enter a collection name below.</label>
                    </div>
                    <div className="row">
                        <input name="collection_name" type="text" value={collectionName} placeholder="My Top Ads"
                            onChange={(e) => setCollectionName(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && saveCollection()} />
                    </div>
                    <div className="row error">
                        {error.length > 0 &&
                            <p>{error}</p>}
                    </div>
                </div>

                <div className="section-footer clear">
                    <div className="button"
                        onClick={saveCollection}>Create a new collection</div>
                    <div className="clear"></div>
                </div>
            </div>
        </ReactModal>
    );
}

const showCreateCollectionModal = (dispatch, params) => {
    params = params || {}
    dispatch(showModal({
        name: 'create-collection',
        params
    }))
}

const AddToCollection = (props) => {
    const modal = useSelector(modalSelector)
    const dispatch = useDispatch()
    const settings = useSelector(settingsSelector)
    const adLocker = useSelector(selectAdLocker)
    const [error, setError] = useState('')
    const [q, setQ] = useState('')
    const [scrollHeight, setScrollHeight] = useState(document.body.scrollHeight)

    const isOpen = modal.name === 'add-to-collection'
    const prevIsOpen = usePrevious(isOpen)

    useEffect(() => {
        if (isOpen && adLocker.totalsStatus === 'idle') {
            dispatch(fetchCollectionTotals({ settings }))
        }
    }, [adLocker.totalsStatus, isOpen, dispatch, settings])

    useEffect(() => {
        if (isOpen && !prevIsOpen) {
            setQ('')
            dispatch(resetAddAd())
            dispatch(resetCollectionList())
        }
    }, [dispatch, isOpen, setError, prevIsOpen])

    useEffect(() => {
        const onScroll = _.throttle(() => {
            setScrollHeight(document.body.scrollHeight)
        }, 300)
        window.addEventListener('scroll', onScroll)
    })

    const pageSize = 5
    const [page, setPage] = useState(0)
    const prevPage = usePrevious(page)

    const goToPrevPage = () => {
        setPage(Math.max(0, page - 1))
    }

    const goToNextPage = () => {
        setPage(Math.min(
            Math.floor(
                adLocker.totals.collections / pageSize), page + 1))
    }

    useEffect(() => {
        if (page !== prevPage) {
            dispatch(resetCollectionList())
        }
    }, [dispatch, page, prevPage])

    useEffect(() => {
        if (isOpen && adLocker.status === 'idle') {
            dispatch(fetchCollections({
                query: {
                    q,
                    ads: 4,
                    pageSize,
                    page
                },
                settings
            }))
        }
    })

    useEffect(() => {
        if (isOpen && adLocker.addAdError) {
            setError(adLocker.addAdError)
            dispatch(resetAddAd())
        }
    }, [isOpen, adLocker.addAdError, dispatch])

    const handleClose = useCallback(() => {
        dispatch(hideModal())
        setError('')
    }, [dispatch])

    const handleAddToCollection = (collection) => {
        setError('')
        savedAd(modal.params.ad, collection)
        dispatch(addToCollection({
            ad: modal.params.ad,
            country: modal.params.country,
            platform: modal.params.platform,
            collection,
            settings,
            dispatch
        }))
    }


    const hasPrevPage = adLocker.totals.collections > 0 && page > 0
    const hasNextPage = adLocker.totals.collections > 0 &&
        page < Math.floor(adLocker.totals.collections / pageSize)

    if (isOpen && adLocker.totalsStatus === 'done'
        && adLocker.totals.collections === 0) {
        showCreateCollectionModal(dispatch, { showSaveAd: modal.params })
        return null;
    }

    return (
        <ReactModal
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "absolute",
                    height: Math.max(scrollHeight, document.documentElement.scrollHeight)
                },
                content: {
                    borderRadius: 0,
                    top: (window.scrollY || window.pageYOffset) + 20,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "absolute",
                    padding: 0,
                    bottom: "auto",
                    inset: ((window.scrollY || window.pageYOffset) + 20) +
                            "px 40px auto 40px",
                },
            }}
            isOpen={isOpen}>
            <div className="add-to-collection-modal">
                <div className="section-header">
                    <h1 className="left">Add to a collection</h1>
                    <div className="right close"
                        onClick={handleClose}>
                        <i className="fa-light fa-sharp fa-close"></i>
                    </div>
                </div>

                <div className="section-body">
                    <div className="search-for-ad-collection">
                        <div className="search-row first">
                            <input name="" type="text" value={q}
                                onChange={(e) => setQ(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        dispatch(resetCollectionList())
                                    }
                                }}
                                placeholder="Search for a collection" />
                            <i className="fa-light fa-sharp fa-search"></i>
                        </div>


                        <div className="collection-list">

                            <LoadingState show={adLocker.status === 'pending'}></LoadingState>

                            {adLocker.collections.map(collection =>
                                <div className="search-row" key={collection.name}>
                                    <div className="name">{collection.name}</div>

                                    <div className="action">
                                        <div className="green-button"
                                            onClick={() => handleAddToCollection(collection)}>Add</div>
                                    </div>

                                    <div className="ads">
                                        {collection.ads.map(ad =>
                                            <div className="tiny-ad left" key={ad.adHash}>
                                                <SmallAd ad={prepareAd(ad)}
                                                    width={42}
                                                    height={42}
                                                    withModal={false}></SmallAd>
                                            </div>
                                        )}
                                    </div>

                                    <div className="clear"></div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="pagination">
                        {hasPrevPage &&
                            <span className="prev" onClick={goToPrevPage}><i className="fa-light fa-sharp fa-chevron-up"></i></span>}
                        {hasNextPage &&
                            <span className="next" onClick={goToNextPage}><i className="fa-light fa-sharp fa-chevron-down"></i></span>}
                    </div>

                    {error && <div className="error">
                        <p>{error}</p>
                    </div>}

                    <div className="or">
                        OR
                    </div>
                </div>

                <div className="section-footer clear">
                    <div className="button left" onClick={() => showCreateCollectionModal(dispatch, { showSaveAd: modal.params })}>
                        Create a new collection
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </ReactModal>
    );
}

const showAddToCollectionModal = (dispatch, params) => {
    params = params || {}
    dispatch(showModal({
        name: 'add-to-collection',
        params
    }))
}

export {
    showCreateCollectionModal,
    showAddToCollectionModal,
    AddToCollection
}

export default CreateCollection