import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        type: 'info',
        message: '',
        isVisible: false
    },
    reducers: {
        notifyInfo(state, action) {
            state.type = 'info'
            state.message = action.payload
            state.isVisible = true
        },
        notifyError(state, action) {
            state.type = 'error'
            state.message = action.payload
            state.isVisible = true
        },
        hide(state) {
            state.isVisible = false
        }
    },
})

const notificationSelector = state => state.notification

export const {notifyError, notifyInfo, hide} = notificationSlice.actions;
export {notificationSelector}
export default notificationSlice.reducer