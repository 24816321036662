import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import settings from './reducers/settings'
import tooltips from './reducers/tooltips'
import bookmark from './reducers/bookmark'
import headerProducts from './reducers/headerProducts'
import pathTypes from './reducers/pathTypes'
import upgradeFlow from './components/upgrade/upgradeSlice'
import topAds from './pages/top-ads/topAdsReducer'
import topAdvertisers from './pages/top-advertisers/topAdvertisersReducer'
import curation from './reducers/curation'
import searches from './pages/search/searchReducer'
import topAdsMenu from './pages/top-ads/menu/menuReducer'
import generateReport from './components/reports/reportsSlice'
import adLocker from './pages/ad-locker/adLockerSlice'
import reports from './pages/reports/reportsSlice'
import moversAndShakers from './pages/movers-and-shakers/moversAndShakersSlice'
import alerts from './pages/alerts/alertsSlice'
import genericScheduledReports from './pages/scheduled-reports/scheduledReportsSlice'
import inbox from './pages/inbox/inboxReducer'
import advertiserCategoriesTool from './pages/tools/advertiserCategoryExplorer/advertiserCategoryToolSlice'
import advertiserEnrichTool from './pages/tools/advertiserDataEnrich/advertiserDataEnrichSlice'
import advertiserDmaReportsTool from './pages/tools/topAdvertisersDmaReports/topAdvertisersDmaReportsSlice'
import verticalReportTool from './pages/tools/verticalReport/verticalReportSlice'
import advertiserCompareTool from './pages/tools/advertiserCompare/advertiserCompareReducer'
import groupManager from './pages/tools/groupManager/groupManagerReducer'
import followAdvertiser from './components/alerts/followAdvertiserSlice'
import followAdvertiserDashboard from './components/alerts/userDashboardSlice'
import followTopAds from './components/alerts/followTopAdsSlice'
import modal from './reducers/modal'
import upgradeModal from './reducers/upgradeModal'
import notification from './reducers/notification'
import paymentMethodNotice from './reducers/paymentMethodNotice';
import advertiserProfile from './pages/advertiser-profile/advertiserReducer'
import brandProfile from './pages/brand-profile/brandReducer'
import campaignProfile from './pages/campaign-profile/campaignReducer'
import publisherProfile from './pages/publisher-profile/publisherReducer'
import channelProfile from './pages/channel-profile/channelReducer'
import networkProfile from './pages/network/networkProfileReducer'
import bookmarks from './pages/bookmarks/bookmarksSlice'
import publisherUiOverview from './pages/publisher_ui/overview/overviewReducer'
import publisherUi from './pages/publisher_ui/reducers/publisherUiReducer'
import publisherUiNewBusiness from './pages/publisher_ui/newBusiness/slices/newBusinessReducer'
import publisherUiLargestAdvertisers from './pages/publisher_ui/largestAdvertisers/slices/largestAdvertisersReducer'
import publisherUiCreatives from './pages/publisher_ui/creatives/slices/creativesReducer'
import publisherUiCompetitiveSets from './pages/publisher_ui/competitiveSets/slices/competitiveSetsReducer'
import publisherUiScheduledReports from './pages/publisher_ui/scheduledReports/scheduledReportsSlice'
import publisherUiAdvertiserOverview from './pages/publisher_ui/advertiserOverview/slices/advertiserOverviewReducer'
import userDashboardAdvertiser from './pages/user-dashboard/advertisers/userDashboardAdvertiserSlice'
import userDashboardAdvertiserChannels from './pages/user-dashboard/advertisers/userDashboardAdvertiserChannelsSlice'
import userDashboardAdEventDetailsSlice from './pages/user-dashboard/advertisers/userDashboardAdEventDetailsSlice'
import runSearch from './reducers/search'
import universalSearch from './components/searchbox/universalSearchSlice'
import performance from './reducers/performance'
import stats from './reducers/uiStats'
import axios from './common/axiosRateLimit'
import asyncDispatchMiddleware from "./common/asyncDispatchMiddleware";
import _ from 'lodash';
import userDashboardPublisherDetailsSlice from './pages/user-dashboard/advertisers/userDashboardPublisherDetailsSlice';
import userDashboardCategoryDetailsSlice from './pages/user-dashboard/publishers/userDashboardCategoryDetailsSlice';
import userDashboardAdvertiserDetailsSlice from './pages/user-dashboard/publishers/userDashboardAdvertiserEventDetailsSlice';
import userDashboardCampaignDetailsSlice from './pages/user-dashboard/advertisers/userDashboardCampaignDetailsSlice';
import userDashboardNetworkDetailsSlice from './pages/user-dashboard/advertisers/userDashboardNetworkDetailsSlice';
import userDashboardPublisherSlice from './pages/user-dashboard/publishers/userDashboardPublisherSlice';
import userDashboardPubNetworkEventDetailsSlice from './pages/user-dashboard/publishers/userDashboardNetworkDetailsSlice';
import userDashboardSettingsSlice from './pages/user-profile/userDashboardSettingsSlice';

const store = configureStore({
  reducer: {
    settings,
    tooltips,
    bookmark,
    headerProducts,
    topAds,
    topAdvertisers,
    curation,
    searches,
    upgradeFlow,
    generateReport,
    adLocker,
    reports,
    moversAndShakers,
    alerts,
    genericScheduledReports,
    inbox,
    followAdvertiser,
    followAdvertiserDashboard,
    followTopAds,
    modal,
    upgradeModal,
    notification,
    paymentMethodNotice,
    pathTypes,
    advertiserCategoriesTool,
    advertiserEnrichTool,
    advertiserDmaReportsTool,
    verticalReportTool,
    advertiserCompareTool,
    groupManager,
    bookmarks,
    publisherUi,
    publisherUiOverview,
    menu: combineReducers({
      topAds: topAdsMenu
    }),
    advertiserProfile,
    brandProfile,
    campaignProfile,
    publisherProfile,
    channelProfile,
    networkProfile,
    publisherUiNewBusiness,
    publisherUiLargestAdvertisers,
    publisherUiCreatives,
    publisherUiCompetitiveSets,
    publisherUiScheduledReports,
    publisherUiAdvertiserOverview,
    requestSearch: runSearch,
    universalSearch,
    stats,
    performance,
    userDashboard: combineReducers({
        events: userDashboardAdvertiser,
        publisherEvents: userDashboardPublisherSlice,
        adEventDetails: userDashboardAdEventDetailsSlice,
        channels: userDashboardAdvertiserChannels,
        pubEventDetails: userDashboardPublisherDetailsSlice,
        catEventDetails: userDashboardCategoryDetailsSlice,
        advEventDetails: userDashboardAdvertiserDetailsSlice,
        campaignEventDetails: userDashboardCampaignDetailsSlice,
        networkEventDetails: userDashboardNetworkDetailsSlice,
        pubNetworkEventDetails: userDashboardPubNetworkEventDetailsSlice,
        settings: userDashboardSettingsSlice
    }),
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(asyncDispatchMiddleware)
})

const abuseWarning = _.once(() => alert('We’re happy you love Adbeat, but you’re just working way too fast for us.'))

axios.interceptors.response.use((response) => {
  return response;
}, function (error) {
/*   // Do something with response error
  if (error.response.status === 401) {
      console.log('unauthorized, logging out ...');
      auth.logout();
      router.replace('/auth/login');
  } */
  const isCanceled = error.message === 'cancel' ||
      error.constructor.toString().indexOf('Cancel') > 0;

  if (isCanceled) {
    return Promise.reject(new Error('cancel'))
  }
  if (error.response.status === 500) {
    return Promise.reject(new Error('error'))
  }
  if (error.response.status === 403) {
    return Promise.reject(new Error('forbidden'))
  }
  if (error.response.status === 401) {
    window.AdbeatSettings = window.AdbeatSettings || {}
    window.AdbeatSettings.loginUrl = error.response.data.loginPage
    return Promise.reject(new Error('unauthorized'))
  }
  if (error.response.status === 408) {
    return Promise.reject(new Error('failed'))
  }
  if (error.response.status === 429) {
    abuseWarning()
    return Promise.reject(new Error('too many requests'))
  }
  return Promise.reject(new Error(error.response.data.error));
});

window.AdbeatReduxStore = store

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
