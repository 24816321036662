import Categories from './categories'
import jquery from 'jquery'
import Profile from "./profile";

let settings = null;
const useSettings = (s) => {
    settings = s
}

const getSearchLink = function (data, country, platform, settings) {
    if (data.link && data.link.startsWith('/advertiser_overview')) {
        return data.link;
    }

    settings = settings || window.AdbeatSettings

    var path;
    if (['campaign', 'brand', 'network'].indexOf(data.term_type) >= 0) {
        if (!data.link) {
            if (data.term_type === 'network') {
                return Profile.url('network', data.network,
                    country || data.country, platform || data.platform);
            }
            if (data.term_type === 'campaign') {
                return Profile.url('campaign', data.campaign,
                    country || data.country, platform || data.platform,
                    data.advertiser)
            }
            if (data.term_type === 'brand') {
                return Profile.url('brand', data.brand,
                    country || data.country, platform || data.platform,
                    data.advertiser)
            }
        }
        path = data.link.split('/');
        if (country) {
            path[2] = country;
        }
        if (platform) {
            path[3] = platform;
        }
        path = path.join('/');
    } else if (data.term_type === 'keyword') {
        path = [
            '',
            'top-ads',
            country || data.country,
            platform || data.platform,
            data.term,
            data.keyword_type || 'creativeText',
            data.date_range || settings.defaultDateRange,
            settings.defaultNetwork,
            'all',
            'all',
            'all'
        ].join('/');
    } else if (data.term_type === 'category') {
        path = [
            '',
            'top-advertisers',
            country || settings.defaultCountry,
            platform || 'all',
            data.date_range || settings.defaultDateRange,
            'all',
            'sumImpressions',
            'desc',
            '1',
            Categories.encodeValue(data.term)
        ].join('/');
    } else if (data.term_type === 'publisher') {
        if (Profile.isChannel(data.term)) {
            path = [
                '',
                'channel',
                country || data.country,
                platform || data.platform,
                data.term
            ].join('/');
        } else {
            path = [
                '',
                data.term_type,
                country || data.country,
                platform || data.platform,
                data.term
            ].join('/');
        }
    }
    else {
        path = [
            '',
            data.term_type,
            country || data.country,
            platform || data.platform,
            data.term
        ].join('/');
    }
    return path;
}

const searchQueryToRecord = function (data) {
    var query = {
        'term_type': data.term_type,
        'country': data.country,
        'platform': data.platform,
        'term': data.term
    };

    if (data.keyword_type) {
        query.keyword_type = data.keyword_type;
    }
    if (data.date_range) {
        query.date_range = data.date_range;
    }
    if (data.link) {
        query.link = data.link;
    }
};

const getUserProfileLink = function () {
    return (settings || window.AdbeatSettings).profileLink;
};

const intercomLearnMore = function (feature) {
    jquery.post('/callisto/intercom_learn_more', {feature: feature});
};

const openInSameTab = function (url) {
    window.open(url, '_self', false);
};

const goToUserProfile = function () {
    openInSameTab(getUserProfileLink());
};

function getHomeUrl() {
    return 'https://' + settings.domain;
}

const goToProductAnnouncements = function () {
    openInSameTab(getHomeUrl() + '/product-announcements');
};

const getSupportLink = function () {
    return 'http://support.adbeat.com/';
};

const goToUserProfileUpgrade = function (plan) {
    if (plan) {
        if (plan === 'enterprise') {
            intercomLearnMore('Adbeat Enterprise');
            return;
        }
        openInSameTab(
            getUserProfileLink() + '/upgrade#' + plan
        );
    } else {
        openInSameTab(
            getUserProfileLink() + '/upgrade'
        );
    }
};

const privateLabelingLink = function () {
    return getUserProfileLink() + '/private_labeling/overview';
};

export {
    getSearchLink, searchQueryToRecord,
    goToUserProfileUpgrade, useSettings,
    openInSameTab, goToUserProfile, goToProductAnnouncements,
    privateLabelingLink, getUserProfileLink, getSupportLink,
    intercomLearnMore
}

const Adbeat = {getSearchLink, searchQueryToRecord}
export default Adbeat