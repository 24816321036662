function insertImageDimensions(url, width, height) {
    return url.replace(
        's3.amazonaws.com/adimages.adbeat.com/',
        'd2ly87iotba5kw.cloudfront.net/adimages.adbeat.com/' 
          + width + 'x' + height + '/'
    );
}

function rawBucketUrl(url) {
    return url.replace(
        /(adimages\.adbeat\.com)/,
        's3.amazonaws.com/$1'
    );
}

function urlWithoutProtocol(url) {
    return url.replace(/https?:/, '');
}

function previewUnavailable(url) {
    return url.match(/preview_unavailable/);
}

function transformPreviewUnavailable(url, width, height) {
    if (!previewUnavailable(url)) {
        return url;
    }

    width = width || 300;
    height = height || 250;

    return url.replace(
        '_unavailable',
        '-unavailable-' + width + 'x' + height
    );
}

function addImgUrl(item) {
    /*jslint regexp: true */
    if (item.creativeUrl) {
        if (item.creativeUrl.match(/.video$/)) {
            item.videoUrl = item.creativeUrl
            item.creativeUrl = item.creativeUrl.replace(/.video$/, '.jpg')
        }

        item.fullImageUrl = item.creativeUrl;

        if (item.creativeUrl.indexOf('storyboard') >= 0) {
            item.fullImageUrl = item.creativeUrl
                .replace('storyboard', 'adimages')
                .replace(/-\d+/, '')
                .replace('png', 'jpg');
        }

        var baseAttachmentOn = item.videoUrl ||
                item.fullImageUrl;

        item.attachmentPath = [
            item.advertiserId,
            baseAttachmentOn.match(/[^/]+$/)[0]
        ].join('/');

        item.protocolRelativeImageUrl = function (width, height) {

            if (width === height && width < 64) {
                width = 64;
                height = 64;
            }

            var url = rawBucketUrl(urlWithoutProtocol(item.fullImageUrl));

            if (previewUnavailable(url)) {
                return transformPreviewUnavailable(url, width, height);
            }

            if (width === undefined && height === undefined) {
                return url;
            }
            return insertImageDimensions(url, width, height);
        };

        item.protocolRelativeVideoUrl = function () {
            return rawBucketUrl(urlWithoutProtocol(item.videoUrl));
        };
    }
    /*jslint regexp: false */
    return item;
}

export default addImgUrl
