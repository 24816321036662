import React from "react"
import {universalSearchSelector} from "./universalSearchSlice";
import {useSelector} from "react-redux";
import HighlightText from "./HighlightText";
import {settingsSelector} from "../../reducers/settings";
import Categories from "../../common/categories";
import Profile from "../../common/profile";

const UniversalSearchDropDown = (props) => {
    const settings = useSelector(settingsSelector)
    const selectorData = useSelector(universalSearchSelector)
    const {country, platform} = props

    const categoriesModel = new Categories(settings)

    const {
        keywords,
        advertisers,
        publishers,
        networks,
        categories,
        brands,
        campaigns,
        ytChannels,
    } = selectorData

    const show = props.show && [
        keywords,
        advertisers,
        publishers,
        networks,
        categories,
        brands,
        campaigns,
        ytChannels].some(item => item.length > 0)

    const showKeyword = keywords.length > 0
    const showAdvertisers = advertisers.length > 0
    const showPublishers = publishers.length > 0
    const showNetworks = networks.length > 0
    const showCategories = categories.length > 0
    const showBrands = brands.length > 0
    const showCampaigns = campaigns.length > 0
    const showYtChannels = ytChannels.length > 0

    const showLeftColumn = [
        advertisers,
        publishers,
        brands,
        campaigns
    ].some(item => item.length > 0)

    const showRightColumn = [
        keywords,
        categories,
        networks,
        ytChannels
    ].some(item => item.length > 0)

    const goToKeyword = (searchItem) => {
        Profile.goToKeyword(searchItem.suggestion, country, platform,
            settings.defaultDateRange, settings.defaultNetwork)
    }

    const goToAdvertiser = (searchItem) => {
        Profile.goToAdvertiser(searchItem.suggestion, country, platform)
    }

    const goToPublisher = (searchItem) => {
        Profile.goToPublisher(searchItem.suggestion, country, platform)
    }

    const goToBrand = (searchItem) => {
        Profile.goToBrand(searchItem.suggestion, country, platform, searchItem.advertiser)
    }

    const goToCampaign = (searchItem) => {
        Profile.goToCampaign(searchItem.suggestion, country, platform, searchItem.advertiser)
    }

    const goToCategory = (searchItem) => {
        window.location.pathname = [
            '',
            'top-advertisers',
            country,
            platform,
            settings.defaultDateRange,
            settings.defaultNetwork,
            settings.metricField,
            'desc',
            '1',
            categoriesModel.encodeValue(searchItem.category)
        ].join('/');
    }

    const goToNetwork = (searchItem) => {
        Profile.goToNetwork(searchItem.network, country, platform)
    }

    const goToYtChannel = (searchItem) => {
        Profile.goToYtChannel(searchItem.channel, country, platform)
    }

    return (
        <div
            className={"results searchbox-autocompletion" + (show ? "" : " hidden")}>
            <div
                className={"left left-column" + (showLeftColumn ? "" : " hidden")}>

                <table
                    className={"advertisers" + (showAdvertisers ? "" : " hidden")}
                >
                    <tbody>
                    {advertisers.map((item) => (
                        <tr key={item.suggestion}
                            onClick={() => goToAdvertiser(item)}>
                            <td>
                                <HighlightText
                                    text={item.suggestion}
                                    term={props.searchTerm}
                                />
                            </td>
                            <td className="text-right capitalized">ADVERTISER</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <hr className={
                    showAdvertisers && (showPublishers || showBrands || showCampaigns) ? "" : "hidden"
                }/>

                <table
                    className={"publishers" + (showPublishers ? "" : " hidden")}>
                    <tbody>
                    {publishers
                        .filter(
                            item => settings.channels ? 
                                true : !Profile.isChannel(item.suggestion))
                        .map((item) => (
                        <tr key={item.suggestion}
                            onClick={() => goToPublisher(item)}>
                            <td>
                                <HighlightText
                                    text={item.suggestion}
                                    term={props.searchTerm}
                                />
                            </td>
                            <td className="text-right capitalized">{Profile.isChannel(item.suggestion) ? "CHANNEL" : "PUBLISHER"}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <hr className={
                    showPublishers && (showBrands || showCampaigns) ? "" : "hidden"
                }/>

                <table
                    className={"brands" + (showBrands ? "" : " hidden")}>
                    <tbody>
                    {brands.map((item) => (
                        <tr key={item.suggestion}
                            onClick={() => goToBrand(item)}>
                            <td>
                                <HighlightText
                                    text={item.suggestion + ' (' + item.advertiser + ')'}
                                    term={props.searchTerm}
                                />
                            </td>
                            <td className="text-right capitalized">BRAND</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <hr className={
                    (showBrands && showCampaigns) ? "" : "hidden"
                }/>

                <table
                    className={"campaigns" + (showCampaigns ? "" : " hidden")}>
                    <tbody>
                    {campaigns.map((item) => (
                        <tr key={item.suggestion}
                            onClick={() => goToCampaign(item)}>
                            <td>
                                <HighlightText
                                    text={item.suggestion + ' (' + item.advertiser + ')'}
                                    term={props.searchTerm}
                                />
                            </td>
                            <td className="text-right capitalized">CAMPAIGN</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div
                className={"left right-column" + (showRightColumn ? "" : " hidden")}>
                <table
                    className={"terms" + (showKeyword ? "" : " hidden")}>
                    <tbody>
                    {keywords.map((item) => (
                        <tr key={item.suggestion}
                            onClick={() => goToKeyword(item)}>
                            <td>
                                <HighlightText
                                    text={item.suggestion}
                                    term={props.searchTerm}
                                />
                            </td>
                            <td className="text-right capitalized">KEYWORD</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <hr className={
                    showKeyword && (showCategories || showNetworks || showYtChannels) ? "" : "hidden"
                }/>

                <table
                    className={"categories" + (showCategories ? "" : " hidden")}>
                    <tbody>
                    {categories.map((item) => (
                        <tr
                            key={item.suggestion}
                            onClick={() => goToCategory(item)}>
                            <td className="capitalize">
                                <HighlightText
                                    text={item.suggestion}
                                    term={props.searchTerm}
                                />
                            </td>
                            <td className="text-right capitalized">CATEGORY</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <hr className={
                    showCategories && (showNetworks || showYtChannels) ? "" : "hidden"
                }/>

                <table
                    className={"networks" + (showNetworks ? "" : " hidden")}>
                    <tbody>
                    {networks.map((item) => (
                        <tr
                            key={item.suggestion}
                            onClick={() => goToNetwork(item)}>
                            <td>
                                <HighlightText
                                    text={item.suggestion}
                                    term={props.searchTerm}
                                />
                            </td>
                            <td className="text-right capitalized">NETWORK</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <hr className={
                    showNetworks && showYtChannels ? "" : "hidden"
                }/>

                <table
                    className={"yt-channels" + (showYtChannels ? "" : " hidden")}>
                    <tbody>
                    {ytChannels.map((item) => (
                        <tr
                            key={item.suggestion}
                            onClick={() => goToYtChannel(item)}>
                            <td>
                                <HighlightText
                                    text={item.suggestion}
                                    term={props.searchTerm}
                                />
                            </td>
                            <td className="text-right capitalized">CHANNEL</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            </div>
        </div>
    )
}


export default UniversalSearchDropDown