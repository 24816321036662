import {useSelector} from 'react-redux'
import Tooltip from '../components/Tooltip'
import {pathTooltipSelector} from "../reducers/tooltips";
import * as ReactDOM from "react-dom";
import {useMemo} from 'react';
import { uniqueId } from 'lodash';

function useTooltip(path, props) {
    const tooltip = useSelector(pathTooltipSelector(path))
    const uniquePath = uniqueId(path)

    const tooltipComponent = useMemo(() => <Tooltip id={uniquePath}
        {...props}>
        <div dangerouslySetInnerHTML={{__html: tooltip}}/>
    </Tooltip>, [tooltip, props, uniquePath])

    return {
        path: uniquePath,
        component: ReactDOM.createPortal(
            tooltipComponent,
            document.body
        )
    }
}

export default useTooltip
