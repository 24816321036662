import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserSpend = createAsyncThunk(
    'fetchAdvertiserSpendTrendLine',
    async (arg) => {
        let entities = []

        let query = arg.query

        if (arg.brand || arg.campaign) {
            query['advertiser'] = arg.advertiser
        }

        if (arg.campaign) {
            entities = [{
                campaign: arg.campaign,
                platform: 'desktop'
            }]

            if (arg.hasMobile) {
                entities.push({
                    campaign: arg.campaign,
                    platform: 'mobile'
                })
            }
        } else if (arg.brand) {
            entities = [{
                brand: arg.brand,
                platform: 'desktop'
            }]

            if (arg.hasMobile) {
                entities.push({
                    brand: arg.brand,
                    platform: 'mobile'
                })
            }
        } else if (arg.channel) {
            entities = [{
                channel: arg.channel,
                platform: 'desktop'
            }]

            if (arg.hasMobile) {
                entities.push({
                    channel: arg.channel,
                    platform: 'mobile'
                })
            }
        } else if (arg.publisher) {
            entities = [{
                publisher: arg.publisher,
                platform: 'desktop'
            }]

            if (arg.hasMobile) {
                entities.push({
                    publisher: arg.publisher,
                    platform: 'mobile'
                })
            }
        } else {
            entities = [{
                advertiser: arg.advertiser,
                platform: 'desktop'
            }]

            if (arg.hasMobile) {
                entities.push({
                    advertiser: arg.advertiser,
                    platform: 'mobile'
                })
            }
        }

        const params = {
            ...query,
            includeEntities: JSON.stringify(entities)
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const spendSlice = createSlice({
    name: 'advertiserSpend',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetAdvertiserSpend(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserSpend.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvertiserSpend.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserSpend = (state) => state.advertiserProfile.overview.spend
export const { resetAdvertiserSpend } = spendSlice.actions
export { selectAdvertiserSpend, fetchAdvertiserSpend }
export default spendSlice.reducer