import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../../common/axiosRateLimit'


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchCampaignTopAds = createAsyncThunk('advertiserCampaignAds',
    async (arg) => {
        const response = await axios.get('/data-api/ads', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const fetchCampaignTrendlines = createAsyncThunk('advertiserCampaignTrendline',
    async (arg) => {
        const response = await axios.get('/data-api/trendlines', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const fetchCampaigns = createAsyncThunk('advertiserCampaigns',
    async (arg) => {
        const response = await axios.get('/data-api/campaigns', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        response.data.rows.forEach(c => {
            arg.dispatch(fetchCampaignTopAds({
                query: {
                    ...arg.query,
                    campaign: c.campaign,
                    pageSize: '2',
                    pageNumber: '1'
                }
            }))
        });

        const trendlineQuery = { ...arg.query }
        delete trendlineQuery.rows
        delete trendlineQuery.sortBy
        delete trendlineQuery.sortOrder
        delete trendlineQuery.pageNumber
        delete trendlineQuery.pageSize

        arg.dispatch(fetchCampaignTrendlines({
            query: {
                ...trendlineQuery,
                metrics: arg.settings.metricField + ',globalSeenDates',
                includeEntities: JSON.stringify(
                    response.data.rows.map(c => ({
                        campaign: c.campaign
                    }))
                )
            }
        }))

        return response.data
    })

const fetchAdvCampaignSplitPlatformTrendlines = createAsyncThunk('advCampaignSplitPlatformTrendlines',
    async (arg) => {
        const response = await axios.get('/data-api/trendlines', {
            params: {
                ...arg.query,
                metrics: arg.settings.metricField,
                includeEntities: JSON.stringify([{
                    campaign: arg.campaign,
                    platform: 'desktop'
                },
                {
                    campaign: arg.campaign,
                    platform: 'mobile'
                }])
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const campaignsSlice = createSlice({
    name: 'advertiserCampaigns',
    initialState: {
        status: 'idle',
        topAdsStatus: 'idle',
        trendlineStatus: 'idle',
        extraPagesAvailable: 0,
        data: [],
        platformTrendlines: {}
    },
    reducers: {
        resetAdvertiserTopCampaigns(state) {
            state.status = 'idle'
            state.topAdsStatus = 'idle'
            state.trendlineStatus = 'idle'
            state.extraPagesAvailable = 0
            state.data = []
            state.platformTrendlines = {}
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchCampaigns.pending]: (state) => {
            state.status = 'loading'
            state.data = []
            state.extraPagesAvailable = 0
        },
        [fetchCampaigns.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows.map(
                c => ({ ...c, ads: [], trendline: {} }))
            state.extraPagesAvailable = action.payload.extraPagesAvailable
        },
        [fetchCampaignTopAds.pending]: (state) => {
            state.topAdsStatus = 'loading'
        },
        [fetchCampaignTopAds.fulfilled]: (state, action) => {
            state.topAdsStatus = 'done'
            state.data = state.data.map(c => {
                const campaign = action.meta.arg.query.campaign
                if (campaign === c.campaign) {
                    return {
                        ...c,
                        ads: action.payload.rows
                    }
                }
                return c
            })
        },
        [fetchCampaignTrendlines.pending]: (state) => {
            state.trendlineStatus = 'loading'
        },
        [fetchCampaignTrendlines.fulfilled]: (state, action) => {
            state.trendlineStatus = 'done'
            state.data = state.data.map(c => {
                const row = action.payload.rows
                    .filter(r => r.entity.campaign === c.campaign)
                    .pop()

                return {
                    ...c,
                    trendline: row
                }
            })
        },
        [fetchAdvCampaignSplitPlatformTrendlines.pending]: (state, action) => {
            state.platformTrendlines[action.meta.arg.campaign] = {
                status: 'loading',
                data: []
            }
        },
        [fetchAdvCampaignSplitPlatformTrendlines.fulfilled]: (state, action) => {
            state.platformTrendlines[action.meta.arg.campaign] = {
                status: 'done',
                data: action.payload.rows
            }
        }
    }
})


const campaignsSelector = state => state.advertiserProfile.campaigns
const selectAdvProfileCampaignPlatformTrendlines = campaign =>
    state => (state.advertiserProfile.campaigns.platformTrendlines[campaign] ||
        { status: 'idle', data: [] })
export {
    fetchCampaigns,
    fetchAdvCampaignSplitPlatformTrendlines,
    campaignsSelector,
    selectAdvProfileCampaignPlatformTrendlines
}
export const { resetAdvertiserTopCampaigns } = campaignsSlice.actions
export default campaignsSlice.reducer