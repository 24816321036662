import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'
import _ from 'lodash'

const fetchTopCategories = createAsyncThunk('fetchTopAdvertiserCategories',
    async (arg) => {
        const response = await axios.get("/data-api/categories", {
            params: {
                ...arg.query,
            }
        })

        return response.data
    })

const topCategoriesSlice = createSlice({
    name: 'topAdvertisersCategories',
    initialState: {},
    extraReducers: {
        [fetchTopCategories.pending]: (state, action) => {
            state[action.meta.arg.query.advertiser] = {
                rows: [],
                status: 'pending'
            }
        },
        [fetchTopCategories.fulfilled]: (state, action) => {
            let resultRows = action.payload.rows.filter(row => {
                let hasSubCategories = action.payload.rows.filter(
                    subRow => _.startsWith(
                        subRow.category, row.category + '/')).length > 0
                    
                return !hasSubCategories;
            })
            state[action.meta.arg.query.advertiser] = {
                rows: resultRows,
                status: 'done'
            }
        }
    }
})

export const selectTopCategories = (advertiser) => (state) => {
    // Categories depend only on advertiser. No need to include network
    if (advertiser in state.topAdvertisers.categories) {
        return state.topAdvertisers.categories[advertiser]
    } else {
        return {
            rows: [],
            status: 'idle'
        }
    }
}

export {fetchTopCategories}
export default topCategoriesSlice.reducer