import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hide, notificationSelector } from "../reducers/notification";

const Notification = () => {
    const notification = useSelector(notificationSelector)
    const dispatch = useDispatch()

    useEffect(() => {
        if (notification.isVisible) {
            window.setTimeout(() => {
                dispatch(hide())
            }, notification.type === 'info' ? 3000 : 5000)
        }
    }, [dispatch, notification.type, notification.isVisible])
    
    return (<div id="notification" className={`notification ${notification.type}`} 
        style={{visibility: notification.isVisible ? 'visible' : 'hidden'}}>
        <div className="message" dangerouslySetInnerHTML={{ __html: notification.message }}/>
    </div>)
}
 
export default Notification;