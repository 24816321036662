let settings
const useSettings = (s) => {
    settings = s
}

const moreDataStr = "More Data";

// name - name of the feature
const featuresDescription = {
    'adMetric': {
        'name': 'Ad Spend data'
    },
    'desktopCountryId': {
        'name': function() {
            // if (settings.defaultCountry === 'all') {
            //     return 'Data by Country';
            // }
            return 'Desktop ad data';
        }
    },
    'displayNetworkId': {
        'name': 'Display Networks'
    },
    'nativeNetworkId': {
        'name': 'Native Networks'
    },
    'categoryId': {
        'name': 'Categories'
    },
    'standardNetworkId': {
        'name': 'Standard Ad Networks'
    },
    'nativeAds': {
        'name': 'Native Ads'
    },
    'adTypeVideo': {
        'name': 'Video Ads'
    },
    'directNetworkId': {
        'name': 'Direct Buy data'
    },
    'mobileCountryId': {
        'name': function() {
            // if (settings.defaultCountry === 'all') {
            //     return 'Data by Country';
            // }
            return 'Mobile insights';
        }
    },
    'channelId': {
        'name': 'Publisher Channels data'
    },
    'dateRange': {
        'name': function (value, upgradeTo) {
            var rv = "3 years of data";
            if (upgradeTo) {
                switch (upgradeTo.features.dateRange) {
                case 365:
                    rv = "1 year of data";
                    break;
                case 180:
                    rv = "6 months of data";
                    break;
                case 90:
                    rv = "3 months of data";
                    break;
                case 30:
                    rv = "1 month of data";
                    break;
                default:
                    break;
                }
            }
            return rv;
        }
    },
    'placements': {
        'name': 'Publisher Placement data'
    },
    'adTags': {
        'name': 'Ad Tags'
    },
    'adSizeId': {
        'name': 'more Ad Sizes'
    },
    'brandId': {
        'name': 'Brand data'
    },
    'alerts': {
        'name': 'more Alerts'
    },
    'smart_alerts': {
        'name': 'Smart Alerts'
    },
    'compare': {
        'name': function (value) {
            if (value === 'upgrade') {
                return 'more advertisers for the Compare tool';
            }
            return 'the Compare tool';
        }
    },
    'campaigns': {
        'name': 'Campaign data'
    },
    'reports': {
        'name': 'More Reports',
        'description_name': 'More Monthly Reports'
    },
    'reports-pptx': {
        'name': 'PowerPoint Reports',
        'description_name': 'Access to PowerPoint reports'
    },
    'multiUser': {
        'name': 'multiple users access'
    },
    'phoneSupport': {
        'name': 'phone support'
    },
    'dedicatedSupport': {
        'name': 'dedicated support rep'
    },
    'training': {
        'name': 'on boarding training'
    },
    'privateLabeling': {
        'name': 'Private Labeling'
    },
    adCollections: {
        name: 'More Collections'
    },
    dataByDevice: {
        name: "Breakout by Device"
    },
    'more-data-google_mobile': {
        name: moreDataStr
    },
    'more-data-standard_2': {
        name: moreDataStr
    },
    'more-data-advanced': {
        name: moreDataStr
    },
    'more-data-enterprise': {
        name: moreDataStr
    },
    'movers-and-shakers': {
        description_name: "the Movers & Shakers report",
        name: "this Report"
    }
};

const planTexts = {
    'intro': {
        'current': {
            'default': 'upgrade-screen-intro-to-advanced'
        }
    },
    'intro_2': {
        'current': {
            'default': 'upgrade-screen-intro-to-advanced'
        }
    },
    'only_google': {
        'current': {
            'default': 'upgrade-screen-google_only-to-standard',
            'standard': 'upgrade-screen-google_only-to-standard',
            'advanced': 'upgrade-screen-google_only-to-advanced',
            'enterprise': 'upgrade-screen-google_only-to-advanced'
        }
    },
    'google_mobile': {
        'current': {
            'default': 'upgrade-screen-google_mobile-to-advanced',
            'standard': 'upgrade-screen-google_mobile-to-advanced',
            'advanced': 'upgrade-screen-google_mobile-to-advanced',
            'enterprise': 'upgrade-screen-google_mobile-to-advanced'
        },
        'new': {
            'features': 'upgrade-screen-google_mobile-new'
        }
    },
    'only_mobile': {
        'current': {
            'default': 'upgrade-screen-mobile_only-current',
            'standard': 'upgrade-screen-mobile_only-current',
            'advanced': 'upgrade-screen-mobile_only-current',
            'enterprise': 'upgrade-screen-mobile_only-current'
        }
    },
    'standard': {
        'current': {
            'default': 'upgrade-screen-standard-to-advanced',
            'advanced': 'upgrade-screen-standard-to-advanced',
            'enterprise': 'upgrade-screen-standard-to-enterprise'
        },
        'new': {
            'features': 'upgrade-screen-standard-new'
        }
    },
    'standard_2': {
        'current': {
            'default': 'upgrade-screen-standard-to-advanced',
            'advanced': 'upgrade-screen-standard-to-advanced',
            'enterprise': 'upgrade-screen-standard-to-enterprise'
        },
        'new': {
            'features': 'upgrade-screen-standard-new'
        },
        'new_from_intro': {
            'features': 'upgrade-screen-standard-new-from-intro'
        }
    },
    'advanced': {
        'current': {
            'default':  'upgrade-screen-advanced-current',
            'enterprise': 'upgrade-screen-advanced-current'
        },
        'new': {
            'features': 'upgrade-screen-advanced-new'
        }
    },
    'enterprise': {
        'new': {
            'features': 'upgrade-screen-enterprise-current',
            "button": "Contact Us"
        }
    }
};

let modal;

const upgrade = {
    featuresDescription: featuresDescription,

    planTexts: planTexts,

    getCurrentPlanText: function (current, suggested) {
        return planTexts[current].current[suggested.toLowerCase()] ||
            planTexts[current].current.default;
    },

    getSuggestedProductText: function(current, suggested) {
        if ((current === 'intro_2' || current === 'intro') &&
            suggested === 'standard_2')
        {
            return planTexts[suggested].new_from_intro;
        }
        return planTexts[suggested].new;
    },

    getSuggestedProduct: function (missedFeatureId, missedValue) {
        var i;
        var currentProduct = settings
                .all_products[settings.current_product];
        var suggestedPlan = null;
        var suggestedPlanName = null;
        var currentValueToCheck;
        var suggestedValueToCheck;

        var getValue = function (product, feature) {
            return product.features[feature];
        };

        // special cases
        var canUpgrade = function (a, b) {
            if (
                missedValue != null && b != null &&
                    missedValue.constructor === b.constructor) {
                return a < b && b >= missedValue;
            }
            return a < b;
        };

        var canUpgradeDateRange = function (a, b) {
            return a < b && missedValue === b;
        };

        var arrayCanUpgrade = function (a, b) {
            var idx;
            for (idx = 0; idx < b.length; idx += 1) {
                if (b[idx] === a) {
                    return true;
                }
            }
            return false;
        };

        currentValueToCheck = getValue(currentProduct, missedFeatureId);

        // That's not accurate right now.
        // Need both platform and country to work
        if (
            missedFeatureId === 'desktopCountryId' ||
                missedFeatureId === 'mobileCountryId'
        ) {
            canUpgrade = arrayCanUpgrade;

            if (!currentValueToCheck.length) {
                currentValueToCheck = 'us';
            }
            if (currentValueToCheck[0] === 'us') {
                currentValueToCheck = 'non-us';
            } else {
                currentValueToCheck = 'us';
            }
        }

        if (missedFeatureId === 'dateRange') {
            canUpgrade = canUpgradeDateRange;
        }

        for (i = 0; i < currentProduct.upgradesTo.length; i += 1) {
            suggestedPlanName = currentProduct.upgradesTo[i];
            suggestedPlan = settings.all_products[suggestedPlanName];

            suggestedValueToCheck = getValue(
                suggestedPlan,
                missedFeatureId
            );

            if (canUpgrade(currentValueToCheck, suggestedValueToCheck)) {
                return suggestedPlanName;
            }
        }

        return suggestedPlanName;
    },

    setModal: (m) => {
        modal = m
    },

    showModal: function (name, value) {
        modal.open({
            name: name,
            value: value
        }, 'upgrade-required-modal');
        modal.dataLoaded(true);
    },

    valueRequiresUpdate: function (value) {
        value = value || '';
        return value
            .toString()
            .substring(
                0,
                settings.upgradeRequiredValue.length
            ) === settings.upgradeRequiredValue;
    },

    checkValue: function (name, value, onUpgradeRequired) {
        onUpgradeRequired = onUpgradeRequired || function () {};
        var values = [value];
        if (value.constructor === Array) {
            values = value;
        }

        return values.some(function(item) {
            if (upgrade.valueRequiresUpdate(item)) {
                onUpgradeRequired();
                upgrade.showModal(
                    name,
                    item.replace(settings.upgradeRequiredValue, '')
                );
                return true;
            }
            return false;
        });
    },

    getMissedNetworkId: function (value) {
        var type = settings.networks.getNetworkType(value) || 'display';
        if (type === 'all') {
            type = 'display';
        }
        return type + 'NetworkId';
    },

    getMissedCountryId: function (value) {
        // That's not accurate right now. We need to know both
        // Country and Platform to do this.
        var found = false;
        var i;

        for (i = 0; i < settings.allowed.countries.length; i += 1) {
            if (settings.allowed.countries[i].id === value) {
                found = true;
                break;
            }
        }
        if (found) {
            return 'mobileCountryId';
        }
        return 'desktopCountryId';
    },

    getMissedPlatformId: function (value) {
        // That's not accurate right now. We need to know both
        // Country and Platform to do this.
        if (value === 'desktop') {
            return 'desktopCountryId';
        }
        return 'mobileCountryId';
    },

    getMissedFeatureId: function (name, value) {
        if (name === 'networkId') {
            return this.getMissedNetworkId(value);
        }
        if (name === 'countryId' || name === "alertCountries") {
            return this.getMissedCountryId(value);
        }
        if (name === 'platformId') {
            return this.getMissedPlatformId(value);
        }
        return name;
    }
};

export {useSettings}

export default upgrade