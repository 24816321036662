import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'


const fetchPublisherGroups = createAsyncThunk('fetchPublisherGroups',
    async (arg) => {
        const response = await axios.get(
            `/callisto/publisher-groups?omit-empty=true`, {
                params: {
                    country: arg.params.country
                }
            })

        return response.data
    })


const publisherSets = createSlice({
    name: 'publisherSets',
    initialState: {
        groups: {
            defaultGroups: [],
            customGroups: []
        },
        status: 'idle'
    },
    reducers: {
        resetPublisherSets(state) {
            state.status = 'idle'
            state.groups = {
                defaultGroups: [],
                customGroups: []
            }
        }
    },
    extraReducers: {
        [fetchPublisherGroups.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchPublisherGroups.fulfilled]: (state, action) => {
            state.status = 'done'
            if (action.payload.status === 'ok') {
                state.groups = {
                    defaultGroups: [{
                        is_default: true,
                        name: 'All Publishers',
                        publishers: ['all']
                    }].concat(action.payload.default_groups),
                    customGroups: action.payload.groups
                }
            }
        }
    }
})

const publisherSetsSelector = state => state.publisherUi.publisherSets

export const {resetPublisherSets} = publisherSets.actions
export {fetchPublisherGroups, publisherSetsSelector}
export default publisherSets.reducer
