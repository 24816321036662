import common from './publisherUiCommonSlice'
import largestAdvertisers from './largestAdvertisersSlice'
import {combineReducers} from '@reduxjs/toolkit'

const largestAdvertisersReducer = combineReducers({
    common,
    creatives: largestAdvertisers
})


export default largestAdvertisersReducer
