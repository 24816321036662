const LoadingState = (props) => {
    return (
        props.show && <div className="loading-overlay"> 
            <div className="spinner" style={{left: props.left + '%', top: props.top + '%'}}> 
                <div className="bounce1"></div> 
                <div className="bounce2"></div> 
                <div className="bounce3"></div> 
            </div> 
        </div>
    )
}

export default LoadingState
