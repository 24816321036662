import React from "react"

const RequiredInputStatus = (props) => {
    const inputValue = props.inputValue

    const isFilledInput = () => {
        let isFilled = false;
        let value = inputValue;

        if (value && value.hasOwnProperty('length')) {
            isFilled = value.length > 0;
        }

        return isFilled;
    }

    return (
        <span className="required-status">
            {
                !isFilledInput() &&
                (<span>*</span>)
            }
            {isFilledInput() && (
                <i className="fa-light fa-sharp fa-check"/>
            )}
        </span>
    )
}

export default RequiredInputStatus