import _ from "lodash"

// higher weight put networks in
// front of the list
const  weights = {
    all: 14,
    '': 13,
    allNative: 12,
    native: 11,
    msn: 10,
    google: 9,
    direct: 8
};

function markAllowed(list, allowed, onlyNetwork) {
    var isNativeAllowed = allowed.some(
            function (n) { return n.id === 'taboola'; });

    var allowedKeys = {
        'all': !onlyNetwork,
        'allByNetwork': !onlyNetwork,
        'native': isNativeAllowed,
        'allNative': isNativeAllowed
    };

    allowed.forEach(function (item) {
        allowedKeys[item.id] = true;
    });

    list.forEach(function (item) {
        item.allowed = allowedKeys[item.id] || false;
    });
}

function populateMapping(list) {
    var result = {}, reverse = {};
    list.forEach(function (item) {
        result[item.id] = item.name;
        reverse[item.name] = item.id;
    });
    result.allProgrammatic = 'Indirect Only';
    return [result, reverse]
}

function populateList(inNetworks, list) {
    let networks = inNetworks.slice(0)
    networks.splice(0, 0, {name: 'All', id: 'all'});

    // add weights
    networks = networks.map(function (item) {
        return {
            ...item,
            weight: weights[item.id] || 0
        }
    });

    // sort by weight first and by lowercase network
    // name after that
    return networks.sort(function (a, b) {
        var weightDiff = a.weight - b.weight;
        if (weightDiff) {
            return -weightDiff;
        }

        var aName = a.name.toLowerCase();
        var bName = b.name.toLowerCase();

        var cmp = 0;

        if (aName > bName) {
            cmp = 1;
        }

        if (aName < bName) {
            cmp = -1;
        }

        return cmp;
    })
}



class NetworkListModel {
    constructor(networks, allowedNetworks, networksByType, settings) {
        if (networks === undefined) {
            throw Error('Empty network list');
        }

        this.list = []

        this.makeListWithAllByNetwork = () => {
            var result = null;

            if (this.list) {
                result = this.list.slice(0);
                result.push({
                    'id': 'allByNetwork',
                    'name': 'All By Network'
                });
                result.push({
                    'id': 'native',
                    'name': 'All By Native Networks'
                });
                result.push({
                    'id': 'allNative',
                    'name': 'All Native Networks'
                });
            }
            return result;
        }

        this.mapping = {}
        this.reverseMapping = {}

        var nativeNetworks = _.sortBy(
            settings.native_networks,
            function (item) {
                return item.name.toLowerCase();
            })
            .map(function (item) {
                return item.id;
            });

        var topNetworks = ['msn', 'direct', 'google'];

        this.isTopNetwork = function (networkId) {
            return topNetworks.indexOf(networkId.toLowerCase()) >= 0;
        };

        this.isAllowed = function (networkId) {
            var i, len = allowedNetworks.length,
                found = false, curNetwork;
            for (i = 0; i < len; i++) {
                curNetwork = allowedNetworks[i];
                if (networkId === curNetwork.id) {
                    found = true;
                    break;
                }
            }
            return found;
        };

        this.groups = [{
            'name': 'Views:',
            'values': [
                'all',
                'allByNetwork',
                'allNative',
                'native',
                'standard'
            ]
        }, {
            'name': 'Top Networks:',
            'values': topNetworks
        }, {
            'name': 'Native Networks:',
            'values': nativeNetworks
        }, {
            'name': 'Other Networks:',
            'values': 'other'
        }];

        this.onlyNetworksList = (function () {
            if (allowedNetworks) {
                return allowedNetworks.filter(function (item) {
                    return item.id !== 'all';
                });
            }
            return allowedNetworks;
        }())

        this.theOnlyNetwork = (function () {
            return allowedNetworks && allowedNetworks.length < 2;
        } ());

        this.list = populateList(networks, this.list);
        this.listWithAllByNetwork = this.makeListWithAllByNetwork();
        [this.mapping, this.reverseMapping] = populateMapping(
            this.listWithAllByNetwork);

        markAllowed(this.list, allowedNetworks, this.theOnlyNetwork);

        markAllowed(
            this.listWithAllByNetwork,
            allowedNetworks, this.theOnlyNetwork
        );

        this.wrapAllByNetworkQuery = function (resultList) {
            return resultList.filter(function (item) {
                return !(item.key === 'networkId' &&
                    item.value === 'allByNetwork');
            });
        };

        this.isNative = function (networkId) {
            return nativeNetworks.indexOf(networkId.toLowerCase()) >= 0;
        };

        this.getNetworkType = function (networkId) {
            var i;
            var key;
            var val;

            for (key in networksByType) {
                if (networksByType.hasOwnProperty(key)) {
                    for (i = 0; i < networksByType[key].length; i++) {
                        val = networksByType[key][i];
                        if (val.id === networkId) {
                            return key;
                        }
                    }
                }
            }

            return null;
        };
    }
}

export default NetworkListModel
export const getNetworkList = (settings) => {
    return new NetworkListModel(
        settings.networks,
        settings.allowed.networks,
        {
            'native': settings.native_networks.concat([
                { id: 'allNative' }, { id: 'native' }]),
            'display': settings.display_networks,
            'all': [{ 'id': 'all' }, { 'id': 'allByNetwork' }],
            'direct': settings.direct_networks
        },
        settings
    )
}
