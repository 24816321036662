import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchNetworkProfileTotals = createAsyncThunk(
    'networkOverviewTotals',
    async (arg) => {
        const request = await axios.get("/data-api/networks", {
            params: {
                ...arg.query,
                rows: 1,
                metrics: arg.settings.metricsList
            },
            cancelToken: getCancelSource().token
        })

        let query = arg.query
        let entity = {}
        let keys = ['advertiser', 'publisher', 'channel', 'brand', 'campaign', 'network']
        keys.forEach(key => {
            if (query.hasOwnProperty(key)) {
                entity[key] = query[key]
                delete query[key]
            }
        })

        const trendlineRequest = axios.get("/data-api/trendlines", {
            params: {
                ...query,
                includeEntities: JSON.stringify(
                    [entity]),
                metrics: 'globalSeenDates',
            },
            cancelToken: getCancelSource().token
        })

        const response = await request
        const trendlineResponse = await trendlineRequest

        let result = response.data
        if (response.data.rows.length > 0 && trendlineResponse.data.rows.length > 0) {
            result.rows[0].globalFirstSeen = trendlineResponse.data.rows[0].globalFirstSeen
        }
        return result
    })


const networkProfileTotalsSlice = createSlice({
    name: 'networkProfileTotals',
    initialState: {
        status: 'idle',
        data: {},
    },
    extraReducers: {
        [fetchNetworkProfileTotals.pending]: (state) => {
            state.status = 'loading'
            state.data = []
        },
        [fetchNetworkProfileTotals.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows[0] || {}
        }
    }
})


const networkProfileTotalsSelector = state => state.networkProfile.totals
export {fetchNetworkProfileTotals, networkProfileTotalsSelector}
export default networkProfileTotalsSlice.reducer