import {useEffect, useState} from "react"
import ReactModal from "react-modal"
import {useDispatch, useSelector} from "react-redux"
import {hideModal, modalSelector} from "../../reducers/modal"
import { settingsSelector } from "../../reducers/settings"
import TrialError from "./TrialError";
import {
    fetchCanGenerateReport,
    fetchGenerateProfileReport,
    clearErrors,
    selectGenerateReport, fetchScheduleProfileReport
} from "./reportsSlice";
import {notifyInfo} from "../../reducers/notification";
import { showUpgradeModal } from "../modals/UpgradeModal";
import useTooltip from "../../hooks/useTooltip"

const ScheduledProfileReportDialog = (props) => {
    const tooltip = useTooltip('generic-scheduled-report')
    const generateReport = useSelector(selectGenerateReport)
    const [reportType, setReportType] = useState('instant')
    const [frequency, setFrequency] = useState('weekly')
    const dispatch = useDispatch()
    const settings = useSelector(settingsSelector);
    
    const handleUpgradeFlow = () => {
        showUpgradeModal(dispatch, {
            name: 'scheduledReports',
            value: true
        })
    }

    useEffect(() => {
        if (generateReport.status === 'idle') {
            dispatch(fetchCanGenerateReport())
        }
    }, [dispatch, generateReport.status])

    useEffect(() => {
        if (generateReport.generateStatus === 'done' && !generateReport.errors.length) {
            dispatch(notifyInfo('Your report is being generated and will be saved on your Reports page'))
            dispatch(hideModal())
        }
    }, [dispatch, generateReport.generateStatus, generateReport.errors.length])

    useEffect(() => {
        if (generateReport.scheduleStatus === 'done' && !generateReport.errors.length) {
            dispatch(notifyInfo('Your report is being generated and will be saved on your Reports page'))
            dispatch(hideModal())
        }
    }, [dispatch, generateReport.scheduleStatus, generateReport.errors.length])


    const showTrialError = !generateReport.canGenerateReport && settings.user.is_trial

    const errors = generateReport.errors
    const handleSubmit = (e) => {
        e.preventDefault()

        if (reportType === 'instant') {
            dispatch(fetchGenerateProfileReport({query: {
                ...props.query,
                startDate: props.startDate,
                endDate: props.endDate,
                country: props.country,
                platform: props.platform,
                metrics: props.metrics,
                profileName: props.profileName,
                profileId: props.profileId,
                reportType: props.reportType,
                title: props.title,
                link: props.link
            }}))
        }

        if (reportType === 'scheduled') {
            dispatch(fetchScheduleProfileReport({query: {
                ...props.query,
                startDate: props.startDate,
                endDate: props.endDate,
                country: props.country,
                platform: props.platform,
                metrics: props.metrics,
                profileName: props.profileName,
                profileId: props.profileId,
                reportType: props.reportType,
                title: props.title,
                link: props.link,
                frequency
            }}))
        }
    }

    return (
        <ReactModal 
            onRequestClose={props.handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            isOpen={props.isOpen}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "fixed",
                    height: document.documentElement.scrollHeight
                },
                content: {
                    bottom: 'auto',
                    borderRadius: 0,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "fixed",
                    padding: 0,
                    overflow: 'none',
                    inset: "80px 40px auto 40px",
                },
            }}
            >
            <div className="query-based-report">
                <form method="post" onSubmit={handleSubmit}>
                    <div className="section-header">
                        <h1 className="left">{props.title}</h1>
                        <div className="right close" onClick={props.handleClose}>
                            <i className="fa-light fa-sharp fa-close"></i>
                        </div>
                    </div>
                    <div className="section-body filters profile-header report-type">
                        <div>
                            <h2 className="title left">Select the type of report you would like to generate</h2>
                            <div className="help-icon left"
                                    data-tip
                                    data-for={tooltip.path}>
                                <i className="fa-sharp fa-light fa-circle-question fa-2x"/>
                            </div>
                        </div>

                        <h2 className="buttons">
                            <div className={`report-button ${reportType === 'instant' ? 'active' : ''}`}
                                onClick={() => setReportType('instant')}>
                                <div>
                                    <i className="fa-regular fa-light fa-sharp fa-3x fa-cloud-download"></i>
                                </div>
                                <div>
                                    <span>Instant</span>
                                </div>
                            </div>
                            
                            <div className={`report-button ${reportType === 'scheduled' ? 'active' : ''}`}
                                data-value={!settings.scheduled_reports ? settings.upgradeRequiredValue + 'scheduledReports' : ''}
                                onClick={() => settings.scheduled_reports ? 
                                    setReportType('scheduled') :
                                    handleUpgradeFlow()}>
                                <div>
                                    <i className="fa-regular fa-light fa-sharp fa-3x fa-calendar"></i>
                                </div>
                                <div>
                                    <span>Scheduled</span>
                                </div>
                            </div>

                            <div className="clear"></div>
                        </h2>

                        <div className="radio-buttons">
                            <div className="radio-button">
                                <input id="weekly-freq" type="radio" name="frequency"
                                    disabled={reportType !== 'scheduled'}
                                    value="weekly" checked={frequency === 'weekly'}
                                    onChange={(e) => setFrequency(e.target.value)}/>
                                <label
                                    className={reportType !== 'scheduled' ? 'disabled' : ''}
                                    htmlFor="weekly-freq">Run this report weekly</label>
                            </div>
                            <div className="radio-button">
                                <input id="monthly-freq" type="radio" name="frequency"
                                    value="monthly" checked={frequency === 'monthly'}
                                    disabled={reportType !== 'scheduled'}
                                    onChange={(e) => setFrequency(e.target.value)}/>
                                <label 
                                    className={reportType !== 'scheduled' ? 'disabled' : ''}
                                    htmlFor="monthly-freq">Run this report monthly</label>
                            </div>
                        </div>
                    </div>
                    <div className="section-footer clear">
                        <button className="save-alert">Generate Report</button>

                        <span className={
                            "error" + (errors.length > 0 ? "" : " hidden")
                        }>
                            {errors.map(e =>
                            <>
                            <span
                                className="pointer"
                                onClick={() => dispatch(clearErrors())}
                                dangerouslySetInnerHTML={{__html: e}}
                            ></span><br />
                            </>
                            )}
                        </span>
                    </div>
                    {showTrialError &&
                        <TrialError
                        onClick={() => dispatch(clearErrors())}
                        ></TrialError>
                    }
                </form>
            </div>
            {tooltip.component}
        </ReactModal>
    )
}

const modalName = 'report-schedule-profile-dialog'

const Wrapper = () => {
    const modal = useSelector(modalSelector) 
    const dispatch = useDispatch()

    if (modal.name === modalName) {
        return (
           <ScheduledProfileReportDialog {...modal.params}
                isOpen={modal.name === modalName}
                handleClose={() => dispatch(hideModal())} />
        )
    }
    return null

}

export default Wrapper;
