import publisherSets from './publisherSets'
import advertiserTrendline from './publisherUiAdvertiserTrendline'
import report from './publisherUiOverviewReportSlice'
import {combineReducers} from '@reduxjs/toolkit'

const publisherUiOverviewReducer = combineReducers({
    publisherSets,
    advertiserTrendline,
    report
})


export default publisherUiOverviewReducer
