import { useCallback, useEffect, useMemo, useRef } from "react"
// eslint-disable-next-line
import { useDispatch, useSelector } from 'react-redux'
import { settingsSelector } from "../reducers/settings";
import Platforms from '../common/platforms'
import jquery from 'jquery'
import { getOnInitialize } from '../common/selectizeHideInput'
import Countries from "../common/countries";
import usePrevious from "../hooks/usePrevious";
import _ from 'lodash'
import { showUpgradeModal } from "./modals/UpgradeModal";
import orderSelectizeOptions from "../common/orderSelectizeOptions";


const PlatformList = (props) => {
    const propsPlatforms = useMemo(
        () => props.platforms || ['all', 'desktop', 'mobile'], [props.platforms])
    const prevPlatforms = usePrevious(propsPlatforms)
    const prevValue = usePrevious(props.value)
    const selectElement = useRef(null)
    const { value } = props
    const platform = value
    const settings = useSelector(settingsSelector)
    const countries = new Countries(
        settings, settings.allAllowedCountries);
    const dispatch = useDispatch()

    const onChange = useCallback((val) => {
        const propsOnChange = props.onChange
        if (_.startsWith(val, settings.upgradeRequiredValue)) {
            showUpgradeModal(dispatch, {
                name: 'platformId',
                value: val.replace(settings.upgradeRequiredValue, '')
            })
            jquery(selectElement.current)
                .selectize()[0].selectize.setValue(value)
        } else {
            jquery(selectElement.current)
                .selectize()[0].selectize.close()
            window.setTimeout(() => propsOnChange(val), 10)
        }
    }, [dispatch, value, settings.upgradeRequiredValue, props.onChange])

    const platforms = new Platforms(
        settings.platforms, settings.allowed.platforms, settings);

    const defaultSource = platforms.list
        .map(function (item) {
            return item.id;
        })

    const items = (function () {
        var keys = {};
        var source = propsPlatforms
        if (!source || !source.length) {
            source = defaultSource;
        }
        var i;

        var isAllowed = function (item) {
            if (props.country) {
                return countries
                    .isAllowedCountryForPlatform(item.id, props.country, platforms);
            }
            return item.allowed;
        };

        if (source) {
            for (i = 0; i < source.length; i++) {
                keys[source[i]] = true;
            }
        }

        return platforms.list
            .filter(function (item) {
                return keys[item.id];
            })
            .map(function (item) {
                return {
                    'text': item.name,
                    'value': isAllowed(item) ?
                        item.id :
                        settings.upgradeRequiredValue + item.id
                };
            });
    }())


    useEffect(() => {
        const createSelectize = (updatedPlatform) => {
            let selectizeOptions = {
                options: items
            }
            selectizeOptions.onInitialize = getOnInitialize(
                selectizeOptions, selectElement.current)
            if (props.options) {
                selectizeOptions = Object.assign(selectizeOptions, props.options)
            }

            const selector = jquery(selectElement.current)
                .selectize(selectizeOptions)[0].selectize

            selector.setValue(updatedPlatform, true)
            selector.off('change')
            selector.on('change', onChange)
            selector.addOption(orderSelectizeOptions(items))
            return selector
        }

        if (selectElement.current &&
            (_.xor(propsPlatforms, prevPlatforms).length ||
                props.value !== prevValue)) {
            createSelectize(platform)
        }
    }, [platform, propsPlatforms, prevPlatforms, items, onChange,
        props.value, prevValue, props.options])

    if (selectElement.current && selectElement.current.selectize) {
        const selector = selectElement.current.selectize
        selector.off('change')
        selector.on('change', onChange)
    }
    if (!propsPlatforms.length) {
        return <plaform-list
            class={`tight-arrow ${props.extraClass || ''}`}>
            <div className="selectize-control single">
                <div className="selectize-input item">
                    <div className="item">Loading...</div>
                </div>
            </div>
        </plaform-list>
    }

    return (
        <platform-list class={`tight-arrow ${props.extraClass || ''}`}>
            <select ref={selectElement} style={{ display: 'none' }}>
            </select>
        </platform-list>
    )
}

export default PlatformList