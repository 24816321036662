import numeral from 'numeral'

let settings
let rates
const useSettings = (s) => {
    settings = s
    rates = settings.currency_rates
}

const countryCurrency = {
    'us': 'USD',
    'ae': 'AED',
    'at': 'EUR',
    'au': 'AUD',
    'br': 'BRL',
    'be': 'EUR',
    'ca': 'CAD',
    'ch': 'CHF',
    'cn': 'CNY',
    'de': 'EUR',
    'es': 'EUR',
    'fr': 'EUR',
    'sk': 'EUR',
    'gb': 'GBP',
    'hk': 'HKD',
    'id': 'IDR',
    'il': 'ILS',
    'in': 'INR',
    'it': 'EUR',
    'jp': 'JPY',
    'mx': 'MXN',
    'nl': 'EUR',
    'ru': 'RUB',
    'se': 'SEK',
    'sg': 'SGD',
    'tr': 'TRY',
    'za': 'ZAR',
    'kr': 'KRW',
    'tw': 'TWD'
};

function pickCurrency(curr, countryId) {
    if (countryId === 'all') {
        countryId = 'us'
    }

    if (settings.adMetricId !== 'adSpend') {
        return '';
    }
    
    if (settings.currency === 'Match') {
        curr = countryCurrency[countryId || 'us'];
    }
    return curr;
}

function guessCurrency(countryId) {
    if (settings.currency === 'Match' && countryId === 'all') {
        return countryCurrency['us'];       
    } else if (settings.currency === 'Match') {
        return countryCurrency[countryId];
    }
    return settings.currency;
}

function convert(dollarAmount, curr, country) {
    if (!dollarAmount) {
        return 0;
    }

    curr = pickCurrency(curr || settings.currency, country);

    return dollarAmount * rates[curr].rate;
}

function unconvert(amount, curr, country) {
    if (!amount) {
        return 0;
    }
    curr = pickCurrency(curr || settings.currency, country);

    return amount / rates[curr].rate;
}


const format = function (dollarAmount, curr, formatStr, country) {
    formatStr = formatStr || '0.[0]a';
    if (settings.adMetricId !== 'adSpend') {
        formatStr = '0.[0]a';
    }
    curr = pickCurrency(curr || settings.currency, country);
    
    if (!(curr in rates)) {
        return numeral(dollarAmount)
            .format(formatStr);
    }
    
    return rates[curr].symbol +
        numeral(convert(dollarAmount, curr))
        .format(formatStr);
}

const guessFormat = function (amount, formatStr, countryId, curr) {
    curr = pickCurrency(curr || settings.currency, countryId || 'us');
    formatStr = formatStr || '$0.[0]a';
    if (settings.adMetricId !== 'adSpend') {
        formatStr = '0.[0]a';
    }

    if (formatStr.startsWith('$')) {
        return rates[curr].symbol +
            numeral(convert(amount, curr, countryId))
            .format(formatStr.slice(1))
            .toUpperCase();
    }
    return numeral(amount)
        .format(formatStr)
        .toUpperCase();
}

const symbol = function (curr) {
    if (settings.adMetricId !== 'adSpend') {
        return '';
    }
    curr = pickCurrency(curr || settings.currency);
    return rates[curr].symbol;
}

export {convert, unconvert, format, guessFormat, symbol,
    pickCurrency,
    guessCurrency,
    countryCurrency as currencyIdByCountry, useSettings}

