import common from './publisherUiCommonSlice'
import creatives from './creativesSlice'
import {combineReducers} from '@reduxjs/toolkit'

const creativesReducer = combineReducers({
    common,
    creatives
})


export default creativesReducer
