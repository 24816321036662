import axios from '../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import _ from 'lodash';

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const orderListKey = 'user_dashboard_publisher_list'
const savePubOrder = newPublishers => {
    window.localStorage.setItem(
        orderListKey,
        newPublishers.join(',')
    );
};
const customOrder = (publishersToOrder) => {
    let publishers = [...publishersToOrder];
    const pubList = (window.localStorage
        .getItem(orderListKey) || '').split(',');
    publishers.sort((a, b) => pubList.findIndex(d => d === a) - 
        pubList.findIndex(d => d === b));
    return publishers; 
};

const preProcessEvents = events => events
    .map(d => {
        d.dateCmp = d.firstSeen
        if ('shifts' in d) {
            d.dateCmp = d.eventDate
        }
        return d;
    })
    .sort((a, b) => b.dateCmp - a.dateCmp)
    .map((d, idx) => {
        d.idx = idx;
        return d;
    })

const fetchPublisherSpendTrendline = createAsyncThunk(
    'fetchPublisherSpendTrendLine',
    async (arg) => {
        let entities = []

        let query = arg.query

        entities = [{
            publisher: arg.publisher,
            platform: 'desktop'
        }]

        if (arg.hasMobile) {
            entities.push({
                publisher: arg.publisher,
                platform: 'mobile'
            })
        }
        
        const params = {
            ...query,
            includeEntities: JSON.stringify(entities)
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)


const fetchDashboardPublishers = createAsyncThunk(
    'fetchDashboardPublishers',
    async () => {
        const request = axios.get("/callisto/dashboard-publishers", {
            cancelToken: getCancelSource().token
        })

        const response = await request

        let result = response.data
        return result
    })

const deleteDashboardPublisher = createAsyncThunk(
    'deleteDashboardPublisher',
    async (arg) => {
        const request = axios.delete(
            "/callisto/dashboard-publishers/" + arg.publisher, {
                cancelToken: getCancelSource().token
            })

        const response = await request

        let result = response.data
        return result
    })

const fetchDashboardPublisherEvents = createAsyncThunk(
    'fetchDashboardPublisherEvents',
    async (arg) => {
        
        const request = axios.get("/callisto/user-dashboard/publisher-events", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        const response = await request

        let result = response.data
        return result
    });



const userDashboardPublisherSlice = createSlice({
    name: 'userDashboardPublisherSlice',
    initialState: {
        publishers: {},
        publisherSpendTrendline: {},
        publisherSpendTrendlineTotals: {},
        orderBy: 'latest',
        orderedPublishers: [],
        deletePublisher: {
            status: 'idle'
        },
        publisherList: {
            data: [],
            status: 'idle'
        } 
    },
    reducers: {
        reset(state) {
            state.publishers = {} 
            state.publisherSpendTrendline = {}
            state.publisherSpendTrendlineTotals = {}
            state.publisherList = {
                data: [],
                status: 'idle'
            }
        },
        resetPublisher(state, action) {
            const publisher = action.payload;
            state.publisherSpendTrendline[publisher] = {
                data: {},
                status: 'idle'
            }
            state.publishers[publisher] = {
               data: [],
               status: 'idle'
            };
        },
        resetList(state) {
            state.publisherList = {
                data: [],
                status: 'idle'
            }
        },
        setOrderBy(state, action) {
            state.orderBy = action.payload;
            userDashboardPublisherSlice.caseReducers.orderPublishers(state, action);
        },
        orderPublishers(state) {
            if (state === undefined) {
                return;
            }
            let publishers = [...current(state).orderedPublishers];
            if (publishers.length === 0) {
                return;
            }

            if (state.orderBy === 'name') {
                publishers.sort()
            } else if (state.orderBy === 'custom') {
                publishers = customOrder(publishers);
            } else if (state.orderBy === 'latest') {
                const eventData = current(state).publishers;
                const latestEvents = {};
                Object.keys(eventData)
                .forEach(publisherId => {
                    latestEvents[publisherId] = eventData[publisherId].data?.[0]?.firstSeen ?? 0;
                });
                if (!_.isEmpty(latestEvents)) {
                    publishers.sort((a, b) => latestEvents[b] - latestEvents[a]);
                }
            }
            state.orderedPublishers = publishers;
        },
        setOrderedPublishers(state, action) {
            state.orderedPublishers = action.payload
        }
    },
    extraReducers: {
        [fetchDashboardPublishers.pending]: (state) => {
            state.publisherList.status = 'pending' 
        },
        [fetchDashboardPublishers.fulfilled]: (state, action) => {
            state.publisherList.data = action.payload.publishers
            state.publisherList.status = 'done'
            userDashboardPublisherSlice.caseReducers.orderPublishers();
        },
        [deleteDashboardPublisher.pending]: (state) => {
            state.deletePublisher.status = 'pending' 
        },
        [deleteDashboardPublisher.fulfilled]: (state) => {
            state.deletePublisher.status = 'idle'
        },
        [fetchDashboardPublisherEvents.pending]: (state, action) => {
            const publisher = action.meta.arg.publisher;
            state.publishers[publisher] = {
                data: [],
                status: 'pending'
            }
        },
        [fetchDashboardPublisherEvents.fulfilled]: (state, action) => {
            const publisher = action.meta.arg.publisher;

            if (action.payload.length > 0) {
                state.publishers[publisher].data = preProcessEvents(action.payload)
                state.publishers[publisher].status = 'done';
            }
            else {
                state.publishers[publisher].data = [] 
                state.publishers[publisher].status = 'done';
            }
        },
        [fetchPublisherSpendTrendline.pending]: (state, action) => {
            const publisher = action.meta.arg.publisher;
            state.publisherSpendTrendline[publisher] = {
                data: [],
                status: 'pending'
            }
        },
        [fetchPublisherSpendTrendline.fulfilled]: (state, action) => {
            const publisher = action.meta.arg.publisher;
            
            if (action.payload.status.toLowerCase() === 'ok') {
                const data = action.payload.rows 
                const sums = {}
                data.map(d => d.metrics).forEach(d => {
                    for (let metric in d) {
                        sums[metric] = sums[metric] || 0
                        sums[metric] += d[metric].reduce((a, b) => a + b)
                    }
                })
                state.publisherSpendTrendline[publisher].data = data
                state.publisherSpendTrendlineTotals[publisher] = sums 
                state.publisherSpendTrendline[publisher].status = 'done';
            }
            else {
                state.publisherSpendTrendline[publisher].data = [] 
                state.publisherSpendTrendlineTotals[publisher] = {}
                state.publisherSpendTrendline[publisher].status = 'done';
            }
            userDashboardPublisherSlice.caseReducers.orderPublishers(state);
        }
    }
})

const orderedPublishersSelector = state => state.userDashboard.publisherEvents.orderedPublishers
const orderBySelector = state => state.userDashboard.events.orderBy;

const userDashboardPublisherSelector = publisher => state => {
    if (_.isEmpty(publisher) ||
        _.isEmpty(state.userDashboard.publisherEvents.publishers) ||
        _.isEmpty(state.userDashboard.publisherEvents.publishers[publisher])) {
        
        return {
           data: [],
           status: 'idle'
        };
    }
    return state.userDashboard.publisherEvents.publishers[publisher];
}
const userDashboardPublisherSpendTrendlineSelector = publisher => state => {
    if (_.isEmpty(publisher) ||
        _.isEmpty(state.userDashboard.publisherEvents.publisherSpendTrendline) ||
        _.isEmpty(state.userDashboard.publisherEvents.publisherSpendTrendline[publisher])) {
        
        return {
           data: [],
           status: 'idle'
        };
    }
    return state.userDashboard.publisherEvents.publisherSpendTrendline[publisher];
}
const userDashboardPublisherSpendTotalsSelector = publisher => state => {
    if (_.isEmpty(publisher) ||
        _.isEmpty(state.userDashboard.publisherEvents.publisherSpendTrendlineTotals) ||
        _.isEmpty(state.userDashboard.publisherEvents.publisherSpendTrendlineTotals[publisher])) {
        
        return {
           data: [],
           status: 'idle'
        };
    }
    return state.userDashboard.publisherEvents.publisherSpendTrendlineTotals[publisher];
}
const userDashboardPublisherListSelector = state => state.userDashboard.publisherEvents.publisherList
export const {
    reset,
    resetPublisher,
    resetList,
    setOrderedPublishers,
    orderPublishers,
    setOrderBy
} = userDashboardPublisherSlice.actions
export { fetchDashboardPublisherEvents, 
    fetchDashboardPublishers,
    fetchPublisherSpendTrendline,
    deleteDashboardPublisher,
    orderedPublishersSelector,
    userDashboardPublisherSpendTrendlineSelector,
    userDashboardPublisherSpendTotalsSelector,
    userDashboardPublisherListSelector,
    userDashboardPublisherSelector,
    savePubOrder,
    orderBySelector,
    preProcessEvents
 }
export default userDashboardPublisherSlice.reducer
