import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvOverviewNewPublishers = createAsyncThunk(
    'advOverviewNewPublishers',
    async (arg) => {
        const response = await axios.get("/data-api/publishers", {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const advOverviewNewPublishersSlice = createSlice({
    name: 'advOverviewNewPublishersSlice',
    initialState: {
        status: 'idle',
        data: [],
    },
    reducers: {
        resetAdvOverviewNewPublishers(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvOverviewNewPublishers.pending]: (state) => {
            state.status = 'loading'
            state.data = []
        },
        [fetchAdvOverviewNewPublishers.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})


const advOverviewNewPublisherSelector = state => state.advertiserProfile.overview.newPublishers
export { fetchAdvOverviewNewPublishers, advOverviewNewPublisherSelector }
export const {resetAdvOverviewNewPublishers} = advOverviewNewPublishersSlice.actions
export default advOverviewNewPublishersSlice.reducer