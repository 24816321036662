import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import processSettings from '../common/settings'
import axios from '../common/axiosRateLimit'

const fetchSettings = createAsyncThunk('settings',
    async () => {
        const response = await axios.get('/user/get-settings')
        return response.data
    })

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        settings: {},
        status: 'init'
    },
    reducers: {
        updateSettings(state, action) {
            state.settings = Object.assign(state.settings, action.payload)
        }
    },
    extraReducers: {
        [fetchSettings.fulfilled]: (state, action) => {
            state.settings = processSettings(action.payload)
            state.status = 'succeeded'
        },
        [fetchSettings.rejected]: (state, action) => {
            if (action.error.message === 'unauthorized') {
                window.location.href = window.AdbeatSettings.loginUrl
            }
        }
    },
})

const settingsSelector = (state) => state.settings.settings

export {fetchSettings, settingsSelector}
export const {updateSettings} = settingsSlice.actions

export default settingsSlice.reducer