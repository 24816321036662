import { combineReducers } from "redux";
import totals from './metaSlice'
import channels from './channelsSlice'
import similarProfiles from "./similar-profiles/similarSlice";

const reducer = combineReducers({
    totals,
    channels,
    similarProfiles
})

export default reducer