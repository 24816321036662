import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from '../common/axiosRateLimit'

const fetchPaymentStatus = createAsyncThunk('fetchPaymentStatus',
    async (arg) => {
        const response = await axios.get('/callisto/ccnotifications/list')

        return response.data
    })

const paymentNotificationsSlice = createSlice({
    name: 'paymentNotifications',
    initialState: {
        notifications: {},
        status: 'idle',
    },
    reducers: {
        reset(state, action) {
            state.notifications = {}
        }
    },
    extraReducers: {
        [fetchPaymentStatus.fulfilled]: (state, action) => {
            state.notifications = action.payload
            state.status = 'done'
        },
    },
})


export const {reset} = paymentNotificationsSlice.actions
const paymentMethodNoticeSelector = state => state.paymentMethodNotice
export {paymentMethodNoticeSelector, fetchPaymentStatus}
export default paymentNotificationsSlice.reducer