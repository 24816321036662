import {useEffect} from "react"
import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import {useSelector} from "react-redux";
import {settingsSelector} from "../reducers/settings";

const SentryInit = () => {
    const settings = useSelector(settingsSelector)

    useEffect(() => {
        if (!settings.sentry || !settings.sentry.enabled) {
            return
        }
        Sentry.init({
            dsn: settings.sentry.dsn,

            // Alternatively, use `process.env.npm_package_version` for a dynamic release version
            // if your build tool supports it.
            integrations: [new Integrations.BrowserTracing()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        })
    }, [settings.sentry])

    return null;
}


export default SentryInit