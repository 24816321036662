import predefinedSets from './predefinedPublishersSlice'
import ownedSites from './ownedSitesSlice'
import {combineReducers} from '@reduxjs/toolkit'

const competitiveSetsReducer = combineReducers({
    predefinedSets,
    ownedSites
})


export default competitiveSetsReducer
