import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'

const fetchDmaReportPeriods = createAsyncThunk('fetchDmaReportPeriods',
    async (arg) => {
        const response = await axios.get(
            '/callisto/top-advertisers-reports/folders',
            {params: arg.query})
        return response.data
    })


const fetchDmaReports = createAsyncThunk('fetchDmaReports',
    async (arg) => {
        const response = await axios.get(
            '/callisto/top-advertisers-reports/links',
            {params: arg.query}
        )

        return response.data
    })


const advertiserDmaReportsSlice = createSlice({
    name: 'advertiserDmaReportsTool',
    initialState: {
        periods: {
            'status': 'idle',
            'data': {}
        },
        reports: {
            'status': 'idle',
            'data': {}
        },
        errors: [],
    },
    reducers: {
        resetErrors(state) {
            state.errors = []
        },
        resetReports(state) {
            state.reports.status = 'idle'
            state.reports.data = {}
        }
    },
    extraReducers: {
        [fetchDmaReportPeriods.rejected]: (state) => {
            state.errors = ["Cannot load data"]
        },
        [fetchDmaReportPeriods.fulfilled]: (state, action) => {
            state.periods.status = 'done'
            if (action.payload.status === 'error') {
                state.errors = [action.payload.message]
                return
            }

            state.periods.data = action.payload.folders
        },
        [fetchDmaReports.rejected]: (state) => {
            state.errors = ["Cannot load data"]
        },
        [fetchDmaReports.fulfilled]: (state, action) => {
            state.reports.status = 'done'
            if (action.payload.status === 'error') {
                state.errors = [action.payload.message]
                return
            }

            state.reports.data = action.payload.links
        }
    }
})


const selectPeriods = (state) => state.advertiserDmaReportsTool.periods
const selectReports = (state) => state.advertiserDmaReportsTool.reports
const selectErrors = (state) => state.advertiserDmaReportsTool.errors

export {
    selectPeriods, selectReports, selectErrors,
    fetchDmaReportPeriods, fetchDmaReports
}

export const {
    resetErrors, resetReports
} = advertiserDmaReportsSlice.actions

export default advertiserDmaReportsSlice.reducer