import './App.scss';
import { AdBlockDetectedWrapper } from 'adblock-detect-react'
import Header from './components/layout/Header'
import Nav from './components/layout/Nav'
import axios from './common/axiosRateLimit'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AdModal from './components/modals/AdModal'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { fetchSettings, settingsSelector } from './reducers/settings'
import { fetchTooltips, tooltipsSelector } from './reducers/tooltips'
import _ from 'lodash'
import ProductList from './components/navigation/ProductList';
import ReactTooltip from "react-tooltip";
import UpgradeModal from './components/modals/UpgradeModal';
import GenerateReportModal from './components/reports/GenerateReportModal'
import ScheduledReportDialog from './components/reports/ScheduledReportDialog'
import ScheduledProfileReportDialog from './components/reports/ScheduledProfileReportDialog'
import ScheduledMoversAndShakersDialog from './components/reports/ScheduledMoversAndShakersDialog'
import GenerateProfileReportModal from './components/reports/GenerateProfileReportModal'
import FollowAdvertiserModal from './components/alerts/FollowAdvertiserModal'
import FollowPublisherModal from './components/alerts/FollowPublisherModal'
import CreateCollection, { AddToCollection } from './components/modals/AdLocker'
import SplashScreen from './SplashScreen'

import Notification from './components/Notification';
import ConfirmAction from './components/modals/ConfirmAction';
import SentryInit from "./components/SentryInit";
import ErrorBoundary from "./components/ErrorBoundary";
import Intercom from "./components/Intercom";
import { initPubUiFilters as initNewBusinessFilters } from './pages/publisher_ui/newBusiness/slices/publisherUiCommonSlice';
import { initPubUiFilters as initLargestAdvertisersFilters } from './pages/publisher_ui/largestAdvertisers/slices/publisherUiCommonSlice';
import { initPubUiFilters as initAdvOverviewFilters } from './pages/publisher_ui/advertiserOverview/slices/publisherUiCommonSlice';
import { initPubUiFilters as initCreativesFilters } from './pages/publisher_ui/creatives/slices/publisherUiCommonSlice';
import { initPubUiFilters as initPubUiOverviewFilters } from './pages/publisher_ui/overview/publisherUiCommonSlice';
import trackUser from './tracking/dataLayer';
import asyncComponent from './components/AsyncComponent';
import PaymentMethodNotice from './components/PaymentMethodNotice';
import UserProfileDashboardSettings from './pages/user-profile/UserProfileDashboardSettings';
const TopAdvertisers = asyncComponent(() => import('./pages/top-advertisers/TopAdvertisers'))
const TopAds = asyncComponent(() => import('./pages/top-ads/TopAds'))
const Reports = asyncComponent(() => import('./pages/reports/Reports'))
const Search = asyncComponent(() => import('./pages/search/Search'))
const AdLockerList = asyncComponent(() => import('./pages/ad-locker/AdLockerList'))
const AdLockerAdList = asyncComponent(() => import('./pages/ad-locker/AdList'))
const Advertiser = asyncComponent(() => import('./pages/advertiser-profile/Advertiser'))
const MoversAndShakers = asyncComponent(() => import("./pages/movers-and-shakers/MoversAndShakers"))
const Alerts = asyncComponent(() => import("./pages/alerts/Alerts"))
const ScheduledReports = asyncComponent(() => import('./pages/scheduled-reports/ScheduledReports'))
const Inbox = asyncComponent(() => import("./pages/inbox/Inbox"))
const ToolsList = asyncComponent(() => import("./pages/tools/ToolsList"))
const AdvertiserCategoryExplorer = asyncComponent(() => import("./pages/tools/advertiserCategoryExplorer/AdvertiserCategoryExporer"))
const AdvertiserDataEnrich = asyncComponent(() => import("./pages/tools/advertiserDataEnrich/AdvertiserDataEnrich"))
const TopAdvertisersDmaReports = asyncComponent(() => import("./pages/tools/topAdvertisersDmaReports/TopAdvertisersDmaReports"))
const TopAdvertiserDmaReportsLegacy = asyncComponent(() => import("./pages/tools/topAdvertisersDmaReports/TopAdvertisersDmaReportsLegacy"))
const VerticalReport = asyncComponent(() => import("./pages/tools/verticalReport/VerticalReport"))
const ChooseLogoModal = asyncComponent(() => import("./pages/tools/verticalReport/form/ChooseLogoModal"))
const VerticalReportGenerated = asyncComponent(() => import("./pages/tools/verticalReport/VerticalReportGenerated"))
const AdvertiserCompare = asyncComponent(() => import("./pages/tools/advertiserCompare/AdvertiserCompare"))
const GroupManager = asyncComponent(() => import("./pages/tools/groupManager/GroupManager"))
const NetworkDashboard = asyncComponent(() => import("./pages/network/NetworkDashboard"))
const NetworkDashboardShortUrl = asyncComponent(() => import("./pages/network/NetworkDashboardShortUrl"))
const Bookmarks = asyncComponent(() => import("./pages/bookmarks/Bookmarks"))
const PublisherCompetitiveOverview = asyncComponent(() => import("./pages/publisher_ui/overview/PublisherCompetitiveOverview"))
const NewBusiness = asyncComponent(() => import("./pages/publisher_ui/newBusiness/NewBusiness"))
const ExpansionOpportunities = asyncComponent(() => import("./pages/publisher_ui/newBusiness/ExpansionOpportunities"))
const TopAdvertisersByImpressions = asyncComponent(() => import("./pages/publisher_ui/newBusiness/TopAdvertisersByImpressions"))
const TopAdvertisersByCreatives = asyncComponent(() => import("./pages/publisher_ui/newBusiness/TopAdvertisersByCreatives"))
const LargestAdvertisersByAdSpend = asyncComponent(() => import("./pages/publisher_ui/largestAdvertisers/LargestAdvertisersByAdSpend"))
const LargestAdvertisersByCreative = asyncComponent(() => import("./pages/publisher_ui/largestAdvertisers/LargestAdvertisersByCreative"))
const BrandProfile = asyncComponent(() => import('./pages/brand-profile/BrandProfile'))
const PublisherUiCreatives = asyncComponent(() => import("./pages/publisher_ui/creatives/PublisherUiCreatives"))
const PublisherUICompetitiveSets = asyncComponent(() => import("./pages/publisher_ui/competitiveSets/PublisherUICompetitiveSets"))
const CampaignProfile = asyncComponent(() => import('./pages/campaign-profile/CampaignProfile'))
const PublisherProfile = asyncComponent(() => import('./pages/publisher-profile/PublisherProfile'))
const PublisherUiScheduledReports = asyncComponent(() => import("./pages/publisher_ui/scheduledReports/PublisherUiScheduledReports"))
const PublisherUIAdvertiserOverview = asyncComponent(() => import("./pages/publisher_ui/advertiserOverview/PublisherUIAdvertiserOverview"))
const LearningCenter = asyncComponent(() => import("./pages/learning-center/LearningCenter"))
const ChannelProfile = asyncComponent(() => import("./pages/channel-profile/ChannelProfile"))
const YoutubeChannelProfile = asyncComponent(() => import("./pages/youtube-channel-profile/YoutubeChannelProfile"))
const UserDashboard = asyncComponent(() => import("./pages/user-dashboard/UserDashboard"))

function App() {
  const settings = useSelector(settingsSelector)
  const tooltips = useSelector(tooltipsSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!_.isEmpty(settings)) {
      dispatch(initNewBusinessFilters(settings))
      dispatch(initLargestAdvertisersFilters(settings))
      dispatch(initAdvOverviewFilters(settings))
      dispatch(initCreativesFilters(settings))
      dispatch(initPubUiOverviewFilters(settings))
      trackUser(settings)
      return
    }

    axios.get('/user/login-status')
      .then((response) => {
        if (response.data.isLoggedIn === false) {
          window.location.href = response.data.loginUrl
        } else {
          if (_.isEmpty(settings)) {
            dispatch(fetchSettings())
          }
          if (_.isEmpty(tooltips)) {
            dispatch(fetchTooltips())
          }
        }
      })
  }, [dispatch, settings, tooltips])

  if (_.isEmpty(settings)) {
    return <SplashScreen />
  }

  return (
    <>
      <ConfirmAction></ConfirmAction>
      <AdModal></AdModal>
      <UpgradeModal></UpgradeModal>
      <GenerateReportModal></GenerateReportModal>
      <ScheduledReportDialog />
      <ScheduledProfileReportDialog />
      <ScheduledMoversAndShakersDialog />
      <GenerateProfileReportModal></GenerateProfileReportModal>
      <FollowAdvertiserModal />
      <FollowPublisherModal />
      <ChooseLogoModal></ChooseLogoModal>
      <CreateCollection></CreateCollection>
      <AddToCollection></AddToCollection>

      <Router>
        {settings &&
          <>
            <Header></Header>
            <AdBlockDetectedWrapper>
              <div className='main'>
                <div className="no-data clear">
                  <h3>It looks like you're using Ad Block</h3>
                  <div className="description">
                    Please disable your Ad Block, and reload the page.
                  </div>
                </div>
              </div>
            </AdBlockDetectedWrapper>
            <ErrorBoundary extraClass="main">
              <Switch>
                <Route path="/advertiser/:country/:platform/:advertiser">
                  <Advertiser></Advertiser>
                </Route>
                <Route path="/advertiser/:advertiser"
                  render={({ match }) => (
                    <Redirect to={`/advertiser/us/desktop/${match.params.advertiser}`} />
                  )}>
                </Route>

                <Route path="/brand/:country/:platform/:brand/:advertiser">
                  <BrandProfile></BrandProfile>
                </Route>

                <Route path="/campaign/:country/:platform/:campaign/:advertiser">
                  <CampaignProfile></CampaignProfile>
                </Route>

                <Route path="/publisher/:country/:platform/:publisher">
                  <PublisherProfile></PublisherProfile>
                </Route>

                <Route path="/channel/:country/:platform/youtube.com%2Fchannel%2F:channel">
                  <YoutubeChannelProfile></YoutubeChannelProfile>
                </Route>

                <Route path="/channel/:country/:platform/youtube.com/channel/:channel">
                  <YoutubeChannelProfile></YoutubeChannelProfile>
                </Route>

                <Route path="/channel/:country/:platform/:channel">
                  <ChannelProfile></ChannelProfile>
                </Route>

                <Route path="/search">
                  <Search></Search>
                </Route>

                <Route path="/ad_locker/collection/:id/:pageNumber?">
                  <AdLockerAdList></AdLockerAdList>
                </Route>

                <Route path="/ad_locker/:pageNumber?">
                  <AdLockerList></AdLockerList>
                </Route>

                <Route path="/ad_locker">
                  <AdLockerList></AdLockerList>
                </Route>

                <Route path="/top-advertisers/:country?/:platform?/:dateRangePreset?/:network?/:sortBy?/:sortOrder?/:pageNumber?/:category?"
                  component={TopAdvertisers}></Route>

                <Route path="/top-advertisers"
                  component={TopAdvertisers}></Route>

                <Route path="/top-ads/:country?/:platform?/:q?/:queryField?/:dateRangePreset?/:network?/:adType?/:adSize?/:advertisers?/:sortBy?/:sortOrder?/:pageNumber?"
                  component={TopAds}></Route>

                <Route path="/top-ads"
                  component={TopAds}></Route>

                <Route path="/reports"
                  component={Reports}></Route>

                <Route path="/movers-and-shakers/:country?/:network?/:resourceType?/:category?"
                  component={MoversAndShakers}></Route>
                <Route path="/movers-and-shakers"
                  component={MoversAndShakers}></Route>

                <Route path="/alerts"
                  component={Alerts}></Route>

                <Route path="/scheduled-reports"
                  component={ScheduledReports}></Route>

                <Route path="/inbox"
                  component={Inbox}></Route>

                <Route path="/bookmarks"
                  component={Bookmarks} />

                <Route path="/network/:country/:platform/:network/:section/default/:dateRangePreset/:sortBy/:sortOrder/:pageNumber" component={NetworkDashboard} />
                <Route path="/network/:country/:platform/:network" component={NetworkDashboardShortUrl} />

                <Route path="/dashboard/:country/:platform/:metric" component={UserDashboard} />
                <Route path="/dashboard" component={UserDashboard} />

                <Route path="/tools/adv_data_enrich" component={AdvertiserDataEnrich}></Route>
                <Route path="/tools/advertiser_category_explorer" component={AdvertiserCategoryExplorer}></Route>
                <Route path="/tools/tool-list" component={ToolsList}></Route>
                <Route path="/tools/top_advertisers_dma_reports" component={TopAdvertisersDmaReports}></Route>
                <Route path="/tools/legacy_top_advertisers_dma_reports" component={TopAdvertiserDmaReportsLegacy}></Route>
                <Route path="/tools/vertical_report" component={VerticalReport}></Route>
                <Route path="/tools/vertical_report_print/:reportId" component={VerticalReportGenerated}></Route>
                <Route path="/tools/advertiser_compare/:country/:platform/:dateRangePreset/:advertisers"
                  component={AdvertiserCompare}></Route>
                <Route path="/tools/advertiser_compare" component={AdvertiserCompare}></Route>
                <Route path="/tools/group_manager" component={GroupManager}></Route>
                <Route path="/tools"><Redirect to="/tools/tool-list" /></Route>

                <Route path="/publisher_competitive_overview/:publisherSet" component={PublisherCompetitiveOverview} />
                <Route path="/publisher_competitive_overview" component={PublisherCompetitiveOverview} />

                <Route path="/new_business/:publisherSet" component={NewBusiness} />
                <Route path="/new_business" component={NewBusiness} />

                <Route path="/expansion_opportunities/:publisherSet" component={ExpansionOpportunities} />
                <Route path="/expansion_opportunities" component={ExpansionOpportunities} />

                <Route path="/advertisers_by_impressions/:publisherSet" component={TopAdvertisersByImpressions} />
                <Route path="/advertisers_by_impressions" component={TopAdvertisersByImpressions} />

                <Route path="/advertisers_by_creative/:publisherSet" component={TopAdvertisersByCreatives} />
                <Route path="/advertisers_by_creative" component={TopAdvertisersByCreatives} />

                <Route path="/advertisers_by_spend/:publisherSet" component={LargestAdvertisersByAdSpend} />
                <Route path="/advertisers_by_spend" component={LargestAdvertisersByAdSpend} />

                <Route path="/advertisers_by_creatives_spend/:publisherSet" component={LargestAdvertisersByCreative} />
                <Route path="/advertisers_by_creatives_spend" component={LargestAdvertisersByCreative} />

                <Route path="/new_creatives/:publisherSet" component={PublisherUiCreatives} />
                <Route path="/new_creatives" component={PublisherUiCreatives} />

                <Route path="/competitive_sets" component={PublisherUICompetitiveSets} />

                <Route path="/scheduled_reports" component={PublisherUiScheduledReports} />

                <Route path="/advertiser_overview/:advertiser/:publisherSet" component={PublisherUIAdvertiserOverview} />
                <Route path="/advertiser_overview/:advertiser" component={PublisherUIAdvertiserOverview} />

                <Route path="/learning-center/videos/:activeVideo" component={LearningCenter} />
                <Route path="/learning-center" component={LearningCenter} />

                <Route path="/user-profile-v2/dashboard-settings" component={UserProfileDashboardSettings} />

                <Route path="/">
                  {settings.show_publisher_ui &&
                    <Redirect to="/publisher_competitive_overview" />}
                  {!settings.show_publisher_ui &&
                    <Redirect to="/dashboard" />}
                </Route>
              </Switch>
            </ErrorBoundary>

            <Notification></Notification>
            <PaymentMethodNotice />
            <Nav></Nav>
            <ProductList></ProductList>
            {!settings.isWinmoUser &&
              <Intercom />
            }
            <SentryInit />
          </>}
        <ReactTooltip id="generic"
          type="light"
          textColor="#7f8fa4"
          effect="solid"
          className="tooltipster-base tooltipster-html react-tooltip-adbeat"
          getContent={(dataTip) => <div className="tooltipster-content">{dataTip}</div>}></ReactTooltip>
        <ReactTooltip id="genericHtml"
          type="light"
          textColor="#7f8fa4"
          effect="solid"
          html={true}
          className="tooltipster-base tooltipster-html react-tooltip-adbeat"></ReactTooltip>
      </Router>
    </>
  );
}

export default App;
