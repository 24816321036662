import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from '../common/axiosRateLimit'
import qs from "qs";
import {resetRecentSearch} from "../pages/search/recentSearchesSlice";


const argToQuery = (arg) => {
    let query = {
        'term_type': arg.term_type,
        'country': arg.country,
        'platform': arg.platform,
        'term': arg.term
    }

    if (arg.keyword_type) {
        query.keyword_type = arg.keyword_type;
    }
    if (arg.date_range) {
        query.date_range = arg.date_range;
    }
    if (arg.link) {
        query.link = arg.link;
    }

    return query
}

const getQueryHash = (arg) => {
    let query = {
        'term_type': arg.term_type,
        'country': arg.country,
        'platform': arg.platform,
        'term': arg.term
    }

    return JSON.stringify(query, Object.keys(query).sort())
}


const recordSearch = createAsyncThunk('recordSearch',
    async (arg) => {
        const response = await axios.post('/callisto/add-search',
            qs.stringify(argToQuery(arg.params)))

        return response.data
    })


const runSearchSlice = createSlice({
    name: 'runSearch',
    initialState: {
        queryHash: null
    },
    reducers: {
        runSearch(state, action) {
            let query = argToQuery(action.payload)

            let queryHash = getQueryHash(query);
            if (state.queryHash !== queryHash) {
                action.asyncDispatch(recordSearch(action.payload))
            }
        }
    },
    extraReducers: {
        [recordSearch.fulfilled]: (state, action) => {
            let query = action.meta.arg.params
            state.queryHash = getQueryHash(query);
            action.asyncDispatch(resetRecentSearch())
        }
    },
})

export const {runSearch} = runSearchSlice.actions

export default runSearchSlice.reducer