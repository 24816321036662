import {createSlice} from "@reduxjs/toolkit";


const adFilterSettingsSlice = createSlice({
    name: 'adFilterSettings',
    initialState: {
        'video': {
            'adType': 'video',
            'adSize': 'all',
            'network': 'all',
            'channel': ''
        },
        'native': {
            'network': 'native',
            'adType': 'all',
            'adSize': 'all',
            'channel': ''
        },
        'standard': {
            'network': 'standard',
            'adType': 'all',
            'adSize': 'all',
            'channel': ''
        }
    },
    reducers: {
        setAdFilter(state, action) {
            let key = action.payload.key
            if (action.payload.hasOwnProperty('network')) {
                state[key]['network'] = action.payload.network
            }
            if (action.payload.hasOwnProperty('adType')) {
                state[key]['adType'] = action.payload.adType
            }
            if (action.payload.hasOwnProperty('adSize')) {
                state[key]['adSize'] = action.payload.adSize
            }
            if (action.payload.hasOwnProperty('channel')) {
                state[key]['channel'] = action.payload.channel
            }
        }
    }
})

const selectAdvertiserFilterState = (state) => state.advertiserProfile.ads.adFilterSettings
export { selectAdvertiserFilterState }
export const { setAdFilter } = adFilterSettingsSlice.actions
export default adFilterSettingsSlice.reducer
