import { isAfter, parse, addMonths } from 'date-fns'; 
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentStatus, paymentMethodNoticeSelector } from "../reducers/paymentMethodNotice";
import _ from 'lodash';
import { settingsSelector } from '../reducers/settings';


const PaymentMethodNotice = () => {
    const settings = useSelector(settingsSelector)
    const notificationClass = 'notification-height'
    const notification = useSelector(paymentMethodNoticeSelector);
    const dispatch = useDispatch();
    const element = useRef();
    const isDismissedKey = 'is_ccard_notification_dismissed'
    const dismissedValue = 'true'
    const isDismissed = () => window.sessionStorage.getItem(isDismissedKey) === dismissedValue 
    const firstRowQS = 'div.main .filters .first-row'
    const dismiss = () => {
        const firstRow = document.querySelector(firstRowQS)
        if (firstRow) {
            firstRow.classList.remove(notificationClass)
        }
        const searchbox = document.getElementById('searchbox')
        if (searchbox) {
            searchbox.classList.remove(notificationClass)
        }
        window.sessionStorage.setItem(isDismissedKey, dismissedValue)
    }

    const [isShown, setIsShown] = useState(!isDismissed())
    const [expiresSoon,  setExpiresSoon] = useState(false)
    const [problemCharging, setProblemCharging] = useState(false)

    useEffect(() => {
        if (notification.status === 'idle') {
            dispatch(fetchPaymentStatus())
        }
    }, [dispatch, notification.status])

    useEffect(() => {
        function isExpiringNextMonth(expirationMonth, expirationYear) {
            const expirationDate = parse(
                `01/${expirationMonth}/${expirationYear}`,
                'dd/MM/yyyy',
                new Date());
            const currentDate = addMonths(new Date(), 1);
            return isAfter(currentDate, expirationDate);
        }

        if (!_.isEmpty(notification.notifications)) {
            const {month, year} = notification.notifications.expiration;
            if (isExpiringNextMonth(month, year)) {
                setIsShown(!isDismissed())
                setExpiresSoon(true)
            }

            if (notification.notifications.last_charge_failed === true) {
                setIsShown(!isDismissed())
                setProblemCharging(true)
            }
        } else {
            setIsShown(false)
        }
    }, [notification.notifications])
    useEffect(() => {
        if (!isDismissed()) {
            const interval = window.setInterval(() => { 
                const main = document.querySelector('div.main')
                if (main) {
                    main.insertBefore(element.current, main.firstChild)
                    window.clearInterval(interval)
                }
            }, 1000);
        }
    }, [])

    useEffect(() => {
        const isSearchPage = window.location.pathname.startsWith('/search')
        const manageNotificationClass = (selector, cssClass, action) => {
            action = action || 'set';
            let attemptsLeft = 100;
            const interval = window.setInterval(() => {
                const element = document.querySelector(selector)
                if (element) {
                    if (action === 'set') {
                        element.classList.add(cssClass)
                    } else if (action === 'delete') {
                        element.classList.remove(cssClass)
                    }
                    window.clearInterval(interval)
                }

                attemptsLeft -= 1;  
                if (attemptsLeft === 0) {
                    window.clearInterval(interval)
                }
            }, 100)
        }
        if (!isDismissed()) {
            if (isShown) {
                manageNotificationClass(firstRowQS, notificationClass, 'set')
                if (isSearchPage) {
                    manageNotificationClass('#searchbox', notificationClass, 'set')
                }
            } else {
                manageNotificationClass(firstRowQS, notificationClass, 'delete')
                if (isSearchPage) {
                    manageNotificationClass('#searchbox', notificationClass, 'delete')
                }
            }
        }
    }, [isShown])

    return <div ref={element}
        className="feature-suggestion" style={{visibility: isShown ? 'visible' : 'hidden' }}>
        <div className="left">
            <i className="fa-light fa-sharp fa-exclamation-triangle"/>
        </div>
        <div className="description left">
            {expiresSoon && <span>Your credit card on file is expiring soon. Please <a href={settings.profileLink+ "/update_card"}>update your billing information.</a></span>}
            {problemCharging && <span>There's a problem with your credit card on file. Please <a href={settings.profileLink + "/update_card"}>update your billing information.</a></span>}
        </div>

        <div className="close right pointer"
                onClick={() => {
                    dismiss()
                    setIsShown(false)
                }}>&#10006;</div>
    </div>
}

export default PaymentMethodNotice
