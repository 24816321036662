import React from "react"

const Link = props => {
    return <a rel="noreferrer" href={props.url} target="_blank">{props.text}</a>;
}

function markdown_links(text) {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const regexNoGroup = /\[[^\]]+\]\([^)]+\)/g;
    const split_text = text.split(regexNoGroup);
    const matches = text.matchAll(regex);
    const result = [];
    let i = 0;
    for (const match of matches) {
        result.push(split_text[i]);
        i++;
        result.push(<Link key={match[2]} url={match[2]} text={match[1]} />);
    }
    if (i < split_text.length) {
        result.push(split_text[i]);
    }
    return <>{result.map(d => d)}</>;
}

const FormError = (props) => {
    const {hasErrors, name, errors} = props

    if (hasErrors && errors.hasOwnProperty(name)) {
        return (
            <p className="error">{markdown_links(errors[name][0])}</p>
        )
    }
    return ""
}

export default FormError