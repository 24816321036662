import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserTopCategories = createAsyncThunk(
    'fetchAdvertiserProfileCategories',
    async (arg) => {
        const params = {
            'rows': 20,
            'sortOrder': 'desc',
            ...arg.query
        }

        const response = await axios.get("/data-api/publisherCategories", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const categoriesSlice = createSlice({
    name: 'advertiserProfileTopCategories',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetAdvertiserCategories(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserTopCategories.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvertiserTopCategories.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserTopCategories = (state) => state.advertiserProfile.overview.byCategory
export const {resetAdvertiserCategories} = categoriesSlice.actions
export {selectAdvertiserTopCategories, fetchAdvertiserTopCategories}
export default categoriesSlice.reducer