const firstSeenAdjustedForPermissions = (firstSeenDate, settings) => {
    if (settings === undefined) {
        throw new Error("Missing required argument: settings");
    }
    const lastIndexDate = settings.last_index_date
    const firstDateOfPermissions = lastIndexDate - parseInt(settings.maxPeriod, 10) + 1

    if (firstSeenDate < firstDateOfPermissions) {
        return firstDateOfPermissions
    }
    return firstSeenDate

}

const daysSeenAdjustedForPermissions = (firstSeen, daysSeen, settings) => {
   const firstSeenFudged = firstSeenAdjustedForPermissions(firstSeen, settings)
   const lastIndexDate = settings.last_index_date
   const maxSeenForPlan = lastIndexDate - firstSeenFudged + 1
   if (maxSeenForPlan < daysSeen) {
       return maxSeenForPlan
   }
   return daysSeen 
}

export default firstSeenAdjustedForPermissions
export {
    daysSeenAdjustedForPermissions
}
