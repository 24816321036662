import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchPublisherTopAdTrends = createAsyncThunk('fetchPublisherTopAdTrends',
    async (arg) => {
        if (!arg.ads.length) {
            return
        }

        const entities = (ad) => {
            return {
                adHash: ad.adHash
            }
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.ads.map(entities))
        }

        delete params.pageNumber
        delete params.pageSize
        delete params.category
        delete params.sortBy
        delete params.sortOrder
        delete params.rows
        delete params.brand
        delete params.campaign

        params.metrics += ',globalSeenDates'

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


const fetchPublisherTopAds = createAsyncThunk('advPublisherTopAds',
    async (arg) => {
        const response = await axios.get('/data-api/ads', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        if ('rows' in response.data) {
            arg.dispatch(fetchPublisherTopAdTrends({
                ads: response.data.rows,
                query: {
                    ...arg.query
                }
            }))

            response.data.rows = response.data.rows.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendLine: {
                    globalDaysSeen: 0
                }
            }))
        }

        return response.data
    })

const fetchPublisherTrendline = createAsyncThunk('advPublisherTrendline',
    async (arg) => {
        const response = await axios.get('/data-api/trendlines', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const fetchAdvProfilePublishers = createAsyncThunk(
    'fetchAdvertiserProfilePublishers',
    async (arg) => {
        const params = {
            ...arg.query,
            entity: 'publisher'
        }

        const response = await axios.get("/data-api/publishers", {
            params: params,
            cancelToken: getCancelSource().token
        })

        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()
        response.data.rows.forEach(p => {
            arg.dispatch(fetchPublisherTopAds({
                dispatch: arg.dispatch,
                query: {
                    ...arg.query,
                    publisher: p.publisher,
                    pageNumber: '1',
                    pageSize: '2'
                }
            }))
            if (!p.channel) {
                arg.dispatch(fetchPublisherChannels({
                    query: {
                        ...arg.query,
                        publisher: p.publisher,
                        metrics: arg.query.metrics
                    }
                }))
            }
        });

        const trendlineQuery = { ...arg.query }
        delete trendlineQuery.rows
        delete trendlineQuery.sortBy
        delete trendlineQuery.sortOrder
        delete trendlineQuery.pageNumber
        delete trendlineQuery.pageSize
        delete trendlineQuery.brand
        delete trendlineQuery.campaign

        arg.dispatch(fetchPublisherTrendline({
            query: {
                ...trendlineQuery,
                metrics: arg.settings.metricField + ',globalSeenDates',
                includeEntities: JSON.stringify(
                    response.data.rows.map(p => ({
                        publisher: p.publisher
                    }))
                )
            }
        }))
        return response.data
    }
)

const fetchAdvPublisherSplitPlatformTrendlines = createAsyncThunk('advPublisherSplitPlatformTrendlines',
    async (arg) => {
        const response = await axios.get('/data-api/trendlines', {
            params: {
                ...arg.query,
                metrics: arg.settings.metricField,
                includeEntities: JSON.stringify([{
                    publisher: arg.publisher,
                    platform: 'desktop'
                },
                {
                    publisher: arg.publisher,
                    platform: 'mobile'
                }])
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const fetchPublisherChannels = createAsyncThunk('advPublisherChannels',
    async (arg) => {
        const query = { ...arg.query }
        query.pageNumber = 1
        const response = await axios.get('/data-api/channels', {
            params: query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const fetchAdvProfilePublisherAdTags = createAsyncThunk('advPublisherAdTags',
    async (arg) => {
        const response = await axios.get("/data-api/adPaths", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const fetchAdvProfilePublisherPlacementUrls = createAsyncThunk('advPublisherPlacementUrls',
    async (arg) => {
        const response = await axios.get("/data-api/placementUrls", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const publishersSlice = createSlice({
    name: 'advertiserProfilePublishers',
    initialState: {
        status: 'idle',
        topAdsStatus: 'idle',
        trendlineStatus: 'idle',
        data: [],
        publisherChannels: {},
        publisherAdTags: {},
        publisherPlacementUrls: {},
        platformTrendlines: {}
    },
    reducers: {
        resetAdvTopPublishers(state) {
            state.status = 'idle'
            state.topAdsStatus = 'idle'
            state.trendlineStatus = 'idle'
            state.data = []
            state.publisherChannels = {}
            state.publisherAdTags = {}
            state.publisherPlacementUrls = {}
            state.platformTrendlines = {}
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvProfilePublishers.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvProfilePublishers.fulfilled]: (state, action) => {
            state.status = 'done'
            state.extraPagesAvailable = action.payload.extraPagesAvailable
            state.data = action.payload.rows.map(r => ({
                ...r,
                ads: [],
                trendline: {}
            }))
            state.publisherChannels = {}
            action.payload.rows.forEach(r => {
                state.publisherChannels[r.publisher] = {
                    status: 'idle',
                    list: []
                }
            })
        },
        [fetchPublisherChannels.pending]: (state, action) => {
            state.publisherChannels[action.meta.arg.query.publisher] = {
                status: 'pending'
            }
        },
        [fetchPublisherChannels.fulfilled]: (state, action) => {
            state.publisherChannels[action.meta.arg.query.publisher] = {
                status: 'done',
                list: action.payload.rows
            }
        },
        [fetchAdvProfilePublisherAdTags.pending]: (state, action) => {
            const query = action.meta.arg.query
            state.publisherAdTags[query.channel || query.publisher] = {
                status: 'pending'
            }
        },
        [fetchAdvProfilePublisherAdTags.fulfilled]: (state, action) => {
            const query = action.meta.arg.query
            state.publisherAdTags[query.channel || query.publisher] = {
                status: 'done',
                list: action.payload.rows
            }
        },
        [fetchAdvProfilePublisherPlacementUrls.pending]: (state, action) => {
            const query = action.meta.arg.query
            state.publisherPlacementUrls[query.channel || query.publisher] = {
                status: 'pending',
                list: [],
            }
        },
        [fetchAdvProfilePublisherPlacementUrls.fulfilled]: (state, action) => {
            const query = action.meta.arg.query
            state.publisherPlacementUrls[query.channel || query.publisher] = {
                status: 'done',
                list: action.payload.rows
            }
        },
        [fetchPublisherTopAds.pending]: (state) => {
            state.topAdsStatus = 'loading'
        },
        [fetchPublisherTopAds.fulfilled]: (state, action) => {
            state.topAdsStatus = 'done'
            state.data = state.data.map(p => {
                const publisher = action.meta.arg.query.publisher
                if (publisher === p.publisher) {
                    return {
                        ...p,
                        ads: action.payload.rows
                    }
                }
                return p
            })
        },
        [fetchPublisherTrendline.pending]: (state) => {
            state.trendlineStatus = 'loading'
        },
        [fetchPublisherTrendline.fulfilled]: (state, action) => {
            state.trendlineStatus = 'done'
            state.data = state.data.map(p => {
                const row = action.payload.rows
                    .filter(r => r.entity.publisher === p.publisher)
                    .pop()

                return {
                    ...p,
                    trendline: row
                }
            })
        },
        [fetchPublisherTopAdTrends.fulfilled]: (state, action) => {
            state.data = state.data.map(p => {
                const publisher = action.meta.arg.query.publisher
                if (publisher === p.publisher) {
                    let ads = p.ads.map((ad) => {
                        let trendline = action.payload.rows.filter((item) => {
                            return item.entity.adHash === ad.adHash
                        }).shift()

                        return {
                            ...ad,
                            trendLine: trendline,
                            trendlineStatus: 'done'
                        }
                    })

                    return {
                        ...p,
                        ads: ads
                    }
                }
                return p
            })
        },
        [fetchAdvPublisherSplitPlatformTrendlines.pending]: (state, action) => {
            state.platformTrendlines[action.meta.arg.publisher] = {
                status: 'loading',
                data: []
            }
        },
        [fetchAdvPublisherSplitPlatformTrendlines.fulfilled]: (state, action) => {
            state.platformTrendlines[action.meta.arg.publisher] = {
                status: 'done',
                data: action.payload.rows
            }
        }
    }
})

const selectAdvProfilePublishers = state => state.advertiserProfile.publishers
const selectAdvProfilePublisherChannels = publisher =>
    state => (state.advertiserProfile.publishers.publisherChannels[publisher] ||
        { status: 'idle', list: [] })
const selectAdvProfilePublisherAdTags = publisher =>
    state => (state.advertiserProfile.publishers.publisherAdTags[publisher] ||
        { status: 'idle', list: [] })
const selectAdvProfilePublisherPlacementUrls = publisher =>
    state => (state.advertiserProfile.publishers.publisherPlacementUrls[publisher] ||
        { status: 'idle', list: [] })
const selectAdvProfilePublisherPlatformTrendlines = publisher =>
    state => (state.advertiserProfile.publishers.platformTrendlines[publisher] ||
        { status: 'idle', data: [] })
export {
    selectAdvProfilePublishers,
    selectAdvProfilePublisherChannels,
    selectAdvProfilePublisherAdTags,
    selectAdvProfilePublisherPlacementUrls,
    selectAdvProfilePublisherPlatformTrendlines,
    fetchAdvProfilePublishers,
    fetchAdvProfilePublisherAdTags,
    fetchAdvProfilePublisherPlacementUrls,
    fetchAdvPublisherSplitPlatformTrendlines
}
export const { resetAdvTopPublishers } = publishersSlice.actions
export default publishersSlice.reducer