import React, { useEffect, useCallback, useRef } from "react"

// eslint-disable-next-line
import selectize from 'selectize'
// eslint-disable-next-line
import selectizeMultiColumn from '../common/selectizeMultiColumn'
import jquery from 'jquery'
import { useDispatch, useSelector } from 'react-redux'
import { settingsSelector } from "../reducers/settings";
import Countries from "../common/countries";
import { getOnInitialize } from '../common/selectizeHideInput'
import usePrevious from "../hooks/usePrevious"
import _ from 'lodash'
import { showUpgradeModal } from "./modals/UpgradeModal"

function backgroundUrlCountryCode(id, upgradeRequiredValue) {
    const countryCode = id.replace(upgradeRequiredValue, '')
    return countryCode
}

const CountryList = React.memo((props) => {
    const prevValue = usePrevious(props.value)
    const settings = useSelector(settingsSelector)
    const selectElement = useRef(null)
    const { value, onChange } = props

    const dispatch = useDispatch()

    const onChangeWrapper = useCallback(value => {
        let strValue = value.toString()
        if (strValue.indexOf(settings.upgradeRequiredValue) >= 0) {
            showUpgradeModal(dispatch, {
                name: 'countryId',
                value: null
            })
            if (selectElement.current && selectElement.current.selectize) {
                selectElement.current.selectize.setValue(prevValue, true)
            }
        } else {
            selectElement.current.selectize.close()
            window.setTimeout(() => onChange(value), 10)
        }
    }, [onChange, selectElement, dispatch, prevValue, settings])
    const country = value

    const countries = new Countries(
        settings, settings.allAllowedCountries);
    
    const propCountries = props.countries || countries.list.map((item) => item.id)

    const prevCountries = usePrevious(propCountries)

    const defaultSource = countries.list.map((item) => item.id)

    const items = (function () {
        var keys = {};

        var source = propCountries
        if (!source || !source.length) {
            source = defaultSource;
        }

        var i;

        var isAllowed = function (item) {
            if (props.platformId) {
                return countries
                    .isAllowedCountryForPlatform(props.platformId, item.id);
            }
            return item.allowed;
        };

        if (source) {
            for (i = 0; i < source.length; i += 1) {
                keys[source[i]] = true;
            }
        }

        if (settings.hasCountryAll || props.allowCountryAll) {
            keys.all = true;
            if (countries.list.find(c => c.id === 'all') === undefined) {
               countries.list.push({
                 id: 'all',
                 name: 'All',
                 allowed: true
               });
            }
        }
        return countries.list
            .filter(function (item) {
                return keys[item.id];
            })
            .map(function (item) {
                return {
                    'text': item.id,
                    'value': isAllowed(item) ?
                        item.id :
                        settings.upgradeRequiredValue + item.id
                };
            });
    }())

    useEffect(() => {

        function renderItem(item, escape) {
            var class_ = 'capitalized';
            var text = item.text;
            
            if (item.value === 'all') {
                class_ = '';
                text = item.text.substr(0, 1).toUpperCase() +
                    item.text.substr(1);
            }
            
            const countryCode = item.value ?
                backgroundUrlCountryCode(item.value, settings.upgradeRequiredValue) :
                ''

            return '<div class="countryMenuItem item ' +
                countryCode +
                '">' +
                '<span class="' + class_ + '">' + escape(text) + '</span>' +
                '</div>';
        }

        const createSelectize = (updatedCountry) => {

            let selectizeOptions = props.options || {};

            selectizeOptions.plugins = selectizeOptions.plugins || {};

            // Do column groups
            selectizeOptions.plugins.column_groups = {
                columnGroups: props.countryAllNotPopular ? countries.groupsAllNotPopular : countries.groups,
                groupSize: 8,
                valuePrefix: settings.upgradeRequiredValue
            }

            selectizeOptions.render = {
                option: renderItem,
                item: renderItem
            }

            selectizeOptions.onInitialize = getOnInitialize(
                selectizeOptions, selectElement.current)

            const selector = jquery(selectElement.current).selectize({
                options: items,
                labelField: 'text',
                valueField: 'value',
                ...selectizeOptions
            })[0].selectize

            selector.off('change')
            selector.on('change', onChangeWrapper)

            selector.setValue(updatedCountry, true)

            return selector
        }

        if (selectElement.current &&
            (_.xor(propCountries, prevCountries).length ||
                props.value !== prevValue)) {
            createSelectize(country)
        }
    }, [country, countries.groups,
        countries.groupsAllNotPopular,
        props.countryAllNotPopular,
        items, onChangeWrapper,
        prevCountries, propCountries, props.options,
        props.value, prevValue,
        settings.upgradeRequiredValue, dispatch])

    if (selectElement.current && selectElement.current.selectize) {
        const selector = selectElement.current.selectize
        selector.off('change')
        selector.on('change', onChangeWrapper)
    }

    if (!propCountries.length) {
        if (jquery(selectElement.current).length &&
            jquery(selectElement.current)[0].selectize) {

            jquery(selectElement.current)[0].selectize.destroy()
        }
        return <country-list class={`tight-arrow country-id ${props.extraClass || ''}`}>
            <div className="selectize-control single">
                <div className="selectize-input item">
                    <div className="item">Loading...</div>
                </div>
            </div>
        </country-list>
    }

    return (
        <country-list class={`tight-arrow country-id ${props.extraClass || ''}`}>
            <select ref={selectElement} style={{ display: 'none' }} name={props.name || ""}>
            </select>
        </country-list>
    )
})

CountryList.displayName = 'CountryList'

export default CountryList
