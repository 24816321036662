
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../common/axiosRateLimit'
import qs from "qs";

const fetchGenericScheduledReports = createAsyncThunk('fetchGenericScheduledReports',
    async () => {
        let path = '/callisto/scheduled-report/list';

        const response = await axios.get(path, {
            params: {
                'rows': 100,
                'start': 0
            }
        })
        return response.data
    })

const removeGenericScheduledReport = createAsyncThunk('removeGenericScheduledReport',
    async (arg) => {
        let path = '/callisto/scheduled-report/delete';

        const response = await axios.post(path,
            qs.stringify({id: arg.id})
        )
        return response.data
    })

const scheduledReportsSlice = createSlice({
    name: 'genericScheduledReports',
    initialState: {
        scheduledReports: {
            status: 'idle',
            rows: [],
        },
        removeScheduledReport: {
            status: 'idle',
        },
        errors: [],
    },
    reducers: {
        resetErrors(state) {
            state.errors = []
        },
        resetScheduledReports(state) {
            state.scheduledReports.status = 'idle'
            state.removeScheduledReport.status = 'idle'
        }
    },
    extraReducers: {
        [fetchGenericScheduledReports.rejected]: (state) => {
            state.errors = ["Cannot load scheduledReports"]
            state.scheduledReports = []
        },
        [fetchGenericScheduledReports.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.scheduledReports.status = 'done'
                state.scheduledReports.rows = action.payload.rows
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
            else {
                state.errors = ['Cannot load scheduledReports']
            }
        },
        [removeGenericScheduledReport.fulfilled]: (state, action) => {
            if ('status' in action.payload && action.payload.status === 'ok') {
                state.removeScheduledReport.status = 'done'
                return
            }
            if ('status' in action.payload && action.payload.status === 'error') {
                state.errors = action.payload.errors
            }
            else {
                state.errors = ['Occurred problem with removing scheduledReport']
            }
        },
    }
})


const selectScheduledReports = (state) => state.genericScheduledReports.scheduledReports
const selectScheduledReportErrors = (state) => state.genericScheduledReports.errors
const selectRemoveScheduledReportStatus = (state) => state.genericScheduledReports.removeScheduledReport

export {
    selectScheduledReports, selectScheduledReportErrors,
    selectRemoveScheduledReportStatus,
    fetchGenericScheduledReports as fetchScheduledReports,
    removeGenericScheduledReport as removeScheduledReport
}

export const {
    resetErrors, resetScheduledReports
} = scheduledReportsSlice.actions

export default scheduledReportsSlice.reducer
