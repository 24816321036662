import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchChannelTrend = createAsyncThunk('fetchAdvProfileChannelTrend',
    async (arg) => {

        const response = await axios.get("/data-api/trendlines", {
            params: arg.query,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


const channelSlice = createSlice({
    name: 'advertiserProfileChannels',
    initialState: {
        trendlines: {}
    },
    reducers: {
        resetAdvPublisherChannels(state) {
            state.trendlines = {}
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchChannelTrend.pending]: (state, action) => {
            state.trendlines[action.meta.arg.channel] = {
                status: 'pending',
                list: []
            }
        },
        [fetchChannelTrend.fulfilled]: (state, action) => {
            state.trendlines[action.meta.arg.channel] = {
                status: 'done',
                list: action.payload.rows
            }
        },
    }
})


const selectAdvProfilePubChannelTrendlines = channel => state => (
    state.advertiserProfile.publisherChannels.trendlines[channel] || { status: 'idle', list: [] })
export const { resetAdvPublisherChannels } = channelSlice.actions
export {
    selectAdvProfilePubChannelTrendlines,
    fetchChannelTrend
}
export default channelSlice.reducer