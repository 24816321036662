import {useEffect, useState} from "react"
import ReactModal from "react-modal"
import {useDispatch, useSelector} from "react-redux"
import {hideModal, modalSelector} from "../../reducers/modal"
import { settingsSelector } from "../../reducers/settings"
import ScheduledCommonDialog from './ScheduledCommonDialog'
import {
    fetchCanGenerateReport,
    fetchGenerateReport,
    clearErrors,
    selectGenerateReport, fetchScheduleReport
} from "./reportsSlice";
import {notifyInfo} from "../../reducers/notification";
import LoadingState from "../LoadingState";

const ScheduledReportDialog = (props) => {
    const generateReport = useSelector(selectGenerateReport)
    const dispatch = useDispatch()
    const settings = useSelector(settingsSelector);
    const [reportType, setReportType] = useState('instant')
    const [frequency, setFrequency] = useState('monthly')
    
    useEffect(() => {
        if (generateReport.status === 'idle') {
            dispatch(fetchCanGenerateReport())
        }
    }, [dispatch, generateReport.status])

    useEffect(() => {
        if (generateReport.generateStatus === 'done' && !generateReport.errors.length) {
            dispatch(notifyInfo('Your report is being generated and will be saved on your Reports page'))
            dispatch(hideModal())
        }
    }, [dispatch, generateReport.generateStatus, generateReport.errors.length])

    useEffect(() => {
        if (generateReport.scheduleStatus === 'done' && !generateReport.errors.length) {
            dispatch(notifyInfo('Your report is being generated and will be saved on your Reports page'))
            dispatch(hideModal())
        }
    }, [dispatch, generateReport.scheduleStatus, generateReport.errors.length])


    const showTrialError = !generateReport.canGenerateReport && settings.user.is_trial

    const errors = generateReport.errors
    const handleSubmit = (e) => {
        e.preventDefault()

        if (reportType === 'instant') {
            dispatch(fetchGenerateReport({query: {
                ...props.query,
                metrics: props.metric,
                name: props.name,
                type: props.type,
                title: props.title,
                link: props.link 
            }}))
        }

        if (reportType === 'scheduled') {
            dispatch(fetchScheduleReport({query: {
                ...props.query,
                metrics: props.metrics,
                name: props.name,
                type: props.type,
                title: props.title,
                link: props.link,
                frequency
            }}))
        }
    }

    return (
        <ReactModal 
            onRequestClose={props.handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            isOpen={props.isOpen}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "fixed",
                    height: document.documentElement.scrollHeight
                },
                content: {
                    bottom: 'auto',
                    borderRadius: 0,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "fixed",
                    padding: 0,
                    overflow: 'none',
                    inset: "80px 40px auto 40px",
                },
            }}
            >
            <LoadingState show={generateReport.generateStatus === 'pending'}/>
            <LoadingState show={generateReport.scheduleStatus === 'pending'}/>
            <ScheduledCommonDialog 
                title={props.title}
                reportType={reportType}
                setReportType={setReportType}
                frequency={frequency}
                setFrequency={setFrequency}
                clearErrors={clearErrors}
                showTrialError={showTrialError}
                errors={errors}
                handleClose={props.handleClose}
                handleSubmit={handleSubmit}/>
        </ReactModal>
    )
}

const modalName = 'report-schedule-dialog'

const Wrapper = () => {
    const modal = useSelector(modalSelector) 
    const dispatch = useDispatch()

    if (modal.name === modalName) {
        return (
           <ScheduledReportDialog {...modal.params}
                isOpen={modal.name === modalName}
                handleClose={() => dispatch(hideModal())} />
        )
    }
    return null

}

export default Wrapper;
