import {useDispatch, useSelector} from "react-redux";
import {settingsSelector} from "../../reducers/settings";
import TrialError from "./TrialError";
import { showUpgradeModal } from "../modals/UpgradeModal";
import useTooltip from "../../hooks/useTooltip";

const ScheduledCommonDialog = (props) => {
    const settings = useSelector(settingsSelector)
    const dispatch = useDispatch()
    const tooltip = useTooltip('generic-scheduled-report')

    const handleUpgradeFlow = () => {
        showUpgradeModal(dispatch, {
            name: 'scheduledReports',
            value: true
        })
    }

    return (
            <div className="query-based-report">
                <form method="post" onSubmit={props.handleSubmit}>
                    <div className="section-header">
                        <h1 className="left">{props.title}</h1>
                        <div className="right close" onClick={props.handleClose}>
                            <i className="fa-light fa-sharp fa-close"></i>
                        </div>
                    </div>
                    <div className="section-body filters profile-header report-type">
                        <div>
                            <h2 className="title left">Select the type of report you would like to generate</h2>
                            <div className="help-icon left"
                                    data-tip
                                    data-for={tooltip.path}>
                                <i className="fa-sharp fa-light fa-circle-question fa-2x"/>
                            </div>
                        </div>

                        <h2 className="buttons">
                            <div className={`report-button ${props.reportType === 'instant' ? 'active' : ''}`}
                                onClick={() => props.setReportType('instant')}>
                                <div>
                                    <i className="fa-regular fa-light fa-sharp fa-3x fa-cloud-download"></i>
                                </div>
                                <div>
                                    <span>Instant</span>
                                </div>
                            </div>
                            
                            <div className={`report-button ${props.reportType === 'scheduled' ? 'active' : ''}`}
                                data-value={!settings.scheduled_reports ? settings.upgradeRequiredValue + 'scheduledReports' : ''}
                                onClick={() => settings.scheduled_reports ? 
                                    props.setReportType('scheduled') :
                                    handleUpgradeFlow()}>
                                <div>
                                    <i className="fa-regular fa-light fa-sharp fa-3x fa-calendar"></i>
                                </div>
                                <div>
                                    <span>Scheduled</span>
                                </div>
                            </div>

                            <div className="clear"></div>
                        </h2>

                        <div className="radio-buttons">
                            <div className="radio-button">
                                <input id="weekly-freq" type="radio" name="frequency"
                                    disabled={props.reportType !== 'scheduled'}
                                    value="weekly" checked={props.frequency === 'weekly'}
                                    onChange={(e) => props.setFrequency(e.target.value)}/>
                                <label
                                    className={props.reportType !== 'scheduled' ? 'disabled' : ''}
                                    htmlFor="weekly-freq">Run this report weekly</label>
                            </div>
                            <div className="radio-button">
                                <input id="monthly-freq" type="radio" name="frequency"
                                    value="monthly" checked={props.frequency === 'monthly'}
                                    disabled={props.reportType !== 'scheduled'}
                                    onChange={(e) => props.setFrequency(e.target.value)}/>
                                <label 
                                    className={props.reportType !== 'scheduled' ? 'disabled' : ''}
                                    htmlFor="monthly-freq">Run this report monthly</label>
                            </div>
                        </div>
                    </div>
                    <div className="section-footer clear">
                        <button className="save-alert">
                            {props.reportType === 'scheduled' ? 'Schedule ' : 'Generate ' } 
                            Report
                        </button>

                        <span className={
                            "error" + (props.errors.length > 0 ? "" : " hidden")
                        }>
                            {props.errors.map((e, idx) =>
                            <div key={idx}>
                                <span
                                    className="pointer"
                                    onClick={() => dispatch(props.clearErrors())}
                                    dangerouslySetInnerHTML={{__html: e}}
                                ></span><br />
                            </div>
                            )}
                        </span>
                    </div>
                    {props.showTrialError &&
                        <TrialError
                        onClick={() => dispatch(props.clearErrors())}
                        ></TrialError>
                    }
                </form>
                {tooltip.component}
            </div>
        )
}

export default ScheduledCommonDialog
