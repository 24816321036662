const shortenCampaignSuggestion = function (suggestion, maxLen) {
    maxLen = maxLen || 55;
    
    var ellipsis = '... (';
    
    var pair = suggestion
            .split('(');

    var campaignName = pair[0];
    var advertiserId = pair[1];
    
    if (suggestion.length > maxLen) {
        suggestion = campaignName
            .slice(0,
                   maxLen - ellipsis.length) +
            ellipsis +
            advertiserId;
    }

    return suggestion;
};

export {shortenCampaignSuggestion}