import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchByDateCreativeData = createAsyncThunk('fetchByDateCreativeData',
    async (arg) => {
        let entities = arg.advertisers.map((advertiser) => {
                return {advertiser}
            }
        )
        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(entities)
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params
        })
        return response.data
    })


const fetchCreativesTrendlines = createAsyncThunk('fetchCreativesTrendlines',
    async (arg) => {
        if (!arg.ads.length) {
            return
        }

        const entities = (ad) => {
            return {
                adHash: ad.adHash
            }
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.ads.map(entities))
        }

        delete params.sortBy
        delete params.sortOrder
        delete params.rows

        params.metrics += ',globalSeenDates'

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })


const fetchCreatives = createAsyncThunk('fetchCreatives',
    async (arg) => {
        const response = await axios.get("/data-api/ads", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(','),
                rows: 15,
            }
        })

        if ('rows' in response.data) {
            arg.dispatch(fetchCreativesTrendlines({
                ads: response.data.rows,
                query: {
                    ...arg.query
                }
            }))

            response.data.rows = response.data.rows.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendLine: {
                    globalDaysSeen: 0
                }
            }))
        }

        return response.data
    })


const fetchTopPhrases = createAsyncThunk('fetchTopPhrases',
    async (arg) => {
        const response = await axios.get("/data-api/topPhrases", {
            params: {
                ...arg.query,
                advertiser: arg.advertisers.join(',')
            }
        })

        return response.data
    })


const advertiserCreativeSlice = createSlice({
    name: 'advertiserCreative',
    initialState: {
        uniqueAds: {
            status: 'idle',
            data: []
        },
        creatives: {
            status: 'idle',
            data: []
        },
        phrases: {
            status: 'idle',
            data: []
        }
    },
    reducers: {
        setValue(state, action) {
            let {comparisonType, key, value} = action.payload
            state[comparisonType][key] = value
        },
        resetData(state) {
            for (const key in state) {
                if (!state.hasOwnProperty(key)) {
                    continue;
                }
                if (state[key].hasOwnProperty('status')) {
                    state[key]['status'] = 'idle'
                }
                if (state[key].hasOwnProperty('data')) {
                    state[key]['data'] = []
                }
            }
        }
    },
    extraReducers: {
        [fetchByDateCreativeData.pending]: (state) => {
            state.uniqueAds.status = 'pending'
        },
        [fetchByDateCreativeData.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.uniqueAds.status = 'error'
                return
            }
            state.uniqueAds.status = 'done'
            state.uniqueAds.data = action.payload.rows
        },
        [fetchCreatives.pending]: (state) => {
            state.creatives.status = 'pending'
        },
        [fetchCreatives.fulfilled]: (state, action) => {
            if (!action.payload || !action.payload.rows) {
                state.creatives.status = 'error'
                return
            }
            state.creatives.status = 'done'
            state.creatives.data = action.payload.rows
        },
        [fetchCreativesTrendlines.fulfilled]: (state, action) => {
            if (!action.payload || !action.payload.rows) {
                return
            }

            state.creatives.data = state.creatives.data.map(
                row => {
                    const trendline = action.payload.rows
                        .filter(tr => {
                            return Object.keys(tr.entity).every(function (key) {
                                return tr.entity[key] === row[key];
                            });
                        }).shift() || {
                        globalDaysSeen: 0
                    }

                    let trendlineStatus = 'done'
                    if (!trendline.globalDaysSeen) {
                        trendlineStatus = 'idle'
                    }

                    return {
                        ...row,
                        trendlineStatus,
                        trendLine: trendline
                    }
                }
            )
        },
        [fetchTopPhrases.pending]: (state) => {
            state.phrases.status = 'pending'
        },
        [fetchTopPhrases.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.phrases.status = 'error'
                return
            }
            state.phrases.status = 'done'
            state.phrases.data = action.payload.phrases
        }
    }
})


const selectDataComparisonType = (comparisonType) => (state) => state.advertiserCompareTool.advertiserCreative[comparisonType]
const selectDataComparisonTypeKey = (comparisonType, key) => (state) => state.advertiserCompareTool.advertiserCreative[comparisonType][key]


export {
    selectDataComparisonType,
    selectDataComparisonTypeKey
}

export {
    fetchByDateCreativeData,
    fetchCreatives,
    fetchTopPhrases
}

export const {
    setValue,
    resetData
} = advertiserCreativeSlice.actions


export default advertiserCreativeSlice.reducer