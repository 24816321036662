import React from "react"
import {default as ComponentError} from "./no-data/Error";
import * as Sentry from "@sentry/browser";


class CustomSentryError extends Error {
  constructor(componentStack, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)
    this.componentStack = componentStack
  }
}


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        Sentry.captureException(new CustomSentryError(errorInfo.componentStack, error))
    }

    render() {
        //console.log(this.state.hasError)

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ComponentError extraClass={this.props.extraClass}/>;
        }

        return this.props.children;
    }
}


export default ErrorBoundary