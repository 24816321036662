import Profile from "../common/profile";

const ProfileLink = (props) => {
    const externalLink = props.externalLink || false;
    const profileId = props.profileId
    const profileHref = 'http://' + props.profileId
    const profileIdText = props.profileLinkText || profileId.replace(/"/g, '')
    const country = props.country || null
    const platform = props.platform || null
    const showExternalLinkOnHover = props.showExternalLinkOnHover || false

    const externalProfileHref = externalLink ? profileHref : Profile.url(props.profileName, profileId, country, platform)

    return (
        <span className="profile-link-container">
            {showExternalLinkOnHover && (
                <>
                    <a className="profile-external-link" href={profileHref}
                       rel="noreferrer" target="_blank">
                        <i className="fa-light fa-sharp fa-external-link"/>
                    </a>
                    {'\u00A0'}
                </>
            )}

            <a href={externalProfileHref}
               className="profile-link pointer">{profileIdText}</a>
        </span>
    );
}

export default ProfileLink;
