import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchLandingPages = createAsyncThunk('advertiserLandingPages',
    async (arg) => {
        const response = await axios.get('/data-api/landingPages', {
            params: arg.query,
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const advLandingPagesSlice = createSlice({
    name: 'advLandingPages',
    initialState: {
        status: 'idle',
        data: [],
        extraPagesAvailable: 0
    },
    reducers: {
        resetAdvLandingPages(state) {
            state.status = 'idle'
            state.data = []
            state.extraPagesAvailable = 0
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchLandingPages.pending]: (state) => {
            state.status = 'loading'
            state.data = []
            state.extraPagesAvailable = 0
        },
        [fetchLandingPages.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
            state.extraPagesAvailable = action.payload.extraPagesAvailable
        },
    }
})


const selectAdvLandingPages = state => state.advertiserProfile.landingPages
export { fetchLandingPages, selectAdvLandingPages }
export const { resetAdvLandingPages } = advLandingPagesSlice.actions
export default advLandingPagesSlice.reducer