import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux"
import { hideModal, modalSelector, showModal } from "../../reducers/modal";
import { settingsSelector } from "../../reducers/settings"
import { showUpgradeModal } from "../modals/UpgradeModal";
import MetricSelect from "./MetricSelect";
import {
    fetchCanGenerateReport,
    fetchGenerateReport,
    clearErrors,
    selectGenerateReport, resetGenerateState
} from "./reportsSlice";
import TrialError from "./TrialError";
import {notifyInfo} from "../../reducers/notification";
import LoadingState from "../LoadingState";


const GenerateReportModal = (props) => {
    const settings = useSelector(settingsSelector)
    const generateReport = useSelector(selectGenerateReport)
    const dispatch = useDispatch()

    const [metric, setMetric] = useState(settings.metricField)

    var availableReportTypes = {
        'pdf': { value: 'pdf', text: 'PDF', 'icon': 'fa-file-pdf-o' },
        //'pptx': { value: 'pptx', text: 'PowerPoint', 'icon': 'fa-file-powerpoint-o' },
        'excel': { value: 'excel', text: 'Excel', 'icon': 'fa-file-excel-o' },
        'zip': { value: 'zip', text: 'Creatives', 'icon': 'fa-file-archive-o' }
    };

    const reportTypes =
        props.types
            .map(function (type) {
                return availableReportTypes[type];
            })
            .filter(d => d)

    const [reportType, setReportType] = useState(reportTypes[0])

    useEffect(() => {
        if (generateReport.status === 'idle') {
            dispatch(fetchCanGenerateReport())
        }
    }, [dispatch, generateReport.status])

    useEffect(() => {
        if (generateReport.generateStatus === 'done' && !generateReport.errors.length) {
            dispatch(notifyInfo('Your report is being generated and will be saved on your Reports page'))
            dispatch(hideModal())
        }
    }, [dispatch, generateReport.generateStatus, generateReport.errors.length])

    if (!settings.exportable_data ||
        (generateReport.status === 'done' &&
            !generateReport.canGenerateReport &&
            !settings.user.is_trial)) {
        showUpgradeModal(dispatch, {
            name: 'reports',
            value: null
        })

        return null
    }

    const modalTitle = props.title
    const {reportTitle, reportName} = props

    const showTrialError = !generateReport.canGenerateReport && settings.user.is_trial

    const errors = generateReport.errors

    const handleSubmit = (e) => {
        e.preventDefault()
        if (props.canBeScheduled) {
            dispatch(hideModal())
            const modalParams = {
                ...props,
                metrics: metric,
                name: reportName,
                type: reportType.value,
                title: reportTitle || modalTitle,
                link: window.location.pathname
            }
            delete modalParams['handleClose']
            dispatch(showModal({
                name: 'report-schedule-dialog',
                params: modalParams
            }))
        } else {
            dispatch(fetchGenerateReport({query: {
                ...props.query,
                metrics: metric,
                name: reportName,
                type: reportType.value,
                title: reportTitle,
                link: window.location.pathname
            }}))
        }
    }

    return (
        <ReactModal
            onRequestClose={props.handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "fixed",
                    height: document.documentElement.scrollHeight
                },
                content: {
                    bottom: 'auto',
                    borderRadius: 0,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "fixed",
                    padding: 0,
                    overflow: 'none',
                    inset: "80px 40px auto 40px",
                },
            }}
            isOpen={props.isOpen}>
            <div className="query-based-report">
                <LoadingState show={generateReport.generateStatus === 'pending'}/>
                <form method="post"
                    onSubmit={handleSubmit}>

                    {_.map(props.query, (value, name) =>
                        <input type="hidden" value={value} name={name} key={name} />
                    )}
                    <input type="hidden" name="name" value={reportName} />
                    <input type="hidden" name="type" value={reportType.value} />
                    <input type="hidden" name="title" value={reportTitle} />
                    <input type="hidden" name="link" data-bind="" />

                    <div className="section-header">
                        <h1 className="left">{modalTitle}</h1>
                        <div className="right close" onClick={props.handleClose}>
                            <i className="fa-light fa-sharp fa-close"></i>
                        </div>
                    </div>

                    {!showTrialError &&
                    <>
                    <div className="section-body filters profile-header report-type">
                        <h2 className="title">Which report format would you like to generate?</h2>

                        {(settings.hasSpend && props.hasMetricSelector) &&
                            <MetricSelect metric={metric}
                                onChange={setMetric}></MetricSelect>
                        }

                        <h2 className="buttons">
                            {reportTypes.map(rt =>
                                <div className={"report-button " + (reportType.value === rt.value ? 'active' : 'inactive')}
                                    onClick={() => setReportType(rt)}
                                    key={rt.value}>
                                    <div>
                                        <i className={"fa-light fa-sharp fa-3x " + rt.icon}></i>
                                    </div>
                                    <div>
                                        <span>{rt.text}</span>
                                    </div>
                                </div>
                            )}
                            <div className="clear"></div>
                        </h2>
                    </div>

                    <div className="section-footer clear">
                        {props.canBeScheduled &&
                            <button className="save-alert">Next</button>
                        }
                        {!props.canBeScheduled &&
                            <button className="save-alert">Generate Report</button>
                        }
                        <span className={
                            "error" + (errors.length > 0 ? "" : " hidden")
                        }>
                            {errors.map(e =>
                            <>
                            <span
                                className="pointer"
                                onClick={() => dispatch(clearErrors())}
                                dangerouslySetInnerHTML={{__html: e}}
                            ></span><br />
                            </>
                            )}
                        </span>
                    </div>
                    </>}

                    {showTrialError &&
                        <TrialError
                        onClick={() => dispatch(clearErrors())}
                        ></TrialError>
                    }
                </form>
            </div>
        </ReactModal>
    );
}

const modalName = 'report'

const Wrapper = () => {
    const modal = useSelector(modalSelector)
    const dispatch = useDispatch()

    if (modal.name === modalName) {
        return <GenerateReportModal {...modal.params}
            isOpen={modal.name === modalName}
            handleClose={() => dispatch(hideModal())}></GenerateReportModal>
    }
    return null
}


const showReportModal = (dispatch, params) => {
    dispatch(resetGenerateState())
    dispatch(showModal({
        name: modalName,
        params
    }))
}
export {showReportModal}
export default Wrapper;
