import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingState from "../../components/LoadingState";
import useTooltip from "../../hooks/useTooltip";
import { fetchTopPublishers, selectTopPublishers } from "./topPublishersSlice";

import { settingsSelector } from "../../reducers/settings"
import { guessFormat } from "../../misc/currency";
import qs from 'qs'
import { showUpgradeModal } from "../../components/modals/UpgradeModal";
import Profile from "../../common/profile";

const TopPublishers = (props) => {
  const tooltip = useTooltip('top_publishers')
  const tooltipDownload = useTooltip('download_excel_file_button')
  const settings = useSelector(settingsSelector)
  const dispatch = useDispatch()
  const publishers = useSelector(selectTopPublishers(props.adHash, props.network))
  const blockType = props.blockType || 'advertiser'

  useEffect(() => {
    if (publishers.status === 'idle' && props.show) {
      const query = {
        adHash: props.adHash,
        startDate: props.startDate,
        endDate: props.endDate,
        pageNumber: 1,
        pageSize: 20,
        metrics: settings.metricsList,
        sortBy: settings.metricField,
        sortOrder: 'desc',
        advertiser: props.advertiser,
        network: props.network,
        platform: props.platform,
        country: props.country
      }
      if (props.campaign) {
        query.campaign = props.campaign
      }
      if (props.brand) {
        query.brand = props.brand
      }
      dispatch(fetchTopPublishers({
        query
      }))
    }
  })

  const handleReportClick = () => {
    const query = {
      country: props.country,
      platform: props.platform,
      advertiser: props.advertiser,
      adHash: props.adHash,
      startDate: props.startDate,
      endDate: props.endDate,
      network: props.network
    }
    if (props.campaign) {
      query.campaign = props.campaign
    }
    if (props.brand) {
      query.brand = props.brand
    }
    if (settings.exportable_data) {
      window.location.href = '/callisto/reports/adPublishers?' +
        qs.stringify(query)
    } else {
      showUpgradeModal(dispatch, {
        name: 'reports',
        value: null
      })
    }
  }

  if (!props.show) {
    return null
  }

  return (
    <div
      className="publishers unfoldable"
    >
      <LoadingState show={publishers.status !== 'done' && publishers.status !== 'error'}></LoadingState>
      <h3 className="left">Top Publishers</h3>
      <div
        className="help-icon left tooltipstered"
        data-tip
        data-for={tooltip.path}
      >
        <i className="fa-sharp fa-light fa-circle-question fa-2x"></i>
      </div>
      <div
        className="help-icon left tooltipstered"
        onClick={handleReportClick}
        data-tip
        data-for={tooltipDownload.path}
        data-value={(settings.exportable_data ? '' :
          settings.upgradeRequiredValue) + "exportable-data"}
      >
        <i className="fa-light fa-sharp fa-2x fa-circle-arrow-down"></i>
      </div>
      <table className="clear">
        <thead>
          <tr>
            <th></th>{settings.hasSpend && <th>Spend</th>}<th>Impr</th>
          </tr>
        </thead>
        <tbody>
          {publishers.rows.map(publisher => (
            <tr key={publisher.publisher}>
              <td className="publisher">
                <profile-link>
                  <div className="profile-link-container">
                    <a
                      className="profile-external-link"
                      target="_blank"
                      rel="noreferrer"
                      href={'http://' + publisher.publisher}
                    >
                      <i className="fa-light fa-sharp fa-external-link"></i>
                    </a> <a
                      className="profile-link pointer"
                      href={`/publisher/${props.country}/${props.platform}/${publisher.publisher}`}
                    >{publisher.publisher}</a>
                  </div>
                </profile-link>
              </td>
              {settings.hasSpend &&
                <td className="capitalized">
                  {guessFormat(publisher.sumAdSpend, '$0.[0]a', props.country)}</td>}
              <td className="capitalized">
                {guessFormat(publisher.sumImpressions, '0.[0]a')}</td>
            </tr>))}
        </tbody>
      </table>
      {publishers.rows.length >= 20 &&
        <div className="see-more-wrapper">
          <div className="see-more button">
            <a href={Profile.url('advertiser', props.advertiser, props.country, props.platform) + "#" + blockType + "-top-publishers"}>
              See All Publishers
            </a>
          </div>
        </div>
      }
      {tooltip.component}
      {tooltipDownload.component}
    </div>
  );
}

export default TopPublishers;