import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchTopAdvertisers = createAsyncThunk('ads/fetchTopAdvertisers',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        let network = arg.query.network

        if (network === 'allByNetwork') {
            network = 'all'
        }

        let params = {
            pageNumber: 1,
            pageSize: 200,
            country: arg.query.country,
            startDate: arg.query.startDate,
            endDate: arg.query.endDate,
            platform: arg.query.platform,
            metrics: 'uniqueAds',
            sortBy: 'uniqueAds',
            sortOrder: 'desc',
            network: network,
            adType: arg.query.adType,
            adSize: arg.query.adSize,
            publisher: arg.query.publisher,
            q: arg.query.q,
            queryField: arg.query.queryField
        }

        if (arg.query.channel) {
            params['channel'] = arg.query.channel
        }

        const response = await axios.get('/data-api/advertisers', {
            params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })

const topAdvertisersSlice = createSlice({
    name: 'ads/topAdvertisers',
    initialState: {
        rows: [],
        status: 'idle'
    },
    reducers: {
        resetAdsTopAdvertisers(state) {
            state.status = 'idle'
        }
    },
    extraReducers: {
        [fetchTopAdvertisers.pending]: (state, action) => {
            state.rows = []
            state.status = 'pending'
        },
        [fetchTopAdvertisers.fulfilled]: (state, action) => {
            state.rows = action.payload.rows
                .sort((a, b) => b.uniqueAds - a.uniqueAds)
            state.status = 'done'
        }
    }
})

const selectTopAdvertisers = (state) => state.topAds.advertisers
export {fetchTopAdvertisers, selectTopAdvertisers}
export const {resetAdsTopAdvertisers} = topAdvertisersSlice.actions
export default topAdvertisersSlice.reducer