import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, modalSelector, showModal } from "../../reducers/modal";

let onConfirm = () => {}

const ConfirmAction = (props) => {
    const modal = useSelector(modalSelector)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(hideModal())
    }

    return (
        <ReactModal
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={{
                overlay: {
                    background: "rgba(0,0,0,0.8)",
                    zIndex: 99,
                    position: "fixed",
                },
                content: {
                    borderRadius: 0,
                    width: "50%",
                    minWidth: 650,
                    margin: "0 auto",
                    position: "fixed",
                    padding: 0,
                    overflow: 'hidden',
                    inset: "80px 40px auto 40px",
                },
            }}
            isOpen={modal.name === 'confirm-action'}>
            <div className="confirm-action-modal">

                <div className="section-body">
                    <div className="row">
                        <div className="exclamation">
                            <i className="fa-light fa-sharp fa-exclamation fa-3x"></i>
                        </div>
                    </div>

                    <div className="row">
                        <h1>Are you sure?</h1>
                    </div>

                    <div className="row">
                        <p className="message">{modal.params.message}</p>
                    </div>

                    <div className="row">
                        <div className="buttons">

                            <div className="button inactive left"
                                onClick={handleClose}>
                                Cancel
              </div>

                            <div className="right button"
                                onClick={onConfirm}>
                                    {modal.params.button}
                            </div>

                            <div className="clear">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </ReactModal>
    );
}

const showConfirmationModal = (dispatch, params, handleConfirm) => {
    params = params || {}
    onConfirm = handleConfirm
    dispatch(showModal({
        name: 'confirm-action',
        params
    }))
}
export { showConfirmationModal }

export default ConfirmAction;
