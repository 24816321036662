import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserOverviewTopPublishers = createAsyncThunk(
    'fetchAdvertiserOverviewTopPublishers',
    async (arg) => {
        const params = {
            'rows': 6,
            'sortOrder': 'desc',
            ...arg.query
        }

        const response = await axios.get("/data-api/publishers", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const publishersSlice = createSlice({
    name: 'advertiserTopPublishers',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetAdvertiserPublishers(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserOverviewTopPublishers.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvertiserOverviewTopPublishers.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserTopPublishers = (state) => state.publisherUiAdvertiserOverview.overview.byPublisher
export const {resetAdvertiserPublishers} = publishersSlice.actions
export {selectAdvertiserTopPublishers, fetchAdvertiserOverviewTopPublishers}
export default publishersSlice.reducer