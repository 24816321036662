import {combineReducers} from "redux";
import totals from './header/totalsNetworkDashboardSlice'
import publishers from './publishers/publishersSlice'

const reducer = combineReducers({
    totals,
    publishers
})

const networkDashboardReducer = (state, action) => {
    if (action.type === 'networks/reset') {
        // setting state to undefined clears it
        // it will clear only advertisers state
        state = undefined
    }

    return reducer(state, action)
}

export default networkDashboardReducer
