import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../common/axiosRateLimit'
import qs from "qs";
import {rangeFor} from "../../components/DatePicker";
import {daysToDate} from "../../common/crawlDays";


const fetchAdvertiserPublisherTrends = createAsyncThunk('fetchAdvertiserPublisherTrends',
    async (arg) => {
        let settings = arg.settings

        const [startDate, endDate] = rangeFor(settings.defaultDateRange,
            daysToDate(settings.first_index_date),
            daysToDate(settings.last_index_date))

        const entities = [{
            'platform': 'desktop'
        }, {
            'platform': 'mobile'
        }]

        const params = {
            advertiser: arg.advertiser,
            country: arg.country,
            metrics: settings.metricsList,
            startDate: startDate,
            endDate: endDate,
            includeEntities: JSON.stringify(entities)
        }

        if (settings.allowed.platformIds.length < 2) {
            params.platform = settings.defaultPlatform
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params
        })
        return response.data
    })


const fetchAdvertiserTrends = createAsyncThunk('fetchAdvertiserTrends',
    async (arg) => {
        let settings = arg.settings

        const [startDate, endDate] = rangeFor(settings.defaultDateRange,
            daysToDate(settings.first_index_date),
            daysToDate(settings.last_index_date))

        const entities = [{
            'advertiser': arg.advertiser
        }]

        const params = {
            metrics: settings.metricsList,
            startDate: startDate,
            endDate: endDate,
            country: arg.country,
            includeEntities: JSON.stringify(entities)
        }

        if (settings.allowed.platformIds.length < 2) {
            params.platform = settings.defaultPlatform
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params
        })
        return response.data
    })


const fetchInboxItems = createAsyncThunk('fetchInboxItems',
    async (arg) => {
        let path = '/callisto/inbox/list';

        const response = await axios.get(path, {
            params: {
                'rows': arg.query.rows,
                'start': arg.query.start,
                'showRead': arg.query.showRead,
                'advertiser': arg.query.advertisers.join(","),
                'eventType': arg.query.eventTypes.join(","),
            }
        })
        return response.data
    })


const fetchInboxAdvertisers = createAsyncThunk('fetchInboxAdvertisers',
    async () => {
        let path = '/callisto/inbox/advertiser-list';

        const response = await axios.get(path)
        return response.data
    })


const fetchInboxUnread = createAsyncThunk('fetchInboxUnread',
    async () => {
        let path = '/callisto/inbox/count_unread';

        const response = await axios.get(path)
        return response.data
    })


const removeInboxItem = createAsyncThunk('removeInboxItem',
    async (arg) => {
        let path = '/callisto/inbox/delete';

        const response = await axios.post(path,
            qs.stringify({id: arg.id})
        )
        arg.dispatch(resetInbox())
        return response.data
    })


const setReadInboxItem = createAsyncThunk('setReadInboxItem',
    async (arg) => {
        let path = '/callisto/inbox/set-read';

        const response = await axios.post(path,
            qs.stringify({
                id: arg.id,
                read: arg.read ? 'true' : 'false'
            })
        )

        arg.dispatch(resetInbox())
        return response.data
    })


const inboxSlice = createSlice({
    name: 'inbox',
    initialState: {
        advertiserTrends: {},
        inboxUnread: {
            status: 'idle',
            countUnread: 0
        },
        inboxItems: {
            status: 'idle',
            hits: [],
            numHits: 0,
            totalCount: 0,
            bounceStatus: false
        },
        inboxAdvertisers: {
            status: 'idle',
            advertisers: []
        },
        removeInboxItem: {
            status: 'idle',
        },
        setReadInboxItem: {
            status: 'idle',
        },
        errors: [],
    },
    reducers: {
        resetErrors(state) {
            state.errors = []
        },
        resetInbox(state) {
            state.inboxItems.status = 'idle'
            state.inboxAdvertisers.status = 'idle'
            state.removeInboxItem.status = 'idle'
            state.setReadInboxItem.status = 'idle'
        }
    },
    extraReducers: {
        [fetchInboxUnread.fulfilled]: (state, action) => {
            state.inboxUnread.status = 'done'
            state.inboxUnread.countUnread = action.payload.count
        },
        [fetchInboxAdvertisers.fulfilled]: (state, action) => {
            state.inboxAdvertisers.status = 'done'
            state.inboxAdvertisers.advertisers = action.payload.advertisers.map(
                (item) => item.advertiser
            )
        },
        [fetchInboxItems.rejected]: (state, action) => {
            state.errors = ['Failed to load data']
        },
        [fetchInboxItems.fulfilled]: (state, action) => {
            state.inboxItems.status = 'done'

            if ('errors' in action.payload) {
                state.errors = action.payload.errors
                return
            }
            state.inboxItems.hits = action.payload.hits
            state.inboxItems.totalCount = action.payload.totalCount
            state.inboxItems.numHits = action.payload.numHits
            state.inboxItems.bounceStatus = action.payload.bounceStatus
            state.inboxUnread.countUnread = action.payload.unreadCount

            let advertisers = new Set();
            let requestAdvertisers = {}

            let source, fetchFunction

            if (action.meta.arg.platformTrendlines) {
                source = state.advertiserTrends
                fetchFunction = fetchAdvertiserPublisherTrends
            } else {
                source = state.advertiserTrends
                fetchFunction = fetchAdvertiserTrends
            }
            for (const advertiser in source) {
                if (source.hasOwnProperty(advertiser)) {
                    advertisers.add(advertiser)
                }
            }

            state.inboxItems.hits.forEach((item) => {
                if (!advertisers.has(item.advertiser)) {
                    requestAdvertisers[item.advertiser] = item.country
                }
            })

            for (const advertiser in requestAdvertisers) {
                if (!requestAdvertisers.hasOwnProperty(advertiser)) {
                    continue;
                }

                action.asyncDispatch(
                    fetchFunction({
                        advertiser,
                        country: requestAdvertisers[advertiser],
                        settings: action.meta.arg.settings
                    })
                )
            }
        },
        [fetchAdvertiserPublisherTrends.fulfilled]: (state, action) => {
            let advertiser = action.meta.arg.advertiser

            if (action.payload.rows) {
                let advertiserTrends = state.advertiserTrends[advertiser] = state.advertiserTrends[advertiser] || {}
                let resultsByPlatform = {}

                action.payload.rows.forEach(trRow => {
                    resultsByPlatform[trRow.entity.platform] = trRow
                })

                advertiserTrends.trendlineStatus = 'done'
                advertiserTrends.trendLinePlatforms = resultsByPlatform
            }
        },
        [fetchAdvertiserTrends.fulfilled]: (state, action) => {
            let advertiser = action.meta.arg.advertiser

            if (action.payload.rows) {
                let advertiserTrends = state.advertiserTrends[advertiser] = state.advertiserTrends[advertiser] || {}

                advertiserTrends.trendlineStatus = 'done'
                advertiserTrends.trendLine = action.payload.rows[0]
            }
        },
        [setReadInboxItem.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.errors = action.payload.errors
            }
        },
        [setReadInboxItem.rejected]: (state) => {
            state.errors = ['Failed to set item read']
        },
        [removeInboxItem.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.errors = action.payload.errors
            }
        },
        [removeInboxItem.rejected]: (state) => {
            state.errors = ['Failed to remove item']
        }
    }
})

const selectInboxCountUnread = (state) => state.inbox.inbox.inboxUnread
const selectInboxAdvertisers = (state) => state.inbox.inbox.inboxAdvertisers
const selectInboxStatus = (state) => state.inbox.inbox

const selectAdvertiserTrends = (advertiser) => (state) => {
    if (state.inbox.inbox.advertiserTrends.hasOwnProperty(advertiser)) {
        return state.inbox.inbox.advertiserTrends[advertiser]
    }
    return {
        trendlineStatus: 'idle',
        trendLinePlatforms: null
    }
}

export {
    selectInboxCountUnread,
    selectInboxAdvertisers, selectInboxStatus,
    fetchInboxAdvertisers, fetchInboxItems, fetchInboxUnread,
    setReadInboxItem, removeInboxItem,
    selectAdvertiserTrends
}

export const {
    resetInbox, resetErrors
} = inboxSlice.actions

export default inboxSlice.reducer

