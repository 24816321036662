import axios from '../../../../common/axiosRateLimit'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import qs from "qs";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchNewBusinessData = createAsyncThunk('fetchNewBusinessData',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        let ownQuery = {
            publisher: arg.params.ownProperties.join(','),
            network: arg.params.network,
            country: arg.params.country,
            platform: arg.params.platform,
            startDate: arg.params.startDate,
            endDate: arg.params.endDate,
            metrics: arg.params.metrics,
            rows: arg.params.rows,
            sortBy: 'sumAdSpend',
            sortOrder: 'desc'
        }

        if (arg.params.category !== '/') {
            ownQuery['category'] = arg.params.category
        }

        let ownAdvertisers = await axios.post("/data-api/advertisers",
            qs.stringify(ownQuery),
            { cancelToken: getCancelSource().token }
        )

        let competitiveQuery = {
            publisher: arg.params.competitiveSet.join(','),
            network: arg.params.network,
            country: arg.params.country,
            platform: arg.params.platform,
            startDate: arg.params.startDate,
            endDate: arg.params.endDate,
            metrics: arg.params.metrics,
            rows: arg.params.rows,
            sortBy: 'sumAdSpend',
            sortOrder: 'desc'
        }

        if (arg.params.category !== '/') {
            competitiveQuery['category'] = arg.params.category
        }

        let competitiveAdvertisers = await axios.post("/data-api/advertisers",
            qs.stringify(competitiveQuery),
            { cancelToken: getCancelSource().token }
        )
        
        return {
            ownAdvertisers: ownAdvertisers.data,
            competitiveAdvertisers: competitiveAdvertisers.data,
        }
    })


const initialState = {
    newBusiness: [],
    expansionOpportunities: [],
    competitiveSet: [],
    competitiveSetByImpressions: [],
    competitiveSetByCreatives: [],
    status: 'idle',
    noData: false
}


const newBusinessSlice = createSlice({
    name: 'newBusiness',
    initialState,
    reducers: {
        resetNewBusiness(state) {
            return initialState
        }
    },
    extraReducers: {
        [fetchNewBusinessData.rejected]: (state, action) => {
            if (action.error.message === 'cancel') {
                state.status = 'pending'
                state.noData = false
            } else {
                state.status = 'done'
                state.noData = true
            }

            state.newBusiness = []
            state.expansionOpportunities = []
            state.competitiveSet = []
            state.competitiveSetByImpressions = []
            state.competitiveSetByCreatives = []
        },
        [fetchNewBusinessData.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchNewBusinessData.fulfilled]: (state, action) => {
            state.status = 'done'

            const ownProperties = new Set(action.meta.arg.params.ownProperties)
            const ownData = action.payload.ownAdvertisers
            const competitive = action.payload.competitiveAdvertisers

            if ('rows' in ownData && 'rows' in competitive && competitive.rows.length > 0) {
                const ownDataSet = new Set(
                    ownData.rows.map((item) => item.advertiser)
                )

                state.noData = false
                state.newBusiness = []
                state.expansionOpportunities = []

                competitive.rows.sort((a, b) => {
                    return b.sumAdSpend - a.sumAdSpend
                }).forEach((item) => {
                    state.competitiveSet.push(item)
                    if (ownProperties.has(item.advertiser)) {
                        return;
                    }
                    if (ownDataSet.has(item.advertiser)) {
                        state.expansionOpportunities.push({
                            ...item,
                            ownItem: ownData.rows.filter(d => d.advertiser === item.advertiser)[0]
                        })
                    } else {
                        state.newBusiness.push(item)
                    }
                })

                state.competitiveSetByImpressions = state.competitiveSet.slice()
                state.competitiveSetByImpressions.sort((a, b) => {
                    return b.sumImpressions - a.sumImpressions
                })

                state.competitiveSetByCreatives = state.competitiveSet.slice()
                state.competitiveSetByCreatives.sort((a, b) => {
                    return (b.uniqueAds - a.uniqueAds) || (b.sumAdSpend - a.sumAdSpend)
                })
            }
            else {
                state.noData = true
            }
        }
    }
})

const selectNewBusinessData = (state) => state.publisherUiNewBusiness.newBusiness

export default newBusinessSlice.reducer

export const {
    resetNewBusiness
} = newBusinessSlice.actions

export {
    fetchNewBusinessData,
    selectNewBusinessData
}
