import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { settingsSelector } from "../../reducers/settings";
import { selectProducts, initialize, show, hide } from '../../reducers/headerProducts'
import EscapeOutside from 'react-escape-outside'

const Products = (props) => {
  const dispatch = useDispatch()
  const settings = useSelector(settingsSelector)
  const products = useSelector(selectProducts)

  useEffect(() => {
    if (products.status === 'idle') {
      dispatch(initialize(settings))
    }
  })

  const showProducts = function (e) {
    if (products.areProductsShown) {
      dispatch(hide())
    } else {
      dispatch(show())
    }
  }

  return (
    <EscapeOutside onEscapeOutside={() => setTimeout(() => dispatch(hide()), 200)}>
    <div
      id="header-text"
      onClickCapture={showProducts}
      className={`left pointer ${props.isSearchShown && !props.isSearchPage ? "squeeze" : ""
        } ${products.areProductsShown ? "active" : ""}`}
    >
      <span>{products.currentProductName}</span>
      {products.products.length > 0 && <i
        className={`fa-solid fa-xs fa-sharp ${props.areProducsShown ? "fa-sort-up" : "fa-sort-down"
          }`}
      ></i>}
    </div>
    </EscapeOutside>
  );
}

export default Products