import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../common/axiosRateLimit";
import { networkIsAllByNetwork } from "../../../misc/allByNetwork";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserStandardAds = createAsyncThunk(
    'fetchAdvertiserStandardAds',
    async (arg) => {
        const params = {
            ...arg.query,
        }

        if (params.brand === 'All Brands') {
            delete params.brand
        }

        const response = await axios.get("/data-api/ads", {
            params,
            cancelToken: getCancelSource().token
        })

        if ('rows' in response.data) {
            response.data.rows = response.data.rows.map((row) => ({
                ...row,
                trendlineStatus: 'idle',
                trendLine: {
                    globalDaysSeen: 0
                }
            }))

            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()

            arg.dispatch(fetchAdTrends({
                ads: response.data.rows,
                query: {
                    ...arg.query
                }
            }))
        }

        return response.data
    }
)

const fetchAdTrends = createAsyncThunk('fetchTopAdStandardTrendlines',
    async (arg) => {
        if (!arg.ads.length) {
            return
        }

        const isAllByNetwork = networkIsAllByNetwork(arg.query.network)

        const entities = (ad) => {
            const result = {
                adHash: ad.adHash
            }
            if (isAllByNetwork) {
                result.network = ad.network
            }
            return result
        }

        let params = {
            ...arg.query,
            includeEntities: JSON.stringify(
                arg.ads.map(entities))
        }

        params.metrics += ',globalSeenDates'

        delete params.pageNumber
        delete params.pageSize
        delete params.adSize
        delete params.sortBy
        delete params.sortOrder
        delete params.adType
        delete params.q
        delete params.queryField
        delete params.minFirstSeen
        delete params.brand
        delete params.campaign
        delete params.channel

        if (isAllByNetwork) {
            delete params.network
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    })

const advStandardAdsSlice = createSlice({
    name: 'advStandardAds',
    initialState: {
        status: 'idle',
        data: [],
        extraPagesAvailable: 0
    },
    reducers: {
        resetAdvertiserStandardAds(state) {
            state.status = 'idle'
            state.extraPagesAvailable = 0
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserStandardAds.pending]: (state) => {
            state.status = 'pending'
            state.extraPagesAvailable = 0
        },
        [fetchAdvertiserStandardAds.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows.map(r => ({
                ...r,
                trendLine: {},
                trendlineStatus: 'loading'
            }))
            state.extraPagesAvailable = action.payload.extraPagesAvailable
        },
        [fetchAdTrends.fulfilled]: (state, action) => {
            let matchEntity = (ad, row) => {
                if (row.entity.adHash !== ad.adHash) {
                    return false
                }
                if (row.entity.hasOwnProperty('network') && row.entity.network !== ad.network) {
                    return false
                }
                return true
            }

            state.data = state.data.map(ad => {
                const trendLine = action.payload.rows.filter(
                    row => matchEntity(ad, row)
                ).shift() || {
                        globalDaysSeen: 0
                    }

                let trendlineStatus = 'done'
                if (!trendLine.globalDaysSeen) {
                    trendlineStatus = 'idle'
                }
                return {
                    ...ad,
                    trendLine,
                    trendlineStatus
                }
            })
        }
    }
})

const selectAdvertiserStandardAds = (state) => state.advertiserProfile.ads.standard
export { selectAdvertiserStandardAds, fetchAdvertiserStandardAds }
export const { resetAdvertiserStandardAds } = advStandardAdsSlice.actions
export default advStandardAdsSlice.reducer