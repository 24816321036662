import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'
import qs from "qs";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const advertisersListAdvertiserSuggestions = createAsyncThunk('advertisersListAdvertiserSuggestions',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        let path = '/data-api/search/advertisers';

        const response = await axios.get(path, {
            params: arg.query,
            cancelToken: getCancelSource().token
        })
        return response.data
    })

const listAdvertiserGroups = createAsyncThunk('listAdvertiserGroups',
    async () => {
        let path = '/callisto/advertiser-groups';
        const response = await axios.get(path)
        return response.data
    })


const addAdvertiserGroup = createAsyncThunk('addAdvertiserGroup',
    async (arg) => {
        let path = '/callisto/advertiser-groups/add';
        const response = await axios.post(path, qs.stringify(arg.query))
        return response.data
    })


const editAdvertiserGroup = createAsyncThunk('editAdvertiserGroup',
    async (arg) => {
        let path = '/callisto/advertiser-groups/edit';
        const response = await axios.post(path, qs.stringify(arg.query))
        return response.data
    })


const deleteAdvertiserGroup = createAsyncThunk('deleteAdvertiserGroup',
    async (arg) => {
        let path = '/callisto/advertiser-groups/delete';
        const response = await axios.post(path, qs.stringify({
            id: arg.id
        }))
        return response.data
    })


const prepareGroup = (item) => {
    const colors = [
        '#ffa021',
        '#ffce20',
        '#18bc8f',
        '#4141f9',
        '#2ea2f8',
    ]

    const rv = {
        ...item,
        advertisers: item.advertisers.filter((item) => item).slice(0, 5),
        advertiserProps: {},
        id: item.id.toString()
    }
    rv.advertisers.forEach((a, idx) => {
        rv.advertiserProps[a] = {
            color: colors[idx],
            id: 'advertiser-' + idx
        }
    })
    
    return rv;
}


const advertiserGroupSlice = createSlice({
    name: 'advertiserGroup',
    initialState: {
        groups: {
            groups: [],
            status: 'idle'
        },
        modifyStatus: 'idle',
        deleteStatus: 'idle',
        suggestions: [],
        formErrors: [],
        currentGroup: prepareGroup({
            dateRange: '180',
            country: 'us',
            platform: 'all',
            id: '',
            name: '',
            advertisers: [],
        }),
        errors: [],
    },
    reducers: {
        resetEditForm(state) {
            state.formErrors = []
            state.modifyStatus = 'idle'
        },
        resetErrors(state) {
            state.errors = []
        },
        setDateRange(state, action) {
            state.currentGroup.dateRange = action.payload.value
        },
        setPlatform(state, action) {
            state.currentGroup.platform = action.payload.value
        },
        setCountry(state, action) {
            state.currentGroup.country = action.payload.value
        },
        setGroup(state, action) {
            let groupId = action.payload.groupId

            let group = state.groups.groups.filter((item) => item.id === groupId).pop()
            if (!group) {
                state.currentGroup.id = ''
                state.currentGroup.name = ''
                return
            }
            state.currentGroup.advertisers = group.advertisers
            state.currentGroup.name = group.name
            state.currentGroup.id = groupId
            state.currentGroup = prepareGroup(state.currentGroup)
        },
        addAdvertiser(state, action) {
            if (state.currentGroup.advertisers.includes(action.payload.advertiser)) {
                return;
            }
            state.currentGroup.advertisers.push(action.payload.advertiser)
            if (state.currentGroup.id) {
                state.groups.groups.forEach((item) => {
                    if (item.id === state.currentGroup.id) {
                        item.advertisers = state.currentGroup.advertisers
                    }
                })
            }
            state.currentGroup = prepareGroup(state.currentGroup)
        },
        removeAdvertiser(state, action) {
            if (!state.currentGroup.advertisers.includes(action.payload.advertiser)) {
                return;
            }

            state.currentGroup.advertisers = state.currentGroup.advertisers.filter(
                (item) => item !== action.payload.advertiser
            )

            if (state.currentGroup.id) {
                state.groups.groups.forEach((item) => {
                    if (item.id === state.currentGroup.id) {
                        item.advertisers = state.currentGroup.advertisers
                    }
                })
            }
            state.currentGroup = prepareGroup(state.currentGroup)
        },
        resetSuggestions(state) {
            state.suggestions = []
        },
        resetAdvertiserGroups(state) {
            state.groups.status = 'idle'
        }
    },
    extraReducers: {
        [listAdvertiserGroups.rejected]: (state) => {
            state.errors = ["Failed to load groups"]
        },
        [listAdvertiserGroups.pending]: (state) => {
            state.groups.status = 'pending'
        },
        [listAdvertiserGroups.fulfilled]: (state, action) => {
            state.groups.status = 'done'
            state.groups.groups = action.payload.groups.map(prepareGroup)
        },
        [advertisersListAdvertiserSuggestions.fulfilled]: (state, action) => {
            let totalScore = action.payload.rows.reduce((sum, suggetion) => sum + suggetion.score, 0) || 1

            state.suggestions = action.payload.rows.map(function (suggestion) {
                suggestion.weight = suggestion.score / totalScore;
                suggestion.label = suggestion.suggestion
                return suggestion;
            })
        },
        [addAdvertiserGroup.pending]: (state) => {
            state.resetEditForm = null
            state.modifyStatus = 'pending'
        },
        [editAdvertiserGroup.pending]: (state) => {
            state.resetEditForm = null
            state.modifyStatus = 'pending'
        },
        [addAdvertiserGroup.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.formErrors = action.payload.errors
                state.modifyStatus = 'error'
                return
            }

            state.modifyStatus = 'done'
            const group = prepareGroup(action.payload.group)

            state.currentGroup.advertisers = group.advertisers
            state.currentGroup.id = group.id
            state.currentGroup.name = group.name

            state.groups.status = 'idle'
        },
        [editAdvertiserGroup.fulfilled]: (state, action) => {
            if ('errors' in action.payload) {
                state.formErrors = action.payload.errors
                state.modifyStatus = 'error'
                return
            }

            state.modifyStatus = 'done'
            const group = prepareGroup(action.payload.group)

            state.currentGroup.advertisers = group.advertisers
            state.currentGroup.id = group.id
            state.currentGroup.name = group.name

            state.groups.status = 'idle'
        },
        [deleteAdvertiserGroup.pending]: (state) => {
            state.deleteStatus = 'pending'
        },
        [deleteAdvertiserGroup.fulfilled]: (state, action) => {
            state.deleteStatus = 'idle'
            if ('errors' in action.payload) {
                state.errors = action.payload.errors
            }
            else {
                state.currentGroup.id = ''
                state.currentGroup.name = ''
                state.groups.status = 'idle'
            }
        }
    }
})


const selectAdvertisers = (state) => state.advertiserCompareTool.advertiserGroup.currentGroup.advertisers
const selectCurrentGroup = (state) => state.advertiserCompareTool.advertiserGroup.currentGroup
const selectGroups = (state) => state.advertiserCompareTool.advertiserGroup.groups
const selectSuggestions = (state) => state.advertiserCompareTool.advertiserGroup.suggestions
const selectFormErrors = (state) => state.advertiserCompareTool.advertiserGroup.formErrors
const selectModifyStatus = (state) => state.advertiserCompareTool.advertiserGroup.modifyStatus
const selectDeleteStatus = (state) => state.advertiserCompareTool.advertiserGroup.deleteStatus
const selectErrors = (state) => state.advertiserCompareTool.advertiserGroup.errors

export {
    selectAdvertisers,
    selectCurrentGroup,
    selectGroups,
    selectSuggestions,
    selectFormErrors,
    selectModifyStatus,
    selectErrors,
    selectDeleteStatus
}

export {
    listAdvertiserGroups,
    addAdvertiserGroup,
    editAdvertiserGroup,
    deleteAdvertiserGroup,
    advertisersListAdvertiserSuggestions
}

export const {
    setDateRange,
    setPlatform,
    setCountry,
    resetErrors,
    resetSuggestions,
    addAdvertiser,
    removeAdvertiser,
    setGroup,
    resetEditForm,
    resetAdvertiserGroups
} = advertiserGroupSlice.actions


export default advertiserGroupSlice.reducer