const trackUser = (settings) => {
  window.dataLayer = window.dataLayer || [];

  try {
    if (typeof window.dataLayer.push === "function") {
      window.dataLayer.push({
        event: "s_identify",
        s_traits: {
          logged_in: "Yes",
          plan_name: settings.current_product_name,
        },
      });

      window.dataLayer.push({
        event: "s_track",
        s_event: "user status",
        s_properties: {
          logged_in: "Yes",
          plan_name: settings.current_product_name,
        },
      });
    }
  } catch (e) {}
};

export default trackUser;
