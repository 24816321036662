import axios from '../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchDashboardPubNetworkEventDetails = createAsyncThunk(
    'fetchDashboardPubNetworkEventDetails',
    async (arg) => {
        const request = axios.get("/data-api/ads", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        const response = await request

        let result = response.data
        return result
    });


const userDashboardPubNetworkEventDetailsSlice = createSlice({
    name: 'userDashboardPubNetworkEventDetailsSlice',
    initialState: {
        status: "idle",
        data: [],
    },
    reducers: {
        reset(state) {
            state.status = "idle";
            state.data = [];
        },
    },
    extraReducers: {
        [fetchDashboardPubNetworkEventDetails.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchDashboardPubNetworkEventDetails.fulfilled]: (state, action) => {
            state.status = 'done'
            if (action.payload.rows.length > 0) {
                state.data = action.payload.rows
            }
            else {
                state.data = [] 
            }
        },
    }
})


const userDashboardPubNetworkEventDetailsSelector = state => state.userDashboard.pubNetworkEventDetails
export const {
    reset
} = userDashboardPubNetworkEventDetailsSlice.actions
export { fetchDashboardPubNetworkEventDetails,
    userDashboardPubNetworkEventDetailsSelector }

export default userDashboardPubNetworkEventDetailsSlice.reducer
