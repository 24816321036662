import { performedSearch } from "../misc/effinSegment";
import {publicsuffix_lookup} from "./tld/effective_tld_names";

const isPublisherChannel = (profileId) => {
    return profileId.indexOf('/') > -1 || publicsuffix_lookup(profileId) !== profileId
}


function isChannel(profileId) {
    return isPublisherChannel(profileId)
}

function url(
    profileName,
    profileId,
    countryId,
    platformId,
    sectionName
) {

    if (profileName === 'publisher') {
        if (isPublisherChannel(profileId)) {
            profileName = 'channel'
        }
    }

    if (profileName === 'campaign' || profileName === 'brand') {
        if (profileId.indexOf('/') > -1) {
            let parts = profileId.split('/')
            profileId = parts[0]
            sectionName = parts[1]
        }
    }

    let path = [
        '',
        profileName.toLowerCase(),
        countryId || window.AdbeatSettings.defaultCountry,
        platformId || window.AdbeatSettings.defaultPlatform,
        encodeURIComponent(profileId)
    ];

    if (sectionName) {
        path.push(sectionName);
    }
    return path.join('/');
}

function goToAdvertiser(advertiserId, countryId, platformId, sectionName) {
    performedSearch(advertiserId, 'advertiser')
    window.open(
        url(
            'advertiser',
            advertiserId,
            countryId,
            platformId,
            sectionName
        ),
        '_self'
    );
}

function goToPublisher(publisherId, countryId, platformId, sectionName) {
    performedSearch(publisherId, 'publisher')
    window.open(
        url(
            'publisher',
            publisherId,
            countryId,
            platformId,
            sectionName
        ),
        '_self'
    );
}

function goToYtChannel(channelId, countryId, platformId) {
    performedSearch(channelId, 'yt-channel')
    window.open([
        '',
        'channel',
        countryId,
        platformId,
        'youtube.com%2Fchannel%2F' +
        channelId
    ].join('/'),
    '_self'
)
}

function goToCampaign(profileId, countryId, platformId, sectionName) {
    performedSearch(profileId, 'campaign')
    window.open(
        url(
            'campaign',
            profileId,
            countryId,
            platformId,
            sectionName
        ),
        '_self'
    );
}

function goToKeyword(keyword, country, platform, dateRange, network) {
    performedSearch(keyword, 'keyword')
    window.open([
            '',
            'top-ads',
            country,
            platform,
            keyword,
            'creativeText',
            dateRange,
            network,
            'all',
            'all',
            'all'
        ].join('/'),
        '_self'
    )
}

function goToBrand(profileId, countryId, platformId, sectionName) {
    performedSearch(profileId, 'brand')
    window.open(
        url(
            'brand',
            profileId,
            countryId,
            platformId,
            sectionName
        ),
        '_self'
    );
}

function goToNetwork(profileId, countryId, platformId) {
    performedSearch(profileId, 'network')
    window.open(
        url(
            'network',
            profileId,
            countryId,
            platformId
        ),
        '_self'
    );
}

function goToProfile(profileName, profileId, countryId, platformId, sectionName) {
    let profileURLFuncMap = {
        advertiser: goToAdvertiser,
        publisher: goToPublisher,
        channel: goToPublisher,
        campaign: goToCampaign
    };

    profileURLFuncMap[profileName.toLowerCase()](
        profileId,
        countryId || window.AdbeatSettings.defaultCountry,
        platformId || window.AdbeatSettings.defaultPlatform,
        sectionName
    );
}

const profile = {
    isChannel,
    url,
    goToAdvertiser,
    goToPublisher,
    goToYtChannel,
    goToCampaign,
    goToKeyword,
    goToBrand,
    goToNetwork,
    goToProfile,
}

export default profile; 
