import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'
import qs from "qs";


const updateScheduledReport = createAsyncThunk('updateScheduledReport',
    async (arg) => {
        const response = await axios.post("/callisto/scheduled_report",
            qs.stringify({
                reportId: arg.params.reportId,  
                publisherSetId: arg.params.publisherSetId,
                publisherSetName: arg.params.publisherSetName,
                newBusiness: arg.params.newBusiness,
                expansionOpportunities: arg.params.expansionOpportunities,
                frequency: arg.params.frequency,
                countries: arg.params.country,
                platform: arg.params.platform,
                network: arg.params.network,
                category: arg.params.category
            })
        )
        return response.data
    })


const fetchScheduledReports = createAsyncThunk('fetchScheduledReports',
    async () => {
        let response = await axios.get("/callisto/scheduled_reports")

        return response.data
    })


const scheduledReportsSlice = createSlice({
    name: 'scheduledReports',
    initialState: {
        status: 'idle',
        reports: [],
        update: {
            status: 'idle',
            error: ''
        }
    },
    reducers: {
        resetUpdateReportsState(state) {
            state.update.status = 'idle'
            state.update.error = ''
            state.status = 'idle'
        },
    },
    extraReducers: {
        [fetchScheduledReports.fulfilled]: (state, action) => {
            state.reports = []
            action.payload.scheduled_reports.forEach((item) => {
                if (item.new_business) {
                    state.reports.push({
                        ...item,
                        reportName: 'New Business Opportunities'
                    })
                }
                if (item.expansion_opportunities) {
                    state.reports.push({
                        ...item,
                        reportName: 'Expansion Opportunities'
                    })
                }
            })
            state.status = 'done'
        },
        [updateScheduledReport.fulfilled]: (state, action) => {
            if ('error' in action.payload) {
                state.update.status = 'error'
                state.update.error = action.payload.error
            }
            else {
                if (action.meta.arg.requestType !== 'delete') {
                    state.update.status = 'done'
                } else {
                    state.update.status = 'deleted'
                }
            }
        }
    }
})


const selectScheduledReports = (state) => state.publisherUiScheduledReports
const selectScheduledReportUpdate = (state) => state.publisherUiScheduledReports.update


export {
    selectScheduledReports,
    selectScheduledReportUpdate,
    fetchScheduledReports,
    updateScheduledReport
}

export const {
    resetUpdateReportsState
} = scheduledReportsSlice.actions


export default scheduledReportsSlice.reducer
