const AdIcon = (props) => {
    const adTypeIcon = new Map([
        ['video', 'fa-video-camera'],
        ['gif', 'fa-film'],
        ['html5', 'fa-html5']
    ])

    return ['gif', 'html5', 'video', 'textimg']
        .includes(props.adType) && 
        <span className="ad-type-icon">
            <i className={`fa ${adTypeIcon.get(props.adType)}`}>
                {props.adType === 'textimg' ? 'T' : ''}
            </i>
        </span>
}
 
export default AdIcon;