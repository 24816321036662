import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserOverviewPublishers = createAsyncThunk(
    'advertiserOverviewPublishers',
    async (arg) => {
        let entities = []
        if (arg.brand) {
            entities = [{
                brand: arg.brand,
                platform: 'desktop'
            },
            {
                brand: arg.brand,
                platform: 'mobile'
            }]
        } else if (arg.campaign) {
            entities = [{
                campaign: arg.campaign,
                platform: 'desktop'
            },
            {
                campaign: arg.campaign,
                platform: 'mobile'
            }]
        } else {
            entities = [{
                advertiser: arg.advertiser,
                platform: 'desktop'
            },
            {
                advertiser: arg.advertiser,
                platform: 'mobile'
            }]
        }

        const params = {
            ...arg.query,
            includeEntities: JSON.stringify(entities)
        }

        const response = await axios.get("/data-api/trendlines", {
            params: params,
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const advOverviewPublishersSlice = createSlice({
    name: 'advertiserOverviewPublishers',
    initialState: {
        status: 'idle',
        data: [],
    },
    reducers: {
        resetAdvOverviewPublishers(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserOverviewPublishers.pending]: (state) => {
            state.status = 'loading'
            state.data = []
        },
        [fetchAdvertiserOverviewPublishers.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})


const advertiserOverviewPublishersSelector = state => state.advertiserProfile.overview.publishers
export { fetchAdvertiserOverviewPublishers, advertiserOverviewPublishersSelector }
export const { resetAdvOverviewPublishers } = advOverviewPublishersSlice.actions
export default advOverviewPublishersSlice.reducer