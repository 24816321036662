import axios from '../../../common/axiosRateLimit'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchDashboardCatEventDetails = createAsyncThunk(
    'fetchDashboardCatEventDetails',
    async (arg) => {
        const request = axios.get("/data-api/ads", {
            params: {
                ...arg.query,
            },
            cancelToken: getCancelSource().token
        })

        const response = await request

        let result = response.data
        return result
    });


const userDashboardCatEventDetailsSlice = createSlice({
    name: 'userDashboardCatEventDetailsSlice',
    initialState: {
        status: "idle",
        data: [],
    },
    reducers: {
        reset(state) {
            state.status = "idle";
            state.data = [];
        },
    },
    extraReducers: {
        [fetchDashboardCatEventDetails.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchDashboardCatEventDetails.fulfilled]: (state, action) => {
            state.status = 'done'
            if (action.payload.rows.length > 0) {
                state.data = action.payload.rows
            }
            else {
                state.data = [] 
            }
        },
    }
})


const userDashboardCatEventDetailsSelector = state => state.userDashboard.catEventDetails
export const {
    reset
} = userDashboardCatEventDetailsSlice.actions
export { fetchDashboardCatEventDetails,
    userDashboardCatEventDetailsSelector }

export default userDashboardCatEventDetailsSlice.reducer
