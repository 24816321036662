import header from './header/publisherUiHeaderSlice'
import common from './publisherUiCommonSlice'
import newBusiness from './newBusiness/newBusinessSlice'
import categories from './categories/publisherUiCategoriesSlice'
import adPath from './adPath/publisherUiAdPathSlice'
import creatives from './creatives/publisherUiCreativesSlice'
import report from '../reducers/publisherUiOverviewReportSlice'
import {combineReducers} from '@reduxjs/toolkit'

const publisherUiOverviewReducer = combineReducers({
    common,
    header,
    newBusiness,
    categories,
    adPath,
    creatives,
    report
})


export default publisherUiOverviewReducer
