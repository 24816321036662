import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

const fetchTopChannels = createAsyncThunk('fetchTopAdChannels',
    async (arg) => {
        const response = await axios.get("/data-api/channels", {
            params: {
                ...arg.query,
            }
        })

        return response.data
    })

const topChannelsSlice = createSlice({
    name: 'topAdsChannels',
    initialState: {},
    reducers: {
        resetTopAdsChannels(state) {
            return {}
        }
    },
    extraReducers: {
        [fetchTopChannels.pending]: (state, action) => {
            let key = action.meta.arg.query.adHash + action.meta.arg.query.network

            state[key] = {
                rows: [],
                status: 'pending'
            }
        },
        [fetchTopChannels.fulfilled]: (state, action) => {
            let key = action.meta.arg.query.adHash + action.meta.arg.query.network

            state[key] = {
                rows: action.payload.rows,
                status: 'done'
            }
        }
    }
})

export const selectTopChannels = (adHash, network) => (state) => {
    let key = adHash + network
    if (key in state.topAds.channels) {
        return state.topAds.channels[key]
    } else {
        return {
            rows: [],
            status: 'idle'
        }
    }
}

export const {resetTopAdsChannels} = topChannelsSlice.actions
export {fetchTopChannels}
export default topChannelsSlice.reducer