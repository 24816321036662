import React from "react"
import { useSelector } from "react-redux"
import { selectPublisherUiSettings } from "../pages/publisher_ui/overview/publisherUiCommonSlice"
import { selectTopPublishers } from "../pages/top-ads/topPublishersSlice"

const AppearsOn = (props) => {
    const publishers = useSelector(selectTopPublishers(props.ad.adHash, props.network))
    const publisherUiSettings = useSelector(selectPublisherUiSettings)
    
    const publisherSet = publisherUiSettings.publisherSet.split(',')
    
    const publishersInSetCount = publishers.rows
        .filter(p => publisherSet.includes(p.publisher))
        .length

    return (
        <div className="appears-on">
            Appears on {publishersInSetCount}/{publisherSet.length} {' '}
            <span className="competitive-set-name">{publisherUiSettings.publisherSetName}</span>
        </div>
    );
}

export default AppearsOn;