import React from 'react';
import adbeatLoginLogo from '../../img/adbeat-login-logo.png'

const GetMoreData = props => {
    return (
        <div className="profileinfo">
            <h2>Get even more data</h2>
            <img src={adbeatLoginLogo} alt="Adbeat Logo"/> <br />
            Did you know that Adbeat can support even more users for your organization?<br /><br />
            <a href="/" onClick={(e) => {
                e.preventDefault()
                window.Intercom('showNewMessage',
                'Hi, I\'d like to learn more about adding more users.')
            }}>Contact Us for Options</a>

        </div>
    )
}

export default GetMoreData