import { useDispatch, useSelector } from "react-redux";
import { settingsSelector } from "../../reducers/settings";
import { hideModal, modalSelector, showModal } from "../../reducers/modal";
import {
    clearErrors,
    fetchFollowAdvertiserOptions,
    resetState,
    saveFollowOptions,
    selectFollowAdvertiserState
} from "./followAdvertiserSlice";
import React, { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import useTooltip from "../../hooks/useTooltip";
import AlertEventSettings from "./AlertEventSettings";
import AddAdvertiserToDashboard from './AddAdvertiserToDashboard';
import CountryList from "../CountryList";
import RequiredInputStatus from "../RequiredInputStatus";
import SelectMenu from "../SelectMenu";
import LoadingState from "../LoadingState";
import FormError from "./FormError";
import { notifyError } from "../../reducers/notification";
import {
    createAdvertiserAlert,
    createAdvertiserBookmark,
    editAdvertiserAlert
} from "../../reducers/uiStats";
import Profile from "../../common/profile";
import {
    addAdvertiserToDashboard,
    removeAdvertiserFromDashboard,
    resetAdvertiserManagement,
} from "./userDashboardSlice";
import {selectAdvertiserIncludedInDashboard,
    fetchIsIncludedInDashboard} from '../../components/alerts/userDashboardSlice';


const FollowAdvertiserModal = (props) => {
    const settings = useSelector(settingsSelector)
    const dispatch = useDispatch()
    const tooltip = useTooltip('follow-advertiser-modal')

    const advertiser = props.advertiser

    const advertiserModalForm = useRef()

    const followAdvertiserOptions = useSelector(selectFollowAdvertiserState)
    const alert = followAdvertiserOptions.alertOptions.alert

    const [notificationMedium, setNotificationMedium] = useState("inbox")
    const [addToBookmarks, setAddToBookmarks] = useState(false)
    const [isAddedToDashboard, setIsAddedToDashboard] = useState(false)
    const [selectedCountries, setSelectedCountries] = useState(
        [settings.defaultCountry]
    )
    const [eventTypes, setEventTypes] = useState([])
    const dashboardAdvertisers = useSelector(selectAdvertiserIncludedInDashboard)

    useEffect(() => {
        if (dashboardAdvertisers.status === 'init') {
            dispatch(fetchIsIncludedInDashboard())            
        }
    }, [dashboardAdvertisers.status, dispatch])

    useEffect(() => {
        if (dashboardAdvertisers.status === 'done') {
            setIsAddedToDashboard(dashboardAdvertisers.data.advertisers
                .map(d => d.advertiser)
                .some(d => props.advertiser === d))
        }
    }, [dashboardAdvertisers.status,
        props.advertiser,
        dashboardAdvertisers.data.advertisers])


    useEffect(() => {
        if (followAdvertiserOptions.alertOptions.status === 'idle') {
            dispatch(fetchFollowAdvertiserOptions({
                settings,
                advertiser
            }))
        }
    }, [dispatch, followAdvertiserOptions, advertiser, settings])

    useEffect(() => {
        if (alert) {
            if (alert.countries && alert.countries.length) {
                setSelectedCountries(alert.countries)
            }
            setEventTypes(alert.eventTypes)
            if (alert.emailDays === null) {
                setNotificationMedium('inbox')
            }
            else if (alert.emailDays === 1) {
                setNotificationMedium('email-daily')
            }
            else if (alert.emailDays === 7) {
                setNotificationMedium('email-weekly')
            }
        }
        else {
            setSelectedCountries([settings.defaultCountry])
            setEventTypes([])
        }
    }, [alert, settings.defaultCountry])

    useEffect(() => {
        setAddToBookmarks(followAdvertiserOptions.alertOptions.savedSearch)
    }, [followAdvertiserOptions.alertOptions.savedSearch])

    const handleClose = () => {
        props.handleClose && props.handleClose();
        dispatch(resetAdvertiserManagement());
        dispatch(resetState());
        dispatch({'type': 'followModalUserDashboardSlice/resetState'});
        dispatch(hideModal());
    }

    const followAdvertiser = (event) => {
        event.preventDefault()

        let formData = new FormData(advertiserModalForm.current)
        formData.append(
            'link',
            Profile.url(
                'advertiser',
                advertiser,
                settings.defaultCountry,
                settings.defaultPlatform
            )
        )

        dispatch(saveFollowOptions({ formData }))
        if (alert === null) {
            dispatch(createAdvertiserAlert({
                advertiser: formData.get('advertiser'),
                link: window.location.pathname,
                countries: formData.getAll('alertCountries').join(','),
                types: formData.getAll('alertEventTypes').join(','),
                pubTo: formData.get('alertPublishTo'),
                period: formData.get('alertEmailPeriod')
            }))
        }
        else {
            dispatch(editAdvertiserAlert({
                advertiser: formData.get('advertiser'),
                link: window.location.pathname,
                countries: formData.getAll('alertCountries').join(','),
                types: formData.getAll('alertEventTypes').join(','),
                pubTo: formData.get('alertPublishTo'),
                period: formData.get('alertEmailPeriod')
            }))
        }

        if (addToBookmarks && !followAdvertiserOptions.alertOptions.savedSearch) {
            dispatch(createAdvertiserBookmark({
                link: window.location.pathname,
                advertiser: formData.get('advertiser')
            }))
        }

        if (isAddedToDashboard) {
            dispatch(addAdvertiserToDashboard({
                advertiser: advertiser,
                last_seen_pinot: props.globalLastSeen
            }));
        } else {
            dispatch(removeAdvertiserFromDashboard({
                advertiser
            }));
        }

        return false;
    }

    useEffect(() => {
        if (
            followAdvertiserOptions.saveAlertStatus.status === 'done' &&
            (dashboardAdvertisers.addAdvertiserState === "done" &&
            dashboardAdvertisers.currentError.length === 0)
        ) {
            if (props.onSuccessEvent) {
                dispatch(props.onSuccessEvent)
            }
            handleClose()
        }
    })

    useEffect(() => {
        if (followAdvertiserOptions.errors.length > 0) {
            dispatch(notifyError(followAdvertiserOptions.errors[0]))
            dispatch(clearErrors())
        }
    })

    const handlerAddToBookmarks = (event) => {
        const { target } = event
        setAddToBookmarks(target.checked)
    }

    const alertOfChanges = eventTypes.length > 0

    const modifyEventTypes = (eventType, enable) => {
        setEventTypes(enable
            ? eventTypes.concat(eventType)
            : eventTypes.filter((item) => item !== eventType))
    }

    return (
        <>
            <ReactModal
                onRequestClose={handleClose}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                style={{
                    overlay: {
                        background: "rgba(0,0,0,0.8)",
                        zIndex: 99,
                        position: "absolute",
                        height: document.documentElement.scrollHeight
                    },
                    content: {
                        bottom: 'auto',
                        top: (window.scrollY || window.pageYOffset) + 20,
                        borderRadius: 0,
                        width: "50%",
                        minWidth: 650,
                        margin: "0 auto",
                        position: "absolute",
                        padding: 0,
                        overflow: 'none',
                        inset: ((window.scrollY || window.pageYOffset) + 20) +
                            "px 40px auto 40px",
                    },
                }}
                isOpen={props.isOpen}>
                <div className="follow-advertiser-template">
                    <form method="post"
                        onSubmit={followAdvertiser}
                        ref={advertiserModalForm}
                    >
                        <input type="hidden" name="advertiser"
                            value={advertiser} />
                        <div className="section-header">
                            <h1 className="left">Follow {advertiser}</h1>
                            <div className="help-icon left"
                                data-tip
                                data-for={tooltip.path}>
                                <i
                                    className="fa-sharp fa-light fa-circle-question fa-2x"
                                />
                            </div>
                            <div className="right close"
                                onClick={handleClose}>
                                <i className="fa-light fa-sharp fa-close" />
                            </div>
                        </div>

                        <div className="section-body">
                            <LoadingState
                                show={followAdvertiserOptions.alertOptions.status !== 'done' ||
                                    followAdvertiserOptions.saveAlertStatus.status === 'pending'} />
                            <p className="font-semi-bold">How would you like to
                                follow this advertiser?</p>

                            <input type="hidden" name="setAlert"
                                value={alertOfChanges ? "1" : ""} />

                            <div className="follow-options">
                                <ul>
                                    <AddAdvertiserToDashboard 
                                        onDashboardStatusChange={(event) => {
                                            const {target} = event;
                                            setIsAddedToDashboard(target.checked)
                                        }}
                                        isAdded={isAddedToDashboard}/>
                                    <AlertEventSettings
                                        type="ad"
                                        alert={alert}
                                        canAddAlerts={followAdvertiserOptions.canAddAlerts}
                                        modifyEventTypes={modifyEventTypes}
                                        saveAlertStatus={followAdvertiserOptions.saveAlertStatus}
                                    />
                                    <AlertEventSettings
                                        type="campaign"
                                        alert={alert}
                                        canAddAlerts={followAdvertiserOptions.canAddAlerts}
                                        modifyEventTypes={modifyEventTypes}
                                        saveAlertStatus={followAdvertiserOptions.saveAlertStatus}
                                    />
                                    <AlertEventSettings
                                        type="publisher"
                                        alert={alert}
                                        canAddAlerts={followAdvertiserOptions.canAddAlerts}
                                        modifyEventTypes={modifyEventTypes}
                                        saveAlertStatus={followAdvertiserOptions.saveAlertStatus}
                                    />
                                    <AlertEventSettings
                                        type="network"
                                        alert={alert}
                                        canAddAlerts={followAdvertiserOptions.canAddAlerts}
                                        modifyEventTypes={modifyEventTypes}
                                        saveAlertStatus={followAdvertiserOptions.saveAlertStatus}
                                    />
                                </ul>
                            </div>
                            <ul className="no-top-padding">
                                <li className={alertOfChanges ? "" : "hidden"}>
                                    <span>Alert Settings</span>
                                    <ul>
                                        <li>
                                            <div className="left input"
                                                title="Click to add or remove Geos">
                                                <CountryList
                                                    value={selectedCountries}
                                                    onChange={(value) => setSelectedCountries(value)}
                                                    name="alertCountries"
                                                    options={{
                                                        maxItems: 5,
                                                        plugins: { remove_button: {} }
                                                    }}
                                                />
                                            </div>
                                            <div className="left">
                                                <RequiredInputStatus
                                                    inputValue={selectedCountries}
                                                />
                                            </div>
                                            <FormError
                                                {...followAdvertiserOptions.saveAlertStatus}
                                                name="alertCountries"
                                            />
                                        </li>

                                        <li>
                                            <div className="input">
                                                <input type="hidden"
                                                    name="alertPublishTo"
                                                    value={notificationMedium.split('-')[0]}
                                                />
                                                <input type="hidden"
                                                    name="alertEmailPeriod"
                                                    value={notificationMedium.split('-')[1]}
                                                />
                                                <SelectMenu
                                                    options={[
                                                        {
                                                            value: 'inbox',
                                                            text: 'Send alerts to my Adbeat Inbox (but don\'t email me)'
                                                        },
                                                        {
                                                            value: 'email-daily',
                                                            text: 'Send alerts to my Adbeat Inbox & Email me Daily'
                                                        },
                                                        {
                                                            value: 'email-weekly',
                                                            text: 'Send alerts to my Adbeat Inbox & Email me Weekly'
                                                        }
                                                    ]}
                                                    value={notificationMedium}
                                                    onChange={(value) => setNotificationMedium(value)}
                                                />
                                            </div>
                                        </li>

                                    </ul>
                                </li>

                                <li>
                                    <input
                                        type="checkbox"
                                        id="addToSavedSearches"
                                        name="addToSavedSearches"
                                        value="1"
                                        checked={addToBookmarks}
                                        onChange={handlerAddToBookmarks}
                                    />
                                    <label htmlFor="addToSavedSearches">
                                        <span>Add this advertiser to my bookmarks</span>
                                    </label>
                                </li>

                            </ul>
                        </div>
                        <div className="section-footer">
                            <button type="submit" className="save-alert">
                                Save settings
                            </button>
                            {followAdvertiserOptions.saveAlertStatus.hasErrors && (
                                <div className="error">
                                    Failed to Submit Alert!
                                    <FormError
                                        {...followAdvertiserOptions.saveAlertStatus}
                                        name="exceeded"
                                    />
                                </div>

                            )}
                            {dashboardAdvertisers.currentError.length > 0&& (
                                <div className="error">
                                    <FormError
                                        errors={{'exceeded': [dashboardAdvertisers.currentError]}}
                                        hasErrors={true}
                                        name="exceeded"
                                    />
                                </div>

                            )}
                        </div>
                    </form>
                    {tooltip.component}
                </div>
            </ReactModal>
        </>
    )
}

const modalName = 'follow-advertiser'

const Wrapper = (props) => {
    const modal = useSelector(modalSelector)
    if (modal.name === modalName) {
        return (<FollowAdvertiserModal
            {...modal.params}
            isOpen={modal.name === modalName}
        />)
    }
    return null
}


const showFollowAdvertiserModal = (dispatch, params) => {
    dispatch(resetState())
    dispatch(showModal({
        name: modalName,
        params
    }))
}


export { showFollowAdvertiserModal }
export default Wrapper;
