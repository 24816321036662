import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _ from 'lodash'
import EscapeOutside from 'react-escape-outside'
import { settingsSelector } from "../reducers/settings"
import '../styles/_date_picker.scss'
import {
    add, endOfWeek, format, isAfter, isBefore, isSameDay, isSameMonth, startOfMonth,
    startOfWeek, startOfYear, sub
} from "date-fns"
import { dateToDays, daysToDate } from "../common/crawlDays"
import { showUpgradeModal } from "./modals/UpgradeModal"

function rangeFor(preset, firstIndexDate, lastIndexDate) {
    let result = []

    switch (preset) {
        case "30":
        case "90":
        case "180":
        case "365":
        case "730":
            result = [sub(lastIndexDate, { days: parseInt(preset, 10) - 1 }),
                lastIndexDate];
            break;
        case "new-ads":
            result = [sub(lastIndexDate, { days: 29 }), lastIndexDate]
            break;
        case 'this-month':
            result = [
                startOfMonth(lastIndexDate),
                lastIndexDate
            ]
            break;
        case 'last-month':
            result = [sub(startOfMonth(lastIndexDate), { months: 1 }),
                sub(startOfMonth(lastIndexDate), {days: 1})]
            break;
        case 'last-3-months':
            result = [sub(startOfMonth(lastIndexDate), { months: 3 }),
                sub(startOfMonth(lastIndexDate), {days: 1})]
            break;
        case 'last-6-months':
            result = [sub(startOfMonth(lastIndexDate), { months: 6 }),
                sub(startOfMonth(lastIndexDate), {days: 1})]
            break;
        case 'this-year':
            result = [startOfYear(lastIndexDate), lastIndexDate]
            break;
        case 'last-year':
            result = [sub(startOfYear(lastIndexDate), { years: 1 }),
                sub(startOfYear(lastIndexDate), {days: 1})]
            break;
        case '1000000':
            result = [firstIndexDate, lastIndexDate]
            break
        default:
            result = preset.split('-').map(d => daysToDate(parseInt(d, 10)))
            break
    }
    return result.map(d => dateToDays(d));
}

function isSameRange(r1, r2) {
    if (r1[2] === 'new-ads' && r2[2] !== 'new-ads') {
        return false;
    }
    if (r2[2] === 'new-ads' && r1[2] !== 'new-ads') {
        return false;
    }
    return r1[0] === r2[0] &&
        r1[1] === r2[1]
}


const Day = (props) => {
    const day = props.day
    const classes = [
        'pointer',
        day.inMonth ? 'in-month' : 'not-in-month',
        day.inRange ? 'in-range' : ''
    ]

    const updateDateRange = (startDate, endDate) => {
        props.updateDateRange(
            `${startDate}-${endDate}`)
    }

    const handleClick = () => {
        if (props.role === 'start') {
            if (isBefore(day.date, daysToDate(props.endDate))) {
                updateDateRange(dateToDays(day.date), props.endDate);
            } else {
                updateDateRange(dateToDays(day.date), props.endDate + 2)
            }
        } else {
            if (isAfter(day.date, daysToDate(props.startDate))) {
                updateDateRange(props.startDate, dateToDays(day.date))
            } else {
                updateDateRange(props.startDate - 2, dateToDays(day.date));
            }
        }
    }

    return (
        <td className={classes.join(' ')}>
            {(isBefore(day.date, daysToDate(props.firstIndexDate)) || 
            isAfter(day.date, daysToDate(props.lastIndexDate))) &&
            <span className="disabled-day">
                {format(day.date, 'd')}
            </span>}
            {(isAfter(day.date, daysToDate(props.firstIndexDate - 1)) &&
             isBefore(day.date, daysToDate(props.lastIndexDate + 1))) &&
            <span onClick={handleClick}>
                {format(day.date, 'd')}
            </span>}
        </td>
    )
}

const MonthView = (props) => {

    let _displayMonth = startOfMonth(props.role === 'start' ?
        daysToDate(props.startDate) :
        daysToDate(props.endDate))

    const [displayMonth, setDisplayMonth] = useState(_displayMonth)

    useEffect(() => {
        if (props.role === 'start') {
            setDisplayMonth(startOfMonth(daysToDate(props.startDate)))
        } else {
            setDisplayMonth(startOfMonth(daysToDate(props.endDate)))
        }
    }, [props.role, props.startDate, props.endDate]);

    const handleDisplayMonthChange = (dm) => {
        //if (isSameMonth(dm, props.maxDepth) || isAfter(dm, props.maxDepth)) {
            setDisplayMonth(dm)
        //}
    }

    function daysInMonthView(date) {
        // first sunday
        const startDay = startOfWeek(date)
        // last saturday
        const endDay = endOfWeek(add(date, { weeks: 5 }))

        let currentDay = startDay
        let result = []
        const startDate = daysToDate(props.startDate)
        const endDate = daysToDate(props.endDate)

        while (!isAfter(currentDay, endDay)) {
            result.push({
                inMonth: isSameMonth(currentDay, date),
                inRange: (isSameDay(currentDay, startDate) ||
                    isAfter(currentDay, startDate)) &&
                    (isSameDay(currentDay, endDate) ||
                        isBefore(currentDay, endDate)),
                date: currentDay
            })
            currentDay = add(currentDay, { days: 1 })
        }

        return result
    }

    function partitionByWeeks(days) {
        days = days.slice(0)
        const result = []
        while (days.length) {
            result.push(days.slice(0, 7))
            days = days.slice(7)
        }
        return result
    }

    const displayDays = partitionByWeeks(
        daysInMonthView(displayMonth))

    return (<>
        <div className="month-picker-container">
            <div className="capitalized font-bold left">
                {format(displayMonth, 'MMM yyyy')}
            </div>
            <div className="right">
                {isSameMonth(displayMonth, daysToDate(props.firstIndexDate)) &&
                <span className="pointer back-one-month disabled">
                    <i className="fa-light fa-sharp fa-chevron-left"></i>   
                </span>
                }
                {isAfter(displayMonth, daysToDate(props.firstIndexDate)) &&
                <span className="pointer back-one-month"
                    onClick={() => handleDisplayMonthChange(sub(displayMonth, { months: 1 }))}>
                    <i className="fa-light fa-sharp fa-chevron-left"></i>
                </span>}

                <span className="month-nav-separator"></span>

                {isSameMonth(displayMonth, daysToDate(props.lastIndexDate)) &&
                <span className="pointer forward-one-month disabled">
                    <i className="fa-light fa-sharp fa-chevron-right"></i>   
                </span>}
                {isBefore(displayMonth, startOfMonth(daysToDate(props.lastIndexDate))) &&
                <span className="pointer forward-one-month"
                    onClick={() => handleDisplayMonthChange(add(displayMonth, { months: 1 }))}>
                    <i className="fa-light fa-sharp fa-chevron-right"></i>
                </span>}
            </div>
        </div>
        <div className="clear">
            <table className="month-view-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>S</th>
                        <th>M</th>
                        <th>T</th>
                        <th>W</th>
                        <th>T</th>
                        <th>F</th>
                        <th>S</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {_.times(9, (idx) => <td key={idx}></td>)}
                    </tr>
                    {displayDays.map((week, idx) => <tr key={"week-" + idx}>
                        <td></td>
                        {week.map((day) =>
                            <Day {...props} day={day} key={format(day.date, 'MMM dd yy')}></Day>
                        )}
                        <td></td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </>)
}

const Dropdown = (props) => {
    const settings = useSelector(settingsSelector)
    const lastIndexDate = daysToDate(settings.last_index_date)
    const firstIndexDate = daysToDate(settings.first_index_date)
    const updateDateRange = props.updateDateRange
    
    const handlePresetChange = (preset) => {
        updateDateRange(preset)
        props.applyDateRange(preset)
    }

    const currentRange = [
        props.startDate,
        props.endDate]

    const presetActiveClass = (preset) => {
        return isSameRange(
            rangeFor(preset, firstIndexDate, lastIndexDate).concat(preset),
            currentRange.concat(props.preset)) ?
            "active" : ""
    }

    const upgradeValue = (preset) => {
        return settings.allowed.periods.includes(String(preset)) ?
            "" : "upgrade-required"
    }

    return (
        <div className="dropdown-container">
            <div className="common-date-ranges-container flex">
                <div className="common-date-ranges">
                    <div className="last-n-days-container">
                        <div className="title">Last Days</div>
                        <ul>
                            <li className={presetActiveClass('30')}
                                onClick={() => handlePresetChange('30')}>30</li>
                            <li className={presetActiveClass('90')}
                                onClick={() => handlePresetChange('90')}>90</li>
                            <li className={presetActiveClass('180')}
                                data-value={upgradeValue('180')}
                                onClick={() => handlePresetChange('180')}>180</li>
                            <li className={presetActiveClass('365')}
                                data-value={upgradeValue('365')}
                                onClick={() => handlePresetChange('365')}>365</li>
                            <li className={presetActiveClass('730')}
                                data-value={upgradeValue('730')}
                                onClick={() => handlePresetChange('730')}>730</li>
                            <li></li>
                        </ul>
                    </div>
                    <div className="common-range-presets">
                        <ul>
                            {props.showNewAds &&
                                <li className={presetActiveClass('new-ads')}
                                    onClick={() => handlePresetChange('new-ads')}>New Ads</li>
                            }
                            <li className={presetActiveClass('this-month')}
                                onClick={() => handlePresetChange('this-month')}>This month</li>
                            <li className={presetActiveClass('last-month')}
                                onClick={() => handlePresetChange('last-month')}>Last month</li>
                            <li className={presetActiveClass('last-3-months')}
                                data-value={upgradeValue(180)}
                                onClick={() => handlePresetChange('last-3-months')}>Last 3 months</li>
                            <li className={presetActiveClass('last-6-months')}
                                data-value={upgradeValue(180)}
                                onClick={() => handlePresetChange('last-6-months')}>Last 6 months</li>
                            <li className={presetActiveClass('this-year')}
                                data-value={upgradeValue(365)}
                                onClick={() => handlePresetChange('this-year')}>This year</li>
                            <li className={presetActiveClass('last-year')}
                                data-value={upgradeValue(730)}
                                onClick={() => handlePresetChange('last-year')}>Last year</li>
                            <li className={presetActiveClass('1000000')}
                                data-value={upgradeValue('1000000')}
                                onClick={() => handlePresetChange('1000000')}>All time</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div className="date-range-span">
                        <span>
                            {format(daysToDate(props.startDate), 
                            props.dateFormat)}
                        </span>
                        <span className="mdash">
                            &mdash;
                            </span>
                        <span>
                            {format(daysToDate(props.endDate), 
                            props.dateFormat)}
                        </span>
                    </div>
                    <div className="flex">
                        <div className="calendars">
                            <div>
                                <MonthView {...props} role="start"></MonthView>
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div>
                                <MonthView {...props} role="end"></MonthView>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        {props.preset !== props.originalPreset &&
                        <>
                            <div className="right btn capitalized pointer"
                                onClick={() => props.applyDateRange(props.preset)}>Apply</div>
                            <div className="right btn capitalized pointer"
                                onClick={props.cancelDateRange}>Cancel</div>
                        </>
                        }
                        </div>
                </div>
            </div>
        </div>
    )
}

const DatePicker = (props) => { 
    const dispatch = useDispatch()
    const dateFormat = 'MMM d, yyyy'
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const settings = useSelector(settingsSelector)
    const lastIndexDate = daysToDate(settings.last_index_date)
    const firstIndexDate = daysToDate(settings.first_index_date)

    const [originalStartDate, originalEndDate] = rangeFor(props.preset,
        firstIndexDate, lastIndexDate)
    const originalPreset = props.preset
    const [preset, setPreset] = useState(originalPreset)
    const [chosenStartDate, setChosenStartDate] = useState(originalStartDate);
    const [chosenEndDate, setChosenEndDate] = useState(originalEndDate);
    const [startDate, setStartDate] = useState(originalStartDate)
    const [endDate, setEndDate] = useState(originalEndDate)

    const maxDepth = rangeFor(
        settings.maxPeriod, firstIndexDate, lastIndexDate)[0]

    const updateDateRange = (newPreset) => {
        const [newStartDate, newEndDate] = rangeFor(newPreset,
            firstIndexDate, lastIndexDate)

        if (!isBefore(newEndDate, newStartDate) &&
            !isSameRange([newStartDate, newEndDate, newPreset],
                [startDate, endDate, preset])
        ) {
            setStartDate(newStartDate)
            setEndDate(newEndDate)
            setPreset(newPreset)
        }
    }

    const applyDateRange = (preset) => {
        let [newStartDate, newEndDate] = rangeFor(
            preset, firstIndexDate, lastIndexDate);
        
        if (newStartDate < maxDepth && 
            settings.first_index_date < maxDepth) {

            showUpgradeModal(dispatch, {
                    name: 'dateRange',
                    value: null
                })
            setStartDate(originalStartDate)
            setEndDate(originalEndDate)
            setPreset(originalPreset)
            return
        }

        setIsDropdownOpen(false)
        setChosenStartDate(newStartDate);
        setChosenEndDate(newEndDate);
        window.setTimeout(() => props.onChange({
            dateRangePreset: preset
        }))
    }

    const cancelDateRange = () => {
        setStartDate(originalStartDate)
        setEndDate(originalEndDate)
        setPreset(originalPreset)
        setIsDropdownOpen(false)
    }

    const toggleDropdown = (event) => {
        setIsDropdownOpen(!isDropdownOpen)
        event.stopPropagation()
        event.preventDefault()
    }

    return (
        <div className="date-picker">
            <div className="tight-arrow" onClick={toggleDropdown}>
                <div className="date-picker-button font-regular selectize-control single">
                    <span className="selectize-input" style={{ paddingRight: 10 }}>
                        {preset === 'new-ads' ? 'New Ads' : ''}
                        {preset !== 'new-ads' ? 
                        `${format(daysToDate(chosenStartDate), dateFormat)} - 
                        ${format(daysToDate(chosenEndDate), dateFormat)}`: ""}
                    </span>

                </div>
            </div>
            {isDropdownOpen &&
                <EscapeOutside onEscapeOutside={toggleDropdown}>
                    <Dropdown {...props}
                        dateFormat={dateFormat}
                        startDate={startDate}
                        endDate={endDate}
                        originalPreset={originalPreset}
                        preset={preset}
                        firstIndexDate={settings.first_index_date}
                        lastIndexDate={settings.last_index_date}
                        applyDateRange={applyDateRange}
                        cancelDateRange={cancelDateRange}
                        updateDateRange={updateDateRange}
                        maxDepth={maxDepth}></Dropdown>
                </EscapeOutside>}
        </div>
    );
}

export { rangeFor }
export default DatePicker;