import { useState } from "react";
import { useSelector } from "react-redux";
import {
    useSettings,
    goToUserProfile,
    openInSameTab, goToProductAnnouncements
} from "../../common/Adbeat";
import { settingsSelector } from "../../reducers/settings";
import EscapeOutside from 'react-escape-outside'
import _bookmark from "../../img/Adbeat Icons/files-24px-outline_bookmark.png"
import _adLocker from '../../img/Adbeat Icons/files-24px-outline_folder-settings-97.png'
import _userProfile from '../../img/Adbeat Icons/users-24px-outline_square-31.png'
import _alertSettings from '../../img/Adbeat Icons/ui-24px-outline-1_bell-53.png'
import _scheduledReports from '../../img/Adbeat Icons/calendar.png'
import _learningCenter from '../../img/Adbeat Icons/design-24px-outline_book-open.png'
import _productUpdates from '../../img/Adbeat Icons/ui-24px-outline-1_notification-69.png'
import _logOut from '../../img/Adbeat Icons/arrows-24px-outline-4_logout.png'

const GearMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const settings = useSelector(settingsSelector)
    useSettings(settings)

    function open(path) {
        return function () {
            openInSameTab(path);
        };
    }

    const entries = [
        {
            title: 'Bookmarks',
            action: open('/bookmarks'),
            icon: _bookmark,
            enabled: true
        },
        {
            title: 'Ad Locker',
            action: open('/ad_locker'),
            icon: _adLocker,
            enabled: true
        },
        {
            title: 'User Profile',
            action: goToUserProfile,
            icon: _userProfile,
            enabled: true
        },
        {
            title: 'Alert Settings',
            action: open('/alerts'),
            icon: _alertSettings,
            enabled: true
        },
        {
            title: 'Report Settings',
            action: open('/scheduled-reports'),
            icon: _scheduledReports ,
            enabled: settings.scheduled_reports
        },
        {
            title: 'Learning Center',
            action: open('https://help.adbeat.com/knowledge-base-category/how-to-use-adbeat/'),
            icon: _learningCenter,
            enabled: true
        }
    ];

    entries.push({
        title: 'Product Updates',
        action: goToProductAnnouncements,
        icon: _productUpdates,
        enabled: true
    });

    if (!settings.isWinmoUser) {
        entries.push({
            title: 'Log Out',
            action: function () {
                window.location.href = '/user/logout';
            },
            icon: _logOut,
            enabled: true
        });
    }

    var columns = [], itemsPerCol = 4;
    var i, len;
    for (i = 0, len = entries.length; i < len; i += itemsPerCol) {
        columns.push(entries.slice(i, i + itemsPerCol));
    }

    return (
        <>
            <div id="gear-icon"
                className={`tool left pointer ${isMenuOpen ? 'active' : ''}`}
                onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <i className="fa-light fa-sharp fa-gear fa-2x"></i>
            </div>

            {isMenuOpen &&
                <EscapeOutside onEscapeOutside={() => setIsMenuOpen(false)}>
                    <div id="gear-icon-menu">
                        <div className="gear-menu-container">
                            {columns.map((column, idx) =>
                                <div className="column left" key={idx}>
                                    {column.map((entry, idx) => {
                                        if (entry.enabled) {
                                            return (<div className="item pointer" onClick={entry.action}
                                                key={idx}>
                                                <div className="image-container left clear">
                                                    <img src={entry.icon} alt={entry.title} />
                                                </div>
                                                <div className="text left">{entry.title}</div>
                                            </div>)
                                        }
                                        return (<div className="item disabled" key={idx}>
                                            <div className="image-container left clear">
                                                <img src={entry.icon} alt={entry.title} />
                                            </div>
                                            <div className="text left">{entry.title}</div>
                                        </div>)
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="clear"></div>

                    </div>
                </EscapeOutside>
            }
        </>
    );
}

export default GearMenu;
