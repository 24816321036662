import numeral from 'numeral'

const Trend = (props) => {
    
    const isNew = props.trend === 20000

    const hasEnded = props.trend === -100;
    
    const isEphemeral = props.trend === -200;

    const isTrendingUp = props.trend > 0;

    const isFlat = props.trend === 0;

    const isTrendingDown = props.trend < 0;

    const trend = numeral(Math.abs(props.trend / 100)).format('0%')

    return ( 
        <>
            {isNew && <span className="direction"><i className="fa-light fa-sharp fa-chevron-up"></i> new</span>}
            
            {hasEnded && <span className="direction"><i className="fa-light fa-sharp fa-chevron-down"></i> ended</span>}
            
            {isFlat && <span className="direction"> -</span>}
            
            {!(isNew || isFlat || hasEnded || isEphemeral) && <span className="direction">
                
                <i className={`fa 
                    ${isTrendingUp ? 'fa-chevron-up' : ''}
                    ${isTrendingDown ? 'fa-chevron-down' : ''}`}></i>
                <span> {trend}</span>
            </span>}
        </>
    );
}
 
export default Trend;