import {createSlice} from '@reduxjs/toolkit'


const performanceSlice = createSlice({
    name: 'performance',
    initialState: {
        events: [],
    },
    reducers: {
        performanceEvent(state, action) {
            state.events.push({
                'name': action.payload.name,
                'time': action.payload.time,
                'url': window.location.pathname
            })
        }
    }
})


export const {performanceEvent} = performanceSlice.actions

export default performanceSlice.reducer