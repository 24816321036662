import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserTopChannels = createAsyncThunk(
    'fetchAdvertiserChannels',
    async (arg) => {
        const params = {
            'rows': 100,
            'sortOrder': 'desc',
            ...arg.query
        }

        const response = await axios.get("/data-api/channels", {
            params: params,
            cancelToken: getCancelSource().token
        })
        return response.data
    }
)

const channelsSlice = createSlice({
    name: 'advertiserTopChannels',
    initialState: {
        status: 'idle',
        data: []
    },
    reducers: {
        resetAdvertiserChannels(state) {
            state.status = 'idle'
            state.data = []
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserTopChannels.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvertiserTopChannels.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})

const selectAdvertiserTopChannels = (state) => state.advertiserProfile.overview.byChannels
export const {resetAdvertiserChannels} = channelsSlice.actions
export {selectAdvertiserTopChannels, fetchAdvertiserTopChannels}
export default channelsSlice.reducer