import { useDispatch, useSelector } from 'react-redux'
import { selectProducts } from '../../reducers/headerProducts'
import {showUpgradeModal} from "../modals/UpgradeModal";

const ProductList = (props) => {
    const products = useSelector(selectProducts)
    const dispatch = useDispatch()

    const handleClick = (product) => {
        if (product.productId) {
            showUpgradeModalForProduct(product.productId)
        } else {
            window.Intercom('showNewMessage', "Hi, I'm interested in adding more seats to my Enterprise account.")
        }
    }

    const showUpgradeModalForProduct = (productId) => {
        showUpgradeModal(dispatch, {
            name: 'more-data-' + productId,
            value: null
        })
    }

    return (
        products.areProductsShown && (
            <ul id="product-list" className="product-list">
                {products.products.map((product) => (
                    <li key={product.name} onClick={(e) => {
                        e.preventDefault()
                        handleClick(product)
                    }}>
                        <span className="anchor">
                            {product.name}
                        </span>
                    </li>
                ))}
            </ul>)
    )
};

export default ProductList;
