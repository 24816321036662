import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../common/axiosRateLimit'

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchPublisherProfileChannels = createAsyncThunk(
    'publisherOverviewChannels',
    async (arg) => {
        const response = await axios.get("/data-api/channels", {
            params: {
                ...arg.query,
                rows: 100
            },
            cancelToken: getCancelSource().token
        })

        return response.data
    })


const publisherProfileChannelsSlice = createSlice({
    name: 'publisherProfileChannels',
    initialState: {
        status: 'idle',
        data: [],
    },
    reducers: {
        resetChannels(state) {
            state.status = 'idle'
            state.data = []
        }
    },
    extraReducers: {
        [fetchPublisherProfileChannels.pending]: (state) => {
            state.status = 'loading'
            state.data = []
        },
        [fetchPublisherProfileChannels.fulfilled]: (state, action) => {
            state.status = 'done'
            state.data = action.payload.rows
        }
    }
})


const publisherProfileChannelsSelector = state => state.publisherProfile.channels
export { fetchPublisherProfileChannels, publisherProfileChannelsSelector }
export const {resetChannels} = publisherProfileChannelsSlice.actions
export default publisherProfileChannelsSlice.reducer