import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../common/axiosRateLimit'
import qs from "qs";


class GroupSliceFactory {
    constructor(entity, entities, replaceRequests) {
        replaceRequests = replaceRequests || {}
        let self = this;

        let cancelSource = axios.CancelToken.source()

        const getCancelSource = () => cancelSource

        this.fetchGroups = replaceRequests.fetchGroups || createAsyncThunk('fetch' + entity + 'Groups',
            async () => {
                const response = await axios.get("/callisto/" + entity + "-groups")
                return response.data
            })

        this.addGroup = replaceRequests.addGroup || createAsyncThunk('add' + entity + 'Group',
            async (arg) => {
                let path = '/callisto/' + entity + '-groups/add';

                let query = {
                    ...arg.query,
                    name: 'Unnamed Group'
                }

                query[entities] = ''

                const response = await axios.post(path, qs.stringify(query))
                return response.data
            })

        this.editGroup = replaceRequests.editGroup || createAsyncThunk('edit' + entity + 'Group',
            async (arg) => {
                let path = '/callisto/' + entity + '-groups/edit';
                const response = await axios.post(path, qs.stringify({
                    ...arg.query,
                    platform: arg.query.platform || 'all',
                    country: arg.query.country || 'us'
                }))
                return response.data
            })

        this.deleteGroup = replaceRequests.deleteGroup || createAsyncThunk('delete' + entity + 'Group',
            async (arg) => {
                let path = '/callisto/' + entity + '-groups/delete';
                const response = await axios.post(path, qs.stringify({
                    id: arg.id
                }))
                return response.data
            })

        this.fetchSuggestions = createAsyncThunk(entity + 'Suggestions',
            async (arg) => {
                let path = '/data-api/search/' + entities;

                const response = await axios.get(path, {
                    params: arg.query,
                    cancelToken: getCancelSource().token
                })
                return response.data
            })

        this.slice = createSlice({
            name: entities,
            initialState: {
                status: 'idle',
                data: [],
                suggestions: [],
                addedGroup: null,
                errors: []
            },
            reducers: {
                resetAddedGroup(state) {
                    state.addedGroup = null
                },
                resetSuggestions(state) {
                    state.suggestions = []
                },
                removeGroup(state, action) {
                    state.data = state.data.filter((item) => {
                        return item.id !== action.payload.group.id
                    })
                    action.asyncDispatch(self.deleteGroup({
                        id: action.payload.group.id
                    }))
                },
                removeGroupMember(state, action) {
                    let newGroup = null
                    state.data = state.data.map((item) => {
                        if (item.id === action.payload.group.id) {
                            item[entities] = item[entities].filter((subItem) => {
                                return subItem !== action.payload.removeTerm
                            })
                            newGroup = item
                        }
                        return item
                    })

                    if (newGroup) {
                        let query = {...newGroup}
                        query[entities] = newGroup[entities].join(',')

                        action.asyncDispatch(self.editGroup({query}))
                    }
                },
                addGroupMember(state, action) {
                    let newGroup = null

                    state.data = state.data.map((item) => {
                        if (item.id === action.payload.group.id) {
                            if (!item[entities].includes(action.payload.newTerm)) {
                                item[entities].push(action.payload.newTerm)
                            }
                            newGroup = item
                        }
                        return item
                    })

                    if (newGroup) {
                        let query = {...newGroup}
                        query[entities] = newGroup[entities].join(',')

                        action.asyncDispatch(self.editGroup({query}))
                    }
                },
                setGroupName(state, action) {
                    let newGroup = null

                    state.data = state.data.map((item) => {
                        if (item.id === action.payload.group.id) {
                            item.name = action.payload.name
                            newGroup = item
                        }
                        return item
                    })

                    if (newGroup) {
                        let query = {...newGroup}
                        query[entities] = newGroup[entities].join(',')

                        action.asyncDispatch(self.editGroup({query}))
                    }
                },
                setGroupCountry(state, action) {
                    let newGroup = null

                    state.data = state.data.map((item) => {
                        if (item.id === action.payload.group.id) {
                            item.country = action.payload.country
                            newGroup = item
                        }
                        return item
                    })

                    if (newGroup) {
                        let query = {...newGroup}
                        query[entities] = newGroup[entities].join(',')

                        action.asyncDispatch(self.editGroup({query}))
                    }
                },
                setGroupField(state, action) {
                    state.data = state.data.map((item) => {
                        if (item.id === action.payload.id) {
                            item[action.payload.key] = action.payload.value
                        }
                        return item
                    })
                },
                resetErrors(state) {
                    state.errors = []
                }
            },
            extraReducers: {
                [self.fetchGroups.pending]: (state) => {
                    state.status = 'pending'
                },
                [self.fetchGroups.fulfilled]: (state, action) => {
                    if ('errors' in action.payload) {
                        state.status = 'error'
                        return
                    }
                    state.status = 'done'
                    state.data = action.payload.groups
                },
                [self.fetchSuggestions.fulfilled]: (state, action) => {
                    let totalScore = action.payload.rows.reduce((sum, suggetion) => sum + suggetion.score, 0) || 1

                    state.suggestions = action.payload.rows.map(function (suggestion) {
                        let result = {
                            ...suggestion
                        }
                        result.weight = result.score / totalScore;
                        result.label = result.suggestion
                        return result;
                    })
                },
                [self.deleteGroup.fulfilled]: (state, action) => {
                    if (action.payload.status !== 'ok') {
                        state.errors = [
                            "Failed to remove group"
                        ]
                    }
                },
                [self.addGroup.fulfilled]: (state, action) => {
                    if (action.payload.status === 'ok') {
                        state.addedGroup = action.payload.group
                        state.data.unshift(state.addedGroup)
                    } else {
                        state.errors = [
                            "Failed to add group"
                        ]
                    }
                },
                [self.editGroup.fulfilled]: (state, action) => {
                    if (action.payload.status === 'ok') {
                        state.data = state.data.map((item) => {
                            if (item.id === action.payload.group.id) {
                                return action.payload.group
                            }
                            return item
                        })
                    } else {
                        state.errors = [
                            "Failed to edit group"
                        ]
                    }
                }
            }
        })

        this.selectGroupsData = (state) => state.groupManager[entities]
    }
}

export default GroupSliceFactory