import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from '../../../../common/axiosRateLimit'
import qs from "qs";
import * as d3 from "d3";


let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource


const fetchPublisherUITopAdPath = createAsyncThunk('fetchPublisherUITopAdPath',
    async (arg) => {
        cancelSource.cancel()
        cancelSource = axios.CancelToken.source()

        const response = await axios.post("/data-api/adPaths",
            qs.stringify({
                publisher: arg.params.publisherSet,
                network: arg.params.network,
                country: arg.params.country,
                platform: arg.params.platform,
                startDate: arg.params.startDate,
                endDate: arg.params.endDate,
                metrics: arg.params.metrics,
                sortBy: 'sumAdSpend',
                rows: 100
            }), {
                cancelToken: getCancelSource().token
            }
        )
        return response.data
    })


const initialState = {
    status: 'idle',
    data: []
}


const publisherUiAdPathSlice = createSlice({
    name: 'adPath',
    initialState,
    reducers: {
        resetAdPath(state) {
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()

            return initialState
        },
    },
    extraReducers: {
        [fetchPublisherUITopAdPath.rejected]: () => {},
        [fetchPublisherUITopAdPath.pending]: (state) => {
            state.status = 'pending'
        },
        [fetchPublisherUITopAdPath.fulfilled]: (state, action) => {
            if ('rows' in action.payload) {
                let total = action.payload.rows.reduce((s, item) => {
                    return s + item.sumAdSpend
                }, 0) || 1

                let count = action.payload.rows.length

                let hasOther = false
                if (count > 10) {
                    count = 11
                    hasOther = true
                }

                let data = action.payload.rows.slice(0, 10).map((item, index) => {
                    let t = 1 - index / (count - 0.999)
                    return {
                        ...item,
                        name: 'adPath-' + item.adPath,
                        share: item.sumAdSpend / total,
                        other: false,
                        color: d3.interpolateSpectral(t * 0.8 + 0.1)
                    }
                })

                if (hasOther) {
                    let sumAdSpend = action.payload.rows.slice(10).reduce((s, item) => {
                        return s + item.sumAdSpend
                    }, 0)

                    data.push({
                        name: 'adPath-Other',
                        other: true,
                        sumAdSpend,
                        share: sumAdSpend / total,
                        color: d3.interpolateSpectral(0.1)
                    })
                }

                state.data = data
                state.status = 'done'
            } else {
                state.status = 'error'
            }
        }
    }
})


const selectPublisherUiAdPath = (state) => state.publisherUiOverview.adPath


export {
    selectPublisherUiAdPath,
    fetchPublisherUITopAdPath
}

export const {
    resetAdPath
} = publisherUiAdPathSlice.actions


export default publisherUiAdPathSlice.reducer