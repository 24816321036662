import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../common/axiosRateLimit";
import { showUpgradeModal } from "../../../../components/modals/UpgradeModal";

let cancelSource = axios.CancelToken.source()
const getCancelSource = () => cancelSource

const fetchAdvertiserTopNetworks = createAsyncThunk(
    'fetchAdvertiserNetworks',
    async (arg) => {
        const params = {
            rows: 150,
            'sortOrder': 'desc',
            ...arg.query
        }

        const response = await axios.get("/data-api/networks", {
            params: params,
            cancelToken: getCancelSource().token
        })

        return response.data
    }
)

const createTrendlineReport = createAsyncThunk(
    'createAdvTrendlineReport',
    (arg) => {
        var networkMapping = arg.networks.mapping;
        var framename = 'trendline-downloads-frame';
        var iframe = document.createElement('iframe');
        iframe.name = framename;
        iframe.style.display = 'none';



        var form = document.createElement('form');
        form.id = 'trendline-download'
        form.action = '/callisto/reports/trendline';
        form.method = 'POST';
        form.target = framename;

        function hiddenInput(name) {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = name;
            form.appendChild(input);
            return input;
        }

        var json = hiddenInput('json');
        var profileId = hiddenInput('profileId');
        var profileName = hiddenInput('profileName');
        var countryId = hiddenInput('countryId');
        var networkName = hiddenInput('networkName');
        var startDate = hiddenInput('startDate');
        var endDate = hiddenInput('endDate');
        var chartDays = hiddenInput('chartDays');
        var topNetworks = hiddenInput('topNetworks');
        var adMetricIdInput = hiddenInput('adMetricId');

        iframe.appendChild(form);
        document.body.appendChild(iframe);

        function makeDownload(adMetricId) {
            adMetricId = adMetricId || arg.settings.adMetricId;
            return function () {
                if (!arg.settings.exportable_data) {
                    arg.dispatch(showUpgradeModal({
                        name: 'reports',
                        value: null
                    }))
                    return;
                }

                topNetworks.value = JSON.stringify(arg.topNetworks);
                json.value = JSON.stringify(arg.trendline)
                profileId.value = arg.profileId
                profileName.value = arg.profileName;
                countryId.value = arg.country
                networkName.value = networkMapping[arg.network];
                startDate.value = arg.startDate;
                endDate.value = arg.endDate;
                chartDays.value = arg.chartDays;
                adMetricIdInput.value = adMetricId;
                form.submit();
            };
        }
        
        makeDownload(arg.adMetric)()
    }
)

const networksSlice = createSlice({
    name: 'advertiserTopNetworks',
    initialState: {
        status: 'idle',
        dataByMetric: {
            sumAdSpend: [],
            sumImpressions: []
        },
        data: []
    },
    reducers: {
        resetAdvertiserNetworks(state) {
            state.status = 'idle'
            state.data = []
            state.dataByMetric = {
                sumAdSpend: [],
                sumImpressions: []
            }
            cancelSource.cancel()
            cancelSource = axios.CancelToken.source()
        }
    },
    extraReducers: {
        [fetchAdvertiserTopNetworks.pending]: (state) => {
            state.status = 'pending'
            state.data = []
        },
        [fetchAdvertiserTopNetworks.fulfilled]: (state, action) => {
            state.status = 'done'

            let metrics = action.meta.arg.query.metrics.split(',').filter(
                item => ['sumAdSpend', 'sumImpressions'].includes(item)
            )

            metrics.forEach(metric => {
                const grouped = {}

                action.payload.rows.forEach(n => {
                    grouped[n.network] = grouped[n.network] || {}
                    const entry = grouped[n.network]
                    entry.byPlatform = entry.byPlatform || {}
                    entry.byPlatform[n.platform] = n[metric]
                    entry.value = entry.value || 0
                    entry.value += n[metric]
                    entry.data = entry.value
                    entry.network = n.network
                    entry.networkName = action.meta.arg.networkName(n.network)
                });
                state.dataByMetric[metric] = Object.keys(grouped).map(k => grouped[k])
                    .sort((a, b) => b.value - a.value)
            })

            state.data = state.dataByMetric[action.meta.arg.query.sortBy]
        }
    }
})

const selectAdvertiserTopNetworks = (state) => state.advertiserProfile.overview.byNetwork
export const { resetAdvertiserNetworks } = networksSlice.actions
export {
    selectAdvertiserTopNetworks,
    fetchAdvertiserTopNetworks,
    createTrendlineReport
}
export default networksSlice.reducer