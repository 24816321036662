import { combineReducers } from "redux";
import overview from "./overview/overviewReducer";
import ads from './top-ads/adsReducer'
import landingPages from './landing-pages/landingPageSlice'
import campaigns from './campaigns/campaignsSlice'
import campaignTopAds from './campaigns/topAdsSlice'
import publishers from './publishers/publisherSlice'
import totals from './metaSlice'
import brands from './brandsSlice'
import menu from './menu/menuReducer'
import publisherChannels from "./publishers/channelSlice";
import campaignPublishers from './campaigns/topPublishersSlice'

const reducer = combineReducers({
    totals,
    brands,
    overview,
    ads,
    landingPages,
    campaigns,
    campaignTopAds,
    campaignPublishers,
    publishers,
    publisherChannels,
    menu
})

export default reducer
